import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Text, Group, Stack, Title } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import { PageSkeleton } from '@/design-system/PageSkeleton';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import { engagementManagerRoles, useHasRole } from '@/hooks/useHasRole';
import { useMedplum } from '@medplum/react';
import { useDocumentTitle } from '@mantine/hooks';

const AdminNavCard: React.FC<{ label: string; description: string; onClick: () => void }> = ({
  label,
  description,
  onClick,
}) => {
  return (
    <PageSkeleton>
      <Group justify="space-between" align="center" onClick={onClick} style={{ cursor: 'pointer' }}>
        <Stack>
          <Title c="black" fz={'h4'}>
            {label}
          </Title>
          <Text fz={'h6'}>{description}</Text>
        </Stack>
        <IconChevronRight size={16} stroke={1.5} />
      </Group>
    </PageSkeleton>
  );
};

export const AdminPage: React.FC = () => {
  useDocumentTitle('Admin - Care Hub');
  const medplum = useMedplum();
  const navigate = useNavigate();
  const location = useLocation();
  const flags = useFeatureFlags();
  const isAdmin = medplum.isProjectAdmin();
  const isOutreachManager = useHasRole(engagementManagerRoles);

  return (
    <PageSkeleton withPaper={false}>
      {location.pathname === '/Admin' && isAdmin && (
        <Stack>
          {flags.EngagementPodMapping && (
            <AdminNavCard
              label="Engagement Pods"
              description="Create and manage engagement pods"
              onClick={() => navigate('/Admin/EngagementPods')}
            />
          )}

          {flags.MarketsAndPayors && (
            <AdminNavCard
              label="Markets and Payors"
              description="Configure features by market and payor"
              onClick={() => navigate('/Admin/MarketsAndPayors')}
            />
          )}
        </Stack>
      )}

      {/* // outreach managers should only see the below content */}
      {location.pathname === '/Admin' && isOutreachManager && (
        <AdminNavCard
          label="Engagement Pod Mapping"
          description="Create and manage engagement pods"
          onClick={() => navigate('/Admin/EngagementPods')}
        />
      )}
      <Outlet />
    </PageSkeleton>
  );
};
