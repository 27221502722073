import { Grid, Group, Paper, Text, TextInput } from '@mantine/core';
import React from 'react';
import { Coding } from '@medplum/fhirtypes';
import { ContactRelationship, ContactType } from 'const-utils';
import { ValueSetDropdown } from '@/design-system/ValueSetDropdown';
import { GetInputPropsReturnType } from '@mantine/form/lib/types';

/**
 * Props for the `ContactRelationshipDefinitionForm` component.
 * //TODO: REFACTOR!!!!
 * @param patientName - The name of the patient to whom the contact is related.
 * @param selectedContactType - The currently selected contact type.
 * @param onSelectContactType - Callback function to handle contact type selection.
 * @param isOtherContactType - Flag indicating if the "Other" contact type is selected.
 * @param onSelectCustomContactType - Callback function to handle custom contact type input.
 * @param contactTypeError - Error message for the contact type selection.
 * @param otherContactTypeError - Error message for the custom contact type input.
 * @param otherContactFormInputProps - Props for the custom contact type input field.
 * @param selectedRelationship - The currently selected relationship to the patient.
 * @param onSelectRelationship - Callback function to handle relationship selection.
 * @param isOtherRelationship - Flag indicating if the "Other" relationship is selected.
 * @param onSelectCustomRelationship - Callback function to handle custom relationship input.
 * @param relationshipError - Error message for the relationship selection.
 * @param otherRelationshipError - Error message for the custom relationship input.
 * @param otherRelationshipFormInputProps - Props for the custom relationship input field.
 * @param required - Flag indicating if the fields are required.
 *
 * @example
 * ```tsx
 * <ContactRelationshipDefinitionForm
 *  patientName="John Doe"
 * selectedContactType={selectedContactType}
 * onSelectContactType={handleContactTypeSelect}
 * isOtherContactType={isOtherContactType}
 * onSelectCustomContactType={handleCustomContactTypeSelect}
 * contactTypeError={'Error if contact type is not selected'}
 * otherContactTypeError={'Error if other contact type is not defined'}
 * otherContactFormInputProps={otherContactFormInputProps}
 * selectedRelationship={selectedRelationship}
 * onSelectRelationship={handleRelationshipSelect}
 * isOtherRelationship={isOtherRelationship}
 * onSelectCustomRelationship={handleCustomRelationshipSelect}
 * relationshipError={'Error if relationship is not selected'}
 * otherRelationshipError={'Error if relationship is not defined'}
 * otherRelationshipFormInputProps={otherRelationshipFormInputProps}
 * />
 * ```
 */

interface ContactRelationshipDefinitionFormProps {
  patientName: string | null;
  selectedContactType?: Coding;
  onSelectContactType: (item: Coding) => void;
  isOtherContactType?: boolean;
  onSelectCustomContactType: (value: string) => void;
  contactTypeError?: string;
  otherContactTypeError?: string;
  otherContactFormInputProps?: GetInputPropsReturnType;
  selectedRelationship?: Coding;
  onSelectRelationship: (item: Coding) => void;
  isOtherRelationship?: boolean;
  onSelectCustomRelationship: (value: string) => void;
  relationshipError?: string;
  otherRelationshipError?: string;
  otherRelationshipFormInputProps?: GetInputPropsReturnType;
  required?: boolean;
}

export const ContactRelationshipDefinitionForm = ({
  patientName,
  selectedContactType,
  onSelectContactType,
  isOtherContactType,
  onSelectCustomContactType,
  contactTypeError,
  otherContactTypeError,
  otherContactFormInputProps,
  selectedRelationship,
  isOtherRelationship,
  onSelectRelationship,
  onSelectCustomRelationship,
  relationshipError,
  otherRelationshipError,
  otherRelationshipFormInputProps,
  required = true,
}: ContactRelationshipDefinitionFormProps) => {
  return (
    <Paper miw="75%" mx="auto" bg="gray.0" p="md" mb="lg" bd="1 solid gray.3" radius={8}>
      <Group justify="center">
        <Text size="md" mb="md">
          Define this contact's relationship to {patientName?.length ? `${patientName}` : 'the patient'}
        </Text>
      </Group>
      <Grid>
        <Grid.Col span={5}>
          <ValueSetDropdown
            valueSet={ContactType}
            label="Contact type"
            placeholder="Please select"
            onSelect={onSelectContactType}
            required={required}
            selectedItem={selectedContactType}
            cypressIdentifier={'contact-type-select'}
            error={contactTypeError}
          />
        </Grid.Col>
        <Grid.Col span={7}>
          <ValueSetDropdown
            valueSet={ContactRelationship}
            label="Relationship to patient"
            placeholder="Please select"
            selectedItem={selectedRelationship}
            onSelect={onSelectRelationship}
            required={required}
            cypressIdentifier={'relationship-select'}
            error={relationshipError}
          />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={5}>
          {isOtherContactType && (
            <TextInput
              withAsterisk
              required={isOtherContactType}
              label="Specify contact type"
              {...otherContactFormInputProps}
              onBlur={(event) => onSelectCustomContactType(event.currentTarget.value)}
              error={otherContactTypeError}
            />
          )}
        </Grid.Col>
        <Grid.Col span={7}>
          {isOtherRelationship && (
            <TextInput
              withAsterisk
              required={isOtherRelationship}
              label="Specify relationship to patient"
              {...otherRelationshipFormInputProps}
              onBlur={(event) => {
                onSelectCustomRelationship(event.currentTarget.value);
              }}
              error={otherRelationshipError}
            />
          )}
        </Grid.Col>
      </Grid>
    </Paper>
  );
};
