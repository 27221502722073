import { UseFormReturnType, useForm } from '@mantine/form';
import { HasContactFields } from '../../shared/ContactReviewForm';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { validateEmail } from 'imagine-dsl/utils/strings';
import { isDuplicatePhoneNumber } from '@/utils/contactReviewUtils';
import { validationMessages, validateContactType, validateRelationship } from './PatientContactsValidation';
import { Coding } from 'medplum-gql';

const PatientContactForm = (
  initialForm: HasContactFields,
): UseFormReturnType<HasContactFields, (values: HasContactFields) => HasContactFields> => {
  const form = useForm<HasContactFields>({
    initialValues: initialForm,
    validateInputOnBlur: true,
    validate: {
      contact: {
        firstName: (value) => (value ? null : validationMessages.required.firstName),
        lastName: (value) => (value ? null : validationMessages.required.lastName),
        emailAddress: (value) => {
          if (!value) return null;
          return validateEmail(value) ? null : validationMessages.invalid.email;
        },
        language: (value, values) => {
          if (values.contact.usePatientLanguage) return null;
          return value ? null : validationMessages.required.language;
        },
        contactType: (value) => {
          return validateContactType(value);
        },
        relationship: (value: Coding | undefined) => {
          return validateRelationship(value);
        },
        phoneNumbers: {
          number: (value, values, path) => {
            if (!value) return validationMessages.required.phone;

            if (!isValidPhoneNumber(value, 'US')) return validationMessages.invalid.phone;

            const valueIndex = Number.parseInt(path.replace(/[^0-9]/g, ''), 10);

            if (isDuplicatePhoneNumber(value, values.contact.phoneNumbers, valueIndex)) {
              return validationMessages.invalid.duplicatePhone;
            }
            return null;
          },
        },
        address: {
          line1: (value, values) =>
            value || values.contact.usePatientAddress ? null : validationMessages.required.address.line1,
          city: (value, values) =>
            value || values.contact.usePatientAddress ? null : validationMessages.required.address.city,
          state: (value, values) =>
            value || values.contact.usePatientAddress ? null : validationMessages.required.address.state,
          zip: (value, values) =>
            value || values.contact.usePatientAddress ? null : validationMessages.required.address.postalCode,
        },
      },
    },
  });

  return form;
};

export default PatientContactForm;
