import { useMedplum } from '@medplum/react';
import { OrganizationType } from 'const-utils/codeSystems/ImaginePediatrics';
import { useCallback } from 'react';
import { notifications } from '@mantine/notifications';
import { Extension } from '@medplum/fhirtypes';

type MarketExtensions = {
  athenaId?: string;
  prapare?: boolean;
  acuteCare?: boolean;
};

export const useFetchMarkets = () => {
  const medplum = useMedplum();
  const fetchMarkets = useCallback(async () => {
    try {
      const marketResponse = await medplum.search(
        'Organization',
        {
          _tag: OrganizationType.Market,
        },
        {
          cache: 'no-cache',
        },
      );
      const markets = marketResponse.entry
        ? marketResponse.entry.map((entry) => {
            const extensions = entry.resource?.extension || [];
            const { athenaId, prapare, acuteCare } = extractMarketExtensions(extensions);

            return {
              name: entry.resource?.name || '',
              status: entry.resource?.active || false,
              extension: extensions,
              id: entry.resource?.id || '',
              athenaId,
              prapare,
              acuteCare,
            };
          })
        : [];
      return markets;
    } catch (error) {
      notifications.show({
        title: 'Error fetching Market responses:',
        message: error as string,
        color: 'status-error',
      });
      return [];
    }
  }, [medplum]);

  return { fetchMarkets };
};

export const extractMarketExtensions = (extensions: Extension[]): MarketExtensions => {
  const athenaId = extensions.find((ext) => ext.url === 'https://imaginepediatrics.org/athena-id')?.valueString;
  const prapare = extensions.some(
    (ext) =>
      ext.url === 'https://imaginepediatrics.org/feature' && ext.valueCode === 'prapare' && ext.valueBoolean === true,
  );
  const acuteCare = extensions.some(
    (ext) =>
      ext.url === 'https://imaginepediatrics.org/feature' &&
      ext.valueCode === 'acute-care-only' &&
      ext.valueBoolean === true,
  );

  return { athenaId, prapare, acuteCare };
};
