import React from 'react';
import { Modal, ModalProps } from '@mantine/core';

type ModalWrapperProps = ModalProps & {
  title: string;
  children: React.ReactNode;
};

export const ModalWrapper: React.FC<ModalWrapperProps> = ({ title, children, ...modalProps }) => {
  return (
    <Modal.Root {...modalProps} size="xl" padding="xl" radius="xl">
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title fz={20} c="imagine-green">
            {title}
          </Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
