import React, { useCallback, useEffect } from 'react';
import { useMedplum } from '@medplum/react';
import { notifications } from '@mantine/notifications';
import { System } from 'const-utils';
import { OrganizationType } from 'const-utils/codeSystems/ImaginePediatrics';
import { ORG_INSURANCE } from 'const-utils/utils/constants';

export const useUpdatePayor = () => {
  const medplum = useMedplum();
  const updatePayor = useCallback(
    async (id: string, payorName: string, active: boolean) => {
      try {
        const updatePayor = await medplum.updateResource({
          id: id,
          name: payorName,
          resourceType: 'Organization',
          active: active,
          type: [
            {
              coding: [
                {
                  system: System.OrganizationType,
                  code: 'ins',
                  display: 'Insurance Company',
                },
              ],
            },
          ],
          identifier: [
            {
              system: System.Organization,
              value: `${ORG_INSURANCE}${payorName}`,
            },
          ],
          meta: {
            tag: [
              {
                system: System.OrganizationType,
                code: OrganizationType.HealthPlan,
              },
            ],
          },
        });
        return updatePayor;
      } catch (error) {
        notifications.show({
          title: 'Error',
          message: 'Failed to update payor',
          color: 'status-error',
        });
        return null;
      }
    },
    [medplum],
  );
  return { updatePayor };
};

export const useFetchExistingPayors = () => {
  const medplum = useMedplum();
  const [existingPayorNames, setExistingPayorNames] = React.useState<string[]>([]);

  useEffect(() => {
    const fetchExistingPayors = async () => {
      try {
        const response = await medplum.search('Organization', {
          _tag: OrganizationType.HealthPlan,
        });
        const payors = response.entry ? response.entry.map((entry) => entry.resource?.name) : [];
        setExistingPayorNames(payors as OrganizationType[]);
      } catch (error) {
        notifications.show({
          title: 'Error',
          message: 'Failed to fetch existing payors.',
          color: 'status-error',
        });
      }
    };
    fetchExistingPayors();
  }, [medplum]);
  return { existingPayorNames };
};
