import React from 'react';
import { Modal, Text, Table, Radio, Group, ThemeIcon } from '@mantine/core';
import { useAddPostalCodesModal } from '../AddPostalCodesModalContext';
import { TitleWithClose } from '@/design-system/modal/TitleWithClose';
import { ButtonContainer } from '@/design-system/modal/ButtonContainer';
import { DuplicationAction } from '../constants';
import { IconArrowsRightLeft, IconX, IconAlertTriangle } from '@tabler/icons-react';

interface ResolveDuplicatePostalCodesProps {
  podName: string;
}

export const ResolveDuplicatePostalCodes = ({ podName }: ResolveDuplicatePostalCodesProps) => {
  const {
    handleClosePostalCodesModal,
    postalCodesToAdd,
    setPostalCodesToAdd,
    postalCodeDuplicates,
    setPostalCodeDuplicates,
    setDuplicatesResolved,
  } = useAddPostalCodesModal();

  const handleDuplicateActionChange = (newAction: string, codeToUpdate: string) => {
    setPostalCodeDuplicates((prevPostalCodes) =>
      prevPostalCodes.map((pc) => (pc.postalCode === codeToUpdate ? { ...pc, duplicateAction: newAction } : pc)),
    );
  };

  const onContinue = () => {
    for (const postalCode of postalCodeDuplicates) {
      if (postalCode.duplicateAction === DuplicationAction.KEEP_IN_CURRENT_POD) {
        setPostalCodesToAdd((prevPostalCodes) => prevPostalCodes.filter((code) => code !== postalCode.postalCode));
      }
    }
    setDuplicatesResolved(true);
  };

  return (
    <>
      <TitleWithClose
        title={
          <Group>
            <ThemeIcon color="status-warn">
              <IconAlertTriangle size="24px" />
            </ThemeIcon>
            Resolve duplicate postal codes
          </Group>
        }
        onClose={handleClosePostalCodesModal}
      />
      <Modal.Body p="0" my="lg">
        <Text>
          There are{' '}
          <Text span fw="700">
            {postalCodeDuplicates.length}
          </Text>{' '}
          postal codes that are currently mapped to other pods. Please review the duplicates and decide whether to move
          them to the new pod or keep them in their current pod.
        </Text>
        <Table my="xl" horizontalSpacing="lg" bg="white">
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Postal Code</Table.Th>
              <Table.Th>Current pod mapping</Table.Th>
              <Table.Th miw="240px">Actions</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {postalCodeDuplicates.map((postalCode) => (
              <Table.Tr key={postalCode.postalCode}>
                <Table.Td>{postalCode.postalCode}</Table.Td>
                <Table.Td>{postalCode.currentPodName}</Table.Td>
                <Table.Td>
                  <Radio.Group
                    style={{ alignItems: 'center' }}
                    value={postalCode.duplicateAction}
                    onChange={(e) => handleDuplicateActionChange(e, postalCode.postalCode)}
                    name={`postalCode-${postalCode.postalCode}`}
                    withAsterisk
                  >
                    <Radio
                      checked={postalCode.duplicateAction === DuplicationAction.MOVE_TO_NEW_POD}
                      style={{ alignItems: 'center' }}
                      my="xs"
                      value={DuplicationAction.MOVE_TO_NEW_POD}
                      label={
                        <Group gap="xs">
                          <IconArrowsRightLeft color="green" size="18px" />
                          <Text>Move to new pod</Text>
                        </Group>
                      }
                    />
                    <Radio
                      checked={postalCode.duplicateAction === DuplicationAction.KEEP_IN_CURRENT_POD}
                      style={{ alignItems: 'center' }}
                      my="xs"
                      value={DuplicationAction.KEEP_IN_CURRENT_POD}
                      label={
                        <Group gap="xs">
                          <IconX color="red" size="18px" />
                          <Text>Keep in current pod</Text>
                        </Group>
                      }
                    />
                  </Radio.Group>
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
        <ButtonContainer
          outlineButtonTitle="Cancel"
          outlineButtonAction={handleClosePostalCodesModal}
          filledButtonTitle="Continue"
          filledButtonAction={onContinue}
          filledButtonDisabled={postalCodesToAdd.length === 0 || !postalCodesToAdd}
        />
      </Modal.Body>
    </>
  );
};
