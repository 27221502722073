import React from 'react';
import { Modal, LoadingOverlay } from '@mantine/core';
import { useAddPostalCodesModal } from './AddPostalCodesModalContext';
import { UploadCsv } from './ModalContent/UploadCsv';
import { ResolveDuplicatePostalCodes } from './ModalContent/ResolveDuplicatePostalCodes';
import { ConfirmMapping } from './ModalContent/ConfirmMapping';

interface ModalProps {
  destinationPodId: string;
  podName: string;
}

export const AddPostalCodesModal = ({ destinationPodId, podName }: ModalProps) => {
  const {
    opened,
    handleClosePostalCodesModal,
    isLoading,
    postalCodesUploaded,
    duplicatesResolved,
    postalCodeDuplicates,
  } = useAddPostalCodesModal();

  const renderModalContent = () => {
    if (!postalCodesUploaded) {
      return <UploadCsv destinationPodId={destinationPodId} podName={podName} />;
    }

    if (!duplicatesResolved && postalCodeDuplicates.length > 0) {
      return <ResolveDuplicatePostalCodes podName={podName} />;
    }

    return <ConfirmMapping destinationPodId={destinationPodId} podName={podName} />;
  };

  return (
    <Modal
      withCloseButton={false}
      centered
      size="xl"
      padding="md"
      opened={opened}
      onClose={handleClosePostalCodesModal}
    >
      <LoadingOverlay visible={isLoading} />
      {renderModalContent()}
    </Modal>
  );
};
