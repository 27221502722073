import { HasContactFields } from '@components/shared/ContactReviewForm';
import { Address, CodeableConcept, ContactPoint, Patient, RelatedPerson } from '@medplum/fhirtypes';
import { System } from 'const-utils';
import { hydratePhoneNumbers } from '@utils/contactReviewUtils';
import { getFirstCodingBySystem } from 'imagine-dsl/utils/codeableConcepts';
import { getPatientRequiresInterpreter, isPrimaryCaregiver } from 'imagine-dsl/utils/patient';
import { formatAddress } from '@medplum/core';

export interface ExtendedRelatedPerson {
  PatientList?: Patient[];
  RelatedPersonList?: RelatedPerson[];
  relationship?: CodeableConcept[];
}

export const hydratePatientContactForm = (
  relatedPatient: ExtendedRelatedPerson,
  patient?: Patient,
): HasContactFields => {
  const caregiver = relatedPatient?.PatientList?.[0];
  const caregiverAddress = caregiver?.address?.[0];
  const patientAddress = patient?.address?.find((address) =>
    address.extension?.find((ext) => ext.url === System.AddressType.toString() && ext.valueCode === 'home'),
  );

  const relationship = getFirstCodingBySystem({
    codeableConcepts: relatedPatient?.relationship,
    system: System.ContactRelationship.toString(),
  });
  const contactType = getFirstCodingBySystem({
    codeableConcepts: relatedPatient?.relationship,
    system: System.ContactType.toString(),
  });

  const patientObject = {
    languageCode: patient?.communication?.[0]?.language?.coding?.[0]?.code ?? '',
    languageDisplay: patient?.communication?.[0]?.language?.coding?.[0]?.display ?? '',
    address: patientAddress ? formatAddress(patientAddress as Address) : '',
  };

  const caregiverObject = {
    languageCode: caregiver?.communication?.[0]?.language?.coding?.[0]?.code ?? '',
    languageDisplay: caregiver?.communication?.[0]?.language?.coding?.[0]?.display ?? '',
    address: caregiverAddress ? formatAddress(caregiverAddress as Address) : '',
  };

  const caregiverInterpreter = getPatientRequiresInterpreter(caregiver?.extension ?? []);
  const usePatientLanguage = patientObject.languageCode === caregiverObject.languageCode;
  const usePatientAddress = patientObject.address === caregiverObject.address;
  const consentToReceiveSms = caregiver?.extension?.find(
    (ext) => ext.url === System.CaregiverSMSConsent.toString(),
  )?.valueBoolean;

  return {
    contact: {
      contactId: caregiver?.id ?? '',
      firstName: caregiver?.name?.[0]?.given?.[0] || '',
      lastName: caregiver?.name?.[0]?.family || '',
      contactType: contactType?.display ?? '',
      relationship: relationship
        ? {
            code: relationship.code ?? '',
            display: relationship.display ?? '',
            system: System.ContactRelationship,
          }
        : {},
      language: caregiverObject.languageDisplay,
      primary: isPrimaryCaregiver(patient, caregiver) ?? true,
      requiresInterpreter: caregiverInterpreter,
      emailAddress: caregiver?.telecom?.find((t: ContactPoint) => t.system === 'email')?.value ?? '',
      address: {
        line1: caregiverAddress?.line?.[0] ?? '',
        line2: caregiverAddress?.line?.[1] ?? '',
        city: caregiverAddress?.city ?? '',
        state: caregiverAddress?.state ?? '',
        zip: caregiverAddress?.postalCode ?? '',
      },
      phoneNumbers: hydratePhoneNumbers(caregiver),
      usePatientAddress,
      usePatientLanguage,
      consentToReceiveSms,
    },
  };
};
