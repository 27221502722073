import {
  Drawer,
  Group,
  Text,
  Stack,
  Button,
  Modal,
  Textarea,
  Loader,
  Box,
  Title,
  ScrollArea,
  Tabs,
  ThemeIcon,
  Collapse,
} from '@mantine/core';
import { System, TaskType } from 'const-utils';
import React, { useMemo, useState } from 'react';
import { type TaskDrawerContentRenderer } from '..';
import {
  GetIncompleteCarePathwayTasksByPatientIdDocument,
  useGetCarePathwayReferralReviewTaskQuery,
  useGetIncompleteCarePathwayTasksByPatientIdQuery,
  useGetSubTasksQuery,
} from 'medplum-gql';
import { useMedplum } from '@medplum/react';
import { capitalize, compact, isEmpty } from 'lodash';
import { useMutation } from '@tanstack/react-query';
import { showNotification } from '@mantine/notifications';
import { getErrorMessage, logError } from '@/errors';
import { useForm } from '@mantine/form';
import { useApolloClient } from '@apollo/client';
import { CarePathwayReferralTask } from 'imagine-dsl/models/tasks/carePathwayReferralTask';
import { TaskDrawerHeader } from '../TaskDrawerHeader';
import { useUserSession } from '@/components/shared/UserSessionContext';
import { TaskActions } from './TaskActions';
import DataDrivenReferral from './DataDrivenReferral';
import ManualReferral from './ManualReferral';
import SubTaskSection from './SubTaskSection';
import { isDefined } from 'imagine-dsl/utils/lists';
import { BaseTask } from 'imagine-dsl/models/tasks/baseTask';
import { CarePathway, carePathwayDisplay, CarePathwayReferralType } from 'const-utils/codeSystems/ImaginePediatrics';
import { IconAlertTriangle, IconChevronDown, IconChevronRight, IconExternalLink } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { Link } from 'react-router-dom';
import { resolveCarePathwayReferral } from 'imagine-dsl/services/carePathwayReferralService';

export const CarePathwayReferralReviewDrawerContent: TaskDrawerContentRenderer = ({
  task: baseTask,
  withViewPatientProfile,
  refetchTasks,
  closeDrawer,
}) => {
  const taskQuery = useGetCarePathwayReferralReviewTaskQuery({ variables: { taskId: baseTask?.id ?? '' } });
  const { data: otherOpenTasksData } = useGetIncompleteCarePathwayTasksByPatientIdQuery({
    variables: { patientId: baseTask?.for?.id ?? '' },
    skip: !baseTask?.for?.id,
  });
  const task = useMemo(
    () => taskQuery.data?.Task && new CarePathwayReferralTask(taskQuery.data?.Task),
    [taskQuery.data?.Task],
  );
  const otherOpenTasks = useMemo(() => {
    if (taskQuery.loading) {
      return [];
    }
    return (
      compact(otherOpenTasksData?.TaskList)
        .filter((t) => t.id !== task?.id)
        .map((t) => new CarePathwayReferralTask(t)) ?? []
    );
  }, [otherOpenTasksData, task?.id, taskQuery.loading]);

  const subTasksQuery = useGetSubTasksQuery({ variables: { partOf: `Task/${task?.id}` } });
  const subTasks = useMemo(() => {
    return (subTasksQuery.data?.TaskList ?? []).filter(isDefined).map((t) => new BaseTask(t));
  }, [subTasksQuery.data]);

  const carePathwayTaskResolveAllowed = subTasks.length > 0;

  const [actionIntent, setActionIntent] = useState<'approve' | 'deny' | undefined>(undefined);
  const patientId = task?.for?.id;
  const { applicationRoles, profile } = useUserSession();
  const medplum = useMedplum();
  const actionForm = useForm({
    initialValues: {
      reason: '',
      note: '',
    },
    validate: {
      reason: (value) => (actionIntent === 'deny' && !value ? 'Reason is required' : undefined),
    },
  });

  const hasPermission = useMemo(() => {
    const requestedPathway = task?.requestedPathway;
    const originalPathway = task?.originalPathway;

    if (requestedPathway === CarePathway.CoreCare) {
      return applicationRoles.includes(originalPathway || '');
    }

    return applicationRoles.includes(requestedPathway || '');
  }, [applicationRoles, task?.requestedPathway, task?.originalPathway]);

  const apolloClient = useApolloClient();

  const { isLoading, mutate: onSubmit } = useMutation(
    async ({ note, reason }: { note: string; reason?: string }) => {
      if (!task?.id || !actionIntent) {
        return;
      }
      if (!hasPermission) {
        return;
      }

      await resolveCarePathwayReferral(medplum, { task, action: actionIntent, actor: profile, reason, note });
    },
    {
      onSuccess: async () => {
        apolloClient
          .refetchQueries({
            include: [GetIncompleteCarePathwayTasksByPatientIdDocument],
          })
          .catch((e) => logError(e));

        await refetchTasks();
        closeDrawer();
        showNotification({
          message:
            actionIntent === 'approve'
              ? 'Successfully approved care pathway referral'
              : 'Successfully denied care pathway referral',
        });
      },
      onError: (error) => {
        logError(error);
        showNotification({
          title: 'Error',
          message: getErrorMessage(error),
          color: 'status-error',
        });
      },
    },
  );

  if (!patientId || !task) {
    return (
      <Drawer.Content>
        <Drawer.Body>
          <Loader />
        </Drawer.Body>
      </Drawer.Content>
    );
  }

  return (
    <Drawer.Content data-cy="care-pathway-task-drawer">
      <Drawer.Header h={3}>
        <Group ml="auto">
          <Drawer.CloseButton />
        </Group>
      </Drawer.Header>
      <Drawer.Body display="flex" h="calc(100% - 60px)" style={{ flexDirection: 'column' }}>
        <Text c="dimmed" size="md" fw={700}>
          Care pathway referral review
        </Text>

        <TaskDrawerHeader patientId={patientId} withViewPatientProfile={withViewPatientProfile} />
        <ScrollArea>
          <Stack h="100%">
            <Box p="md" flex="1">
              <Group gap="sm">
                <Title order={2} size={18}>
                  Care pathway referral
                </Title>
              </Group>
              <Tabs defaultValue="details">
                <Tabs.List>
                  <Tabs.Tab value="details">Details</Tabs.Tab>
                  <OtherOpenReferralsTab otherTasks={otherOpenTasks} />
                </Tabs.List>
                <Tabs.Panel value="details">
                  <TaskDetailsTabContent task={task} patientId={patientId} />
                </Tabs.Panel>
                <Tabs.Panel value="other-open-referrals">
                  <Box>
                    {isEmpty(otherOpenTasks) && <Text>There are no other open referral tasks for this patient</Text>}
                    {otherOpenTasks.map((t) => (
                      <OtherTaskItem key={t.id} task={t} patientId={patientId} />
                    ))}
                  </Box>
                </Tabs.Panel>
              </Tabs>
            </Box>
          </Stack>
        </ScrollArea>
        <TaskActions
          hasPermission={hasPermission}
          task={task}
          setActionIntent={setActionIntent}
          refetchTasks={refetchTasks}
          carePathwayTaskResolveAllowed={carePathwayTaskResolveAllowed}
          profile={profile}
        />
        <Modal
          size="lg"
          centered
          opened={!!actionIntent}
          onClose={() => setActionIntent(undefined)}
          title={
            <Text c="imagine-green" fw="bold" size="xl">
              {capitalize(actionIntent)} care pathway referral
            </Text>
          }
        >
          <Text>Are you sure you want to {actionIntent} this care pathway referral?</Text>
          <Stack mt="lg">
            {actionIntent === 'deny' && <Textarea label="Reason" required {...actionForm.getInputProps('reason')} />}
            <Textarea label="Notes" {...actionForm.getInputProps('note')} />
          </Stack>
          <Group mt="xl" justify="right" gap="sm">
            <Button disabled={isLoading} variant="default" onClick={() => setActionIntent(undefined)}>
              Cancel
            </Button>
            <Button
              loading={isLoading}
              onClick={() => {
                if (actionForm.validate().hasErrors) {
                  return;
                }

                onSubmit(actionForm.values);
              }}
            >
              {capitalize(actionIntent)}
            </Button>
          </Group>
        </Modal>
      </Drawer.Body>
    </Drawer.Content>
  );
};

const OtherOpenReferralsTab = ({ otherTasks }: { otherTasks: CarePathwayReferralTask[] }): JSX.Element => {
  const leftSection = (
    <ThemeIcon color="status-warn">
      <IconAlertTriangle />
    </ThemeIcon>
  );

  return (
    <Tabs.Tab
      disabled={isEmpty(otherTasks)}
      value="other-open-referrals"
      leftSection={isEmpty(otherTasks) ? undefined : leftSection}
    >
      Other open referrals
    </Tabs.Tab>
  );
};

const TaskDetailsTabContent = ({
  task,
  patientId,
}: {
  task: CarePathwayReferralTask;
  patientId: string;
}): JSX.Element => {
  const subTasksQuery = useGetSubTasksQuery({ variables: { partOf: `Task/${task?.id}` } });
  const subTasks = useMemo(() => {
    return (subTasksQuery.data?.TaskList ?? []).filter(isDefined).map((t) => new BaseTask(t));
  }, [subTasksQuery.data]);
  const referralType = task.referralType;
  const newCarePathway = task?.inputBySystem(System.CarePathway)?.valueString;
  const taskTypes = [
    {
      label:
        newCarePathway === 'CC'
          ? 'Cancel all future longitudinal appointments'
          : 'Schedule visit(s) for care pathway change',
      value:
        newCarePathway === 'CC'
          ? TaskType.CancelAllLongitudinalAppointments
          : TaskType.ScheduleVisitsForCarePathwayChange,
    },
  ];

  const isTaskResolved = task?.isResolved;

  return (
    <>
      <ManualReferral task={task} />
      {referralType === CarePathwayReferralType.Data.toString() && <DataDrivenReferral task={task} />}
      {!isTaskResolved && (
        <SubTaskSection
          task={task}
          patientId={patientId}
          taskTypes={taskTypes}
          subTasksQuery={subTasksQuery}
          subTasks={subTasks}
        />
      )}
    </>
  );
};

const OtherTaskItem = ({ task, patientId }: { task: CarePathwayReferralTask; patientId: string }): JSX.Element => {
  const [opened, { toggle }] = useDisclosure(false);

  return (
    <React.Fragment key={task.id}>
      <Group mt={8} onClick={toggle} style={{ cursor: 'pointer' }}>
        <ThemeIcon>{opened ? <IconChevronDown size={15} /> : <IconChevronRight size={15} />}</ThemeIcon>
        <Text>
          Referral to{' '}
          <strong>
            {task.requestedPathway ? carePathwayDisplay[task.requestedPathway as CarePathway] : 'unknown'}
          </strong>{' '}
        </Text>
      </Group>
      <Collapse in={opened}>
        <Box ml={44}>
          <Link to={`/Task?task=${task.id}`}>
            <Group c="imagine-green" gap={2}>
              <Text>View task</Text>
              <ThemeIcon>
                <IconExternalLink size={14} />
              </ThemeIcon>
            </Group>
          </Link>
          <TaskDetailsTabContent task={task} patientId={patientId} />
        </Box>
      </Collapse>
    </React.Fragment>
  );
};
