import React, { useState, useEffect } from 'react';
import { Drawer, Group, Loader, ScrollArea } from '@mantine/core';
import { TaskDrawerHeader } from '@/components/tasks/drawer/TaskDrawerHeader';
import { useMedplum } from '@medplum/react';
import { BaseTask } from 'imagine-dsl/models/tasks/baseTask';
import { useGetTaskQuery, useGetTouchpointsByTaskIdQuery } from 'medplum-gql';
import { RelatedTaskActions } from './RelatedTaskActions';
import { CommunicationRequest } from 'imagine-dsl/models/communications/communicationRequest';
import { AdtAlerts } from './AdtAlerts';
import { TaskProgress } from './TaskProgress';
import { ActivityHistory } from './ActivityHistory';
import { TaskTouchpoint } from 'imagine-dsl/models/communications/taskTouchpoint';
import { showNotification } from '@mantine/notifications';

export type CommunicationDrawerProps = {
  patientId: string | null | undefined;
  close: () => void;
  communicationRequest?: CommunicationRequest;
  task?: BaseTask;
};
export const TOCDrawerContent = ({
  patientId,
  close,
  communicationRequest,
  task: existingTask,
}: CommunicationDrawerProps) => {
  const medplum = useMedplum();
  const [task, setTask] = useState<BaseTask | null>(null);
  const [touchpoints, setTouchpoints] = useState<TaskTouchpoint[]>([]);
  const [communicationRequestId, setCommunicationRequestId] = useState<string | null>(communicationRequest?.id ?? null);
  const {
    data: taskData,
    loading,
    refetch: refetchTask,
  } = useGetTaskQuery({
    variables: { taskId: '' },
    skip: !!existingTask,
  });

  const { refetch: refetchTouchpoints } = useGetTouchpointsByTaskIdQuery({
    variables: { taskRef: '' },
  });

  const fetchTouchpoints = async (taskId: string) => {
    if (!taskId) return;
    try {
      const response = await refetchTouchpoints({ taskRef: `Task/${taskId}` });
      if (response.data.CommunicationList !== null) {
        const touchpointMap = response?.data?.CommunicationList?.map((request) => {
          if (request) {
            return new TaskTouchpoint(request);
          }
          return null;
        }).filter((touchpoint) => touchpoint !== null);

        if (touchpointMap) {
          setTouchpoints(touchpointMap);
        }
      }
    } catch (error) {
      showNotification({
        color: 'status-error',
        title: 'Error fetching activity history',
        message: (error as Error).message,
      });
    }
  };

  useEffect(() => {
    const fetchTask = async () => {
      try {
        const task = await medplum.searchOne('Task', `based-on=CommunicationRequest/${communicationRequest?.id}`);

        if (task) {
          refetchTask({ taskId: task.id });
          fetchTouchpoints(task.id!);
        }
      } catch (err) {
        throw new Error(`Error fetching task: ${err}`);
      }
    };

    // an existing task is passed in by the TaskDrawer component
    // since the Base Task already exists at that level, there's no need to re-fetch
    // we just need to extract the communication request id from the basedOn field if communicationRequest is not provided
    if (existingTask?.id) {
      const communicationRequestId = existingTask.basedOn?.split('/')[1];
      if (communicationRequestId) {
        setCommunicationRequestId(communicationRequestId);
      }
      fetchTouchpoints(existingTask.id);
      return setTask(existingTask);
    }

    fetchTask();
  }, []);

  useEffect(() => {
    if (taskData?.Task) {
      const baseTask = new BaseTask(taskData.Task);
      setTask(baseTask);
      if (baseTask.id) {
        fetchTouchpoints(baseTask.id);
      }
    }
  }, [taskData]);

  if (loading) {
    return <Loader />;
  }

  if (!patientId) {
    return <></>;
  }

  return (
    <Drawer.Content>
      <Drawer.Header h={3}>
        <Group ml="auto">
          <Drawer.CloseButton />
        </Group>
      </Drawer.Header>
      <Drawer.Body style={{ display: 'flex', flexDirection: 'column', height: 'calc(100% - 60px)' }}>
        <TaskDrawerHeader patientId={patientId} withViewPatientProfile={true} />
        <ScrollArea>
          <AdtAlerts patientId={patientId} communicationRequestId={communicationRequest?.id} task={task || undefined} />
          <TaskProgress communicationRequestId={communicationRequestId} />
          {task && <ActivityHistory task={task} touchpoints={touchpoints} patientId={patientId} />}
        </ScrollArea>
        {task && (
          <RelatedTaskActions
            task={task}
            patientId={patientId}
            closeDrawer={close}
            communicationRequestId={communicationRequestId ?? ''}
          />
        )}
      </Drawer.Body>
    </Drawer.Content>
  );
};
