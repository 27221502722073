import React from 'react';
import { Stack, Center, Text } from '@mantine/core';

export interface EmptyStateProps {
  icon: React.ReactNode;
  message: string;
}

export function EmptyState({ icon, message }: EmptyStateProps) {
  return (
    <Stack align="center" gap="xs" mt="lg">
      <Center>{icon}</Center>
      <Text size="sm" c="dimmed" ta="center">
        {message}
      </Text>
    </Stack>
  );
}
