import { MedplumClient, createReference, parseReference } from '@medplum/core';
import {
  Attachment,
  Consent,
  DocumentReference,
  Patient,
  QuestionnaireResponseItem,
  Reference,
} from '@medplum/fhirtypes';
import { selectDisplayByCode, System, ContactType, ContactRelationship } from 'const-utils';
import { getName } from '../utils/patient';
import {
  PageSizes,
  PDFDocument,
  PDFFont,
  PDFImage,
  PDFPage,
  PDFPageDrawTextOptions,
  rgb,
  StandardFonts,
} from 'pdf-lib';
import fs from 'node:fs';
import path from 'node:path';
import fontkit from '@pdf-lib/fontkit';
import {
  ConsentCode,
  ConsentIdentifier,
  ConsentResponse,
  ConsentType,
  DocumentReferenceTag,
  QuestionnaireType,
} from 'const-utils/codeSystems/ImaginePediatrics';
import { format } from 'date-fns';
import {
  doesConsentExist,
  generateDraftConsent,
  getJurisdiction,
  getRequiredConsents,
  getConsentSignature,
  getConsentSignerContactType,
  getConsentSignerRelationship,
} from '../utils/consent';

export const generateConsents = async (
  medplum: MedplumClient,
  id: string,
  type: ConsentType.PatientConsent | ConsentType.CaregiverConsent,
): Promise<void> => {
  const isPatientConsent = type === ConsentType.PatientConsent;
  let jurisdiction: { code: string; display: string } | undefined;

  if (isPatientConsent) {
    jurisdiction = await getJurisdiction(medplum, id);
  }

  const requiredConsents = getRequiredConsents(type, jurisdiction?.code);

  const allConsents = await medplum.searchResources('Consent', {
    patient: `Patient/${id}`,
  });

  const draftOrActiveConsents =
    allConsents?.filter((consent) => consent.status === 'draft' || consent.status === 'active') || [];

  for (const consentType of requiredConsents) {
    if (draftOrActiveConsents.length === 0) {
      generateDraftConsent(medplum, id, consentType, jurisdiction);
      continue;
    }

    const consentAlreadyExists = await doesConsentExist(medplum, draftOrActiveConsents, consentType);

    if (!consentAlreadyExists) {
      generateDraftConsent(medplum, id, consentType, jurisdiction);
    }
  }
};

/**
 * getSignedConsentDocument 'signs' a Consent by embedding the associated patient and signer/performer names into the static document reference
 * associated with the Consent
 *
 * This is expected to be used with post-Verity signed Consents as it is dependant on a Consent.performer which does not exist on legacy consents migrated from Verity
 *
 * @param medplum - medplum client
 * @param consent - Consent resource
 * @param options - options for signing
 * @returns Promise<Uint8Array>
 */
export const getSignedConsentDocument = async (medplum: MedplumClient, consent: Consent): Promise<Uint8Array> => {
  const patientRef = consent?.patient;
  if (!patientRef) {
    throw new Error('missing patient');
  }

  const sourceReference = consent.sourceReference;
  if (!sourceReference) {
    throw new Error('missing document reference');
  }

  const isManualUpload = consent.meta?.tag?.some((tag) => tag.system === System.ManualConsentUpload.toString());
  if (isManualUpload) {
    return getManuallySignedConsent(sourceReference as Reference<DocumentReference>, medplum);
  }

  const performerRef = consent.performer?.at(0);
  if (!performerRef) {
    throw new Error('missing performer reference');
  }

  const consentedAt = consent.dateTime;
  if (!consentedAt) {
    throw new Error('missing consent date time');
  }

  const [patient, performer] = await Promise.all([
    medplum.readReference(patientRef),
    medplum.readReference(performerRef as Reference<Patient>),
  ]);

  const patientBirthDate = patient.birthDate;
  if (!patientBirthDate) {
    throw new Error('missing patient birth date');
  }

  const patientName = getName(patient, { use: 'official' });
  if (!patientName) {
    throw new Error('unable to produce "official" name for patient');
  }
  const performerName = getName(performer, { use: 'official' });
  const consentSignature = getConsentSignature(consent);

  if (!performerName) {
    throw new Error('unable to produce consent signature nor "official" name for performer');
  }

  const signatureText = consentSignature ?? performerName;

  let isBrandedConsent = false;
  let pdfDoc: PDFDocument | undefined;

  const [resourceType, resourceId] = parseReference(sourceReference);
  if (resourceType === 'QuestionnaireResponse') {
    const consentTypeDisplay = consent.meta?.tag?.find((tag) => tag.system === System.ConsentType)?.display;

    const signerRelationship = getConsentSignerRelationship(consent);
    const signerContactType = getConsentSignerContactType(consent);

    pdfDoc = await generateQuestionnaireResponsePDF({
      medplum,
      resourceId,
      title: consentTypeDisplay ?? 'Consent',
      patientName,
      patientBirthDate,
      signatureText,
      signerContactType,
      signerRelationship,
      consentedAt,
    });

    if (pdfDoc) {
      return pdfDoc.save();
    }
  } else {
    const documentReference = await medplum.readReference(sourceReference as Reference<DocumentReference>);
    isBrandedConsent =
      documentReference.meta?.tag?.some(
        (tag) => tag.system === System.DocumentBranding.toString() && tag.code === 'branded',
      ) ?? false;
    const arrayBuffer = await getDocumentReferenceContent(documentReference);
    pdfDoc = await PDFDocument.load(arrayBuffer);
    pdfDoc.registerFontkit(fontkit);
  }

  if (!pdfDoc) {
    throw new Error('PDFDoc not generated');
  }
  const cursiveFontBuffer = fs.readFileSync(path.join(__dirname, 'assets', 'fonts', 'CedarvilleCursive-Regular.ttf'));

  if (cursiveFontBuffer) {
    const arrayBuffer = new Uint8Array(cursiveFontBuffer).buffer;
    cursiveFont = await pdfDoc.embedFont(arrayBuffer);
  }

  const pages = pdfDoc.getPages();
  const firstPage = pages.at(0);
  if (!firstPage) {
    throw new Error('no pages found in pdf document');
  }

  const xOffset = isBrandedConsent ? 50 : 70;
  const patientHeaderOffset = isBrandedConsent ? 150 : xOffset;

  //TODO: in future allow placing the patient name in the header of the document of the translated document as well
  //also eventual desire is to allow for serving up caregivers language preference and then only displaying English version with sig in care hub?
  const firstPages: PDFPage[] = [firstPage];

  for (const page of firstPages) {
    addPatientNameToConsentDocument(page, patientName, patientBirthDate, patientHeaderOffset);
  }

  const lastPages: PDFPage[] = !isBrandedConsent
    ? [pages.at(-1) as PDFPage]
    : [pages.at(pages.length / 2 - 1) as PDFPage, pages.at(-1) as PDFPage];
  const Y_NEWLINE_OFFSET = 20;
  const Y_SIGNATURE_LINE_OFFSET = 25;
  const fontSize = 14;
  let yOffset = isBrandedConsent ? 200 : 100;

  for (const page of lastPages) {
    writeLineToConsentDocument(page, 'Patient/Parent/Guardian signature:', fontSize, xOffset, yOffset);
    yOffset -= Y_SIGNATURE_LINE_OFFSET;
    writeLineToConsentDocument(page, signatureText, fontSize, xOffset, yOffset, true);
    yOffset -= Y_SIGNATURE_LINE_OFFSET;
    writeLineToConsentDocument(
      page,
      `Patient/Parent/Guardian printed name: ${signatureText}`,
      fontSize,
      xOffset,
      yOffset,
    );
    yOffset -= Y_NEWLINE_OFFSET;
    writeLineToConsentDocument(
      page,
      `Accepted by ${signatureText} on ${format(consentedAt, 'MM/dd/yyyy')}`,
      fontSize,
      xOffset,
      yOffset,
    );
  }

  return pdfDoc.save();
};

const addPatientNameToConsentDocument = (
  page: PDFPage,
  patientName: string,
  patientBirthDate: string,
  xOffset: number,
) => {
  const { height: firstPageHeight } = page.getSize();
  page.drawText(`Patient: ${patientName} - ${format(patientBirthDate, 'MM/dd/yyyy')}`, {
    x: xOffset,
    y: firstPageHeight - 50,
    color: rgb(0, 0, 0),
    size: 14,
  });
};

let cursiveFont: PDFFont | undefined = undefined;

const writeLineToConsentDocument = (
  page: PDFPage,
  text: string,
  fontSize: number,
  xOffset: number,
  yBaseline: number,
  cursive = false,
) => {
  page.drawText(text, {
    x: xOffset,
    y: yBaseline,
    size: fontSize,
    font: cursive ? cursiveFont : undefined,
  });
};

const getDocumentReferenceContent = async (documentReference: DocumentReference): Promise<ArrayBuffer> => {
  const documentReferenceContentUrl = documentReference.content?.at(0)?.attachment?.url;
  if (!documentReferenceContentUrl) {
    throw new Error('missing document reference content url');
  }

  const res = await fetch(documentReferenceContentUrl);
  return res.arrayBuffer();
};

const getManuallySignedConsent = async (
  documentReferenceRef: Reference<DocumentReference>,
  medplum: MedplumClient,
): Promise<Uint8Array> => {
  const documentReference = await medplum.readReference(documentReferenceRef as Reference<DocumentReference>);
  const arrayBuffer = await getDocumentReferenceContent(documentReference);
  const pdfDoc = await PDFDocument.load(arrayBuffer);
  return pdfDoc.save();
};

const manualConsentTag = {
  system: System.ManualConsentUpload,
  code: DocumentReferenceTag.ManualConsentUpload,
  display: DocumentReferenceTag.ManualConsentUpload,
};
const consentResponseTag = (response: ConsentResponse = ConsentResponse.Accept) => {
  return {
    system: System.ConsentResponse,
    code: response,
    display: response,
  };
};
/**
 * This method will upload a pdf to draft consent to treat and release of information for the given patient
 * How do we want to handle if no draft is found? Do we create the consent? Verify if one is currently "final"? Then override it?
 *
 * @param medplum - medplum client
 * @param patientReference - reference to the patient
 * @param attachment - the consent attachment
 * @param type - the consent type
 * @param response - the consent response of accept or deny, defaults to accept
 */
export const uploadPatientConsent = async ({
  medplum,
  patientReference,
  attachment,
  type,
  response = ConsentResponse.Accept,
}: {
  medplum: MedplumClient;
  patientReference: Reference<Patient>;
  attachment: Attachment;
  type: QuestionnaireType;
  response?: ConsentResponse;
}): Promise<void> => {
  //TODO: Update to only look for draft of the type passed in

  let consent: Consent | undefined;

  consent = await medplum.searchOne('Consent', `patient=${patientReference.reference}&_tag=${type}&status=draft`);
  // Look up the DocumentReference type using the ConsentCode map
  const documentReferenceConsentType = ConsentCode[type];
  if (!consent) {
    //look for legacy consent
    const consentDocRef = await medplum.searchOne('DocumentReference', {
      status: 'current',
      category: ConsentType.PatientConsent,
      type: documentReferenceConsentType,
    });
    consent = await medplum.searchOne(
      'Consent',
      `patient=${patientReference.reference}&source-reference=DocumentReference/${consentDocRef?.id}&status=draft`,
    );
  }

  if (!consent) {
    throw new Error('Patient does not have a consent, of this type, in draft. Document not uploaded.');
  }

  const consentDocumentUpload = await medplum.createResource({
    resourceType: 'DocumentReference',
    masterIdentifier: {
      system: ConsentIdentifier.ConsentToTreat,
      value: 'manual',
    },
    content: [
      {
        attachment,
      },
    ],
    docStatus: 'final',
    status: 'superseded', //using this status instead of current to help differentiate between manual, the real status that matters is the "consent itself"
    type: {
      coding: [
        {
          code: documentReferenceConsentType,
          display: documentReferenceConsentType,
        },
      ],
    },
    category: [
      {
        coding: [
          {
            code: ConsentType.PatientConsent,
            display: ConsentType.PatientConsent,
          },
        ],
      },
    ],
    subject: patientReference,
    date: new Date().toISOString(),
    meta: {
      tag: [manualConsentTag],
    },
  });
  const tags = [...(consent.meta?.tag || []), manualConsentTag, consentResponseTag(response)];
  await medplum.updateResource({
    ...consent,
    sourceReference: createReference(consentDocumentUpload),
    meta: { ...consent.meta, tag: tags },
    dateTime: new Date().toISOString(),
    status: 'active',
  });
};

/**
 * This method will upload a pdf to draft consent to treat and release of information for the given Caregiver
 * How do we want to handle if no draft is found? Do we create the consent? Verify if one is currently "final"? Then override it?
 *
 * @param medplum - medplum client
 * @param caregiverReference - reference to the caregiver
 * @param attachment - the consent attachment
 * @param type - the consent type
 * @returns Promise<void>
 */
export const uploadCaregiverConsent = async ({
  medplum,
  caregiverReference,
  attachment,
  type,
}: {
  medplum: MedplumClient;
  caregiverReference: Reference<Patient>;
  attachment: Attachment;
  type: QuestionnaireType;
}): Promise<void> => {
  let consent: Consent | undefined;
  consent = await medplum.searchOne('Consent', `patient=${caregiverReference.reference}&_tag=${type}&status=draft`);

  if (!consent) {
    const eulaRef = await medplum.searchOne('DocumentReference', {
      status: 'current',
      category: ConsentType.CaregiverConsent,
    });

    consent = await medplum.searchOne(
      'Consent',
      `patient=${caregiverReference.reference}&source-reference=DocumentReference/${eulaRef?.id}&status=draft`,
    );
  }

  if (consent) {
    //TODO: Determine if we still upload the document even if no consent draft exist
    const consentDocumentUpload = await medplum.createResource({
      resourceType: 'DocumentReference',
      masterIdentifier: {
        system: ConsentIdentifier.EULA,
        value: 'manual',
      },
      content: [
        {
          attachment,
        },
      ],
      docStatus: 'final',
      status: 'superseded', //using this status instead of current to help differentiate between manual, the real status that matters is the "consent itself"
      type: {
        coding: [
          {
            code: ConsentType.Eula,
            display: ConsentType.Eula,
          },
        ],
      },
      category: [
        {
          coding: [
            {
              code: ConsentType.CaregiverConsent,
              display: ConsentType.CaregiverConsent,
            },
          ],
        },
      ],
      subject: caregiverReference,
      date: new Date().toISOString(),
      meta: {
        tag: [manualConsentTag],
      },
    });
    const tags = [...(consent.meta?.tag || []), manualConsentTag, consentResponseTag()];
    await medplum.updateResource({
      ...consent,
      sourceReference: createReference(consentDocumentUpload),
      meta: { ...consent.meta, tag: tags },
      dateTime: new Date().toISOString(),
      status: 'active',
    });
  } else {
    throw new Error('Caregiver does not have a consent, of this type, in draft. Document not uploaded.');
  }
};

export const generateQuestionnaireResponsePDF = async ({
  medplum,
  resourceId,
  //TODO: refactor, simply to not necessarily pass in all of these string values which can be found on the document
  title,
  patientName,
  patientBirthDate,
  signatureText,
  signerContactType,
  signerRelationship,
  consentedAt,
}: {
  medplum: MedplumClient;
  resourceId: string;
  title: string;
  patientName: string;
  patientBirthDate: string;
  signatureText: string;
  signerContactType?: string;
  signerRelationship?: string;
  consentedAt: string;
}): Promise<PDFDocument | undefined> => {
  const questionnaireResponse = await medplum.readResource('QuestionnaireResponse', resourceId);

  const pdfDoc = await PDFDocument.create();
  const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);

  // Add the first page
  let page = pdfDoc.addPage(PageSizes.Letter);
  const { height, width } = page.getSize();

  // Set page dimensions and margins for letter size paper
  const margin = 50;
  const contentWidth = width - 2 * margin;
  const defaultFontSize = 12;
  const grpHeadingFontSize = 17;
  const headerFontSize = 14;
  const defaultParagraphSpacing = 28;

  const logoImageBytes = fs.readFileSync(path.join(__dirname, 'assets', 'images', 'imagine-logo.png'));
  let logoImage: PDFImage;

  page.moveTo(50, height - margin / 2 - 100); //set position to where image should be drawn

  if (logoImageBytes) {
    const arrayBuffer = new Uint8Array(logoImageBytes).buffer;
    pdfDoc.registerFontkit(fontkit);

    logoImage = await pdfDoc.embedPng(arrayBuffer);

    page.drawImage(logoImage, {
      width: 250,
      height: 100,
    });
    page.moveDown(16);
  }
  page.drawText(`Patient: ${patientName} - ${format(patientBirthDate, 'MM/dd/yyyy')}`, {
    color: rgb(0, 0, 0),
    size: 14,
  });

  const titleFontSize = title.length > 25 ? 24 : 30;
  page.moveDown(titleFontSize + 4); //title font is 30 so add 4 to line height

  page.drawText(title, {
    size: titleFontSize,
    font: timesRomanFont,
    color: rgb(0, 0, 0),
    maxWidth: contentWidth,
  });
  page.moveDown(defaultParagraphSpacing * 0.8);

  //define methods that will help write the remainder of the PDF
  const renderQuestionnaireItems = ({ items, depth }: { items: QuestionnaireResponseItem[]; depth?: number }) => {
    for (const item of items) {
      if (item.item) {
        //item is a group
        if (item.text) {
          //add header for the group
          //move page down for next group of items
          page.moveDown(defaultParagraphSpacing);
          drawGroupHeading(item.text);
        }
        //render the remaining items of the group
        renderQuestionnaireItems({ items: item.item, depth: depth ? depth + 1 : 1 });
      } else if (item.text) {
        const isBullet = isBulletDisplayType(item);
        const isHeader = isHeadingDisplayType(item);
        const itemMargin = margin + (depth && isBullet ? depth * 10 : 0);
        const itemFontSize = isHeader ? headerFontSize : defaultFontSize;
        const lines = splitTextIntoLines({
          text: item.text,
          fontSize: itemFontSize,
          margin: itemMargin,
          isBullet,
        });
        for (const line of lines) {
          drawCurrentLine({
            line,
            drawOptions: { size: itemFontSize, x: itemMargin, maxWidth: contentWidth, font: timesRomanFont },
          });
        }
        page.moveDown(itemFontSize + 2);

        //if there is an answer, add it to the document below the above lines
        const itemResponse = item.answer?.[0].valueCoding?.display ?? undefined;
        if (itemResponse) {
          const responseLines = splitTextIntoLines({
            text: itemResponse,
            fontSize: defaultFontSize,
            margin: itemMargin,
          });
          for (const line of responseLines) {
            drawCurrentLine({
              line,
              drawOptions: { size: defaultFontSize, x: itemMargin, maxWidth: contentWidth, font: timesRomanFont },
            });
          }
          page.moveDown(defaultFontSize + 2);
        }
      }
    }
  };

  const drawGroupHeading = (text: string) => {
    //ensure that after drawing the header we have enough space for the next two - three lines minimum
    const minOffsetNeeded = grpHeadingFontSize + defaultParagraphSpacing + defaultFontSize * 2;
    maybeAddNewPage(minOffsetNeeded);
    drawCurrentLine({
      line: text,
      drawOptions: { size: grpHeadingFontSize, font: timesRomanFont, maxWidth: contentWidth },
    });
    page.moveDown(grpHeadingFontSize + 6);
  };

  const splitTextIntoLines = ({
    text,
    fontSize,
    isBullet = false,
  }: {
    text: string;
    fontSize: number;
    margin: number;
    isBullet?: boolean;
  }) => {
    const words = text.split(' ');
    const lines = [];
    let currentLine = isBullet ? '• ' : '';

    for (const word of words) {
      const lineWithWord = currentLine ? `${currentLine} ${word}` : word;
      const lineWidth = timesRomanFont.widthOfTextAtSize(lineWithWord, fontSize);

      if (lineWidth <= contentWidth - margin) {
        currentLine = lineWithWord;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }

    if (currentLine) {
      lines.push(currentLine);
    }

    return lines;
  };

  const drawCurrentLine = ({ line, drawOptions }: { line: string; drawOptions: PDFPageDrawTextOptions }) => {
    //determine current page position and if we need a new page
    maybeAddNewPage(drawOptions.size ?? defaultFontSize);
    page.drawText(line, drawOptions);
    page.moveDown((drawOptions.size ?? defaultFontSize) + 2);
  };

  const maybeAddNewPage = (newTextHeight: number) => {
    const { y } = page.getPosition();
    if (y < newTextHeight + margin) {
      page = pdfDoc.addPage();
      page.moveTo(margin, height - margin / 2);
    }
  };

  renderQuestionnaireItems({ items: questionnaireResponse.item ?? [] });

  const cursiveFontBuffer = fs.readFileSync(path.join(__dirname, 'assets', 'fonts', 'CedarvilleCursive-Regular.ttf'));

  if (cursiveFontBuffer) {
    const arrayBuffer = new Uint8Array(cursiveFontBuffer).buffer;
    cursiveFont = await pdfDoc.embedFont(arrayBuffer);
  }
  pdfDoc.registerFontkit(fontkit);
  maybeAddNewPage(150);
  const { y } = page.getPosition();

  const Y_NEWLINE_OFFSET = 20;
  const fontSize = 14;
  let yOffset = y - 25;

  if (signerRelationship && signerContactType) {
    const contactTypeDisplay = selectDisplayByCode(ContactType, signerContactType) ?? `Other - ${signerContactType}`;
    const contactRelationshipDisplay =
      selectDisplayByCode(ContactRelationship, signerRelationship) ?? `Other - ${signerRelationship}`;

    writeLineToConsentDocument(page, 'Signature:', fontSize, margin, yOffset);
    yOffset -= Y_NEWLINE_OFFSET;
    writeLineToConsentDocument(page, signatureText, fontSize, margin, yOffset, true);
    yOffset -= Y_NEWLINE_OFFSET;
    writeLineToConsentDocument(page, `Printed name: ${signatureText}`, fontSize, margin, yOffset);
    yOffset -= Y_NEWLINE_OFFSET;
    writeLineToConsentDocument(
      page,
      `Relationship to Patient: ${contactRelationshipDisplay}`,
      fontSize,
      margin,
      yOffset,
    );
    yOffset -= Y_NEWLINE_OFFSET;
    writeLineToConsentDocument(page, `Contact Type: ${contactTypeDisplay}`, fontSize, margin, yOffset);
  } else {
    writeLineToConsentDocument(page, 'Patient/Parent/Guardian signature:', fontSize, margin, yOffset);
    yOffset -= Y_NEWLINE_OFFSET;
    writeLineToConsentDocument(page, signatureText, fontSize, margin, yOffset, true);
    yOffset -= Y_NEWLINE_OFFSET;
    writeLineToConsentDocument(
      page,
      `Patient/Parent/Guardian printed name: ${signatureText}`,
      fontSize,
      margin,
      yOffset,
    );
  }
  yOffset -= Y_NEWLINE_OFFSET;
  writeLineToConsentDocument(
    page,
    `Accepted by ${signatureText} on ${format(consentedAt, 'MM/dd/yyyy')}`,
    fontSize,
    margin,
    yOffset,
  );

  //save and return the doc
  await pdfDoc.save();
  return pdfDoc;
};

export const isBulletDisplayType = (item: QuestionnaireResponseItem): boolean | undefined => {
  return item.extension?.some(
    (extension) =>
      extension.url === (System.QuestionnaireItemDisplayType as string) && extension.valueCode === 'bullet',
  );
};

export const isHeadingDisplayType = (item: QuestionnaireResponseItem): boolean | undefined => {
  return item.extension?.some(
    (extension) =>
      extension.url === (System.QuestionnaireItemDisplayType as string) && extension.valueCode === 'heading',
  );
};
