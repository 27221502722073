import { Title, AppShell as MantineAppShell, Group, Text, ActionIcon, Avatar } from '@mantine/core';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { HELP_HREF } from '../shared/constants';
import { IconBell, IconLogout, IconMail } from '@tabler/icons-react';
import { formatHumanName } from '@medplum/core';
import { useMedplum, useMedplumProfile } from '@medplum/react';
import { HumanName } from '@medplum/fhirtypes';
import { IconMenu } from '@/design-system/IconMenu';
import { MenuDropdown } from './notifications/MenuDropdown';
import { useNotificationContext } from '@/pages/Notifications/NotificationsProvider';
import { PanelSelector } from '../patientPanel/PanelSelector';

const PAGE_TITLES: { [key: string]: string } = {
  Task: 'Tasks',
  OutreachTask: 'Tasks',
  MyPatients: 'My Patients',
  Outreach: 'Outreach',
  'release-notes': 'Release Notes',
  Patients: 'Patients',
  Users: 'Users',
  Patient: 'Patient Profile',
  PatientPanel: 'Patient Panel',
  notifications: 'Notifications',
  Admin: 'Admin',
};

export const HorizontalNav = ({ pl }: { pl?: number }): JSX.Element => {
  const profile = useMedplumProfile();
  const location = useLocation();
  const page = location.pathname.split('/').at(1);
  const pageTitle = page ? PAGE_TITLES[page] : 'Command Center';
  const medplum = useMedplum();
  const navigate = useNavigate();
  const { unreadCount } = useNotificationContext();

  if (!profile) {
    return <></>;
  }

  const handleSignout = async (): Promise<void> => {
    await medplum.signOut();
    navigate('/signin');
  };

  return (
    <MantineAppShell.Header h={72} pl={pl}>
      <Group justify="space-between" pt={18}>
        <Group>
          <Title order={2}>{pageTitle}</Title>
          {page === 'PatientPanel' && <PanelSelector />}
        </Group>
        <Group mr={12}>
          <IconMenu
            icon={<IconBell />}
            alertContent={unreadCount?.toString()}
            iconSize={36}
            width={493}
            position="bottom-end"
            shadow="lg"
            dropdown={<MenuDropdown />}
          />
          <ActionIcon size={36} radius="xl" variant="outline" component="a" href={HELP_HREF}>
            <IconMail size={20} />
          </ActionIcon>
          <Group>
            <Avatar color="primary-50" size="md" />
            <Text size="sm" c="black">
              {formatHumanName(profile?.name?.[0] as HumanName)}
            </Text>
          </Group>
          <ActionIcon size="lg" onClick={handleSignout}>
            <IconLogout />
          </ActionIcon>
        </Group>
      </Group>
    </MantineAppShell.Header>
  );
};
