import React, { forwardRef } from 'react';
import { Practitioner } from '@medplum/fhirtypes';
import { Group, Text, Box } from '@mantine/core';
import { AsyncAutocompleteOption } from '@medplum/react';

export const CustomPractitionerLineItemComponent = forwardRef<HTMLDivElement, AsyncAutocompleteOption<Practitioner>>(
  ({ label, resource: _resource, active: _active, ...others }: AsyncAutocompleteOption<Practitioner>, ref) => {
    return (
      <Box ref={ref} {...others}>
        <Group wrap="nowrap">
          <Text>{label}</Text>
        </Group>
      </Box>
    );
  },
);

export default CustomPractitionerLineItemComponent;
