export const HEADER_HEIGHT = 60;
export const VERTICAL_NAV_WIDTH = 296;
export const VERTICAL_NAV_WIDTH_COLLAPSED = 86;
export const HELP_HREF = 'https://imaginepediatrics.atlassian.net/servicedesk/customer/portal/3/group/-1';

export const CONTACT_TYPE_OTHER = 'other';
export const RELATIONSHIP_OTHER = 'OTH';
export const OTHER_DISPLAY = 'Other';
export const CONTACT_TYPE_REQUIRED_ERROR_MESSAGE = 'Contact type is required';
export const RELATIONSHIP_REQUIRED_ERROR_MESSAGE = 'Relationship is required';
export const CUSTOM_CONTACT_TYPE_REQUIRED_ERROR_MESSAGE = 'Please specify contact type';
export const CUSTOM_RELATIONSHIP_REQUIRED_ERROR_MESSAGE = 'Please specify relationship';
