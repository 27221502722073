import { Group, Stack } from '@mantine/core';
import { Communication } from '@medplum/fhirtypes';
import { getSentByImaginePediatrics } from 'imagine-dsl/utils/communication';
import React, { forwardRef } from 'react';
import { AssignmentHistory } from './AssignmentHistory';
import { Message } from './Message';
import { TimelineItem } from '../utils/timeline';

interface TimelineItemProps {
  item: TimelineItem;
  sameGroup?: boolean;
  sameSender?: boolean;
}

export const TimelineItemComponent = forwardRef<HTMLDivElement, TimelineItemProps>(
  ({ item, sameGroup = false, sameSender = false }: TimelineItemProps, ref): JSX.Element => {
    return (
      <Stack ref={ref} my={12} key={item.resource?.id}>
        {item.itemType === 'Communication' && (item.resource as Communication).payload && (
          <Group
            style={{
              justifyContent: getSentByImaginePediatrics(item.resource as Communication) ? 'flex-end' : 'unset',
            }}
            gap={10}
            align="top"
          >
            <Message message={item.resource as Communication} sameGroup={sameGroup} sameSender={sameSender} />
          </Group>
        )}
        {item.itemType === 'TaskHistory' && <AssignmentHistory timelineItem={item} />}
      </Stack>
    );
  },
);
