import { MedplumClient } from '@medplum/core';
import { EpisodeOfCare, EpisodeOfCareStatusHistory } from '@medplum/fhirtypes';
import { OutreachStatus, ProgramStatus, System } from 'const-utils';
import { updatePatientTag } from 'imagine-dsl/services/patientService';
import { getEpisodeOfCareStatusFromProgramStatus, updateProgramStatusHistory } from 'imagine-dsl/utils/patient';

const outreachStatusDataRetention = async (
  outreachStatusValue: OutreachStatus,
  outreachStatusDate: string,
  patientId: string,
  medplum: MedplumClient,
  episodeOfCare?: EpisodeOfCare,
  outreachStatusReason?: string,
): Promise<void> => {
  const refreshedPatient = await medplum.readResource('Patient', patientId);
  const episodeOfCareId = episodeOfCare?.id || '';
  const formattedDate = new Date(outreachStatusDate).toISOString();
  const currentStatus = getEpisodeOfCareStatusFromProgramStatus(ProgramStatus.NotEnrolled);

  const newStatusHistory: EpisodeOfCareStatusHistory = {
    status: currentStatus,
    period: {
      start: formattedDate,
    },
    extension: [
      {
        url: System.OutreachStatus,
        valueCode: outreachStatusValue,
      },
      ...(outreachStatusReason
        ? [
            {
              url: System.OutreachStatusReason,
              valueCode: outreachStatusReason,
            },
          ]
        : []),
    ],
  };

  if (!episodeOfCareId) {
    await medplum.createResourceIfNoneExist(
      {
        resourceType: 'EpisodeOfCare',
        status: currentStatus,
        patient: {
          reference: `Patient/${patientId}`,
        },
        statusHistory: [newStatusHistory],
        period: {
          start: formattedDate,
        },
      },
      `patient=Patient/${patientId}`,
    );
  } else {
    const refreshedEpisodeOfCare = await medplum.readResource('EpisodeOfCare', episodeOfCareId);
    const statusHistory = refreshedEpisodeOfCare?.statusHistory || [];

    const updatedStatusHistory = updateProgramStatusHistory(
      statusHistory,
      newStatusHistory,
      formattedDate,
      System.OutreachStatus.toString(),
      outreachStatusValue,
      outreachStatusReason,
    );

    const updatedEpisodeOfCare = {
      ...refreshedEpisodeOfCare,
      status: currentStatus,
      period: updatedStatusHistory[updatedStatusHistory.length - 1]?.period,
      statusHistory: updatedStatusHistory,
    };

    await medplum.updateResource(updatedEpisodeOfCare);
  }

  await updatePatientTag(medplum, refreshedPatient, System.OutreachStatus, outreachStatusValue);
};

export default outreachStatusDataRetention;
