import React, { useEffect, useState } from 'react';
import { Table, Text, LoadingOverlay, Badge, Button, Box } from '@mantine/core';
import { useFetchPayors } from '@/hooks/PayorHooks/useFetchPayors';
import { Pagination } from '@/design-system/Pagination';
import { PayorModal } from './PayorModal';
import { notifications } from '@mantine/notifications';

type Payor = {
  id: string;
  name: string;
  status: boolean;
};

const PAGE_SIZE = 10;

export const PayorsPage = ({ refreshKey }: { refreshKey: number }) => {
  const { fetchPayors } = useFetchPayors();
  const [payors, setPayors] = useState<Payor[]>([]);
  const [loading, setLoading] = useState(true);
  const [editPayor, setEditPayor] = useState(false);
  const [selectedPayor, setSelectedPayor] = useState<Payor | null>(null);

  const handleUpdate = async () => {
    setLoading(true);
    try {
      const payorData = await fetchPayors();
      setPayors(payorData || []);
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: `Failed to fetch payors: ${error}`,
        color: 'status-error',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleUpdate();
  }, [refreshKey]);

  const handleEditPayor = (payor: Payor) => {
    setSelectedPayor(payor);
    setEditPayor(true);
  };

  return (
    <Box>
      {selectedPayor && (
        <PayorModal
          mode="edit"
          opened={editPayor}
          onClose={() => setEditPayor(false)}
          payorData={selectedPayor}
          onUpdate={handleUpdate}
        />
      )}

      <Table highlightOnHover horizontalSpacing="sm" verticalSpacing="sm">
        <Table.Thead>
          <Table.Tr>
            <Table.Th>ID</Table.Th>
            <Table.Th>Name</Table.Th>
            <Table.Th>Status</Table.Th>
            <Table.Th>Actions</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {loading ? (
            <Table.Tr>
              <Table.Th colSpan={2}>
                <LoadingOverlay visible zIndex={1000} overlayProps={{ radius: 'sm', blur: 2 }} />
              </Table.Th>
            </Table.Tr>
          ) : (
            payors.map((payor, index) => (
              <Table.Tr key={index}>
                <Table.Td>{payor.id}</Table.Td>
                <Table.Td>{payor.name}</Table.Td>
                <Table.Td>
                  <Badge color={payor.status ? 'green' : 'red'} variant="filled">
                    {payor.status ? 'Active' : 'Inactive'}
                  </Badge>
                </Table.Td>
                <Table.Td>
                  <Button onClick={() => handleEditPayor(payor)} variant="outline">
                    Edit
                  </Button>
                </Table.Td>
              </Table.Tr>
            ))
          )}
          {payors.length === 0 && !loading && (
            <Table.Tr>
              <Table.Td colSpan={2}>
                <Text size="xl" fw={700} c="imagine-green" mt="lg" ml="lg">
                  No payors found.
                </Text>
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
      <Pagination perPage={PAGE_SIZE} count={payors.length} />
    </Box>
  );
};
