import { useMedplum } from '@medplum/react';
import { notifications } from '@mantine/notifications';
import { useCallback } from 'react';
import { System } from 'const-utils';

export const useInactivateMarket = () => {
  const medplum = useMedplum();
  const inactivateMarket = useCallback(
    async (id: string, reason: string) => {
      try {
        const inactivateMarket = await medplum.patchResource('Organization', id, [
          {
            op: 'replace',
            path: '/active',
            value: false,
          },
          {
            op: 'add',
            path: '/extension/-',
            value: {
              url: System.OrganizationDeactivationNote,
              valueString: reason,
            },
          },
        ]);
        notifications.show({
          title: 'Market Inactivated',
          message: 'The market has been successfully inactivated',
          color: 'status-success',
        });
        return inactivateMarket;
      } catch (error) {
        notifications.show({
          title: 'Error',
          message: 'Failed to inactivate market',
          color: 'status-error',
        });
        return null;
      }
    },
    [medplum],
  );
  return { inactivateMarket };
};

export const useActivateMarket = () => {
  const medplum = useMedplum();
  const activateMarket = useCallback(
    async (id: string, reason: string) => {
      try {
        const activateMarket = await medplum.patchResource('Organization', id, [
          {
            op: 'replace',
            path: '/active',
            value: true,
          },
          {
            op: 'add',
            path: '/extension/-',
            value: {
              url: System.OrganizationActivationNote,
              valueString: reason,
            },
          },
        ]);
        notifications.show({
          title: 'Market Inactivated',
          message: 'The market has been successfully activated',
          color: 'status-success',
        });
        return activateMarket;
      } catch (error) {
        notifications.show({
          title: 'Error',
          message: 'Failed to activate market',
          color: 'status-error',
        });
        return null;
      }
    },
    [medplum],
  );
  return { activateMarket };
};
