import { getErrorMessage, logError } from '@/errors';
import { useApolloClient } from '@apollo/client';
import {
  ActionIcon,
  Group,
  Textarea,
  Text,
  Box,
  Button,
  UnstyledButton,
  ThemeIcon,
  Divider,
  LoadingOverlay,
  Select,
  type ComboboxItem,
  Popover,
} from '@mantine/core';
import { useDebouncedCallback } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import { AttachmentModal } from './AttachmentModal';
import { notifications } from '@mantine/notifications';
import { createReference } from '@medplum/core';
import { Attachment, DocumentReference, Reference } from '@medplum/fhirtypes';
import { useMedplum, AttachmentButton, AttachmentDisplay, useMedplumProfile } from '@medplum/react';
import {
  IconAlertCircle,
  IconArrowsLeftRight,
  IconLanguage,
  IconPaperclip,
  IconSend,
  IconX,
} from '@tabler/icons-react';
import {
  CommunicationMedium,
  Concepts,
  codeSystemConcepts,
  DocumentType,
  System,
  TaskType,
  HL7System,
} from 'const-utils';
import { GetDocumentsForPatientDocument, useGetActiveEncounterIdsByPatientIdQuery, Maybe } from 'medplum-gql';
import { assignTask } from 'imagine-dsl/services/taskService';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { buildCommunication } from 'imagine-dsl/utils/communication';
import { buildTask } from 'imagine-dsl/utils/task';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import { imagineClient } from '@/hooks/useImagineApolloClient';
import { useTranslateChatTextMutation } from 'imagine-gql/client';
import { Task } from 'imagine-dsl/utils/types/task';
import { PreferredLanguage } from 'const-utils/codeSystems/ImaginePediatrics/Patient';
import { getCookie, setCookie } from '@/utils/cookies';

interface ChatFormData {
  contentString: string;
  attachment: Attachment | undefined;
}

interface ChatInputProps {
  patientId: string;
  patientLanguagePreference: string;
  managingOrgId?: Maybe<string>;
  task?: Task;
  assignee?: Maybe<{
    id?: Maybe<string>;
  }>;
  incompleteEncounterId?: Maybe<string>;
  refetchMessages: () => Promise<void>;
  refreshTasks: () => Promise<void>;
}

export function ChatInput({
  refreshTasks,
  task,
  patientId,
  managingOrgId,
  patientLanguagePreference,
  assignee,
  incompleteEncounterId,
  refetchMessages,
}: ChatInputProps): JSX.Element {
  const medplum = useMedplum();
  const profile = useMedplumProfile();
  const apolloClient = useApolloClient();
  const [pendingAttachment, setPendingAttachment] = useState<Attachment | undefined>();
  const [pendingAttachmentDescription, setPendingAttachmentDescription] = useState('');
  const [pendingAttachmentType, setPendingAttachmentType] = useState<string>(DocumentType.ChatAttachment);
  const [sendingChat, setSendingChat] = useState(false);
  const [isTranslationToggled, setIsTranslationToggled] = useState(false);
  const [isTranslationLoading, setIsTranslationLoading] = useState(false);
  const [isSubmissionPendingTranslation, setIsSubmissionPendingTranslation] = useState(false);
  const [isPopoverOpened, setIsPopoverOpened] = useState(true);
  const [isError, setIsError] = useState(false);
  const [translatedText, setTranslatedText] = useState('');
  const [sourceLanguage, setSourceLanguage] = useState<string>(PreferredLanguage.En);
  const [targetLanguage, setTargetLanguage] = useState<string>(
    patientLanguagePreference.includes(PreferredLanguage.En) ? PreferredLanguage.Es : patientLanguagePreference,
  );
  const flags = useFeatureFlags();
  const originalText = useRef('');

  const isTranslationEnabled = flags.TranslateMessage && isTranslationToggled;

  const initialValues: ChatFormData = {
    contentString: '',
    attachment: undefined,
  };

  const filteredLanguagePreferences = [
    PreferredLanguage.Asl,
    PreferredLanguage.Unknown,
    PreferredLanguage.Misc,
    PreferredLanguage.Cpf,
    PreferredLanguage.Fy,
    PreferredLanguage.FyNL,
  ];

  const preferredLanguageCodes = codeSystemConcepts[Concepts.PreferredLanguage].concept;
  const spanishLanguages = preferredLanguageCodes
    ?.filter((c) => c.code.includes('es'))
    .map((c) => {
      return { label: c.display, value: c.code } as ComboboxItem;
    });
  const englishLanguages = preferredLanguageCodes
    ?.filter((c) => c.code.includes('en'))
    .map((c) => {
      return { label: c.display, value: c.code } as ComboboxItem;
    });

  const otherLanguages =
    preferredLanguageCodes
      ?.filter(
        (c) =>
          !filteredLanguagePreferences.includes(c.code as PreferredLanguage) &&
          !c.code.includes('es') &&
          !c.code.includes('en'),
      )
      .map((c) => {
        return { label: c.display, value: c.code } as ComboboxItem;
      }) || [];

  const languages = [
    { group: 'English', items: englishLanguages || [] },
    { group: 'Spanish', items: spanishLanguages || [] },
    { group: 'Other', items: otherLanguages || [] },
  ];

  // Enforce mutual exclusivity between source and target languages
  const sourceLanguages = languages.map((group) => {
    return {
      group: group.group,
      items: group.items.map((item) => {
        if (item.value === targetLanguage) {
          return { ...item, disabled: true };
        }

        return item;
      }),
    };
  });

  const targetLanguages = languages.map((group) => {
    return {
      group: group.group,
      items: group.items.map((item) => {
        if (item.value === sourceLanguage) {
          return { ...item, disabled: true };
        }

        return item;
      }),
    };
  });

  const [translate] = useTranslateChatTextMutation({
    client: imagineClient,
  });

  const form = useForm({
    initialValues,
  });

  const { data: activeEncounterIds } = useGetActiveEncounterIdsByPatientIdQuery({
    variables: {
      patientId: `Patient/${patientId}`,
    },
    skip: !patientId,
  });

  const encounterId =
    incompleteEncounterId || task?.focus?.resource?.id || activeEncounterIds?.EncounterList?.at(0)?.id;

  const fetchTranslatedText = (sourceLanguage: string, targetLanguage: string): void => {
    if (!form.values.contentString) {
      setTranslatedText('');
      setIsTranslationLoading(false);
      return;
    }

    translate({
      variables: {
        text: form.values.contentString,
        sourceLanguage,
        targetLanguage,
      },
    })
      .then(({ data }) => {
        setTranslatedText(data?.translateText?.translatedText || '');
      })
      .catch((err) => {
        notifications.show({
          title: 'Error translating text',
          message: getErrorMessage(err),
          color: 'status-error',
        });
      })
      .finally(() => {
        setIsTranslationLoading(false);
      });
  };

  const debouncedFetchTranslatedText = useDebouncedCallback(
    () => fetchTranslatedText(sourceLanguage, targetLanguage),
    750,
  );

  const onSendChat = useCallback(
    async (formData: ChatFormData): Promise<void> => {
      let docReference: DocumentReference | undefined;
      const patientReference = { reference: `Patient/${patientId}` };

      if (form.values.attachment) {
        docReference = await medplum.createResource({
          resourceType: 'DocumentReference',
          content: [
            {
              attachment: form.values.attachment,
            },
          ],
          type: {
            coding: [
              {
                system: System.DocumentType,
                code: pendingAttachmentType,
              },
            ],
          },
          status: 'current',
          subject: patientReference,
          description: pendingAttachmentDescription,
        });

        await medplum.createResource(
          buildTask({
            status: 'ready',
            type: TaskType.Document,
            description: 'Document review by caregiver',
            intent: 'order',
            for: patientReference,
            basedOn: [createReference(docReference)],
          }),
        );
      }

      const contentReference: Reference<DocumentReference> | undefined = docReference
        ? createReference(docReference)
        : undefined;

      if (!profile) {
        throw new Error('Profile not found');
      }

      const communicationObj = buildCommunication({
        medium: CommunicationMedium.Chat,
        contentString: isTranslationEnabled ? translatedText : formData.contentString,
        sender: createReference(profile),
        subject: patientReference,
        encounter: encounterId
          ? {
              reference: `Encounter/${encounterId}`,
            }
          : undefined,
        contentReference,
        extension:
          isTranslationEnabled && translatedText.length
            ? [
                {
                  url: HL7System.Translation,
                  extension: [
                    {
                      url: 'lang',
                      valueString: targetLanguage,
                    },
                    {
                      url: 'source',
                      valueString: sourceLanguage,
                    },
                    {
                      url: 'content',
                      valueString: originalText.current,
                    },
                  ],
                },
              ]
            : undefined,
      });

      medplum
        .createResource(communicationObj)
        .then(async () => {
          form.reset();
          await refetchMessages();
          await apolloClient.refetchQueries({ include: [GetDocumentsForPatientDocument] }).catch(logError);
        })
        .catch((error) => {
          notifications.show({
            title: 'Error',
            message: error,
            color: 'status-error',
          });
        });
    },
    [
      apolloClient,
      form,
      isTranslationEnabled,
      medplum,
      patientId,
      pendingAttachmentDescription,
      pendingAttachmentType,
      profile,
      refetchMessages,
      targetLanguage,
      sourceLanguage,
      translatedText,
      encounterId,
    ],
  );

  const onSubmit = useCallback(
    (values: ChatFormData): void => {
      setSendingChat(true);

      if (isTranslationEnabled) {
        originalText.current = values.contentString;
        values.contentString = translatedText;
      }

      onSendChat(values)
        .then(() => {
          setSendingChat(false);

          if (isTranslationEnabled) {
            setTranslatedText('');
            originalText.current = '';
          }

          if (isError) {
            setIsError(false);
          }
        })
        .catch((err) => {
          setSendingChat(false);
          setIsError(true);
          notifications.show({
            title: 'Error sending message',
            message: getErrorMessage(err),
            color: 'status-error',
          });
        });
    },
    [isTranslationEnabled, translatedText, isError, onSendChat],
  );

  const onUploadChange = (value: Attachment | undefined): void => {
    setPendingAttachment(value);
  };

  const onAttachDocument = (value: Attachment | undefined): void => {
    form.setFieldValue('attachment', value);
  };

  const onAssignToMe = (): void => {
    if (!task?.id || !profile?.id || !managingOrgId) {
      return;
    }

    assignTask(medplum, profile.id!, task.id!, managingOrgId)
      .then(async () => {
        await refreshTasks();
      })
      .catch((err) => {
        notifications.show({
          title: 'Error assigning task',
          message: getErrorMessage(err),
          color: 'status-error',
        });
      });
  };

  const isSendDisabled = sendingChat || (!form.values.contentString && !form.values.attachment) || isTranslationLoading;
  const isChatAllowed = !!assignee || task?.status === 'completed' || task === undefined;
  const isTranslationBlank = translatedText === '';
  const isPopoverEnabled = getCookie('hasSeenNewTranslationFeature') !== 'true';

  const translationPlaceholder = `${
    languages?.flatMap((language) => language.items || []).find((item) => item.value === targetLanguage)?.label
  } translation`;

  // Adds a line break after each newline so it's rendered correctly below the chat input
  const formattedTranslatedText = translatedText.split('\n').map((line, index) => (
    <React.Fragment key={`${line}-${index}`}>
      {line}
      <br />
    </React.Fragment>
  ));

  const handleTranslationViewToggle = () => {
    if (isTranslationEnabled) {
      setTranslatedText('');
    }

    if (isPopoverEnabled) {
      closeAndDisablePopover();
    }

    fetchTranslatedText(sourceLanguage, targetLanguage);
    setIsTranslationToggled(!isTranslationToggled);
  };

  const closeAndDisablePopover = () => {
    setIsPopoverOpened(false);
    setCookie('hasSeenNewTranslationFeature', 'true', 30);
  };

  const handleSwitchLanguagesButton = () => {
    const tempLanguage = sourceLanguage;
    setSourceLanguage(targetLanguage);
    setTargetLanguage(tempLanguage);

    const tempContent = form.values.contentString;
    form.setFieldValue('contentString', translatedText);
    setTranslatedText(tempContent);
  };

  const handleSourceLanguageChange = (value: string | null) => {
    if (!value) return;

    setSourceLanguage(value);
  };

  const handleTargetLanguageChange = (value: string | null) => {
    if (!value) return;

    setTargetLanguage(value);
    fetchTranslatedText(sourceLanguage, value);
  };

  // Required to submit the message after translation is done if 'Enter' is pressed
  useEffect(() => {
    if (isSubmissionPendingTranslation && !isTranslationLoading) {
      setIsSubmissionPendingTranslation(false);
      onSubmit(form.values);
      setTranslatedText('');
    }
  }, [isSubmissionPendingTranslation, isTranslationLoading, form.values, onSubmit]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>): void => {
    if (isTranslationEnabled) {
      setIsTranslationLoading(true);
      debouncedFetchTranslatedText();
    }

    if (event.key === 'Enter' && event.shiftKey) {
      return;
    }

    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();

      if (form.values.attachment || form.values.contentString) {
        // If translating, trigger useEffect to submit after translation is done
        if (isTranslationLoading) {
          setIsSubmissionPendingTranslation(true);
        } else {
          onSubmit(form.values);
          setTranslatedText('');
        }
      }
    }
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Group pos="relative">
        {!isChatAllowed && (
          <Box h={44} bg="imagine-gray.1" bd="1px solid brand-gray.4" flex="1">
            <Group mt={10} ml={8} gap={6}>
              <UnstyledButton onClick={() => onAssignToMe()} td="underline" fw={700} c="imagine-green">
                Assign to me
              </UnstyledButton>
              <Text>to send a message</Text>
            </Group>
          </Box>
        )}

        {isChatAllowed && (
          <Group
            pos="relative"
            bd={isError ? '1px solid status-error' : '1px solid var(--mantine-color-brand-gray-6)'}
            w="100%"
            wrap={isTranslationEnabled ? 'wrap' : 'nowrap'}
            py={8}
            px={14}
          >
            <Textarea
              name="contentString"
              placeholder={'Type your message. Use Shift + Enter to add a new line.'}
              autosize
              maxRows={11}
              onKeyDown={handleKeyDown}
              {...form.getInputProps('contentString')}
              w="100%"
              styles={{
                input: {
                  border: 'none',
                  paddingLeft: 0,
                },
              }}
            />

            {isTranslationEnabled && (
              <>
                <Divider w="100%" size="xs" />
                <Text
                  data-testid="translated-text"
                  bg="var(--mantine-color-primary-50-1)"
                  c={translatedText === '' ? 'brand-gray.6' : 'brand-gray.8'}
                  p="0.25rem"
                  pos="relative"
                  style={{
                    borderRadius: 8,
                    maxHeight: '250px',
                    overflowY: 'auto',
                  }}
                >
                  <LoadingOverlay
                    visible={!isSubmissionPendingTranslation && isTranslationLoading}
                    loaderProps={{ type: 'dots', size: 'md' }}
                  />
                  {isTranslationBlank ? translationPlaceholder : formattedTranslatedText}
                </Text>
              </>
            )}

            <Group w={isTranslationEnabled ? '100%' : 'fit-content'} justify="space-between">
              {isTranslationEnabled && (
                <Group data-testid="language-selectors">
                  <Select
                    data={sourceLanguages}
                    value={sourceLanguage}
                    onChange={handleSourceLanguageChange}
                    allowDeselect={false}
                    nothingFoundMessage="Nothing found..."
                    searchable
                  />

                  <ActionIcon onClick={handleSwitchLanguagesButton}>
                    <ThemeIcon>
                      <IconArrowsLeftRight size={20} />
                    </ThemeIcon>
                  </ActionIcon>

                  <Select
                    data={targetLanguages}
                    value={targetLanguage}
                    onChange={handleTargetLanguageChange}
                    allowDeselect={false}
                    nothingFoundMessage="Nothing found..."
                    searchable
                  />
                </Group>
              )}

              <Group wrap="nowrap">
                {/* TODO: Remove this popover before 4-21-25 */}
                {flags.TranslateMessage && (
                  <Popover
                    opened={isPopoverOpened}
                    position="top"
                    offset={{ crossAxis: -100 }}
                    withArrow
                    shadow="md"
                    radius="lg"
                  >
                    <Popover.Target>
                      <ActionIcon
                        data-testid="translate-button"
                        bg={isTranslationEnabled ? 'var(--mantine-color-primary-50-1)' : 'none'}
                        radius="lg"
                        p="md"
                        onClick={handleTranslationViewToggle}
                      >
                        <ThemeIcon>
                          <IconLanguage size={20} />
                        </ThemeIcon>
                      </ActionIcon>
                    </Popover.Target>
                    {isPopoverEnabled && (
                      <Popover.Dropdown bg="imagine-green" c="white" w="250px" p={0}>
                        <div
                          style={{
                            display: 'flex',
                          }}
                        >
                          <Text size="xs" p="xs">
                            <b>NEW:</b> Translate your messages from English to Spanish
                          </Text>
                          <ActionIcon onClick={closeAndDisablePopover} bg="none" pr="xs" pt="xs">
                            <ThemeIcon c="white">
                              <IconX size={16} />
                            </ThemeIcon>
                          </ActionIcon>
                        </div>
                      </Popover.Dropdown>
                    )}
                  </Popover>
                )}

                <AttachmentButton onUpload={onUploadChange}>
                  {(props) => (
                    <ActionIcon {...props} disabled={!isChatAllowed}>
                      <ThemeIcon>
                        <IconPaperclip size={20} />
                      </ThemeIcon>
                    </ActionIcon>
                  )}
                </AttachmentButton>

                <Divider orientation="vertical" />
                <ActionIcon disabled={isSendDisabled} type="submit">
                  <ThemeIcon>
                    <IconSend size={20} />
                  </ThemeIcon>
                </ActionIcon>
              </Group>
            </Group>
            {isError && (
              <Group pos="absolute" right={0} bottom={-32} gap={0}>
                <ThemeIcon c="status-error">
                  <IconAlertCircle size={16} />
                </ThemeIcon>
                <Text c="status-error" size="xs">
                  Message failed to send.{' '}
                  <b>
                    <u>Retry sending</u>
                  </b>
                </Text>
              </Group>
            )}
          </Group>
        )}

        <LoadingOverlay visible={isSubmissionPendingTranslation} loaderProps={{ type: 'dots', size: 'xl' }} />
      </Group>
      {form.values.attachment && (
        <>
          <AttachmentDisplay value={form.values.attachment as Attachment} maxWidth={200} />
          <Button
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              e.stopPropagation();
              form.setFieldValue('attachment', undefined);
            }}
          >
            Remove
          </Button>
        </>
      )}
      {pendingAttachment && (
        <AttachmentModal
          pendingAttachment={pendingAttachment}
          pendingAttachmentType={pendingAttachmentType}
          setPendingAttachment={setPendingAttachment}
          setAttachmentDescription={setPendingAttachmentDescription}
          setAttachmentType={setPendingAttachmentType}
          onAttachDocument={onAttachDocument}
        />
      )}
    </form>
  );
}
