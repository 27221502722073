import { Alert, Box, Divider, Drawer, Loader } from '@mantine/core';
import { Patient, Practitioner } from '@medplum/fhirtypes';
import React from 'react';
import { Footer } from './footer';
import { Communication, Maybe } from 'medplum-gql';
import { ChatTimeline } from '@/components/shared/chatTimeline';
import { IconAlertCircle } from '@tabler/icons-react';
import classes from './TaskDrawer.module.css';
import { ChatInput } from './ChatInput';
import { genericErrorMessage } from '@/errors';
import { useChatDrawer } from './ChatDrawerProvider';
import { CompactPatientHeader } from '../shared/patient/PatientHeader';
import { ExpandableDrawer } from '@/design-system/ExpandableDrawer';
import { getPatientFirstLanguagePreferenceCode } from 'imagine-dsl/utils/patient';

interface ChatDrawerProps {
  withViewPatientProfile?: boolean;
}

const DefaultChatDrawer = ({ withViewPatientProfile = false }: ChatDrawerProps): JSX.Element => {
  const context = useChatDrawer();

  if (!context.patient?.id) {
    return <></>;
  }

  const loading = context.incompleteTasksLoading || context.timelineLoading;

  if (loading) {
    return <Loader />;
  }

  const {
    timelineError,
    refetchTimeline,
    refetchTasks,
    messages,
    onClose,
    completedTasks,
    patient,
    profile,
    incompleteTask,
    opened,
    loadMoreTimeline,
    totalTimelineCount,
  } = context;

  return (
    <ExpandableDrawer id="task-drawer" size="xxl" opened={opened} close={onClose}>
      <Drawer.Content>
        <Drawer.Header p="xs" mih={0}>
          <Drawer.CloseButton />
        </Drawer.Header>
        <Drawer.Body display="flex" h="calc(100% - 60px)" p={0} style={{ flexDirection: 'column' }}>
          <Box className={classes.patientHeader}>
            <CompactPatientHeader patientId={patient.id} withViewPatientProfile={withViewPatientProfile} />
          </Box>
          <Box px={80} style={{ flexGrow: 1, overflow: 'scroll' }} pb={16} mt={16} mih={140}>
            {loading && <Loader />}
            {timelineError && (
              <Alert icon={<IconAlertCircle size="1rem" />} title={genericErrorMessage} color="red">
                {timelineError.message}
              </Alert>
            )}
            {messages && (
              <ChatTimeline
                loadMoreTimeline={loadMoreTimeline}
                completedTasks={completedTasks}
                currentTask={incompleteTask}
                messages={messages as Communication[]}
                totalTimelineCount={totalTimelineCount}
              />
            )}
          </Box>
          <Box px={80} pt={12} style={{ borderTop: '1px solid var(--mantine-color-brand-gray-4' }} mx={0}>
            <ChatInput
              managingOrgId={
                (incompleteTask?.for?.resource as Maybe<Patient> | undefined)?.managingOrganization?.resource?.id
              }
              task={incompleteTask}
              refetchMessages={async () => {
                await refetchTimeline();
              }}
              refreshTasks={async () => {
                await refetchTasks();
              }}
              assignee={incompleteTask?.owner?.resource}
              patientId={patient.id}
              patientLanguagePreference={getPatientFirstLanguagePreferenceCode(patient.communication || [])}
            />
          </Box>
          <Divider mx={80} my={8} />
          {incompleteTask && (
            <Box px={80}>
              <Footer
                refreshTasks={refetchTasks}
                task={incompleteTask}
                assignee={incompleteTask.owner?.resource as Practitioner}
                profile={profile}
              />
            </Box>
          )}
        </Drawer.Body>
      </Drawer.Content>
    </ExpandableDrawer>
  );
};

export function ChatDrawer({ withViewPatientProfile = false }: ChatDrawerProps): JSX.Element {
  return <DefaultChatDrawer withViewPatientProfile={withViewPatientProfile} />;
}
