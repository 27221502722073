import React, { useEffect, useState } from 'react';
import {
  Modal,
  Button,
  CloseButton,
  Group,
  Flex,
  TextInput,
  LoadingOverlay,
  Select,
  ComboboxItem,
} from '@mantine/core';
import { useMedplum } from '@medplum/react';
import { MappingResourceIdToPodName } from 'imagine-dsl/utils/types/engagementPodMapping';
import {
  createEngagementPodAndMappingBundle,
  getBasicResourceIdFromBundle,
} from 'imagine-dsl/utils/engagementPodMapping';
import { notifications } from '@mantine/notifications';
import { Bundle, Resource } from '@medplum/fhirtypes';
import { useMarketOptions } from '@/hooks/MarketHooks/useMarketOptions';

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  addPodMapping: (newMapping: MappingResourceIdToPodName) => void;
}

export const CreateEngagementPodModal = ({ isOpen, closeModal, addPodMapping }: ModalProps) => {
  const [podName, setPodName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [markets, setMarkets] = useState<ComboboxItem[]>([]);
  const [selectedMarket, setSelectedMarket] = useState<string | null>(null);
  const medplum = useMedplum();
  const { fetchMarketOptions } = useMarketOptions();

  useEffect(() => {
    const fetchMarkets = async () => {
      try {
        const marketOptions = await fetchMarketOptions();
        const comboboxFields = marketOptions.map((market) => {
          return {
            label: market.name as string,
            value: market.identifier,
          };
        });

        setMarkets(comboboxFields);
      } catch (err) {
        notifications.show({
          message: 'Error fetching market options',
          color: 'status-error',
        });
      }
    };

    fetchMarkets();
  }, [fetchMarketOptions]);

  const handleOnChange = (value: string | null) => {
    if (value === null) {
      setSelectedMarket(null);
    }
    setSelectedMarket(value);
  };

  const onCreate = async () => {
    setIsLoading(true);
    const bundle: Bundle<Resource> = createEngagementPodAndMappingBundle(podName, selectedMarket!);

    try {
      const result = await medplum.executeBatch(bundle);
      if (result) {
        const basicResourceId = getBasicResourceIdFromBundle(result);
        const newPod: MappingResourceIdToPodName = {
          podName,
          id: basicResourceId,
        };
        addPodMapping(newPod);
      }
      notifications.show({
        message: 'Pod created',
        color: 'status-success',
      });
    } catch (error) {
      notifications.show({
        message: 'Error creating engagement pod',
        color: 'status-error',
      });
    } finally {
      setIsLoading(false);
      closeModal();
    }
  };

  return (
    <Modal withCloseButton={false} centered size={'lg'} padding={'md'} opened={isOpen} onClose={closeModal}>
      <LoadingOverlay visible={isLoading} />
      <Group justify="space-between">
        <Modal.Title c="imagine-green">Create new pod</Modal.Title>
        <CloseButton onClick={closeModal} />
      </Group>
      <Modal.Body p="0" my="lg">
        <TextInput label="Pod name" required onChange={(e) => setPodName(e.currentTarget.value)} />
        <Select required my="xl" label="Market" data={markets} onChange={(value) => handleOnChange(value)} clearable />
      </Modal.Body>
      <Flex justify={'end'}>
        <Button mr={'md'} variant="outline" onClick={closeModal}>
          Cancel
        </Button>
        <Button disabled={!podName || !selectedMarket} onClick={onCreate}>
          Create pod
        </Button>
      </Flex>
    </Modal>
  );
};
