export enum ClinicalTeamPod {
  PodA = 'pod-a',
  PodB = 'pod-b',
  PodC = 'pod-c',
  FL = 'fl-pod',
  FLFlex = 'fl-flex',
  TXFlex = 'tx-flex',
  BHTexas = 'bh-texas',
  BHFlorida = 'bh-florida',
  BHCrisis = 'bh-crisis',
  BHNewYork = 'bh-new-york',
  NYPod = 'ny-pod',
}

export enum CareTeam {
  ClinicalCareTeam = 'Clinical Care Team',
  EngagementCareTeam = 'Engagement Care Team',
}
