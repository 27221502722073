import React, { useEffect, useMemo } from 'react';
import { Box, Grid, Text, TextInput, CloseButton } from '@mantine/core';
import { ExistingAthenaProviderIdsProps } from './types';
import useAthenaProviderIds from '@/hooks/useAthenaProviderIds';

export const ExistingAthenaProviderIds = ({
  athenaMarketIds,
  setAthenaProviderIdEntries,
  athenaProviderIdEntries,
  marketOptions,
}: ExistingAthenaProviderIdsProps) => {
  const { getMarketDisplayName, handleExistingProviderIdChange } = useAthenaProviderIds(athenaProviderIdEntries);

  const marketEntries = useMemo(
    () => Object.entries(athenaMarketIds).filter(([_, providerId]) => providerId),
    [athenaMarketIds],
  );

  useEffect(() => {
    if (marketEntries.length === 0) return;

    const existingMarkets = athenaProviderIdEntries
      .filter((entry) => entry.id.startsWith('existing-'))
      .map((entry) => entry.market);

    const newEntries = marketEntries
      .filter(([marketIdentifier, _]) => {
        const marketOption = marketOptions.find((option) => option.marketIdentifier === marketIdentifier);
        return marketOption && !existingMarkets.includes(marketOption.value);
      })
      .map(([marketIdentifier, providerId]) => {
        const marketOption = marketOptions.find((option) => option.marketIdentifier === marketIdentifier);
        return {
          id: `existing-${marketIdentifier}`,
          market: marketOption?.value || '',
          providerId,
        };
      })
      .filter((entry) => entry.market);

    if (newEntries.length > 0) {
      setAthenaProviderIdEntries((prevEntries) => [...prevEntries, ...newEntries]);
    }
  }, [marketEntries, marketOptions, athenaProviderIdEntries, setAthenaProviderIdEntries]);

  if (marketEntries.length === 0) return null;

  const handleProviderIdChange = (marketIdentifier: string, value: string) => {
    handleExistingProviderIdChange(
      marketIdentifier,
      value,
      marketOptions,
      athenaProviderIdEntries,
      setAthenaProviderIdEntries,
    );
  };

  const handleClearProviderId = (marketIdentifier: string) => {
    handleProviderIdChange(marketIdentifier, '');
  };

  const renderMarketInput = (marketIdentifier: string) => {
    const marketDisplay = getMarketDisplayName(marketIdentifier, marketOptions);

    const marketOption = marketOptions.find((option) => option.marketIdentifier === marketIdentifier);
    const existingEntry = marketOption
      ? athenaProviderIdEntries.find((entry) => entry.market === marketOption.value && entry.id.startsWith('existing-'))
      : null;

    const currentValue = existingEntry ? existingEntry.providerId : athenaMarketIds[marketIdentifier] || '';

    return (
      <Grid.Col span={{ base: 12, xs: 6, sm: 4, md: 3 }} key={marketIdentifier}>
        <Box>
          <Text size="sm" fw={500}>
            {marketDisplay}
          </Text>
          <TextInput
            placeholder="Enter provider ID"
            value={currentValue}
            onChange={(e) => handleProviderIdChange(marketIdentifier, e.target.value)}
            styles={{ input: { width: '100%' } }}
            rightSection={
              currentValue !== '' ? (
                <CloseButton onClick={() => handleClearProviderId(marketIdentifier)} size="sm" />
              ) : undefined
            }
            rightSectionPointerEvents="auto"
          />
        </Box>
      </Grid.Col>
    );
  };

  return (
    <Box mb="sm">
      <Text fw={500} size="md" mb="md">
        Existing Athena Provider IDs:
      </Text>
      <Grid gutter="md">{marketEntries.map(([marketIdentifier, _]) => renderMarketInput(marketIdentifier))}</Grid>
    </Box>
  );
};

export default ExistingAthenaProviderIds;
