import React from 'react';
import { Box, Group, Loader, Paper, Stack, Table, Text, Title } from '@mantine/core';
import { useADTEncountersForTaskQuery } from '@/hooks/useADTEncountersForTaskQuery';
import { formatWithYesterdayAndToday } from 'imagine-dsl/utils/dates';
import { BaseTask } from 'imagine-dsl/models/tasks/baseTask';
import { isDefined } from 'imagine-dsl/utils/lists';
import { IconBell } from '@tabler/icons-react';

type AdtAlertsProps = {
  patientId: string;
  task?: BaseTask;
  communicationRequestId?: string;
};

export const AdtAlerts = ({ patientId, task, communicationRequestId }: AdtAlertsProps) => {
  const adtEncountersQuery = useADTEncountersForTaskQuery(patientId, communicationRequestId, task);
  const encounters = (adtEncountersQuery.data?.EncounterList ?? []).filter(isDefined);

  return (
    <>
      {adtEncountersQuery.loading ? (
        <Loader />
      ) : (
        <Stack h="100%" style={{ flex: 1 }}>
          <Box p="md" flex="1">
            <Group gap="sm">
              <IconBell />
              <Title size={18}>ADT alerts ({encounters.length})</Title>
            </Group>
            <Paper my="md" withBorder>
              <Table>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>Alert type</Table.Th>
                    <Table.Th>Admit date</Table.Th>
                    <Table.Th>Discharge date</Table.Th>
                    <Table.Th>Location</Table.Th>
                    <Table.Th>Diagnosis</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {encounters.map((encounter) => {
                    const location = encounter.location?.at(0)?.location.display;
                    const encounterClass = encounter.class.display;
                    const start = encounter.period?.start;
                    const end = encounter.period?.end;

                    return (
                      <Table.Tr key={encounter.id}>
                        <Table.Td>
                          <Text fw="bold">BH TOC - {encounterClass || 'inpatient'}</Text>
                        </Table.Td>
                        <Table.Td>
                          <Text>
                            {start ? formatWithYesterdayAndToday(new Date(start), 'EEE, MMM d yyyy h:mm a') : 'Unknown'}
                          </Text>
                        </Table.Td>
                        <Table.Td>
                          <Text>
                            {end ? formatWithYesterdayAndToday(new Date(end), 'EEE, MMM d yyyy h:mm a') : 'Unknown'}
                          </Text>
                        </Table.Td>
                        <Table.Td>
                          <Text>{location || 'Unknown'}</Text>
                        </Table.Td>
                        <Table.Td>
                          {encounter.reasonCode?.map((reason) => (
                            <Text key={`${encounter.id}-${reason.coding?.[0].code}`}>
                              {reason.coding?.[0].display || 'Unknown'}
                            </Text>
                          ))}
                        </Table.Td>
                      </Table.Tr>
                    );
                  })}
                </Table.Tbody>
              </Table>
            </Paper>
          </Box>
        </Stack>
      )}
    </>
  );
};
