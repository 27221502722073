import React from 'react';
import { Menu, Button, Checkbox, Text } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { useSearchParams } from 'react-router-dom';

/**
 * A component that displays alert types and 'All' option.
 * Utilizes URLSearchParams 'alertType' param name.
 *
 * @example
 * <AlertTypeFilter />
 */
export const AlertTypeFilter = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedAlertType = searchParams.get('alertType');

  const setSelectedAlertType = (alertType: string) => {
    if (alertType === 'All') {
      searchParams.delete('alertType');
      setSearchParams(searchParams);
      return;
    }
    searchParams.set('alertType', alertType);
    setSearchParams(searchParams);
  };

  return (
    <Menu closeOnItemClick={false} position="bottom-start" shadow="md">
      <Menu.Target>
        <Button
          variant="outline"
          bg={!selectedAlertType ? 'white' : 'var(--mantine-color-primary-50-1)'}
          rightSection={<IconChevronDown size={14} />}
        >
          {
            <Text pl={5} fw={!selectedAlertType ? 'normal' : 'bold'}>
              {!selectedAlertType ? 'Alert type' : 'Alert type (1)'}
            </Text>
          }
        </Button>
      </Menu.Target>
      <Menu.Dropdown w={250} p={10}>
        <Menu.Item
          p={10}
          leftSection={<Checkbox checked={!selectedAlertType} onChange={() => setSelectedAlertType('All')} />}
        >
          All
        </Menu.Item>
        <Menu.Item
          p={10}
          leftSection={
            <Checkbox checked={selectedAlertType === 'Inpatient'} onChange={() => setSelectedAlertType('Inpatient')} />
          }
        >
          Inpatient
        </Menu.Item>
        <Menu.Item
          p={10}
          leftSection={<Checkbox checked={selectedAlertType === 'ED'} onChange={() => setSelectedAlertType('ED')} />}
        >
          ED
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
