import React, { useMemo, useState } from 'react';
import { Text, Grid, Group, ActionIcon, Flex, Box, Avatar, ThemeIcon } from '@mantine/core';
import {
  IconPhoneFilled,
  IconMailFilled,
  IconWorld,
  IconHome,
  IconEditCircle,
  IconSettings,
} from '@tabler/icons-react';
import { ContactPoint, Extension, Patient, RelatedPerson } from '@medplum/fhirtypes';
import PatientContactInfo from './PatientContactsInfo';
import { EditPhoneNumbersModal, PhoneNumber } from '../modals/EditPhoneNumbersModal';
import { useMedplum } from '@medplum/react';
import { System } from 'const-utils';
import PatientInvalidContacts from './PatientInvalidContacts';
import { notifications } from '@mantine/notifications';
import { CaregiverDevices } from '@/components/devices/CaregiverDevices';
import { ApolloQueryResult } from '@apollo/client';
import { GetPatientQuery } from 'medplum-gql';
import { logError } from '@/errors';
import { getPhoneStatus } from 'imagine-dsl/utils/patient';

interface ExtendedRelatedPerson extends RelatedPerson {
  PatientList?: Patient[];
}
interface ContactsProps {
  patient: Patient;
  relatedPerson?: ExtendedRelatedPerson;
  withDetails?: boolean;
  refetch?: () => Promise<ApolloQueryResult<GetPatientQuery>>;
}

const filterTelecom = (entity?: { telecom?: ContactPoint[] }, type?: string): ContactPoint[] =>
  entity?.telecom?.filter((item: ContactPoint) => item?.system === type) ?? [];

export const PatientContacts = ({ patient, relatedPerson, withDetails, refetch }: ContactsProps): JSX.Element => {
  const [isEditPhoneModalOpen, setEditPhoneModalOpen] = useState(false);
  const contact = relatedPerson?.PatientList?.[0];

  const medplum = useMedplum();
  const patientPhones = filterTelecom(patient, 'phone');
  const patientEmails = filterTelecom(patient, 'email');
  const relatedPersonPhones = filterTelecom(contact, 'phone');
  const relatedPersonEmails = filterTelecom(contact, 'email');

  const editablePhoneNumbers = patientPhones.map((phone) => {
    return {
      number: phone.value,
      status: phone.extension?.find((ext) => ext.url === System.PhoneStatus.toString())?.valueCode ?? '',
      isPrimary: phone.extension?.find((ext) => ext.url === System.PrimaryPhone.toString())?.valueBoolean ?? false,
    };
  });

  const { validPhones: validPatientPhones, invalidPhones: invalidPatientPhones } = useMemo(
    () => getPhoneStatus(patientPhones),
    [patientPhones],
  );

  const { validPhones: validRelatedPersonPhones, invalidPhones: invalidRelatedPersonPhones } = useMemo(
    () => getPhoneStatus(relatedPersonPhones),
    [relatedPersonPhones],
  );

  const onSavePhoneNumbers = async (data: PhoneNumber[]): Promise<void> => {
    const patientId = patient?.id as string;
    const patientResource = await medplum.readResource('Patient', patientId);
    const telecom = patientResource.telecom || [];

    const nonPhoneTelecoms = telecom.filter((t) => t.system !== 'phone');

    // Create a new telecom array with the updated phone numbers
    const phoneTelecoms = data.map((phone) => {
      const existingPhoneIndex = telecom.findIndex((t) => t.value === phone.number);
      const primaryExtension: Extension = {
        url: System.PrimaryPhone,
        valueBoolean: phone.isPrimary,
      };

      let invalidPhoneStatusExtension: Extension | undefined = undefined;
      if (phone.status) {
        invalidPhoneStatusExtension = {
          url: System.PhoneStatus,
          valueCode: phone.status,
        };
      }

      const extensions = [primaryExtension, invalidPhoneStatusExtension].filter(Boolean) as Extension[];

      if (existingPhoneIndex !== -1) {
        const updatedPhone = {
          ...telecom[existingPhoneIndex],
          extension: extensions,
        };
        return updatedPhone;
      } else {
        return {
          system: 'phone' as const,
          value: phone.number,
          extension: extensions,
        };
      }
    });

    const updatedTelecom = [...nonPhoneTelecoms, ...phoneTelecoms];

    const updatedPatient = {
      ...patientResource,
      telecom: updatedTelecom,
    };

    try {
      await medplum.updateResource(updatedPatient);
      setEditPhoneModalOpen(false);
      notifications.show({
        message: 'Phone numbers have been edited',
        color: 'status-success',
      });
      if (refetch) {
        await refetch();
      }
    } catch (error) {
      logError(error);
      notifications.show({
        message: 'Failed to edit phone numbers',
        color: 'status-error',
      });
    }
  };

  return (
    <>
      {withDetails && contact?.photo?.length && (
        <Grid justify="center" align="center" style={{ margin: '10px 0 10px 5px' }}>
          <Avatar src={contact?.photo?.[0].url} size={'lg'} color="imagine-green" />
        </Grid>
      )}
      {!withDetails ? (
        <Group gap="xs">
          <Flex justify="space-between" style={{ width: '100%' }}>
            <Text fw={700}>Phone numbers ({patientPhones?.length})</Text>
            <ActionIcon color="imagine-green" mb={5} onClick={() => setEditPhoneModalOpen(true)}>
              <IconEditCircle size={20} cursor="pointer" />
            </ActionIcon>
          </Flex>
          <PatientContactInfo contactPoints={validPatientPhones} />
          <PatientInvalidContacts contactPoints={invalidPatientPhones} />
        </Group>
      ) : (
        <Group gap="xs">
          <Grid justify="start" align="center" style={{ margin: '10px 0 10px 5px' }}>
            <ThemeIcon size="xs" mr={8} color="imagine-green" variant="transparent">
              <IconPhoneFilled size={14} />
            </ThemeIcon>
            <Text fw={700}>Phone numbers ({relatedPersonPhones.length})</Text>
          </Grid>
          <PatientContactInfo contactPoints={validRelatedPersonPhones} />
          <PatientInvalidContacts contactPoints={invalidRelatedPersonPhones} />
        </Group>
      )}
      {!withDetails ? (
        <>
          <Text fw={700} mb={5}>
            Email address
          </Text>
          {patientEmails.length > 0 ? (
            <PatientContactInfo contactPoints={patientEmails} />
          ) : (
            <Text ml={10} mb={3}>
              --
            </Text>
          )}
        </>
      ) : (
        <>
          <Grid justify="start" align="center" style={{ margin: '10px 0 10px 5px' }}>
            <ThemeIcon size="xs" variant="transparent" color="imagine-green" mr={8}>
              <IconMailFilled size={14} />
            </ThemeIcon>
            <Text fw={700}>Email address</Text>
          </Grid>
          {relatedPersonEmails[0]?.value ? (
            <PatientContactInfo contactPoints={relatedPersonEmails} />
          ) : (
            <Text ml={28} mb={3}>
              --
            </Text>
          )}
          <Grid justify="start" align="center" style={{ margin: '10px 0 10px 5px' }}>
            <ThemeIcon size="xs" mr={8} color="imagine-green" variant="transparent">
              <IconWorld size={14} />
            </ThemeIcon>
            <Text fw={700}>Language</Text>
          </Grid>
          {contact?.communication ? (
            <PatientContactInfo caregiver={relatedPerson} type="Language" />
          ) : (
            <Text ml={28} mb={3}>
              --
            </Text>
          )}

          <Grid justify="start" align="center" style={{ margin: '10px 0 10px 5px' }}>
            <ThemeIcon size="xs" mr={8} color="imagine-green" variant="transparent">
              <IconHome size={14} />
            </ThemeIcon>
            <Text fw={700}>Address</Text>
          </Grid>
          {contact?.address ? (
            <PatientContactInfo caregiver={relatedPerson} type="Address" />
          ) : (
            <Text ml={28} mb={3}>
              --
            </Text>
          )}

          {contact?.id && (
            <>
              <Grid justify="start" align="center" style={{ margin: '10px 0 10px 5px' }}>
                <ThemeIcon size="xs" mr={8} color="imagine-green" variant="transparent">
                  <IconSettings size={14} />
                </ThemeIcon>
                <Text fw={700}>Device settings</Text>
              </Grid>
              <Box ml="22px">
                <CaregiverDevices caregiverId={contact.id} />
              </Box>
            </>
          )}
        </>
      )}
      {isEditPhoneModalOpen && (
        <EditPhoneNumbersModal
          opened={isEditPhoneModalOpen}
          onClose={() => setEditPhoneModalOpen(false)}
          onSave={(data) => onSavePhoneNumbers(data)}
          initialPhoneNumbers={editablePhoneNumbers as PhoneNumber[]}
        />
      )}
    </>
  );
};
