import React, { useEffect } from 'react';
import { Button, Group, Stack, TextInput, Tabs, Text, LoadingOverlay } from '@mantine/core';
import { PayorFormFields } from './PayorFormFields';
import { useInactivatePayor, useActivatePayor } from '@/hooks/PayorHooks/useInactivatePayor';
import { ModalWrapper } from '../Modals/ModalWrapper';
import { notifications } from '@mantine/notifications';
import { capitalize } from 'lodash';

type PayorModalProps = {
  mode: 'edit' | 'create';
  opened: boolean;
  onClose: () => void;
  onUpdate: () => void;
  payorData?: {
    id: string;
    name: string;
    status: boolean;
  };
};

export const PayorModal: React.FC<PayorModalProps> = ({ mode, opened, onClose, onUpdate, payorData }) => {
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [confirmationInput, setConfirmationInput] = React.useState('');
  const [confirmationAction, setConfirmationAction] = React.useState<'activate' | 'inactivate'>('inactivate');
  const [isLoading, setIsLoading] = React.useState(false);
  const confirmationFormatted = capitalize(confirmationAction);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
  };

  const { inactivatePayor } = useInactivatePayor();
  const { activatePayor } = useActivatePayor();

  const handleConfirmation = async () => {
    if (confirmationInput === payorData?.name) {
      try {
        const action = confirmationAction === 'inactivate' ? inactivatePayor : activatePayor;
        const result = await action(payorData.id);
        if (result) {
          setShowConfirmation(false);
          onClose();
          onUpdate();
          notifications.show({
            title: 'Success',
            message: `Payor successfully ${confirmationAction}.`,
            color: 'status-success',
          });
        }
      } catch (error) {
        notifications.show({
          title: 'Error',
          message: `${confirmationFormatted} failed. Please enter the correct payor name to confirm.`,
          color: 'status-error',
        });
      } finally {
        setIsLoading(false);
        onClose();
      }
    } else {
      notifications.show({
        title: 'Error',
        message: 'Market name does not match. Please enter the correct market name to confirm.',
        color: 'status-error',
      });
    }
  };

  useEffect(() => {
    if (!opened) {
      setShowConfirmation(false);
      setConfirmationInput('');
      setConfirmationAction('inactivate');
      setIsLoading(false);
    }
  }, [opened]);

  return (
    <ModalWrapper title={mode === 'edit' ? 'Update Payor' : 'Add Payor'} opened={opened} onClose={onClose}>
      <LoadingOverlay visible={isLoading} />
      <form onSubmit={handleSubmit}>
        {mode === 'edit' && (
          <Tabs defaultValue="edit" variant="outline">
            <Tabs.List>
              <Tabs.Tab value="edit">Edit Payor</Tabs.Tab>
              {payorData?.status === true ? (
                <Tabs.Tab value="inactivate" onClick={() => setConfirmationAction('inactivate')}>
                  Inactivate Payor
                </Tabs.Tab>
              ) : (
                <Tabs.Tab value="activate" onClick={() => setConfirmationAction('activate')}>
                  Activate Payor
                </Tabs.Tab>
              )}
            </Tabs.List>

            <Tabs.Panel value="edit">
              <PayorFormFields
                mode="edit"
                onClose={onClose}
                onUpdate={onUpdate}
                payorData={{
                  name: payorData?.name || '',
                  id: payorData?.id || '',
                  status: payorData?.status ? 'active' : 'inactive',
                }}
              />
            </Tabs.Panel>

            <Tabs.Panel value="inactivate">
              <Stack gap={14} p="md">
                <TextInput label="Payor ID" placeholder="Enter Payor ID" defaultValue={payorData?.id} disabled />
                <TextInput label="Payor Name" defaultValue={payorData?.name} disabled />
              </Stack>
              <Group justify="flex-end" ml="auto" pt={10}>
                <Button variant="outline" onClick={onClose}>
                  Cancel
                </Button>
                <Button onClick={() => setShowConfirmation(true)}>Inactivate Payor</Button>
              </Group>
            </Tabs.Panel>

            <Tabs.Panel value="activate">
              <Stack gap={14} p="md">
                <TextInput label="Payor ID" placeholder="Enter Payor ID" defaultValue={payorData?.id} disabled />
                <TextInput label="Payor Name" defaultValue={payorData?.name} disabled />
              </Stack>
              <Group justify="flex-end" ml="auto" pt={10}>
                <Button variant="outline" onClick={onClose}>
                  Cancel
                </Button>
                <Button onClick={() => setShowConfirmation(true)}>Activate Payor</Button>
              </Group>
            </Tabs.Panel>
          </Tabs>
        )}
        {mode === 'create' && <PayorFormFields mode="create" onClose={onClose} onUpdate={onUpdate} />}
      </form>

      {showConfirmation && (
        <ModalWrapper
          title={`Confirm ${confirmationAction.charAt(0).toUpperCase() + confirmationAction.slice(1)}`}
          opened={showConfirmation}
          onClose={() => setShowConfirmation(false)}
        >
          <Text>
            Are you sure you want to {confirmationAction} <strong>{payorData?.name}</strong> as a payor?
          </Text>
          <TextInput
            placeholder="Type payor name to confirm"
            value={confirmationInput}
            onChange={(e) => setConfirmationInput(e.currentTarget.value)}
            required
          />
          <Group justify="flex-end" ml="auto" pt={10}>
            <Button variant="outline" onClick={() => setShowConfirmation(false)}>
              Cancel
            </Button>
            <Button onClick={handleConfirmation}>Confirm</Button>
          </Group>
        </ModalWrapper>
      )}
    </ModalWrapper>
  );
};
