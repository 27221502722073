import { toZonedTime, format } from 'date-fns-tz';
// biome-ignore lint/style/useNodejsImportProtocol: node:buffer is not available in the browser
import { Buffer } from 'buffer';
import { MedplumClient, parseReference } from '@medplum/core';
import { Practitioner, QuestionnaireResponse, Task } from '@medplum/fhirtypes';
import { athenaIdentifier, getName } from '../utils/patient';
import { getAthenaIdExtension } from '../utils/extensions';
import { getAthenaProviderIdForMarket, getMarketIdentifier } from '../utils/identifiers';
import { generateAthenaQuestionnairePDF } from './athenaQuestionnaireService';
import { extractQuestionsAndAnswers, TranslationCodeMapping } from './questionnaireService';
import { System } from 'const-utils';
import { getTimezone } from '../utils/timezone';

export type Question = {
  questionid: string;
  answer?: any;
  freetextanswer?: any;
};

export const getAthenaToken = async (clientId: string, clientSecret: string, baseUrl: string) => {
  const authUrl = `${baseUrl}/oauth2/v1/token`;
  const scope = 'athena/service/Athenanet.MDP.*';

  const data = new URLSearchParams({
    grant_type: 'client_credentials',
    scope: scope || '',
  });

  const basicAuth = Buffer.from(`${clientId}:${clientSecret}`).toString('base64');

  try {
    const response = await fetch(authUrl, {
      method: 'POST',
      headers: {
        Authorization: `Basic ${basicAuth}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: data.toString(),
    });

    if (!response.ok) {
      throw new Error(`Error fetching athena token:', ${response.status} ${response.statusText}`);
    }

    const authResponse = await response.json();
    return authResponse;
  } catch (error) {
    throw new Error(`Error fetching athena token:', ${error}`);
  }
};

export const createOpenAppointment = async (
  authToken: string,
  baseUrl: string,
  practiceId: string,
  departmentId: string,
  providerid: string,
  appointmentDate: string,
  appointmentTime: string,
) => {
  const body = new URLSearchParams({
    appointmentdate: appointmentDate,
    appointmenttime: appointmentTime,
    appointmenttypeid: '803',
    departmentid: departmentId,
    providerid: providerid,
  });

  try {
    const url = `${baseUrl}/v1/${practiceId}/appointments/open`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: body,
    });

    if (!response.ok) {
      const { error } = await response.json();
      throw new Error(error);
    }

    const appointments = await response.json();
    return appointments;
  } catch (error) {
    throw new Error(`Failed to create new appointment slot: ${error}`);
  }
};

export const getAppointment = async (authToken: string, baseUrl: string, practiceId: string, appointmentId: string) => {
  const url = `${baseUrl}/v1/${practiceId}/appointments/${appointmentId}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const { error } = await response.json();
      throw new Error(error);
    }

    const appointment = await response.json();
    return appointment;
  } catch (error) {
    throw new Error(`Failed to fetch appointment: ${error}`);
  }
};

export const bookAppointment = async (
  authToken: string,
  baseUrl: string,
  practiceId: string,
  patientId: string,
  appointmentId: string,
  departmentId: string,
) => {
  const url = `${baseUrl}/v1/${practiceId}/appointments/${appointmentId}?patientid=${patientId}`;

  const body = new URLSearchParams({
    appointmenttypeid: '803',
    departmentid: departmentId,
    donotsendconfirmationemail: 'true',
    ignoreschedulablepermission: 'true',
    bookingnote: 'PRAPARE screener',
  });

  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      body: body.toString(),
    });

    if (!response.ok) {
      const { error } = await response.json();
      throw new Error(error);
    }

    const appointment = await response.json();
    return appointment;
  } catch (error) {
    throw new Error(`Failed to book appointment: ${error}`);
  }
};

export const checkInToAppointment = async (
  authToken: string,
  baseUrl: string,
  practiceId: string,
  appointmentId: string,
  patientId: string,
) => {
  const url = `${baseUrl}/v1/${practiceId}/appointments/${appointmentId}/checkin?patientid=${patientId}`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      const { error } = await response.json();
      throw new Error(error);
    }

    const checkInJSON = await response.json();
    return checkInJSON;
  } catch (error) {
    throw new Error(`Failed to check into appointment: ${error}`);
  }
};

export const postScreenerDocument = async (
  authToken: string,
  baseUrl: string,
  practiceId: string,
  departmentId: string,
  patientId: string,
  encounterId: string,
  appointmentId: string,
  attachmentContents: string,
) => {
  try {
    const url = `${baseUrl}/v1/${practiceId}/patients/${patientId}/documents/encounterdocument`;
    const formData = new URLSearchParams({
      appointmentid: appointmentId,
      departmentid: departmentId,
      encounterid: encounterId,
      documentsubclass: 'PATIENTHISTORY',
      attachmentcontents: attachmentContents,
      status: 'open',
    });

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      },
      body: formData.toString(),
    });

    if (!response.ok) {
      const { error } = await response.json();
      throw new Error(error);
    }

    const documentResponse = await response.json();

    return documentResponse;
  } catch (error) {
    throw new Error(`Failed to post screener document: ${error}`);
  }
};

export const createQuestionnaireScreener = async (
  authToken: string,
  baseUrl: string,
  practiceId: string,
  encounterId: string,
) => {
  try {
    const url = `${baseUrl}/v1/${practiceId}/chart/encounter/${encounterId}/questionnairescreeners`;

    //this is the template ID for a prapare screener in Athena, confirm with Victor this is the same in prod environment
    const formData = new URLSearchParams({
      templateid: '1921',
    });

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formData.toString(),
    });

    if (!response.ok) {
      const { error } = await response.json();
      throw new Error(error);
    }

    const questionnaireScreeners = await response.json();
    return questionnaireScreeners;
  } catch (error) {
    throw new Error(`Failed to create questionnaire screener: ${error}`);
  }
};

export const getQuestionnaireScreener = async (
  authToken: string,
  baseUrl: string,
  practiceId: string,
  encounterId: string,
) => {
  try {
    const url = `${baseUrl}/v1/${practiceId}/chart/encounter/${encounterId}/questionnairescreeners`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (!response.ok) {
      const { error } = await response.json();
      throw new Error(error);
    }

    const questionnaireScreeners = await response.json();
    return questionnaireScreeners;
  } catch (error) {
    throw new Error(`Failed to get questionnaire screener: ${error}`);
  }
};

export const updateQuestionnaireScreener = async (
  authToken: string,
  baseUrl: string,
  practiceId: string,
  encounterId: string,
  questionnaireId: string,
  documentIds: string[],
  screenerAnswers: Question[],
) => {
  try {
    const url = `${baseUrl}/v1/${practiceId}/chart/encounter/${encounterId}/questionnairescreeners`;

    const body = new URLSearchParams({
      documentids: documentIds.join(','),
      questionnaireid: questionnaireId,
      questions: JSON.stringify(screenerAnswers),
      ignorescore: 'true',
      score: '0',
    });

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: body.toString(),
    });

    if (!response.ok) {
      const { error } = await response.json();
      throw new Error(error);
    }

    const questionnaireUpdateResponse = await response.json();

    return questionnaireUpdateResponse;
  } catch (error) {
    throw new Error(`Failed to update questionnaire screener: ${error}`);
  }
};

interface SendScreenerToAthenaProps {
  athena: AthenaConfig;
  departmentId: string;
  providerId: string;
  athenaPatientId: string;
  attachmentContents: string;
  screenerAnswers: Question[];
  targetTimezone: string;
}

/**
 * This function handles the Athena API calls to send a screener to Athena.
 * It will first request the auth token from Athena, then create an open appointment, book the appointment, check in to the appointment,
 * retrieve the appointment to get the encounter ID, post the screener document, create the screener, update the screener with the answers.
 *
 * @param athena
 * @param departmentId
 * @param providerId
 * @param athenaPatientId
 * @param attachmentContents
 * @param screenerAnswers
 * @param targetTimezone
 * @returns either a boolean if this request failed (false) or the encounterId if successful.
 * The encounter ID is applied to the task extension for linking directly into Athena
 */
export const sendScreenerToAthena = async ({
  athena,
  departmentId,
  providerId,
  athenaPatientId,
  attachmentContents,
  screenerAnswers,
  targetTimezone,
}: SendScreenerToAthenaProps): Promise<boolean | string> => {
  //Retrieve Athena auth token
  const tokenData = await getAthenaToken(athena.athenaClientId, athena.athenaClientSecret, athena.athenaBaseUrl);
  if (!tokenData) {
    return false;
  }
  const athenaAuthToken = tokenData.access_token;

  const now = new Date();

  const targetDate = toZonedTime(now, targetTimezone);

  const today = format(targetDate, 'yyyy-MM-dd', { timeZone: targetTimezone });
  const time = format(targetDate, 'HH:mm', { timeZone: targetTimezone });

  const appointmentData = await createOpenAppointment(
    athenaAuthToken,
    athena.athenaBaseUrl,
    athena.athenaPracticeId,
    departmentId,
    providerId,
    today,
    time,
  );
  if (!appointmentData) {
    return false;
  }

  const appointmentId = Object.keys(appointmentData.appointmentids)[0];
  //book opened appointment
  const bookingResult = await bookAppointment(
    athenaAuthToken,
    athena.athenaBaseUrl,
    athena.athenaPracticeId,
    athenaPatientId,
    appointmentId,
    departmentId,
  );
  if (!bookingResult) {
    return false;
  }

  //check in to appointment
  const checkedInAppointment = await checkInToAppointment(
    athenaAuthToken,
    athena.athenaBaseUrl,
    athena.athenaPracticeId,
    appointmentId,
    athenaPatientId,
  );
  if (!checkedInAppointment) {
    return false;
  }

  //retrieve booked appointment for encounter id
  const appointments = await getAppointment(
    athenaAuthToken,
    athena.athenaBaseUrl,
    athena.athenaPracticeId,
    appointmentId,
  );
  if (!appointments) {
    return false;
  }

  const encounterId = appointments[0].encounterid;
  if (!encounterId) {
    return false;
  }

  //post screener document
  const documentResponse = await postScreenerDocument(
    athenaAuthToken,
    athena.athenaBaseUrl,
    athena.athenaPracticeId,
    departmentId,
    athenaPatientId,
    encounterId,
    appointmentId,
    attachmentContents,
  );
  if (!documentResponse) {
    return false;
  }

  //create screener
  const createScreenerResponse = await createQuestionnaireScreener(
    athenaAuthToken,
    athena.athenaBaseUrl,
    athena.athenaPracticeId,
    encounterId,
  );
  if (!createScreenerResponse) {
    return false;
  }

  const screenerResponse = await getQuestionnaireScreener(
    athenaAuthToken,
    athena.athenaBaseUrl,
    athena.athenaPracticeId,
    encounterId,
  );

  if (!screenerResponse) {
    return false;
  }

  const questionnaireId = screenerResponse.questionnairescreeners[0].questionnaireid;

  //update screener
  const updatedScreener = await updateQuestionnaireScreener(
    athenaAuthToken,
    athena.athenaBaseUrl,
    athena.athenaPracticeId,
    encounterId,
    questionnaireId,
    [documentResponse.encounterdocumentid],
    screenerAnswers,
  );

  if (!updatedScreener) {
    return false;
  }

  return encounterId;
};

interface AthenaConfig {
  athenaClientId: string;
  athenaClientSecret: string;
  athenaBaseUrl: string;
  athenaPracticeId: string;
}

interface HandleSendPRAPAREToAthenaArgs {
  medplum: MedplumClient;
  patientId: string;
  questionnaireResponseId: string;
  taskId: string;
  questionsAndAnswers: Question[];
  imagineLogoBaseUrl: string;
  athenaProviderId: string;
  departmentId: string;
  targetTimezone: string;
  athenaConfig: AthenaConfig;
}

export const handleSendPRAPAREToAthena = async ({
  medplum,
  patientId,
  questionnaireResponseId,
  taskId,
  questionsAndAnswers,
  imagineLogoBaseUrl,
  athenaProviderId,
  departmentId,
  targetTimezone,
  athenaConfig,
}: HandleSendPRAPAREToAthenaArgs) => {
  const patient = await medplum.readResource('Patient', patientId);

  const task = await medplum.readResource('Task', taskId);

  if (!patient) {
    throw new Error('There was an error retrieving the patient resource.');
  }

  if (!task) {
    throw new Error('There was an error retrieving this task.');
  }

  const patientBirthDate = patient.birthDate;
  const patientName = getName(patient, { use: 'official' });

  if (!patientName || !patientBirthDate) {
    return;
  }

  const athenaSubjectId = athenaIdentifier(patient);

  if (!athenaSubjectId) {
    throw new Error('There was an error retreiving the athena ID for this patient');
  }

  const pdfDoc = await generateAthenaQuestionnairePDF(
    medplum,
    questionnaireResponseId,
    'PRAPARE Sceener',
    patientName,
    patientBirthDate,
    imagineLogoBaseUrl,
  );

  const docData = await pdfDoc?.save();

  if (docData) {
    try {
      const attachmentContents = Buffer.from(docData).toString('base64');

      if (
        !athenaConfig ||
        !athenaConfig.athenaClientId ||
        !athenaConfig.athenaClientSecret ||
        !athenaConfig.athenaBaseUrl ||
        !athenaConfig.athenaPracticeId
      ) {
        throw new Error('Athena configuration is missing');
      }

      let resultEncounterId: string | boolean;
      let sendError: unknown; // will capture the error if one occurs
      try {
        resultEncounterId = await sendScreenerToAthena({
          athena: athenaConfig,
          departmentId,
          providerId: athenaProviderId,
          athenaPatientId: athenaSubjectId,
          attachmentContents,
          screenerAnswers: questionsAndAnswers,
          targetTimezone,
        });
      } catch (e) {
        resultEncounterId = false;
        sendError = e;
      }

      const currentExtensions = task.extension ?? [];

      const isValidEncounterId = typeof resultEncounterId === 'string' && Boolean(resultEncounterId);

      const newExtensions = [
        {
          url: System.AthenaSentScreener,
          valueBoolean: isValidEncounterId,
        },
        ...(isValidEncounterId
          ? [
              {
                url: System.AthenaEncounterId,
                valueString: resultEncounterId as string,
              },
            ]
          : []),
      ];

      const updatedTask: Task = {
        ...task,
        extension: [...currentExtensions, ...newExtensions],
      };

      await medplum.updateResource(updatedTask);

      if (!isValidEncounterId) {
        throw (
          sendError ||
          new Error('There was an error sending this screener to Athena. Please enter the survey results manually')
        );
      }

      return true;
    } catch (e) {
      const error = e as Error;
      throw new Error(
        error.message ?? 'There was an error sending this screener to Athena. Please enter the survey results manually',
      );
    }
  } else {
    //failure to generate PDF;
    throw new Error('There was an error generating the PDF for this survey');
  }
};

export interface PRAPAREToAthenaArgs {
  departmentId: string;
  providerId: string;
  targetTimezone: string;
  questionsAndAnswers: Question[];
}

interface HandleCanSendPRAPAREToAthenaArgs {
  medplum: MedplumClient;
  patientId: string;
  questionnaireResponse: QuestionnaireResponse;
  translatedItemMapping: TranslationCodeMapping[];
  task?: Task;
  selectedProvider?: Practitioner;
}

export const handleCanSendPRAPAREToAthena = async ({
  medplum,
  patientId,
  questionnaireResponse,
  translatedItemMapping,
  task,
  selectedProvider,
}: HandleCanSendPRAPAREToAthenaArgs): Promise<PRAPAREToAthenaArgs | undefined> => {
  const patient = await medplum.readResource('Patient', patientId);

  if (!patient) {
    throw new Error('There was an error retrieving the patient resource.');
  }

  if (!task) {
    throw new Error('There was an error retrieving this task.');
  }

  const patientBirthDate = patient.birthDate;
  const patientName = getName(patient, { use: 'official' });

  if (!patientName || !patientBirthDate) {
    return;
  }

  if (!selectedProvider) {
    throw new Error('Please choose a provider');
  }

  if (!questionnaireResponse?.id) {
    throw new Error('There was an error retrieving the questionnaire response for this task');
  }

  const athenaSubjectId = athenaIdentifier(patient);

  if (!athenaSubjectId) {
    throw new Error('There was an error retreiving the athena ID for this patient');
  }

  const managingOrganization = await medplum.readResource(
    'Organization',
    parseReference(patient.managingOrganization)?.[1],
  );
  const departmentId = getAthenaIdExtension(managingOrganization)?.valueString;

  if (!departmentId) {
    throw new Error('Could not determine department/market ID for patient');
  }

  const marketIdentifier = getMarketIdentifier(managingOrganization);

  if (!marketIdentifier) {
    throw new Error('Could not determine market identifier for patient');
  }

  // Need to figure out how to remove MarketIdentifier and add with organizationService
  const targetTimezone = getTimezone(marketIdentifier);

  const athenaProviderId = getAthenaProviderIdForMarket(selectedProvider, marketIdentifier);

  if (!athenaProviderId) {
    //selected provider has no athena id for the market that this patient is in.
    throw new Error('This Provider does not have an Athena ID for the market that this patient is in');
  }

  const questionsAndAnswers = extractQuestionsAndAnswers(questionnaireResponse.item ?? [], translatedItemMapping);

  return {
    departmentId,
    providerId: athenaProviderId,
    targetTimezone,
    questionsAndAnswers,
  };
};
