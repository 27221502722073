import React from 'react';
import { ActivityType } from './types';
import { useActivityData } from './useActivityData';
import { match } from 'ts-pattern';
import { TouchpointActivityItem } from '../../shared/TouchpointActivityItem';
import { VideoCallActivityItem } from './VideoCallActivityItem';
import { AlertActivityItem } from './AlertActivityItem';
import { ADTActivityItem } from './ADTActivityItem';
import { TaskActivityItem } from './TaskActivityItem';

interface ActivityTableItemMuxProps {
  data: ReturnType<typeof useActivityData>['data'];
  count: number;
  patientId: string;
}

export const ActivityTableMux = ({ data, patientId, count }: ActivityTableItemMuxProps): JSX.Element => {
  if (data === undefined) {
    return <></>;
  }

  return (
    <>
      {count !== 0 &&
        data.map((att) =>
          match(att)
            .with({ type: ActivityType.Touchpoint }, (att) => (
              <TouchpointActivityItem key={att.data.id} patientId={patientId} touchpoint={att.data} />
            ))
            .with({ type: ActivityType.Video }, (att) => (
              <VideoCallActivityItem key={att.data.encounterId} video={att.data} />
            ))
            .with({ type: ActivityType.Alert }, (att) => (
              <AlertActivityItem patientId={patientId} key={att.data.id} alert={att.data} />
            ))
            .with({ type: ActivityType.Task }, (att) => <TaskActivityItem key={att.data.id} alert={att.data} />)
            .with({ type: ActivityType.ADT }, (att) => (
              <ADTActivityItem patientId={patientId} key={att.data.id} encounter={att.data} />
            ))
            .otherwise(() => {
              return null;
            }),
        )}
    </>
  );
};
