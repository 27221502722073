import React, { useEffect, useMemo } from 'react';
import { Box, Container, Grid, Group, LoadingOverlay, Paper } from '@mantine/core';
import { LinkedPatients } from '@/components/patientProfile/LinkedPatients';
import { PatientPrimaryInformation } from '@/components/patientProfile/patientPrimaryInformation';
import { PatientActivity } from '@/components/patientProfile/patientActivity/PatientActivity';
import { PatientContactOverview } from '@/components/patientProfile/patientContacts/PatientContactOverview';
import { useGetPatientQuery, Patient as GraphqlPatient, RelatedPerson } from 'medplum-gql';
import { useParams } from 'react-router-dom';
import { PatientDocuments } from '@/components/patientProfile/patientDocuments/PatientDocuments';
import { ProgramStatus } from 'const-utils';
import PatientCareTeam from '@/components/patientProfile/PatientCareTeam';
import { Patient } from '@medplum/fhirtypes';
import { ChatDrawer } from '@/components/chat';
import { useDisclosure, useDocumentTitle } from '@mantine/hooks';
import { getName, getProgramStatus } from 'imagine-dsl/utils/patient';
import { ChatDrawerProvider } from '@/components/chat/ChatDrawerProvider';
import { useRecentPatients } from '@/hooks/usePatientList';
import { IconPointFilled } from '@tabler/icons-react';
import { PhoneDisplay } from '@/components/shared/patient/PhoneDisplay';
import { AddressDisplay } from '@/components/shared/patient/AddressDisplay';
import { TimeZoneDisplay } from '@/components/shared/patient/TimeZoneDisplay';
import { ActiveVideoAlert } from '@/components/patientProfile/alerts/ActiveVideoAlert';
import { ResetDemoButton } from '@/components/ResetDemoButton';
import { notifications } from '@mantine/notifications';
import { NotesFullWidth } from '@/components/shared/patient/notes';
import { isValidPatient } from '@/utils/queryTypes';
import { CompletePatientHeader } from '@/components/shared/patient/PatientHeader';
import { ResetPatientButton } from '@/components/ResetPatientButton';
import { useMedplum } from '@medplum/react';
import { PatientOpenTasks } from '@/components/patientProfile/patientOpenTasks';

interface Params extends Record<string, string | undefined> {
  id?: string;
}

export function PatientProfile(): JSX.Element {
  const { id = '' } = useParams<Params>();
  const { add: addRecentPatient, patientIds: recentPatients } = useRecentPatients();
  const [chatDrawerOpened, { open: openChatDrawer, close: closeChatDrawer }] = useDisclosure();
  const medplum = useMedplum();

  useEffect(() => {
    if (id && recentPatients.at(0) !== id) {
      addRecentPatient(id);
    }
  }, [addRecentPatient, recentPatients, id]);

  const { data, loading, refetch } = useGetPatientQuery({
    variables: {
      id,
    },
  });

  const patient = useMemo(() => data?.Patient, [data]);

  const name = getName(patient);

  useDocumentTitle(patient ? `${name} - Care Hub` : 'Loading - Care Hub');

  if (loading) {
    return <LoadingOverlay visible />;
  }

  if (!isValidPatient(patient)) {
    return <div>No patient found</div>;
  }

  const programStatus = getProgramStatus(patient);

  return (
    <Container w="100%" my={20} size="xl">
      <Paper mb={20} radius="lg" shadow="xs" style={{ overflow: 'hidden' }}>
        <Box p="xl" pb="sm">
          <CompletePatientHeader
            patientId={patient.id}
            withOutreachButton={programStatus === ProgramStatus.NotEnrolled}
            withVideoCallButton={programStatus !== ProgramStatus.NotEnrolled}
            openChatDrawer={programStatus !== ProgramStatus.NotEnrolled ? openChatDrawer : undefined}
          />
        </Box>
        <Box
          style={{
            padding: 'var(--mantine-spacing-sm) var(--mantine-spacing-lg)',
          }}
          bg="imagine-green.0"
        >
          <Group>
            <PhoneDisplay patient={patient} />
            <IconPointFilled size={14} />
            <AddressDisplay patient={patient} />
            <IconPointFilled size={14} />
            <TimeZoneDisplay patient={patient} />
          </Group>
        </Box>
      </Paper>
      <NotesFullWidth patientId={patient.id!} />
      <ActiveVideoAlert patientId={id} />

      <Grid gutter="md">
        <Grid.Col span={3}>
          <PatientContactOverview patient={patient as Patient} refetch={refetch} />
          <LinkedPatients
            patient={patient as GraphqlPatient}
            linkedPatients={patient.RelatedPersonList as RelatedPerson[]}
            refetch={refetch}
          />
          <PatientDocuments
            patient={patient as GraphqlPatient}
            linkedPatients={patient.RelatedPersonList as RelatedPerson[]}
            refetch={refetch}
          />
          <Group justify="center" mt={10}>
            <ResetDemoButton
              patientId={id}
              onSuccess={async () => {
                await refetch();
                notifications.show({
                  message: 'Demo Reset Successful',
                });
              }}
            />
          </Group>
          {medplum.isProjectAdmin() && (
            <Group justify="center" mt={10}>
              <ResetPatientButton patientId={id} />
            </Group>
          )}
        </Grid.Col>
        <Grid.Col span={9}>
          <PatientOpenTasks patientId={id} />
          <PatientCareTeam patient={data} refetch={refetch} />
          <PatientPrimaryInformation patient={patient as GraphqlPatient} refetch={refetch} />
          <PatientActivity patientId={id} refresh={refetch} />
        </Grid.Col>
      </Grid>
      <ChatDrawerProvider opened={chatDrawerOpened} patient={patient} close={closeChatDrawer}>
        <ChatDrawer />
      </ChatDrawerProvider>
    </Container>
  );
}
