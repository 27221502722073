import React, { useMemo } from 'react';
import { Box, Group, Paper, Text, Stack } from '@mantine/core';
import { ExtendedRelatedPerson } from '@/components/patientProfile/handlers/patientContactForm';
import { ContactPoint } from '@medplum/fhirtypes';
import { PhoneNumbersList } from './PhoneNumbersList';
import { InvalidPhoneNumbersList } from './InvalidPhoneNumbersList';
import { EmailList } from './EmailList';
import { AddressInfo } from './AddressInfo';
import { IconPhone, IconMail, IconHome } from '@tabler/icons-react';
import { EmptyState } from './EmptyState';
import { getPhoneStatus } from 'imagine-dsl/utils/patient';

interface ContactInfoProps {
  contact: ExtendedRelatedPerson;
}

const filterTelecom = (entity?: { telecom?: ContactPoint[] }, type?: string): ContactPoint[] =>
  entity?.telecom?.filter((item: ContactPoint) => item?.system === type) ?? [];

export function ContactInfo({ contact }: ContactInfoProps) {
  const relatedPatient = contact?.PatientList?.[0];
  const phoneNumbers = filterTelecom(relatedPatient, 'phone');
  const emailAddresses = filterTelecom(relatedPatient, 'email');
  const addresses = relatedPatient?.address;

  const { validPhones, invalidPhones } = useMemo(() => getPhoneStatus(phoneNumbers), [phoneNumbers]);
  const hasValidEmails = emailAddresses.some((email) => email.value !== null);
  const hasPhoneNumbers = phoneNumbers.some((phone) => phone.value !== null);

  return (
    <Box>
      <Text fw={700} mb="md" c="imagine-green">
        Contact info
      </Text>
      <Group grow align="flex-start">
        <Paper withBorder p="md" radius="md">
          <Text size="sm" fw={700} mb="xs">
            Phone numbers
          </Text>
          {hasPhoneNumbers ? (
            <Stack gap="xs">
              <PhoneNumbersList phoneNumbers={validPhones} hasInvalidNumbers={invalidPhones.length > 0} />
              {invalidPhones.length > 0 && <InvalidPhoneNumbersList phoneNumbers={invalidPhones} />}
            </Stack>
          ) : (
            <EmptyState
              icon={<IconPhone size={24} color="var(--mantine-color-gray-5)" />}
              message="No phone numbers have been added for this caregiver."
            />
          )}
        </Paper>

        <Paper withBorder p="md" radius="md">
          <Text size="sm" fw={700} mb="xs">
            Email addresses
          </Text>
          {hasValidEmails ? (
            <EmailList emails={emailAddresses} />
          ) : (
            <EmptyState
              icon={<IconMail size={24} color="var(--mantine-color-gray-5)" />}
              message="No email addresses have been added for this caregiver."
            />
          )}
        </Paper>

        <Paper withBorder p="md" radius="md">
          <Text size="sm" fw={700} mb="xs">
            Address
          </Text>
          {addresses ? (
            <AddressInfo addresses={addresses} />
          ) : (
            <EmptyState
              icon={<IconHome size={24} color="var(--mantine-color-gray-5)" />}
              message="An address has not been added for this caregiver."
            />
          )}
        </Paper>
      </Group>
    </Box>
  );
}
