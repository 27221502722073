import { OrganizationType } from 'const-utils/codeSystems/ImaginePediatrics';
import { MedplumClient } from '@medplum/core';

export interface Identifier {
  identifier: string;
  name?: string;
}

export const fetchOptions = async (
  medplum: MedplumClient,
  organizationType: OrganizationType,
): Promise<Identifier[]> => {
  const organizations = await medplum.searchResources('Organization', {
    _tag: organizationType,
  });

  const identifiers = organizations.map((organization) => {
    const identifier = organization.identifier?.find(
      (id) => id.system === 'https://imaginepediatrics.org/organization',
    );
    const name = organization.name;
    return {
      identifier: identifier?.value || '',
      name: name || '',
    };
  });
  return identifiers;
};
