import { Button, ComboboxItem, Group, Modal, Select, Stack, TextInput, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useEffect, useState } from 'react';
import { TaskPriorityCodes, useSaveTask } from './useSaveTask';
import { Maybe, SearchPractitionersQueryVariables, useSearchPractitionersQuery } from 'medplum-gql';
import { formatHumanName } from '@medplum/core';
import { HumanName, Task } from '@medplum/fhirtypes';
import { useUserSession } from './UserSessionContext';
import { SelectWithSearch } from './SelectWithSearch';
import { format } from 'date-fns';
import { manuallyCreatableTaskTypeOptions } from 'const-utils/codeSystems/ImaginePediatrics';

interface UpsertTaskModalProps {
  patientId: string;
  opened: boolean;
  closeModal: () => Promise<void>;
  operation: 'Create' | 'Edit';
  existingTask?: TaskFormValues;
  taskTypes?: ComboboxItem[];
  taskOverridesFn?: (t: Task) => Task;
  refetchTasks?: (() => Promise<unknown>)[];
  communicationRequestId?: string;
  onTaskSaved?: (task: Task) => void;
}

export interface TaskFormValues {
  taskType: string;
  assignedTo: string;
  assignedToName?: string;
  dueDate?: string;
  dueTime: string;
  priority: string;
  notes: string;
  id?: string;
  requestor?: string;
  createdDate?: string;
}

const UpsertTaskModal = ({
  operation,
  patientId,
  opened,
  closeModal,
  existingTask,
  taskTypes: taskTypeOptions = manuallyCreatableTaskTypeOptions,
  taskOverridesFn: overridesFn,
  refetchTasks,
  communicationRequestId,
  onTaskSaved,
}: UpsertTaskModalProps): JSX.Element => {
  const { profile } = useUserSession();
  const form = useForm<TaskFormValues>({
    initialValues: {
      taskType: existingTask?.taskType ?? (taskTypeOptions.length === 1 ? taskTypeOptions[0].value : ''),
      assignedTo: existingTask?.assignedTo ?? '',
      dueTime: existingTask?.dueTime ?? '23:59',
      dueDate: existingTask?.dueDate ? format(new Date(existingTask.dueDate), 'yyyy-MM-dd') : undefined,
      notes: existingTask?.notes ?? '',
      priority: existingTask?.priority ?? '',
      id: existingTask?.id,
      requestor: existingTask?.requestor ?? profile.id,
      createdDate: existingTask?.createdDate ?? new Date().toISOString(),
    },
    validate: {
      taskType: (value) => !value && 'Task Type is required',
      assignedTo: (value) => !value && 'Assignee is required',
      dueDate: (value) => !value && 'Due Date is required',
    },
    validateInputOnBlur: true,
  });
  const [searchParams, setSearchParams] = useState<Maybe<SearchPractitionersQueryVariables>>();
  const { data } = useSearchPractitionersQuery({
    skip: !searchParams,
    variables: searchParams || {},
  });
  const [practitionerOptions, setPractitionerOptions] = useState<ComboboxItem[]>();
  useEffect(() => {
    if (data) {
      setPractitionerOptions(
        data.PractitionerList?.filter((p) => p?.name?.[0].family && p?.name?.[0].given).map((practitioner) => {
          return {
            label: formatHumanName(practitioner?.name?.[0] as HumanName),
            value: practitioner?.id ?? '',
          };
        }),
      );
    }
  }, [data]);

  const onSearch = (name: Maybe<string>): void => {
    if (!name) {
      setSearchParams({});
    } else {
      setSearchParams({ name });
    }
  };
  const onAssignToTeamMember = async (practitionerId: string): Promise<void> => {
    form.setFieldValue('assignedTo', practitionerId);
    form.validateField('assignedTo');
  };

  const [saveTask, loading] = useSaveTask(patientId, form.values, communicationRequestId);

  const close = async (): Promise<void> => {
    await closeModal();
  };

  const handleSave = async (): Promise<void> => {
    if (form.isValid()) {
      const result = await saveTask(overridesFn);
      if (result) {
        form.reset();
        await close();
        onTaskSaved?.(result as Task);
        refetchTasks?.forEach((refetch: () => Promise<unknown>) => refetch());
      }
    } else {
      form.validate();
    }
  };

  return (
    <Modal.Root size="lg" padding="xl" radius="xl" opened={opened} onClose={close} centered>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title c="imagine-green" style={{ fontSize: '20px' }}>
            {operation} Task
          </Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <Stack gap="md">
            <Select
              label="Task type"
              placeholder="Please select"
              required
              disabled={taskTypeOptions.length === 1}
              data={taskTypeOptions}
              {...form.getInputProps('taskType')}
            />
            <SelectWithSearch
              label="Assign to"
              assign={onAssignToTeamMember}
              search={onSearch}
              listOptions={practitionerOptions}
              fieldValidation={() => form.validateField('assignedTo')}
              fieldError={form.errors.assignedTo}
              formValue={form.values.assignedTo}
              defaultValue={existingTask?.assignedToName}
            />
            <Group grow>
              <TextInput required label="Due Date" type="date" {...form.getInputProps('dueDate')} />
              <TextInput required label="Due Time" type="time" {...form.getInputProps('dueTime')} />
            </Group>
            <Select
              label="Select priority"
              data={TaskPriorityCodes.map((p) => ({
                label: p.display ?? p.code,
                value: p.code,
              }))}
              {...form.getInputProps('priority')}
            />
            <Textarea label="Notes" {...form.getInputProps('notes')} autosize minRows={4} maxRows={6} />
            <Group justify="flex-end" mt={'sm'}>
              <Button variant="outline" onClick={close}>
                Cancel
              </Button>
              <Button onClick={handleSave} loading={loading}>
                Save Task
              </Button>
            </Group>
          </Stack>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};

export default UpsertTaskModal;
