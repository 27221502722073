import { useMedplum } from '@medplum/react';
import { OrganizationType } from 'const-utils/codeSystems/ImaginePediatrics';
import { useCallback } from 'react';
import { notifications } from '@mantine/notifications';

export const useFetchPayors = () => {
  const medplum = useMedplum();
  const fetchPayors = useCallback(async () => {
    try {
      const payorResponse = await medplum.search(
        'Organization',
        {
          _tag: OrganizationType.HealthPlan,
        },
        {
          cache: 'no-cache',
        },
      );
      const payors = payorResponse.entry
        ? payorResponse.entry.map((entry) => ({
            name: entry.resource?.name || '',
            status: entry.resource?.active || false,
            id: entry.resource?.id || '',
          }))
        : [];
      return payors;
    } catch (error) {
      notifications.show({
        title: 'Error fetching Payor responses:',
        message: error as string,
        color: 'status-error',
      });
      return [];
    }
  }, [medplum]);
  return { fetchPayors };
};
