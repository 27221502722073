import { Table, Group, Menu, ActionIcon, Text, Checkbox } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { HumanNameDisplay } from '@medplum/react';
import { IconPointFilled, IconDotsVertical, IconUserPlus, IconUser, IconBell } from '@tabler/icons-react';
import { ProgramStatus } from 'const-utils';
import { programStatusDisplay, TaskType } from 'const-utils/codeSystems/ImaginePediatrics';
import { EMPTY_DATA_PLACEHOLDER } from 'const-utils/utils/constants';
import { CommunicationRequest as CommunicationRequestModel } from 'imagine-dsl/models/communications/communicationRequest';
import { formatAge } from 'imagine-dsl/utils/strings';
import { HumanName, Task } from '@medplum/fhirtypes';
import React from 'react';
import { UnassignedBadge } from '../shared/UnassignedBadge';
import UpsertTaskModal from '../shared/UpsertTaskModal';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { TOCDrawerContent } from '../drawer/bhtocAlert/DrawerContent';
import { ExpandableDrawer } from '@/design-system/ExpandableDrawer';

interface TOCPanelRowProps {
  request: CommunicationRequestModel;
  resolved: boolean;
  onSelectedChanged: (isSelected: boolean, selectedRequestId?: string) => void;
  isSelected: boolean;
  onTaskSaved?: (task: Task) => void;
}

export function TOCPanelRow({
  request,
  resolved,
  onSelectedChanged,
  isSelected,
  onTaskSaved,
}: TOCPanelRowProps): JSX.Element {
  const [openTaskModal, handleTaskModal] = useDisclosure();
  const [alertDrawerOpened, { close: closeAlertDrawer, open: openAlertDrawer }] = useDisclosure();

  const navigate = useNavigate();

  if (!request.isValid() || !request.id) {
    return <></>;
  }
  const closeTaskModal = async (): Promise<void> => {
    handleTaskModal.close();
  };
  const handleViewAlertClick = () => {
    openAlertDrawer();
  };
  const handleRowClick = () => {
    if (!alertDrawerOpened) {
      openAlertDrawer();
    }
  };
  const handleCreateTaskClick = async (): Promise<void> => {
    handleTaskModal.open();
  };
  const patient = request.patient;
  if (!patient || !patient.id) {
    return <></>;
  }
  const programStatusCode = request.programStatus;
  const programStatusText = programStatusCode
    ? programStatusDisplay[programStatusCode as ProgramStatus] || 'Unknown'
    : 'Unknown';
  const taskTypes = [
    {
      label: 'Schedule Appointment BH TOC',
      value: TaskType.ScheduleAppointmentBHTOC,
    },
  ];

  return (
    <Table.Tr>
      <Table.Td>
        <Checkbox onChange={(e) => onSelectedChanged(e.target.checked, request.id)} checked={isSelected} />
      </Table.Td>
      <Table.Td onClick={handleRowClick} style={{ cursor: 'pointer' }}>
        <Text>
          <HumanNameDisplay value={patient.name?.[0] as HumanName} />
        </Text>
        <Group gap={1}>
          <Text size="xs" c={'dimmed'}>
            {request.market}
          </Text>
          <IconPointFilled size={14} color="gray" />
          <Text size="xs" c={'dimmed'}>
            {formatAge(patient.birthDate, { emptyText: EMPTY_DATA_PLACEHOLDER, abbreviate: true })}
          </Text>
          <IconPointFilled size={14} color="gray" />
          <Text size="xs" c={'dimmed'}>
            {programStatusText}
          </Text>
        </Group>
      </Table.Td>
      <Table.Td onClick={handleRowClick} style={{ cursor: 'pointer' }}>
        {request.pod?.name || 'Unassigned pod'}
      </Table.Td>
      <Table.Td onClick={handleRowClick} style={{ cursor: 'pointer' }}>
        <Text fw={700}>{request.alertType}</Text>
      </Table.Td>
      <Table.Td onClick={handleRowClick} style={{ cursor: 'pointer' }}>
        {resolved ? request.caseOutcome : request.outreachStatus}
      </Table.Td>
      <Table.Td onClick={handleRowClick} style={{ cursor: 'pointer' }}>
        {request.assignee ? <HumanNameDisplay value={request.assignee.name?.[0] as HumanName} /> : <UnassignedBadge />}
      </Table.Td>
      <Table.Td onClick={handleRowClick} style={{ cursor: 'pointer' }}>
        {request.dischargeDate ? format(request.dischargeDate, 'M/d/yyyy') : ''}
      </Table.Td>
      <Table.Td>
        <Menu>
          <Menu.Target>
            <ActionIcon variant="outline" size="md" radius="xl">
              <IconDotsVertical style={{ width: '70%', height: '70%' }} stroke={1.5} />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown w={250}>
            <Menu.Item leftSection={<IconUserPlus size={14} />} onClick={handleCreateTaskClick}>
              Create Task
            </Menu.Item>
            <Menu.Item leftSection={<IconBell size={14} />} onClick={handleViewAlertClick}>
              View alert
            </Menu.Item>
            <Menu.Item leftSection={<IconUser size={14} />} onClick={() => navigate(`/Patient/${patient.id}`)}>
              View patient profile
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
        {openTaskModal && (
          <UpsertTaskModal
            patientId={patient.id}
            opened={openTaskModal}
            closeModal={closeTaskModal}
            operation="Create"
            taskTypes={taskTypes}
            communicationRequestId={request.id}
            onTaskSaved={onTaskSaved}
          />
        )}
        {alertDrawerOpened && (
          <ExpandableDrawer size="xxl" opened={alertDrawerOpened} close={closeAlertDrawer}>
            <TOCDrawerContent patientId={patient.id} close={closeAlertDrawer} communicationRequest={request} />
          </ExpandableDrawer>
        )}
      </Table.Td>
    </Table.Tr>
  );
}
