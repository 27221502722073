import React, { CSSProperties } from 'react';
import { Coding } from '@medplum/fhirtypes';
import { ValueSetDropdown } from '@/design-system/ValueSetDropdown';
import { ContactType } from 'const-utils';

/**
 * Props for the `ContactTypeDropdown` component.
 *
 * @property `selectedItem` - Optional. The currently selected contact type, represented as a `Coding` object.
 * This property is optional to handle empty initial state, form resets, and allow for more flexible implementation patterns.
 * @property `onSelect` - Callback function that handles the event when a new contact type is selected.
 * @property `error` - Optional. Error message to be displayed for form validation feedback.
 * @property `required` - Optional. Flag indicating whether the dropdown is a required field. Defaults to `true`.
 * @property `style` - Optional. Custom inline CSS styles for the component.
 * @property `cypressIdentifier` - Optional. Identifier for end-to-end testing with Cypress. Defaults to
 *  `'contact-type-select'` if not provided. Useful for uniquely identifying multiple instances of this component in a test suite.
 */
interface ContactTypeDropdownProps {
  selectedItem?: Coding;
  onSelect: (item: Coding) => void;
  error?: string;
  required?: boolean;
  style?: CSSProperties;
  cypressIdentifier?: string;
}

/**
 * `ContactTypeDropdown` renders a dropdown for selecting contact types.
 *
 * This component wraps the `ValueSetDropdown` component and is pre-configured to use the `ContactType` value set.
 *
 * @param selectedItem - Optional. The currently selected contact type, represented as a `Coding` object.
 * @param onSelect - Callback function that handles the event when a new contact type is selected.
 * @param error - Optional. Error message to be displayed for form validation feedback.
 * @param required - Optional. Flag indicating whether the dropdown is a required field. Defaults to true.
 * @param style - Optional. Custom inline CSS styles for the component.
 * @param cypressIdentifier - Optional. Identifier for end-to-end testing with Cypress. Defaults to 'contact-type-select' if not provided.
 *
 * @returns A `ValueSetDropdown` component configured for contact type selection.
 *
 * @example
 * ```tsx
 * <ContactTypeDropdown
 *   onSelect={(coding) => handleSelect(coding)}
 *   selectedItem={currentContact}
 *   required={true}
 * />
 * ```
 */
export const ContactTypeDropdown = ({
  selectedItem,
  onSelect,
  error,
  required = true,
  style,
  cypressIdentifier = 'contact-type-select',
}: ContactTypeDropdownProps) => {
  return (
    <ValueSetDropdown
      valueSet={ContactType}
      label="Contact type"
      placeholder="Please select"
      onSelect={onSelect}
      required={required}
      selectedItem={selectedItem}
      cypressIdentifier={cypressIdentifier}
      error={error}
      style={style}
    />
  );
};
