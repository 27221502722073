import { AssigneeSection } from '@/components/chat/footer/AssigneeSection';
import { Grid, Stack, Group, Button, Container, Tooltip } from '@mantine/core';
import { Practitioner } from '@medplum/fhirtypes';
import { IconCheck, IconX } from '@tabler/icons-react';
import { CarePathwayReferralTask } from 'imagine-dsl/models/tasks/carePathwayReferralTask';
import { getName } from 'imagine-dsl/utils/patient';
import React from 'react';

interface TaskActionsProps {
  task: CarePathwayReferralTask;
  refetchTasks: () => Promise<unknown>;
  setActionIntent: (intent: 'approve' | 'deny' | undefined) => void;
  hasPermission: boolean;
  carePathwayTaskResolveAllowed: boolean;
  profile: Practitioner;
}

export const TaskActions = (props: TaskActionsProps): JSX.Element => {
  const { task, setActionIntent, hasPermission, carePathwayTaskResolveAllowed, profile } = props;
  const { isResolved } = task;

  const isActionable = !isResolved;
  const isOwnedByAnother = task.owner !== null && task.owner?.id !== profile.id;
  const disableApproveAction = !hasPermission || !carePathwayTaskResolveAllowed || isOwnedByAnother;
  const disableDenyAction = !hasPermission || isOwnedByAnother;

  const getApproveTooltip = (): string => {
    if (!hasPermission) {
      return 'You do not have the appropriate permissions to take action on this task';
    }
    if (!carePathwayTaskResolveAllowed) {
      return 'Missing or incomplete sub-tasks that must be created or completed before resolving the parent task';
    }
    if (isOwnedByAnother) {
      return 'You cannot approve a task owned by another user';
    }
    return 'The task will be assigned to you when you perform this action';
  };

  const getDenyTooltip = (): string => {
    if (!hasPermission) {
      return 'You do not have the appropriate permissions to take action on this task';
    }
    if (isOwnedByAnother) {
      return 'You cannot deny a task owned by another user';
    }
    return 'The task will be assigned to you when you perform this action';
  };

  const approveTooltipLabel = getApproveTooltip();
  const denyTooltipLabel = getDenyTooltip();

  if (!isActionable) {
    return <></>;
  }

  return (
    <Container pt={16} pb={24} mx={10}>
      <Grid justify="space-between">
        <Stack style={{ gap: 0 }}>
          {hasPermission && (
            <AssigneeSection
              taskMarket={task.market!}
              assigneeId={task.owner?.id}
              assigneeName={getName(task.owner)}
              taskId={task.id!}
              disabled={task.completed}
              refreshTasks={props.refetchTasks}
              profile={profile}
            />
          )}
        </Stack>
        <Group>
          <Tooltip label={approveTooltipLabel}>
            <Button
              disabled={disableApproveAction}
              onClick={() => setActionIntent('approve')}
              variant="outline"
              leftSection={<IconCheck />}
            >
              Approve
            </Button>
          </Tooltip>
          <Tooltip label={denyTooltipLabel}>
            <Button
              disabled={disableDenyAction}
              onClick={() => setActionIntent('deny')}
              variant="outline"
              leftSection={<IconX />}
            >
              Deny
            </Button>
          </Tooltip>
        </Group>
      </Grid>
    </Container>
  );
};
