import {
  ClinicalImpression,
  CoverageInfoFragment,
  Maybe,
  Meta,
  PatientHealthPlanInfoFragment,
  Reference,
} from 'medplum-gql';
import { Extension, Telecom } from './shared';
import { isDefined } from 'imagine-dsl/utils/lists';

export interface PatientWithMeta {
  id?: Maybe<string>;
  meta?: Maybe<Meta>;
}

export interface PatientWithExtension {
  id?: Maybe<string>;
  extension?: Extension;
}

export interface PatientWithAllPhones {
  telecom?: Telecom;
  RelatedPersonList?: Maybe<
    Maybe<{
      PatientList?: Maybe<Maybe<{ meta?: Maybe<Meta>; telecom?: Telecom; extension?: Extension }>[]>;
    }>[]
  > | null;
}

export interface PatientWithManagingOrg {
  id?: Maybe<string>;
  managingOrganization?: Maybe<Reference & PatientHealthPlanInfoFragment>;
}

export interface PatientWithCoverage {
  CoverageList?: Maybe<Maybe<CoverageInfoFragment>[]>;
}

export interface PatientWithCaregivers {
  RelatedPersonList?: Maybe<
    Maybe<{
      PatientList?: Maybe<
        Maybe<{ resourceType: string; meta?: Maybe<Meta>; telecom?: Telecom; extension?: Extension }>[]
      >;
      patient: {
        reference?: Maybe<string>;
      };
      resourceType: string;
    }>[]
  >;
  id?: Maybe<string>;
}

export interface PatientWithCluster {
  id?: Maybe<string>;
  ClinicalImpressionList?: Maybe<ClinicalImpression[]>;
}

export interface PatientCommunication {
  language?: {
    coding?:
      | {
          code?: Maybe<string>;
          system?: Maybe<string>;
          display?: Maybe<string>;
        }[]
      | null;
  };
}

export const isPatientInFosterCare = (patient?: PatientWithCoverage): boolean => {
  if (!patient) {
    return false;
  }

  return (
    patient.CoverageList?.filter(isDefined).some((coverage) =>
      coverage.class?.some((item: { value: string }) => item.value.toLowerCase().includes('foster')),
    ) ?? false
  );
};
