import React from 'react';
import { Text, ActionIcon, Group } from '@mantine/core';
import { IconCopy, IconPhone } from '@tabler/icons-react';
import { GetPatientQuery, Patient } from 'medplum-gql';
import { getPrimaryPhoneNumber } from 'imagine-dsl/utils/patient';
import { formatPhoneString } from 'imagine-dsl/utils/strings';
import { copyToClipboardHandler } from './clipboard';

interface PhoneDisplayProps {
  patient: GetPatientQuery['Patient'];
}

export function PhoneDisplay({ patient }: PhoneDisplayProps): JSX.Element {
  const phoneNumber = getPrimaryPhoneNumber(patient as Patient);

  return (
    <Group gap="xs">
      <IconPhone size={20} />
      <Text>{formatPhoneString(phoneNumber) ?? '--'}</Text>
      <ActionIcon
        c="brand-gray.7"
        disabled={!phoneNumber}
        variant="transparent"
        onClick={() => copyToClipboardHandler(phoneNumber)}
      >
        <IconCopy size="16" />
      </ActionIcon>
    </Group>
  );
}
