import { MedplumClient, parseReference } from '@medplum/core';
import { System } from 'const-utils';

/**
 * Resolves a CommunicationRequest associated with a task by updating the status reason and marking it as completed
 * @param medplum - The Medplum client instance
 * @param taskId - The ID of the task
 * @param reason - The reason code to add to the status reason
 */
export const resolveCommunicationRequest = async (
  medplum: MedplumClient,
  taskId: string,
  reason?: { system: string; code: string },
): Promise<void> => {
  const task = await medplum.readResource('Task', taskId);

  const communicationRequestRefs = task.basedOn?.filter((ref) => ref.reference?.startsWith('CommunicationRequest/'));

  if (!communicationRequestRefs || communicationRequestRefs.length === 0) {
    return;
  }

  const [_, communicationRequestId] = parseReference(communicationRequestRefs[0]);
  if (!communicationRequestId) {
    return;
  }

  const communicationRequest = await medplum.readResource('CommunicationRequest', communicationRequestId);

  const existingStatusReasons = communicationRequest.statusReason?.coding || [];

  const otherReasons = existingStatusReasons.filter((coding) => coding.system !== System.BHTOCTaskCaseOutcome);

  const updatedStatusReasons = [
    ...otherReasons,
    {
      system: System.BHTOCTaskCaseOutcome,
      code: reason?.code || '',
    },
  ];

  await medplum.updateResource({
    ...communicationRequest,
    status: 'completed',
    statusReason: {
      coding: updatedStatusReasons,
    },
  });
};
