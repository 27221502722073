import { useState } from 'react';
import { useMedplum, useMedplumProfile } from '@medplum/react';
import { combineDateAndTimeLocalTimezone } from 'imagine-dsl/utils/dates';
import { TaskFormValues } from './UpsertTaskModal';
import { TaskPriority, extractValues } from 'const-utils';
import { bulkTaskCreation } from 'imagine-dsl/services/taskService';
import { notifications } from '@mantine/notifications';
import { getErrorMessage, logError } from '@/errors';
import { useApolloClient } from '@apollo/client';
import { GetPatientPanelDocument } from 'medplum-gql';
import { Bundle } from '@medplum/fhirtypes';

type CallbackWithLoader = [() => Promise<Bundle | undefined>, boolean];

export const TaskPriorityCodes = extractValues(TaskPriority);

export const useBulkTaskCreation = (
  patientIds: string[],
  formValues: TaskFormValues,
  communicationRequestPatientIdMapping?: Record<string, string>,
): CallbackWithLoader => {
  const medplum = useMedplum();
  const profile = useMedplumProfile();
  const apolloClient = useApolloClient();

  const [loading, setLoading] = useState(false);

  const saveTask = async (): Promise<Bundle | undefined> => {
    if (!profile?.id || !formValues.dueDate) {
      return undefined;
    }

    setLoading(true);

    try {
      const { taskType, assignedTo, priority, notes, requestor, createdDate } = formValues;
      const dueDate = combineDateAndTimeLocalTimezone(formValues.dueDate, formValues.dueTime);

      const response = await bulkTaskCreation({
        medplum,
        taskType,
        businessStatus: priority,
        forPatientIds: patientIds,
        assignToPractitionerId: assignedTo,
        requestedByPractitionerId: requestor ?? profile.id,
        dueDate: dueDate ?? '',
        notes,
        createdDate,
        communicationRequestPatientIdMapping,
      });

      await apolloClient.refetchQueries({ include: [GetPatientPanelDocument] });
      return response;
    } catch (err) {
      notifications.show({
        title: 'Error creating tasks',
        message: getErrorMessage(err),
        color: 'status-error',
      });
      logError(err);
    } finally {
      setLoading(false);
    }

    return undefined;
  };

  return [saveTask, loading];
};
