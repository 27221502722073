import { ContactReviewForm, PhoneNumber } from '@/components/shared/ContactReviewForm';
import { Coding, Patient } from '@medplum/fhirtypes';
import { ContactRelationship, ContactType, HL7System, System } from 'const-utils';
import { GetPatientQuery, Patient as GraphqlPatient } from 'medplum-gql';
import { getFirstCodingBySystem } from 'imagine-dsl/utils/codeableConcepts';
import { getAllPhoneContactPoints } from 'imagine-dsl/utils/patient';
import { cleanPhoneNumber } from 'imagine-dsl/utils/strings';
import { RELATIONSHIP_OTHER } from '@/components/shared/constants';

type HydrateContactOptions = {
  firstNameOverride?: string;
  lastNameOverride?: string;
  relationshipOverride?: Coding;
  phoneNumbersOverride?: PhoneNumber[];
  contactTypeOverride?: Coding;
};

export const hydrateContactReviewForm = (
  patient: GetPatientQuery['Patient'],
  opts?: HydrateContactOptions,
): ContactReviewForm => {
  const address = patient?.address?.[0];

  const caregiver = patient?.RelatedPersonList?.filter((l) => (l?.PatientList?.length ?? 0) > 0)?.[0]?.PatientList?.[0];
  const requiresInterpreter = patient?.extension?.find(
    (e) => e.url === HL7System.InterpreterRequired.toString(),
  )?.valueBoolean;

  return {
    address: {
      line1: address?.line?.[0] ?? '',
      line2: address?.line?.[1] ?? '',
      city: address?.city ?? '',
      state: address?.state ?? '',
      zip: address?.postalCode ?? '',
    },
    languagePreferences: {
      language: patient?.communication?.[0]?.language?.coding?.[0]?.display ?? '',
      requiresInterpreter: requiresInterpreter ?? false,
    },
    contact: hydrateContact(caregiver as GraphqlPatient, patient as GraphqlPatient, opts),
    links: {},
  };
};

export const hydrateContact = (
  caregiver: GraphqlPatient,
  patient: GraphqlPatient,
  opts?: HydrateContactOptions,
): ContactReviewForm['contact'] => {
  const relationship =
    opts?.relationshipOverride ??
    getFirstCodingBySystem({
      codeableConcepts: patient?.RelatedPersonList?.[0]?.relationship,
      system: System.ContactRelationship.toString(),
    });
  const contactType =
    opts?.contactTypeOverride ??
    getFirstCodingBySystem({
      codeableConcepts: patient?.RelatedPersonList?.[0]?.relationship,
      system: System.ContactType.toString(),
    });
  const language = caregiver?.communication?.[0]?.language?.coding?.[0]?.display ?? '';
  const address = caregiver?.address?.[0];

  return {
    contactId: caregiver?.id ?? '',
    firstName: opts?.firstNameOverride || caregiver?.name?.[0]?.given?.[0] || '',
    lastName: opts?.lastNameOverride || caregiver?.name?.[0]?.family || '',
    contactType: contactType?.display ?? '',
    customRelationship: opts?.relationshipOverride ? opts?.relationshipOverride?.display : undefined,
    customContactType: opts?.contactTypeOverride ? opts?.contactTypeOverride?.display : undefined,
    relationship: relationship
      ? {
          code: relationship.code ?? '',
          display: relationship.display ?? '',
          system: System.ContactRelationship,
        }
      : undefined,
    language,
    primary: caregiver?.extension?.find((e) => e.url === System.PrimaryCaregiver.toString())?.valueBoolean ?? true,
    requiresInterpreter:
      caregiver?.extension?.find((e) => e.url === HL7System.InterpreterRequired.toString())?.valueBoolean ?? false,
    emailAddress:
      caregiver?.telecom?.find((t) => t.system === 'email')?.value ??
      patient?.telecom?.find((t) => t.system === 'email')?.value ??
      '',
    address: {
      line1: address?.line?.[0] ?? '',
      line2: address?.line?.[1] ?? '',
      city: address?.city ?? '',
      state: address?.state ?? '',
      zip: address?.postalCode ?? '',
    },
    usePatientAddress: !address,
    usePatientLanguage: !language,
    phoneNumbers: opts?.phoneNumbersOverride ?? hydratePhoneNumbers(patient),
  };
};

export const hydratePhoneNumbers = (
  patient?: Patient | GraphqlPatient | null,
): ContactReviewForm['contact']['phoneNumbers'] => {
  if (!patient) {
    return [];
  }

  const contactPoints = getAllPhoneContactPoints(patient as GraphqlPatient);
  const hasPrimary = contactPoints.some(
    (pn) => pn?.extension?.find((e) => e.url === System.PrimaryPhone.toString())?.valueBoolean,
  );

  const phoneNumbers: string[] = [];

  return contactPoints
    .filter((c) => c.value?.length) // Filter out invalid phone numbers that have potential to be saved in an abandoned outreach 'log call & exit'
    .map((t, idx) => {
      const primary =
        t.extension?.find((e) => e.url === System.PrimaryPhone.toString())?.valueBoolean ??
        (hasPrimary ? false : idx === 0);

      const status = t.extension?.find((e) => e.url === System.PhoneStatus.toString())?.valueCode ?? '';
      return {
        number: t.value ?? '',
        type: t.use ?? '',
        status,
        primary,
      };
    })
    .filter((phoneNumber) => {
      if (!phoneNumbers.includes(cleanPhoneNumber(phoneNumber.number))) {
        phoneNumbers.push(cleanPhoneNumber(phoneNumber.number));
        return true;
      } else {
        return false;
      }
    });
};

export const isDuplicatePhoneNumber = (value: string, arrayOfValues: PhoneNumber[], valueIndex: number): boolean => {
  let isDuplicatePhoneNumber: boolean = false;

  arrayOfValues.forEach((phone, index) => {
    if (cleanPhoneNumber(phone.number) === cleanPhoneNumber(value) && valueIndex > index) {
      isDuplicatePhoneNumber = true;
    }
  });

  return isDuplicatePhoneNumber;
};

/**
 * Acts as a lookup service and converts a display string into a
 * standardized FHIR Coding object. Provides consistent handling
 * of standard contact types, custom contact types, and legacy contact types.
 */
export const getContactTypeCoding = (display: string): Coding => {
  const concept = ContactType.compose?.include?.[0].concept?.find((concept) => concept.display === display);
  return (
    concept || {
      code: 'other',
      display,
      system: System.ContactType,
    }
  );
};

/**
 * Utility function to structure relationship dropdown to handle both legacy
 * relationships (non standardized) and standardized relationships (include custom relationships)
 */
export const parseRelationshipCode = (coding: Coding | undefined): Coding => {
  if (!coding) {
    return {
      code: '',
      display: '',
    } as Coding;
  }

  // Get all valid relationship codes from the value set
  const validRelationshipCodes = ContactRelationship.compose?.include?.[0].concept?.map((c) => c.code) ?? [];

  // If code is not in valid relationship value set OR is "Other", treat it as "Other"
  if (!validRelationshipCodes.includes(coding.code ?? '') || coding.code === RELATIONSHIP_OTHER) {
    return {
      code: RELATIONSHIP_OTHER,
      display: 'Other',
      system: System.ContactRelationship,
    };
  }

  return {
    ...coding,
    system: System.ContactRelationship,
  };
};

/**
 * Gets the display value for a relationship coding, handling "Other" case specially
 */
export const getRelationshipDisplay = (coding: Coding | undefined): string => {
  if (!coding) {
    return '';
  }

  if (coding.code === RELATIONSHIP_OTHER) {
    return coding.display ?? 'Other';
  }

  return coding.display ?? '';
};
