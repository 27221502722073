import { useMedplum } from '@medplum/react';
import { System } from 'const-utils';
import { OrganizationType } from 'const-utils/codeSystems/ImaginePediatrics';
import { notifications } from '@mantine/notifications';
import { useCallback } from 'react';
import { ORG_INSURANCE } from 'const-utils/utils/constants';

export const useCreatePayor = () => {
  const medplum = useMedplum();
  const createPayor = useCallback(
    async (payorName: string, active: boolean) => {
      try {
        const createPayor = await medplum.createResource({
          name: payorName,
          resourceType: 'Organization',
          active: active,
          type: [
            {
              coding: [
                {
                  system: System.OrganizationType,
                  code: 'ins',
                  display: 'Insurance Company',
                },
              ],
            },
          ],
          identifier: [
            {
              system: System.Organization,
              value: `${ORG_INSURANCE}${payorName}`,
            },
          ],
          meta: {
            tag: [
              {
                system: System.OrganizationType,
                code: OrganizationType.HealthPlan,
              },
            ],
          },
        });
        return createPayor;
      } catch (error) {
        notifications.show({
          title: 'Error',
          message: 'Failed to create payor',
          color: 'status-error',
        });
        return null;
      }
    },
    [medplum],
  );
  return { createPayor };
};
