import React, { Suspense, useCallback, useEffect } from 'react';
import { Paper, Alert, Text } from '@mantine/core';
import { useMedplumProfile, Loading, useMedplum } from '@medplum/react';
import { IconAlertCircle } from '@tabler/icons-react';
import {
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Outlet,
  RouterProvider,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Providers, { AuthenticatedProviders } from './Providers';
import { VideoChat } from './components/VideoChat';
import { CommandCenterPage } from './pages/CommandCenter/Page';
import { LandingPage } from './pages/LandingPage';
import OutreachPage from './pages/Outreach/Page';
import { PatientProfile } from './pages/PatientProfile/Page';
import { PatientsPage } from './pages/PatientsPage';
import { Notifications } from '@mantine/notifications';
import { createHttpLink, ApolloClient, from, InMemoryCache } from '@apollo/client';
import Pusher from 'pusher-js';
import { localStorageKeys, sessionStorageKeys } from './localAndSessionStorage';
import { theme } from './theme';
import { getConfig } from './config';
import { setContext } from '@apollo/client/link/context';
import fragments from 'medplum-gql/src/fragments.json';
import { logError } from './errors';
import { TaskPage } from './pages/Task/Page';
import { useActivityTracking } from './hooks/useActivityTracking';
import { useNewVersionNotification } from './hooks/useNewVersionNotification';
import AppShell from './components/AppShell';
import { SignInPage } from './pages/Automation/SignInPage';
import { CARE_HUB_LOGO_SRC } from './components/CareHubLogo';
import { useFavicon } from '@mantine/hooks';
import { MyPatientsPage } from './pages/MyPatients/Page';
import { UsersPage } from './pages/Users/Page';
import { ReleaseNotesPage } from './pages/ReleaseNotes';
import { VerticalNavProvider } from './components/nav/VerticalNavContext';
import { PatientPanelPage } from './pages/PatientPanel/Page';
import { NotificationsPage } from './pages/Notifications/Page';
import { AdminPage } from './pages/Admin/Page';
import { errorLink, getAuthHeaders, medplum } from './utils/session';
import { MarketsAndPayorsPage } from './pages/Admin/MarketsAndPayors/Page';
import { EngagementPodsPage } from './pages/Admin/EngagementPods/Page';
import { EngagementPodPage } from './pages/Admin/EngagementPod/Page';
import { AddPostalCodesModalProvider } from './components/engagementPodMapping/AddPostalCodesModal/AddPostalCodesModalContext';

const config = getConfig();

const httpLink = createHttpLink({
  uri: config.graphqlUrl,
});

const pusher = new Pusher(config.pusherAppKey, {
  cluster: 'us2',
});

const authLink = setContext(async (_, { headers }) => {
  await medplum.refreshIfExpired();
  const login = medplum.getActiveLogin();
  const accessToken = login?.accessToken;

  return {
    headers: {
      ...headers,
      ...getAuthHeaders(accessToken),
    },
  };
});

const client = new ApolloClient({
  link: from([authLink, errorLink, httpLink]),
  defaultOptions: {
    query: {
      errorPolicy: 'all',
    },
    watchQuery: {
      errorPolicy: 'all',
    },
  },
  cache: new InMemoryCache({
    addTypename: true,
    possibleTypes: fragments.possibleTypes,
  }),
});

const splitConfig: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: config.splitSdkKey || '',
    key: config.splitWorkspaceId || '',
    trafficType: 'provider',
  },
  features: config.splitFeatures,
};

const ErrorBoundaryFallback: Sentry.FallbackRender = ({ error }) => (
  <Paper shadow="sm" p="xl" m="md">
    <Alert color="red" title="You have encountered an error" icon={<IconAlertCircle />}>
      <Text>{(error as Error).toString()}</Text>
      <Text c="dimmed" size="xs" mt="md">
        A report has been sent to the technology team and this will be investigated shortly
      </Text>
    </Alert>
  </Paper>
);

const RequireAuth = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const medplum = useMedplum();
  const profile = useMedplumProfile();

  const onInactive = useCallback(() => {
    // NOTE: `medplum.signOut` clears ALL local storage keys so we must set the inactive alert key AFTER the sign out
    // Open Medplum issue to address the destructive-nature of `medplum.signOut` & `medplum.clear` w/r/t local storage https://github.com/medplum/medplum/issues/3803
    medplum
      .signOut()
      .catch(logError)
      .finally(() => {
        localStorage.setItem(localStorageKeys.inactiveAlert, '1');
      });
  }, [medplum]);

  useActivityTracking({
    userId: profile?.id,
    onInactive,
  });
  useNewVersionNotification();
  useEffect(() => {
    if (!profile) {
      return;
    }

    Sentry.setUser({
      id: profile.id,
      email: profile.telecom?.find((t) => t.system === 'email')?.value,
    });
  }, [profile]);

  if (!profile) {
    const redirectTo = window.location.href.split(window.location.origin).at(1);
    const query = redirectTo && redirectTo !== '/' ? `?redirectTo=${encodeURIComponent(redirectTo)}` : '';
    if (redirectTo) {
      sessionStorage.setItem(sessionStorageKeys.redirectTo(), decodeURIComponent(redirectTo));
    }
    return <Navigate to={`/signin${query}`} />;
  }

  return <>{children}</>;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={
        <Providers client={client} theme={theme} medplum={medplum} pusher={pusher} split={splitConfig}>
          <Sentry.ErrorBoundary fallback={ErrorBoundaryFallback}>
            <Suspense fallback={<Loading />}>
              <Outlet />
              <Notifications position="top-right" />
            </Suspense>
          </Sentry.ErrorBoundary>
        </Providers>
      }
    >
      <Route path="/signin" element={<LandingPage />} />
      <Route path="/Automation/signin" element={<SignInPage />} />
      <Route
        path="/"
        element={
          <RequireAuth>
            <AuthenticatedProviders>
              <VerticalNavProvider>
                <AppShell>
                  <Outlet />
                  <VideoChat />
                </AppShell>
              </VerticalNavProvider>
            </AuthenticatedProviders>
          </RequireAuth>
        }
      >
        <Route path="/" element={<CommandCenterPage />} />
        <Route path="Patients" element={<PatientsPage />} />
        <Route path="MyPatients" element={<MyPatientsPage />} />
        <Route path="PatientPanel" element={<PatientPanelPage />} />
        <Route path="Patient/:id" element={<PatientProfile />} />
        <Route path="Outreach/:id" element={<OutreachPage />} />
        <Route path="Task" element={<TaskPage />} />
        <Route path="Users" element={<UsersPage />} />
        <Route path="Admin" element={<AdminPage />}>
          <Route
            element={
              <AddPostalCodesModalProvider>
                <Outlet />
              </AddPostalCodesModalProvider>
            }
          >
            <Route path="EngagementPods" element={<EngagementPodsPage />} />
            <Route path="EngagementPod/:id" element={<EngagementPodPage />} />
          </Route>
          <Route path="MarketsAndPayors" element={<MarketsAndPayorsPage />} />
        </Route>
        <Route path="release-notes" element={<ReleaseNotesPage />} />
        <Route path="notifications" element={<NotificationsPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Route>,
  ),
);

export function App(): JSX.Element | null {
  useFavicon(CARE_HUB_LOGO_SRC);

  return <RouterProvider router={router} fallbackElement={<Loading />} />;
}
