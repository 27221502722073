/**
 * Reusable utility functions for working with Medplum's coding type.
 *
 * This file provides helper functions to extract commonly used properties from a Coding
 * object. The Coding type represents a coded concept from a terminology system. Medplum's
 * Coding type can be imported by various component files separately and used as is, however
 * by centralizing the logic here, we can ensure consistency across the Carehub application
 * when handling Coding objects and make future updates and extensions easier.
 */

import { Coding } from '@medplum/fhirtypes';

/**
 * Extracts the unique code from a Coding object.
 *
 * If the code is missing, returns 'Unknown Code' as a fallback value.
 *
 * @param item - A Coding object from Medplum's or Imagine Pediatric's FHIR types.
 * @returns The code string representing the unique identifier for the concept.
 */
export const getCode = (selectedItem: Coding): string => selectedItem.code ?? 'Unknown code';

/**
 * Extracts the display label from a Coding object.
 *
 * If the display label is missing, it returns 'Unknown' as a fallback value.
 *
 * @param item - A coding object from Medplum's or Imagine Pediatric's FHIR types.
 * @returns The display label for the concept, or 'Unknown' if not provided.
 */
export const getDisplay = (selectedItem: Coding): string => selectedItem.display ?? 'Unknown display';
