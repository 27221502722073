import React from 'react';
import { Text, Stack } from '@mantine/core';
import { TaskType } from 'const-utils';
import { BHSurveyType } from 'const-utils/codeSystems/ImaginePediatrics';
import { BaseTask } from 'imagine-dsl/models/tasks/baseTask';
import { useGetBhtocTaskEncounterClassesQuery } from 'medplum-gql';
import { uniq } from 'lodash';

type TaskTitleRenderer = ({ task }: { task: BaseTask }) => React.ReactNode;

const OutreachTaskTitleRenderer: TaskTitleRenderer = ({ task }) => {
  return task.task?.code?.coding?.[0].display ?? 'Unknown';
};

const taskTitleColumnRenderers: Record<TaskType, TaskTitleRenderer> = {
  [TaskType.CarePathwayReferralReview]: () => <Text>Care pathway referral review</Text>,
  [TaskType.ScreenerReview]: () => (
    <Stack gap={0}>
      <Text>PRAPARE screener</Text>
    </Stack>
  ),
  [TaskType.ReviewBHSurvey]: ({ task }) => {
    const bhTypeDisplay = task.isSurveyReviewTask() && task.bhSurveyType === BHSurveyType.GAD7 ? 'GAD7' : 'PHQ9';
    return (
      <Stack gap={0}>
        <Text>BH Survey {bhTypeDisplay}</Text>
      </Stack>
    );
  },
  [TaskType.Document]: () => null,
  [TaskType.Chat]: () => null,
  [TaskType.Screener]: () => null,
  [TaskType.BHSurvey]: () => null,
  [TaskType.SuicideRisk]: () => null,
  [TaskType.FirstCall]: OutreachTaskTitleRenderer,
  [TaskType.Callback]: OutreachTaskTitleRenderer,
  [TaskType.InformationRequest]: OutreachTaskTitleRenderer,
  [TaskType.InformationRequestVerification]: OutreachTaskTitleRenderer,
  [TaskType.CallPartneringProvider]: OutreachTaskTitleRenderer,
  [TaskType.NetworkEngagement]: OutreachTaskTitleRenderer,
  [TaskType.OutreachFollowUp]: OutreachTaskTitleRenderer,
  [TaskType.AppointmentRequest]: OutreachTaskTitleRenderer,
  [TaskType.NoShowFollowUp]: OutreachTaskTitleRenderer,
  [TaskType.ReEngagement]: OutreachTaskTitleRenderer,
  [TaskType.ServiceRecovery]: OutreachTaskTitleRenderer,
  [TaskType.MedicalTOCOutreach]: OutreachTaskTitleRenderer,
  [TaskType.ScheduleAppointmentBHTOC]: () => <Text>Schedule appointment BH TOC</Text>,
  [TaskType.BHTOC]: ({ task }) => {
    const taskQuery = useGetBhtocTaskEncounterClassesQuery({
      variables: { id: task.id! },
      skip: !task.id,
    });

    const encounterClass = taskQuery.data?.Task?.basedOn
      ?.map((r) => {
        return r.resource?.__typename === 'Encounter' && r.resource.class.code;
      })
      .filter((v) => typeof v === 'string');
    if (taskQuery.loading || !encounterClass?.length) {
      return <Text>BH TOC</Text>;
    }
    const uniqEncounterClass = uniq(encounterClass).join(', ');

    return <Text>BH TOC - {uniqEncounterClass}</Text>;
  },
  [TaskType.ScheduleVisitsForCarePathwayChange]: () => <Text>Schedule visits for care pathway change</Text>,
  [TaskType.CancelAllLongitudinalAppointments]: () => <Text>Cancel all longitudinal appointments</Text>,
  [TaskType.DisasterPreparednessSurvey]: () => null,
  [TaskType.ReviewDisasterPreparednessSurvey]: () => (
    <Stack gap={0}>
      <Text>Disaster preparedness survey</Text>
    </Stack>
  ),
};

export const useGetTaskTitleRenderers = () => {
  return taskTitleColumnRenderers;
};
