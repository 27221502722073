import React, { useEffect, useState } from 'react';
import { Button, Badge, Box, Modal, Group, Text, Title, useMantineTheme } from '@mantine/core';
import { debounce } from 'lodash';
import athenaLogoSrc from '@assets/logo/athena.svg';
import { IconAlertTriangle, IconCheck, IconExternalLink, IconAlertCircleFilled } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { ResourceInput, useMedplum } from '@medplum/react';
import { notifications } from '@mantine/notifications';
import { Practitioner, QuestionnaireResponse, Task } from '@medplum/fhirtypes';
import { handleCanSendPRAPAREToAthena, PRAPAREToAthenaArgs } from 'imagine-dsl';
import { getConfig } from '@/config';
import { Maybe } from 'medplum-gql';
import CustomPractitionerLineItemComponent from '@/components/CustomPractitionerLineItemComponent';
import { getErrorMessage } from '@/errors';
import { getAthenaEncounterExtension, getHasBeenSentToAthenaExtension } from 'imagine-dsl/utils/extensions';
import { useSendPrapareToAthenaMutation } from 'imagine-gql/client';
import { imagineClient } from '@/hooks/useImagineApolloClient';
import { TranslationCodeMapping } from 'imagine-dsl/services/questionnaireService';

interface AthenaStatusContentProps {
  patientId: string;
  translatedItemMapping: TranslationCodeMapping[];
  taskId?: Maybe<string>;
  questionnaireResponse?: Maybe<QuestionnaireResponse>;
}

export function AthenaStatusContent({
  patientId,
  translatedItemMapping,
  taskId,
  questionnaireResponse,
}: AthenaStatusContentProps): JSX.Element {
  const [hasBeenSent, setHasBeenSent] = useState<boolean | undefined>(false);
  const [failedToSend, setFailedToSend] = useState<boolean | undefined>(false);
  const [encounterId, setEncounterId] = useState('');

  const theme = useMantineTheme();
  const medplum = useMedplum();
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [task, setTask] = useState<Task | undefined>(undefined);
  const [selectedProvider, setSelectedProvider] = useState<Practitioner | undefined>(undefined);

  const [sendPrapareToAthenaMutation] = useSendPrapareToAthenaMutation({
    client: imagineClient,
  });

  const config = getConfig();

  const retrieveTask = async () => {
    if (taskId) {
      const t = await medplum.readResource('Task', taskId, { cache: 'no-cache' });
      setTask(t);

      const hasBeenSent = getHasBeenSentToAthenaExtension(t);

      if (hasBeenSent && hasBeenSent.valueBoolean === false) {
        setFailedToSend(true);
      }

      if (hasBeenSent && hasBeenSent.valueBoolean === true) {
        setHasBeenSent(true);

        const encounterId = getAthenaEncounterExtension(t)?.valueString;
        if (encounterId) {
          setEncounterId(encounterId);
        }
      }
    }
  };

  useEffect(() => {
    retrieveTask();
  }, [taskId]);

  const handleSendToAthena = debounce(async () => {
    if (!questionnaireResponse) {
      return;
    }

    setIsLoading(true);

    let sendToAthenaArgs: PRAPAREToAthenaArgs | undefined;
    try {
      sendToAthenaArgs = await handleCanSendPRAPAREToAthena({
        medplum,
        patientId,
        questionnaireResponse,
        translatedItemMapping,
        task,
        selectedProvider,
      });
    } catch (error) {
      notifications.show({
        title: 'Failed to send PRAPARE to Athena',
        message: getErrorMessage(error),
        color: 'red',
      });
    }

    if (sendToAthenaArgs) {
      try {
        const args = {
          patientId,
          questionnaireResponseId: questionnaireResponse?.id!,
          questionsAndAnswers: sendToAthenaArgs.questionsAndAnswers,
          taskId: task?.id!,
          imagineLogoBaseUrl: `${window.location.protocol}//${window.location.host}`, //CORS fix
          providerId: sendToAthenaArgs.providerId,
          targetTimezone: sendToAthenaArgs.targetTimezone,
          departmentId: sendToAthenaArgs.departmentId,
        };

        const result = await sendPrapareToAthenaMutation({
          variables: {
            request: {
              ...args,
            },
          },
        });

        if (!result?.data?.sendPRAPAREToAthena?.success) {
          notifications.show({
            title: 'Failed to send PRAPARE to Athena',
            message: result?.data?.sendPRAPAREToAthena?.message || 'Unknown error',
            color: 'red',
          });
        }
      } catch (error) {
        notifications.show({
          title: 'Failed to send PRAPARE to Athena',
          message: getErrorMessage(error),
          color: 'red',
        });
      } finally {
        retrieveTask();
      }
    }

    setIsLoading(false);
    setModalVisible(false);
  }, 500);

  return (
    <Box>
      <Title>Survey Status</Title>
      <Box h={65} style={{ alignItems: 'center', alignContent: 'center' }}>
        {!hasBeenSent && !failedToSend && (
          <Box display="flex" style={{ alignItems: 'center' }}>
            <Badge
              p={12}
              color={theme.colors['status-warn'][10]}
              variant="light"
              size="md"
              leftSection={<IconAlertTriangle />}
            >
              {'Needs to be sent to Athena'}
            </Badge>

            <Button
              onClick={() => {
                setModalVisible(true);
              }}
              ml={12}
              leftSection={<img src={athenaLogoSrc} height={16} alt="Athena Logo" />}
              variant="outline"
              pl={10}
            >
              Send to Athena
            </Button>
          </Box>
        )}

        {hasBeenSent && (
          <Box display="flex" style={{ alignItems: 'center' }}>
            <Badge p={12} color="status-success" variant="light" size="md" leftSection={<IconCheck />}>
              {'Survey sent to Athena'}
            </Badge>

            <Box bg="brand-gray.4" ml={12} mr={12} h="md" w={2} style={{ borderRadius: '99px' }} />

            <Link
              to={`https://${config.appEnv !== 'prod' ? 'preview.' : ''}athenahealth.com/277600/1/globalframeset.esp?MAIN=ax/encounter/${encounterId}/intake `}
              target="_blank"
            >
              <Button
                leftSection={<img src={athenaLogoSrc} height={16} alt="Athena Logo" />}
                rightSection={<IconExternalLink size={16} />}
                variant="transparent"
                p={0}
                h="lg"
              >
                View Survey results in Athena
              </Button>
            </Link>
          </Box>
        )}

        {failedToSend && (
          <Box bd="1px solid red" p={12} display="flex" style={{ backgroundColor: theme.colors['status-error'][10] }}>
            <IconAlertCircleFilled size={24} color="red" />

            <Box>
              <Text style={{ fontWeight: 'bold' }} ml={12} size="lg">
                Send to Athena Failed
              </Text>
              <Text ml={12} size="lg">
                Please enter the survey results manually
              </Text>
            </Box>
          </Box>
        )}
      </Box>

      <Modal
        size="lg"
        centered
        opened={modalVisible}
        onClose={() => setModalVisible(false)}
        title={
          <Text c="imagine-green" fw="bold" size="xl">
            Send PRAPARE to Athena
          </Text>
        }
      >
        <Text mb={5}>Select provider from your pod or team</Text>

        <ResourceInput
          key={'thing'}
          defaultValue={undefined}
          onChange={(p) => {
            setSelectedProvider(p as Practitioner);
          }}
          resourceType="Practitioner"
          name="assigned-to"
          placeholder={'Please select'}
          itemComponent={CustomPractitionerLineItemComponent}
          searchCriteria={{ '_tag:': 'internal' }}
        />

        <Group mt="xl" justify="right" gap="sm">
          <Button disabled={isLoading} variant="default" onClick={() => {}}>
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            loading={isLoading}
            onClick={() => {
              handleSendToAthena();
            }}
          >
            Send
          </Button>
        </Group>
      </Modal>
    </Box>
  );
}
