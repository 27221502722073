import React from 'react';
import { TouchpointActivityItem } from '@/components/shared/TouchpointActivityItem';
import { Box, Stack, Table, Text, ThemeIcon, Title } from '@mantine/core';
import { OutreachAttempt } from 'imagine-dsl/utils/outreach';
import { IconChecklist } from '@tabler/icons-react';
import { formatWithYesterdayAndToday } from 'imagine-dsl/utils/dates';
import { HumanNameDisplay } from '@medplum/react';
import { HumanName } from '@medplum/fhirtypes';
import { BaseTask } from 'imagine-dsl/models/tasks/baseTask';

type ActivityHistoryProps = {
  patientId: string;
  touchpoints: OutreachAttempt[];
  task: BaseTask;
};
export const ActivityHistory = ({ patientId, touchpoints, task }: ActivityHistoryProps) => {
  const taskResolvedBy = task?.owner?.name?.[0];
  const taskCompletionDate = task?.executionPeriodEnd ? task.executionPeriodEnd : undefined;

  const resolvedTaskRow = (
    <Table.Tr>
      <Table.Td>
        <Stack justify="flex-start" mt="25px">
          <ThemeIcon bd="1px solid status-success.9" c="status-success.9" pos="relative" p={2} radius="100%">
            <IconChecklist size={16} />
          </ThemeIcon>
        </Stack>
      </Table.Td>
      <Table.Td>
        <Text size="xs" c="brand-gray.7">
          {formatWithYesterdayAndToday(taskCompletionDate, 'EEE, MMMM do, yyyy hh:mm aaa', true)}
        </Text>
        {taskResolvedBy && (
          <Text mt="10px" fw="700">
            Task{' '}
            <Text span>
              resolved by{' '}
              <Text span fw="700">
                <HumanNameDisplay value={taskResolvedBy as HumanName} />
              </Text>
            </Text>
          </Text>
        )}
      </Table.Td>
    </Table.Tr>
  );

  return (
    <Box mb="md" p="md">
      <Title size={18} mb="md">
        Activity ({task.completed ? touchpoints?.length + 1 : touchpoints?.length})
      </Title>
      {touchpoints.length === 0 ? (
        <div>No actions have been taken on this ADT alert</div>
      ) : (
        <Table verticalSpacing="lg" mih="95%" horizontalSpacing="6px">
          <Table.Tbody>
            {task.completed && resolvedTaskRow}
            {touchpoints.map((touchpoint) => (
              <TouchpointActivityItem
                key={touchpoint.id}
                touchpoint={touchpoint}
                patientId={patientId}
                elementMaxWidth="100%"
              />
            ))}
          </Table.Tbody>
        </Table>
      )}
    </Box>
  );
};
