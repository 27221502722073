import React, { useMemo } from 'react';
import { Box, Button, Group, Select, Stack, TextInput, ThemeIcon } from '@mantine/core';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import { NewAthenaProviderIdsProps } from './types';
import useAthenaProviderIds from '@/hooks/useAthenaProviderIds';

export const NewAthenaProviderIds = ({
  athenaProviderIdEntries,
  setAthenaProviderIdEntries,
  addAthenaIdEntry,
  removeAthenaIdEntry,
  athenaMarketIds,
  marketOptions,
}: NewAthenaProviderIdsProps) => {
  const { handleMarketChange, handleProviderIdChange, getUsedMarkets } = useAthenaProviderIds(athenaProviderIdEntries);

  const newEntries = useMemo(
    () => athenaProviderIdEntries.filter((entry) => !entry.id.startsWith('existing-')),
    [athenaProviderIdEntries],
  );

  const usedMarkets = useMemo(
    () => getUsedMarkets(athenaMarketIds, marketOptions),
    [getUsedMarkets, athenaProviderIdEntries, athenaMarketIds, marketOptions],
  );

  return (
    <Box mb="md">
      <Stack gap="md">
        {newEntries.map((entry) => (
          <Group key={entry.id} grow align="center">
            <Select
              label="Market"
              placeholder="Select market"
              data={marketOptions.map((option) => {
                const isUsed = usedMarkets.includes(option.value) && option.value !== entry.market;

                return {
                  value: option.value,
                  label: option.label,
                  disabled: isUsed,
                };
              })}
              value={entry.market}
              onChange={(value) =>
                handleMarketChange(entry.id, value || '', athenaProviderIdEntries, setAthenaProviderIdEntries)
              }
            />
            <TextInput
              label="Athena provider ID"
              placeholder="Enter provider ID"
              value={entry.providerId}
              onChange={(e) =>
                handleProviderIdChange(entry.id, e.target.value, athenaProviderIdEntries, setAthenaProviderIdEntries)
              }
            />
            <ThemeIcon color="red" onClick={() => removeAthenaIdEntry(entry.id)}>
              <IconTrash cursor="pointer" />
            </ThemeIcon>
          </Group>
        ))}
      </Stack>

      <Button
        variant="outline"
        fullWidth
        onClick={addAthenaIdEntry}
        leftSection={<IconPlus size={16} />}
        mt={newEntries.length > 0 ? 'md' : 'xs'}
      >
        Add Athena Provider ID
      </Button>
    </Box>
  );
};

export default NewAthenaProviderIds;
