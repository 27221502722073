import { Button, MantineThemeOverride } from '@mantine/core';

export const theme: MantineThemeOverride = {
  // https://www.figma.com/design/TzVdW6B6D9FDMSC701Gowf/Care-Hub-Design-System?node-id=263-9219
  fontFamily: 'Verdana, sans-serif',
  headings: {
    fontWeight: 'bold',
    sizes: {
      h1: {
        fontSize: '34px',
        fontWeight: '400',
      },
      h2: {
        fontSize: '26px',
        fontWeight: '400',
      },
      h3: {
        fontSize: '22px',
        fontWeight: '400',
      },
      h4: {
        fontSize: '18px',
        fontWeight: '700',
      },
    },
  },
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '0.875rem',
    lg: '1.0rem',
    xl: '1.125rem',
  },
  primaryColor: 'imagine-green',
  colors: {
    'status-error': [
      '#ffebeb',
      '#fad7d7',
      '#eeadad',
      '#e3807f',
      '#da5a59',
      '#d54241',
      '#c92a2a',
      '#bc2727',
      '#a82022',
      '#93151b',
      '#FDF5F5',
    ],
    'status-warn': [
      '#FFF4E6',
      '#FFE8CC',
      '#FFD8A8',
      '#FFC078',
      '#FFA94D',
      '#FF922B',
      '#FD7E14',
      '#F76707',
      '#E8590C',
      '#D4460F',
      '#D9480F',
    ],
    'status-info': [
      '#e8f6ff',
      '#d5e8f9',
      '#abcff0',
      '#7cb4e8',
      '#579de1',
      '#408ede',
      '#3287dd',
      '#2574c5',
      '#1967b1',
      '#00599d',
    ],
    'status-success': [
      '#EBFBEE',
      '#D3F9D8',
      '#B2F2BB',
      '#8CE99A',
      '#69D87C',
      '#51CF66',
      '#40C057',
      '#37B24D',
      '#529C4F',
      '#488847',
    ],
    'highlight-yellow': [
      '#fffdeb',
      '#fefbd5',
      '#fff7a5',
      '#fff370',
      '#ffef48',
      '#ffed34',
      '#ffeb2b',
      '#e3d121',
      '#c9b916',
      '#ada000',
    ],
    'status-highlight': [
      '#e2f8ff',
      '#ceeaff',
      '#9fd1fb',
      '#6db8f5',
      '#339AF0',
      '#2894ef',
      '#118eef',
      '#007ad6',
      '#006dc1',
      '#005dac',
    ],
    'gray-darken': [
      '#fef2f5',
      '#eae6e7',
      '#cdcdcd',
      '#b2b2b2',
      '#9a9a9a',
      '#8b8b8b',
      '#848484',
      '#757575',
      '#676465',
      '#5e5457',
    ],
    'imagine-green': [
      '#EBF9FB',
      '#D0E2E6',
      '#B2D0D6',
      '#94BEC6',
      '#76ABB7',
      '#5899A7',
      '#345A62',
      '#355C64',
      '#233D43',
      '#121F21',
    ],
    'brand-gray': [
      '#FAFAFA',
      '#F5F5F5',
      '#EFEFEF',
      '#E1E1E1',
      '#BEBEBE',
      '#9F9F9F',
      '#767676',
      '#626262',
      '#434343',
      '#222222',
    ],
    'brand-yellow': [
      '#fff9e1',
      '#fff1cc',
      '#ffe29b',
      '#ffd264',
      '#ffc438',
      '#ffbc1c',
      '#ffb809',
      '#e3a100',
      '#ca8f00',
      '#af7a00',
    ],
    'brand-blue': [
      '#ebedff',
      '#d1d6fa',
      '#9fa9f8',
      '#6a7af7',
      '#4151f6',
      '#2b39f6',
      '#212df8',
      '#1821dd',
      '#101dc5',
      '#0017ad',
    ],
    'secondary-brand': [
      '#FCFDFD',
      '#F8FBFB',
      '#F2F8F8',
      '#DEEDED',
      '#67919A',
      '#4D7E88',
      '#426E77',
      '#345A62',
      '#27474E',
      '#173238',
    ],
    'primary-50': [
      '#e8fafd',
      '#E1F1F6',
      '#b7dae6',
      '#8ec7d8',
      '#6fb6cd',
      '#5aabc6',
      '#4da6c3',
      '#3c91ad',
      '#2e819b',
      '#137089',
    ],
    'imagine-gray': [
      '#F1F3F5',
      '#efefef',
      '#cbccce',
      '#acb1b6',
      '#9199a1',
      '#808a95',
      '#778391',
      '#65707d',
      '#586571',
      '#485765',
    ],
    'touchpoint-icon': [
      '#ffedfa',
      '#f7daec',
      '#e7b4d3',
      '#d88cb9',
      '#cb6aa4',
      '#c35497',
      '#c04890',
      '#aa397e',
      '#983070',
      '#862562',
    ],
  },
  components: {
    Select: {
      defaultProps: {
        searchable: true,
      },
    },
    Modal: {
      defaultProps: {
        closeOnEscape: true,
        radius: 'lg',
      },
    },
    ActionIcon: {
      defaultProps: {
        variant: 'white',
      },
    },
    Title: {
      defaultProps: {
        c: 'imagine-green',
      },
    },
    DateTimePicker: {
      defaultProps: {
        valueFormat: 'YYYY-MM-DD hh:mm a',
      },
    },
    ThemeIcon: {
      defaultProps: {
        variant: 'transparent',
      },
    },
    Button: Button.extend({
      defaultProps: {
        radius: '8',
      },
    }),
  },
};
