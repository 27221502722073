import { ORG_BUSINESS } from 'const-utils/utils/constants';

// Thinking of ways to make more dynamic, but would be based on future markets (ie: if Markets only keep State as market name vs location in State)
export const getTimezone = (identifier: string): string => {
  const marketTimezoneMap: Record<string, string> = {
    [`${ORG_BUSINESS}Texas`]: 'America/Chicago',
    [`${ORG_BUSINESS}Florida`]: 'America/Detroit',
    [`${ORG_BUSINESS}DC`]: 'America/Detroit',
    [`${ORG_BUSINESS}New York`]: 'America/Detroit',
  };
  return marketTimezoneMap[identifier] || '';
};
