import React from 'react';
import { Modal, Group, CloseButton } from '@mantine/core';

interface TitleWithCloseProps {
  title: string | JSX.Element;
  onClose: () => void;
}

export const TitleWithClose = ({ title, onClose }: TitleWithCloseProps) => {
  return (
    <Group justify="space-between">
      <Modal.Title c="imagine-green">{title}</Modal.Title>
      <CloseButton onClick={onClose} />
    </Group>
  );
};
