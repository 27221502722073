import { Basic, Bundle } from '@medplum/fhirtypes';
import { CareTeamType, System } from 'const-utils';
import { BasicType } from 'const-utils/codeSystems/ImaginePediatrics';
import { v4 as uuidv4 } from 'uuid';

/**
 * Creates a Bundle to generate a CareTeam (engagement pod) resource
 * and its corresponding Basic resource for mapping.
 *
 * @param podName - The name of the Engagement Pod
 * @param organizationIdentifier - The market that the pod should be associated with
 *
 * @returns A Bundle containing the CareTeam and Basic resources
 */
export const createEngagementPodAndMappingBundle = (podName: string, organizationIdentifier: string): Bundle => {
  const transactionId = uuidv4();

  const bundle: Bundle = {
    resourceType: 'Bundle',
    type: 'transaction',
    entry: [
      {
        // this uuid is to link the CareTeam and Basic resources
        // https://www.medplum.com/docs/fhir-datastore/fhir-batch-requests#internal-references
        fullUrl: `urn:uuid:${transactionId}`,
        request: {
          method: 'POST',
          url: 'CareTeam',
        },
        resource: {
          resourceType: 'CareTeam',
          name: podName,
          status: 'inactive',
          meta: {
            tag: [
              {
                system: System.CareTeam,
                code: CareTeamType.EngagementPod,
              },
            ],
          },
          identifier: [
            {
              system: System.Pod,
              value: podName,
            },
          ],
        },
      },
      {
        request: {
          method: 'POST',
          url: 'Basic',
        },
        resource: {
          resourceType: 'Basic',
          meta: {
            tag: [
              {
                system: System.BasicType,
                code: BasicType.EngagementPodMapping,
              },
            ],
          },
          subject: {
            reference: `urn:uuid:${transactionId}`,
            display: podName,
          },
          code: { coding: [] },
          identifier: [{ system: System.Organization, value: organizationIdentifier }],
        },
      },
    ],
  };

  return bundle;
};

/**
 * Retrieves the Basic resource ID from the Bundle response.
 * @param bundle - The Bundle response from the Medplum API
 *
 * @returns The ID of the Basic resource
 */
export const getBasicResourceIdFromBundle = (bundle: Bundle): string => {
  const basicResource = bundle.entry?.find((x) => x.resource?.resourceType === 'Basic')?.resource;
  return basicResource?.id || '';
};

/**
 * Checks to see if postal code exists in any  mapping resource
 *
 * @param allResources - all Basic resources tagged engagement-pod-mapping
 * @param postalCode - The postal code to check
 *
 * @returns the Basic id & pod name if postal code exists
 * @returns null if postal code does not exist
 */
export const getCurrentMapping = (
  allResources: Basic[],
  postalCode: string,
): { resourceId: string; podName: string } | null => {
  const found = allResources.find((resource) => {
    return resource.code?.coding?.some((coding) => coding.code === postalCode);
  });

  if (!found) {
    return null;
  }

  return {
    resourceId: found.id!,
    podName: found.subject?.display!,
  };
};
