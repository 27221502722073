import React from 'react';
import { Stack, TextInput, MultiSelect, Select, NumberInput, Button, Box, Group, LoadingOverlay } from '@mantine/core';
import { useCreateMarket, useFetchAthenaIds } from '@/hooks/MarketHooks/useCreateMarket';
import { useFetchExistingMarkets, useUpdateMarket } from '@/hooks/MarketHooks/useUpdateMarket';
import { useMarketForm } from '@/hooks/MarketHooks/useMarketForm';
import { notifications } from '@mantine/notifications';

type MarketFormFieldsProps = {
  marketData?: {
    name?: string;
    id?: string;
    status?: string;
    features?: string[];
    athenaId?: number;
    prapare?: boolean;
    acuteCare?: boolean;
  };
  onClose: () => void;
  onUpdate: () => void;
  mode: 'create' | 'edit';
};

export const MarketFormFields: React.FC<MarketFormFieldsProps> = ({ marketData, mode, onClose, onUpdate }) => {
  const { createMarket } = useCreateMarket();
  const { updateMarket } = useUpdateMarket();
  const { formData, handleInputChange } = useMarketForm(marketData);
  const { existingMarketNames } = useFetchExistingMarkets();
  const { athenaIds } = useFetchAthenaIds();
  const [isLoading, setIsLoading] = React.useState(false);

  const handleCreateMarket = async () => {
    setIsLoading(true);
    try {
      const result = await createMarket(
        formData.name,
        formData.status === 'active',
        formData.athenaId.toString(),
        formData.features.includes('prapare'),
        formData.features.includes('acute'),
      );
      if (result) {
        notifications.show({
          title: 'Success',
          message: 'Market created successfully',
          color: 'status-success',
        });
        onClose();
      }
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Market creation failed!',
        color: 'status-error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateMarket = async () => {
    setIsLoading(true);
    try {
      const result = await updateMarket(
        formData.id,
        formData.name,
        formData.status === 'active',
        formData.athenaId.toString(),
        formData.features.includes('prapare'),
        formData.features.includes('acute'),
      );
      if (result) {
        notifications.show({
          title: 'Success',
          message: 'Market updated successfully',
          color: 'status-success',
        });
        onUpdate();
        onClose();
      }
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Market update failed',
        color: 'status-error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    switch (mode) {
      case 'create':
        if (!formData.name || existingMarketNames.includes(formData.name)) {
          notifications.show({
            title: 'Error',
            message: 'Please enter a market name or select a unique name.',
            color: 'status-error',
          });
          return;
        }
        if (athenaIds.includes(formData.athenaId.toString())) {
          notifications.show({
            title: 'Error',
            message: 'Please enter a unique Athena ID.',
            color: 'status-error',
          });
          return;
        }
        await handleCreateMarket();
        break;

      case 'edit':
        if (!formData.features || formData.features.length === 0) {
          notifications.show({
            title: 'Error',
            message: 'Please select at least one feature.',
            color: 'status-error',
          });
          return;
        }
        await handleUpdateMarket();
        break;

      default:
        notifications.show({
          title: 'Error',
          message: 'Invalid mode.',
          color: 'status-error',
        });
        break;
    }
  };

  return (
    <Box>
      <LoadingOverlay visible={isLoading} />
      <Stack gap={14} p="md">
        {mode === 'edit' && (
          <TextInput
            label="Market ID"
            placeholder="Enter Market ID"
            disabled
            value={formData.id}
            onChange={(e) => handleInputChange('id', e.target.value)}
          />
        )}

        <TextInput
          label="Market Name"
          placeholder="Select Market Name"
          required
          value={formData.name}
          onChange={(e) => handleInputChange('name', e.currentTarget.value)}
        />

        <MultiSelect
          label="Feature"
          data={[
            { value: 'prapare', label: 'PRAPARE' },
            { value: 'acute', label: 'Acute Care' },
          ]}
          required
          value={formData.features}
          onChange={(value) => handleInputChange('features', value)}
        />

        {mode === 'create' && (
          <Select
            label="Status"
            placeholder="Select Status"
            data={[
              { value: 'active', label: 'Active' },
              { value: 'inactive', label: 'Inactive' },
            ]}
            required
            value={formData.status}
            onChange={(value) => handleInputChange('status', value)}
          />
        )}

        <NumberInput
          label="Athena ID"
          placeholder="Enter Athena ID"
          required
          allowDecimal={false}
          allowNegative={false}
          value={formData.athenaId}
          onChange={(value) => handleInputChange('athenaId', value)}
        />
      </Stack>
      <Group justify="flex-end" ml="auto" pt={10}>
        <Button variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={(mode === 'create' && !formData.name) || !formData.features || formData.athenaId === 0}
          onClick={handleSubmit}
        >
          {mode === 'create' ? 'Add Market' : 'Update Market'}
        </Button>
      </Group>
    </Box>
  );
};
