export const getCookie = (name: string): string | null => {
  const cookies = document.cookie.split(';');
  const targetCookie = cookies.find((cookie) => cookie.trim().startsWith(`${name}=`));
  return targetCookie ? targetCookie.split('=')[1] : null;
};

export const setCookie = (name: string, value: string, days: number): void => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
};
