import React from 'react';
import { Box, Grid, Group, Divider } from '@mantine/core';
import { AssigneeSection } from '@/components/chat/footer/AssigneeSection';
import { useUserSession } from '@/components/shared/UserSessionContext';
import { bhTOCTaskCaseOutcomeOptions } from 'const-utils/codeSystems/ImaginePediatrics';
import { ResolveTaskButton } from '../../tasks/ResolveTaskButton';
import { System } from 'const-utils';
import { getName } from 'imagine-dsl/utils/patient';
import { BaseTask } from 'imagine-dsl/models/tasks/baseTask';
import { logError } from '@/errors';
import { useTaskAlertContext } from '@/pages/Task/TaskAlertProvider';
import { TOCTouchpointButton } from '@/components/patientPanel/TOCTouchpointButton';

type RelatedTaskActionsProps = {
  task: BaseTask;
  patientId: string;
  closeDrawer: () => void;
  communicationRequestId: string;
};

export const RelatedTaskActions = ({
  task,
  patientId,
  closeDrawer,
  communicationRequestId,
}: RelatedTaskActionsProps) => {
  const { profile } = useUserSession();
  const { refetchTasks } = useTaskAlertContext();

  const onCloseDrawerHandler = async () => {
    await refetchTasks().catch((e) => logError(e));
    closeDrawer();
  };

  const isTaskActionable = (task?.status !== 'completed' && task?.status !== 'cancelled') || !task?.isResolved;
  const assignee = task.owner;

  const taskId = task.id && typeof task.id === 'string' ? task.id : undefined;

  return (
    <>
      {isTaskActionable && (
        <>
          <Divider />
          <Box p="md" style={{ backgroundColor: 'white' }}>
            <Grid justify="space-between" align="center">
              <Grid.Col span={6}>
                <Group>
                  <AssigneeSection
                    taskMarket={task.market!}
                    assigneeId={assignee?.id}
                    assigneeName={getName(assignee)}
                    taskId={task.id!}
                    disabled={task.completed}
                    refreshTasks={refetchTasks}
                    profile={profile}
                  />
                </Group>
              </Grid.Col>
              <Grid.Col span={6} style={{ display: 'flex', justifyContent: 'flex-end', gap: '12px' }}>
                <TOCTouchpointButton
                  basedOn={[{ reference: `Task/${taskId}`, display: task.taskType?.display }]}
                  patientId={patientId}
                  communicationRequestId={communicationRequestId}
                  taskId={taskId}
                  variant="outline"
                  size="sm"
                />
                <ResolveTaskButton
                  taskId={task.id!}
                  callback={onCloseDrawerHandler}
                  reasons={{
                    system: System.BHTOCTaskCaseOutcome,
                    options: bhTOCTaskCaseOutcomeOptions,
                  }}
                />
              </Grid.Col>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};
