import { Extension, Organization, Task } from '@medplum/fhirtypes';
import { merge } from './lists';
import { System } from 'const-utils';

export const addExtensions = (
  existingExtensions?: Extension[],
  newExtensions?: (Extension | undefined | null | false)[],
): Extension[] => {
  const _existingExtensions = existingExtensions ?? [];
  const _newExtensions = (newExtensions ?? []).filter((e) => !!e) as Extension[];

  return merge(_existingExtensions, _newExtensions, 'url');
};

export const selectExtension = (extensions: Extension[] | undefined, url: string): Extension | undefined => {
  return extensions?.find((e) => e.url === url);
};

export const getAthenaEncounterExtension = (task?: Task) => {
  const encounterExtension = task?.extension?.find((extension) => extension.url === System.AthenaEncounterId);

  return encounterExtension;
};

export const getHasBeenSentToAthenaExtension = (task?: Task) => {
  const sentScreenerExension = task?.extension?.find((extension) => extension.url === System.AthenaSentScreener);

  return sentScreenerExension;
};

export const getAthenaIdExtension = (task?: Organization) => {
  const idExtension = task?.extension?.find((extension) => extension.url === System.AthenaId);

  return idExtension;
};
