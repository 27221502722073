import React from 'react';
import { Box, Group, Text, ActionIcon, Divider } from '@mantine/core';
import { Address } from '@medplum/fhirtypes';
import { IconHome, IconCopy } from '@tabler/icons-react';
import { formatAddress } from 'imagine-dsl/utils/strings';
import { copyToClipboardHandler } from '@/components/shared/patient/clipboard';
import { System } from 'const-utils';
import { capitalize } from '@medplum/core';

interface AddressInfoProps {
  addresses?: Address[];
}

export function AddressInfo({ addresses }: AddressInfoProps) {
  return addresses?.map((address, index) => {
    const formattedAddress = formatAddress(address);
    const addressType = address?.extension?.find((ext) => ext.url === System.AddressType.toString())?.valueCode;
    const isLastItem = index === addresses.length - 1;
    return (
      <>
        <Box key={address.id}>
          <Group wrap="nowrap" align="center">
            <IconHome size={16} style={{ flexShrink: 0 }} />
            <Text size="sm">{formattedAddress}</Text>
            <ActionIcon color="imagine-green" size="sm" onClick={() => copyToClipboardHandler(formattedAddress)}>
              <IconCopy size={16} />
            </ActionIcon>
          </Group>
          <Text c="dimmed" ml={30}>
            {capitalize(addressType ?? '')}
          </Text>
        </Box>
        {!isLastItem && <Divider my="sm" color="var(--mantine-color-gray-2)" />}
      </>
    );
  });
}
