import { UseFormReturnType } from '@mantine/form';
import { HasContactFields } from '../../shared/ContactReviewForm';
import { Client } from '@/hooks/useApiClient';
import { logError } from '@/errors';

export const PatientContactFormHandler = async (
  client: Client,
  patientId: string,
  form: UseFormReturnType<HasContactFields>,
  refreshPatient?: () => void,
  isEditing?: boolean,
): Promise<void> => {
  const body = {
    patientId,
    isUpdate: isEditing,
    ...form.values,
  };

  return client
    .fetch('/api/patients/contact', {
      method: 'POST',
      body: JSON.stringify(body),
    })
    .then((res) => res.json())
    .then((res) => {
      refreshPatient?.();
      form.setFieldValue('contact.contactId', res.caregiver.id);
    })
    .catch((err) => {
      logError(err);
      throw new Error('Failed to save contact info');
    });
};
