import React from 'react';
import { Box, Group, Text, Badge, ActionIcon, Divider } from '@mantine/core';
import { ContactPoint } from '@medplum/fhirtypes';
import { IconPhone, IconCopy } from '@tabler/icons-react';
import { formatPhoneString } from 'imagine-dsl/utils/strings';
import { copyToClipboardHandler } from '@/components/shared/patient/clipboard';
import { capitalize } from '@medplum/core';
import { isPrimaryPhone } from 'imagine-dsl/utils/patient';

interface PhoneNumbersListProps {
  phoneNumbers: ContactPoint[];
  hasInvalidNumbers?: boolean;
}

export function PhoneNumbersList({ phoneNumbers, hasInvalidNumbers }: PhoneNumbersListProps) {
  return (
    <>
      {phoneNumbers.map((phone, index) => {
        const isPrimary = isPrimaryPhone(phone);
        const isLastItem = index === phoneNumbers.length - 1 && !hasInvalidNumbers;

        return (
          <Box key={phone.value}>
            <Group align="center">
              <IconPhone size={16} />
              <Box>
                <Group gap="xs">
                  <Box>
                    <Text fw={700}>{formatPhoneString(phone.value)}</Text>
                    <Text c="dimmed">{capitalize(phone.use ?? '')}</Text>
                  </Box>
                  <ActionIcon color="imagine-green" size="sm" onClick={() => copyToClipboardHandler(phone.value)}>
                    <IconCopy size={16} />
                  </ActionIcon>
                  {isPrimary && (
                    <Badge size="sm" variant="filled" color="imagine-green">
                      Primary
                    </Badge>
                  )}
                </Group>
                {phone.period?.end && (
                  <Text size="xs" c="dimmed">
                    Last called {phone.period.end} days ago
                  </Text>
                )}
              </Box>
            </Group>
            {!isLastItem && <Divider my="sm" color="var(--mantine-color-gray-2)" />}
          </Box>
        );
      })}
    </>
  );
}
