import { Box, Checkbox, Grid, Group, Paper, Select, Stack, TextInput, Title, Text } from '@mantine/core';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { UnitedStates } from '@/utils/usa';
import { PreferredLanguage, System, extractValues } from 'const-utils';
import { useOutreach } from '../Context';
import ContactEditForm from '@/components/ContactEditForm';
import { Coding } from '@medplum/fhirtypes';
import { Patient as GraphqlPatient, Maybe, SearchPatientByAddressQueryVariables } from 'medplum-gql';
import { capitalize } from 'lodash';
import { useRecommendedLinks } from '@/hooks/useRecommendedLinks';
import { formatAddress, formatAgeAndDateOfBirth, pluralize } from 'imagine-dsl/utils/strings';
import { getName, is21OrOlder } from 'imagine-dsl/utils/patient';
import { PatientEligibilityBadge } from '@/components/shared/patient/PatientEligibilityBadge';
import { Over21Badge } from '@/components/Over21Badge';
import { getContactTypeCoding } from '@/utils/contactReviewUtils';
import { CONTACT_TYPE_OTHER, OTHER_DISPLAY, RELATIONSHIP_OTHER } from '@/components/shared/constants';
import { ContactRelationshipDefinitionForm } from '@/components/shared/ContactRelationshipDefinition';

type ReviewContactInfoComponent = JSX.Element;

const PreferredLanguageCodes = extractValues(PreferredLanguage);

export default function ReviewContactInfo(): ReviewContactInfoComponent {
  const outreach = useOutreach();
  const {
    contactReviewForm: form,
    patient,
    queuePatientToLink,
    updatedQueuedLinkRelationship,
    updatedQueuedLinkContactType,
    dequeuePatientToLink,
    queuedPatientsToLink,
  } = outreach;

  const getAddressSearch = useCallback((): Maybe<SearchPatientByAddressQueryVariables> => {
    if (!form.values.address?.line1) {
      return null;
    }

    return {
      address: formatAddress({
        ...form.values.address,
        line: [form.values.address.line1, form.values.address.line2].filter(Boolean) as string[],
      }),
      addressCity: form.values.address.city,
      addressPostalCode: form.values.address.zip,
      addressState: form.values.address.state,
    };
  }, [form.values.address]);
  const { data: recommendedLinks = [] } = useRecommendedLinks(patient, getAddressSearch);

  useEffect(() => {
    if (recommendedLinks.length > 0) {
      // Make a shallow copy of the current links
      const currentLinks = { ...form.values.links };

      // Bail out if id is missing
      for (const link of recommendedLinks) {
        if (!link.id) {
          continue;
        }

        // If this link isn't in the form yet, add a default object
        if (!currentLinks[link.id]) {
          currentLinks[link.id] = {
            contactType: '',
            relationship: { code: '', display: '', system: '' },
            customContactType: '',
            customRelationship: '',
            selected: false,
          };
        }
      }
      form.setFieldValue('links', currentLinks);
    }
  }, [recommendedLinks, form]);

  return (
    <Paper shadow="xs" p="xl" radius="lg">
      <Title mb="xl" order={3}>
        Review contact info
      </Title>
      <form id="review-contact-info-form" style={{ width: '100%' }}>
        <Title order={4}>Patient's address</Title>
        <br />
        <Group grow align="start">
          <TextInput
            withAsterisk
            label="Address line 1"
            placeholder="123 E Main Street"
            name="addressLine1"
            {...form.getInputProps('address.line1')}
          />
          <TextInput
            name="addressLine2"
            label="Address line 2"
            placeholder="Unit 1"
            {...form.getInputProps('address.line2')}
          />
        </Group>
        <br />
        <Grid grow>
          <Grid.Col span={6}>
            <TextInput
              name="city"
              withAsterisk
              label="City"
              placeholder="Chicago"
              {...form.getInputProps('address.city')}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <Select
              data-cy="state-select"
              withAsterisk
              label="State"
              placeholder="Select state"
              data={UnitedStates.map((state) => ({ label: state.name, value: state.abbreviation }))}
              {...form.getInputProps('address.state')}
            />
          </Grid.Col>
          <Grid.Col span={3}>
            <TextInput
              name="zipCode"
              withAsterisk
              label="Zip code"
              placeholder="60601"
              {...form.getInputProps('address.zip')}
            />
          </Grid.Col>
        </Grid>
        <br />
        <Title order={4}>Patient's language preference</Title>
        <br />
        <Group>
          <Select
            data-cy="language-select"
            label="Language"
            placeholder="Select language"
            data={PreferredLanguageCodes.map((d) => d.display) as string[]}
            {...form.getInputProps('languagePreferences.language')}
          />
          <Checkbox
            label="Requires interpreter"
            pt="lg"
            {...form.getInputProps('languagePreferences.requiresInterpreter', { type: 'checkbox' })}
          />
        </Group>
        {recommendedLinks?.length > 0 && (
          <Box mt="lg">
            <Title order={4}>Link patient(s)</Title>
            <Text>
              There {pluralize(recommendedLinks, 'are', 'is')}{' '}
              <Text span fw="bold">
                {recommendedLinks.length}
              </Text>{' '}
              {pluralize(recommendedLinks, 'patients', 'patient')} who may be associated with{' '}
              <Text span fw="bold">
                {getName(patient)}
              </Text>
            </Text>
            <Stack mt="sm">
              {recommendedLinks.map((link) => {
                return (
                  <RecommendedLinkItem
                    key={link.id}
                    link={link}
                    queuePatientToLink={queuePatientToLink}
                    updatedQueuedLinkRelationship={updatedQueuedLinkRelationship}
                    updatedQueuedLinkContactType={updatedQueuedLinkContactType}
                    dequeuePatientToLink={dequeuePatientToLink}
                    queuedPatientsToLink={queuedPatientsToLink}
                  />
                );
              })}
            </Stack>
          </Box>
        )}
        <br />
        <Title order={4}>Contact</Title>
        <br />
        <Paper bg="brand-gray.0" p="xl" radius="lg">
          <ContactEditForm formWithContact={form} />
        </Paper>
        <br />
      </form>
    </Paper>
  );
}

type RecommendedLinkItemProps = {
  link: GraphqlPatient;
  queuePatientToLink: (patient: GraphqlPatient, relationship: Coding) => void;
  updatedQueuedLinkRelationship: (patient: GraphqlPatient, relationship: Coding, customRelationship?: string) => void;
  updatedQueuedLinkContactType: (patient: GraphqlPatient, contactType: string, customContactType?: string) => void;
  dequeuePatientToLink: (patient: GraphqlPatient) => void;
  queuedPatientsToLink: { patient: GraphqlPatient; relationship: Coding }[];
};

const RecommendedLinkItem = ({
  link,
  queuePatientToLink,
  updatedQueuedLinkRelationship,
  updatedQueuedLinkContactType,
  dequeuePatientToLink,
  queuedPatientsToLink,
}: RecommendedLinkItemProps) => {
  const { contactReviewForm: form } = useOutreach();

  const linkPath = `links.${link.id}`;

  const queuedLink = queuedPatientsToLink.find((p) => p.patient.id === link.id);

  const handleContactTypeChange = (item: Coding) => {
    const isOther = item.code === CONTACT_TYPE_OTHER;
    setIsOtherContactType(isOther);
    const customContactType = isOther ? '' : form.values.links[link.id!]?.customContactType;
    form.setFieldValue(`${linkPath}.contactType`, item.display);
    form.setFieldValue(`${linkPath}.customContactType`, customContactType);
    updatedQueuedLinkContactType(link, item.display || '', customContactType);
  };

  const handleCustomContactTypeChange = (value: string) => {
    form.setFieldValue(`${linkPath}.customContactType`, value);
    updatedQueuedLinkContactType(link, OTHER_DISPLAY, value);
  };

  const handleRelationshipChange = (item: Coding): void => {
    const isOther = item.code === RELATIONSHIP_OTHER;
    setIsOtherRelationship(isOther);

    // Compute the new customRelationship value based on the selection.
    // If "Other" is selected, we clear it; otherwise, we keep the current value.
    const customRelationship = isOther ? form.values.links[link.id!]?.customRelationship : '';

    // Update the global Mantine form state for the relationship and its custom value
    form.setFieldValue(`${linkPath}.relationship`, item);
    form.setFieldValue(`${linkPath}.customRelationship`, customRelationship);

    // Call the context update function to update the queued link.
    updatedQueuedLinkRelationship(link, item, customRelationship);
  };

  const handleCustomRelationshipChange = (value: string) => {
    form.setFieldValue(`${linkPath}.customRelationship`, value);
    updatedQueuedLinkRelationship(
      link,
      { code: RELATIONSHIP_OTHER, display: OTHER_DISPLAY, system: System.ContactType },
      value,
    );
  };

  // Required because of nested form structure
  const getLinkError = (
    linkId: string,
    field: 'contactType' | 'relationship' | 'customContactType' | 'customRelationship',
  ): string | undefined => {
    const errors = form.errors.links as
      | Record<
          string,
          Partial<{ contactType: string; relationship: string; customContactType: string; customRelationship: string }>
        >
      | undefined;
    return linkId ? errors?.[linkId]?.[field] : undefined;
  };

  const [isOtherRelationship, setIsOtherRelationship] = useState<boolean>(false);
  const [isOtherContactType, setIsOtherContactType] = useState<boolean>(false);

  const linkAddress = useMemo(() => {
    return (
      link?.address?.find((address) =>
        address.extension?.find((ext) => ext.url === System.AddressType.toString() && ext.valueCode === 'home'),
      ) ?? link?.address?.[0]
    );
  }, [link]);

  return (
    <Stack>
      <Checkbox
        flex="2"
        key={link.id}
        label={
          <Box>
            <Group>
              <Text>
                {getName(link)} {formatAgeAndDateOfBirth(link?.birthDate)}
                {is21OrOlder(link?.birthDate) && <Over21Badge />}
                {link.gender ? ` ${capitalize(link.gender)}` : ''}
              </Text>
              <PatientEligibilityBadge patientTags={link.meta?.tag || []} />
            </Group>
            {linkAddress && <Text c="dimmed">{formatAddress(linkAddress)}</Text>}
          </Box>
        }
        checked={!!queuedLink}
        onChange={(e) => {
          if (e.target.checked) {
            form.setFieldValue(`${linkPath}.selected`, true);
            queuePatientToLink(link, {
              code: '',
              display: '',
              system: System.ContactType,
            });
          } else {
            form.setFieldValue(`${linkPath}.selected`, false);
            dequeuePatientToLink(link);
          }
        }}
      />
      {!!queuedLink && (
        <ContactRelationshipDefinitionForm
          patientName={getName(link)}
          selectedContactType={getContactTypeCoding(form.values.links[link.id!]?.contactType)}
          onSelectContactType={handleContactTypeChange}
          onSelectCustomContactType={handleCustomContactTypeChange}
          isOtherContactType={isOtherContactType}
          contactTypeError={link.id ? getLinkError(link.id, 'contactType') : undefined}
          otherContactTypeError={link.id ? getLinkError(link.id, 'customContactType') : undefined}
          otherContactFormInputProps={form.getInputProps(`${linkPath}.customContactType`)}
          selectedRelationship={form.values.links[link.id!]?.relationship}
          onSelectRelationship={handleRelationshipChange}
          onSelectCustomRelationship={handleCustomRelationshipChange}
          isOtherRelationship={isOtherRelationship}
          relationshipError={link.id ? getLinkError(link.id, 'relationship') : undefined}
          otherRelationshipError={link.id ? getLinkError(link.id, 'customRelationship') : undefined}
          otherRelationshipFormInputProps={form.getInputProps(`${linkPath}.customRelationship`)}
        />
      )}
    </Stack>
  );
};
