import React, { useMemo } from 'react';
import { Table, Text, LoadingOverlay, Badge, Group, ThemeIcon } from '@mantine/core';
import { HumanName, Organization, Practitioner } from '@medplum/fhirtypes';
import { HumanNameDisplay } from '@medplum/react';
import { TasksDrawer } from './drawer';
import { formatAge } from 'imagine-dsl/utils/strings';
import { useTaskAlertContext } from '@/pages/Task/TaskAlertProvider';
import { format } from 'date-fns';
import { ClusterBadge } from '../ClusterBadge';
import { useGetCarePathway } from '@/hooks/useGetCarePathway';
import { EMPTY_DATA_PLACEHOLDER } from 'const-utils/utils/constants';
import { IconAlertTriangle, IconPointFilled, IconHomeHeart } from '@tabler/icons-react';
import { PriorityBadge } from './PriorityBadge';
import { BaseTask } from 'imagine-dsl/models/tasks/baseTask';
import { Pagination } from '@/design-system/Pagination';
import { useGetTaskTitleRenderers } from '@/hooks/useGetTaskTitleRenderers';
import { useSearchParams } from 'react-router-dom';
import { useGetTaskQuery } from 'medplum-gql';
import { useTaskDrawerHandle } from './useTaskDrawer';
import { extractPod } from 'imagine-dsl/utils/careTeam';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import { isPatientInFosterCare, PatientWithCoverage } from 'imagine-dsl/utils/types/patient';
import { isDefined } from 'imagine-dsl/utils/lists';
import { CareTeam, TaskType } from 'const-utils/codeSystems/ImaginePediatrics';
import { useDocumentTitle } from '@mantine/hooks';

const TaskRow = ({
  task,
  openTaskDrawer,
  isInternalCareTeamHeaderActive,
}: {
  task: BaseTask;
  openTaskDrawer: (taskId: string) => void;
  isInternalCareTeamHeaderActive: boolean;
}): JSX.Element => {
  useDocumentTitle('Tasks - Care Hub');
  const patient = task?.for;
  const assignedTo = task?.owner as Practitioner;
  const taskTitleColumnRenderers = useGetTaskTitleRenderers();
  const TaskTitle = taskTitleColumnRenderers[task.type!] ?? (() => <Text>{task.type}</Text>);
  const { carePathway, cluster } = useGetCarePathway({ patientId: patient?.id || '' });
  const filteredCareTeamList =
    patient?.CareTeamList?.filter((team) => team?.name && team?.name !== CareTeam.EngagementCareTeam).filter(
      isDefined,
    ) || [];
  const isFosterCare = isPatientInFosterCare(patient as PatientWithCoverage);
  const pod = extractPod(filteredCareTeamList);
  const podName = pod?.name;
  const flags = useFeatureFlags();

  return (
    <Table.Tr onClick={() => openTaskDrawer(task.id!)} key={task?.id} style={{ cursor: 'pointer' }}>
      <Table.Td>
        <Text size="lg">
          <HumanNameDisplay value={patient?.name?.[0] as HumanName} />
        </Text>
        {patient?.birthDate && (
          <Group gap={'2'}>
            <Text c="gray">
              {(patient?.managingOrganization?.resource as Organization)?.name ?? EMPTY_DATA_PLACEHOLDER}
            </Text>
            <IconPointFilled size={14} color="gray" />
            <Text c="gray">
              {formatAge(patient?.birthDate, { emptyText: EMPTY_DATA_PLACEHOLDER, abbreviate: true })}
            </Text>
            <IconPointFilled size={14} color="gray" />
            {flags.AthenaPrapareIntegration && isFosterCare && (
              <Group gap={'sm'} pl={6} pr={6}>
                <ThemeIcon size={18} color="touchpoint-icon.1" radius="xl" variant="filled">
                  <IconHomeHeart size={14} color="purple" />
                </ThemeIcon>
                <Text c="gray">Foster</Text>
                <IconPointFilled size={14} color="gray" />
              </Group>
            )}
            <ClusterBadge
              carePathway={carePathway}
              cluster={cluster || 'Unknown'}
              iconOnly
              hideSubtext
              condensedDisplay
            />
          </Group>
        )}
      </Table.Td>
      {flags.AthenaPrapareIntegration &&
        isInternalCareTeamHeaderActive &&
        (task.type === TaskType.ScreenerReview ? (
          <Table.Td>{podName ? podName : EMPTY_DATA_PLACEHOLDER}</Table.Td>
        ) : (
          <Table.Td>{EMPTY_DATA_PLACEHOLDER}</Table.Td>
        ))}
      <Table.Td>
        <TaskTitle task={task} />
      </Table.Td>
      <Table.Td>{task.priority ? <PriorityBadge priority={task.priority} /> : EMPTY_DATA_PLACEHOLDER}</Table.Td>
      <Table.Td>
        {!assignedTo ? (
          <Badge color="indigo" variant="filled">
            Unassigned
          </Badge>
        ) : (
          <HumanNameDisplay value={assignedTo?.name?.[0]} />
        )}
      </Table.Td>
      <Table.Td>{task.authoredOn ? format(task.authoredOn, 'Pp') : EMPTY_DATA_PLACEHOLDER}</Table.Td>
      <Table.Td>
        {task.dueDate ? format(task.dueDate, 'Pp') : EMPTY_DATA_PLACEHOLDER}
        {task.isOverdue && (
          <Group align="center">
            <IconAlertTriangle color="orange" size={20} />
            <Text fw={'bold'} size="lg">
              Overdue
            </Text>
          </Group>
        )}
      </Table.Td>
    </Table.Tr>
  );
};

const PAGE_SIZE = 10;
export const TasksTable = (): JSX.Element => {
  const { loading, error, refetchTasks, tasks, totalTasks } = useTaskAlertContext();
  const flags = useFeatureFlags();
  const [searchParams] = useSearchParams();
  const { openDrawer, closeDrawer } = useTaskDrawerHandle();

  const focusedTaskId = useMemo(() => {
    return searchParams.get('task');
  }, [searchParams]);

  const foundTask = useMemo(() => {
    if (!tasks || !focusedTaskId) {
      return undefined;
    }

    return tasks.find((task) => task?.id === focusedTaskId);
  }, [tasks, focusedTaskId]);

  const { data } = useGetTaskQuery({
    variables: { taskId: focusedTaskId! },
    skip: !focusedTaskId && !foundTask,
  });

  const focusedTask = useMemo(() => {
    if (foundTask) {
      return foundTask;
    }

    return data?.Task ? new BaseTask(data.Task) : undefined;
  }, [data, foundTask]);

  if (error) {
    return (
      <Text size="xl" fw={700} c="imagine-green" mt="lg" ml="lg">
        Error fetching tasks
      </Text>
    );
  }

  const isInternalCareTeamHeaderActive =
    tasks.filter((task) => task?.task?.description === 'Review survey response').length >= 1;

  return (
    <>
      <TasksDrawer
        withViewPatientProfile
        task={focusedTask}
        onClose={closeDrawer}
        opened={!!focusedTask}
        refetchTasks={[refetchTasks]}
      />
      <Table highlightOnHover horizontalSpacing="sm" verticalSpacing="sm">
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Patient</Table.Th>
            {flags.AthenaPrapareIntegration && isInternalCareTeamHeaderActive && (
              <Table.Th>Internal Care Team Pod</Table.Th>
            )}
            <Table.Th>Task</Table.Th>
            <Table.Th>Priority</Table.Th>
            <Table.Th>Assigned to</Table.Th>
            <Table.Th>Created Date</Table.Th>
            <Table.Th>Due Date</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {loading ? (
            <tr>
              <td colSpan={3}>
                <LoadingOverlay visible zIndex={1000} overlayProps={{ radius: 'sm', blur: 2 }} />
              </td>
            </tr>
          ) : (
            tasks
              ?.filter((task) => !!task)
              .map((task) => (
                <TaskRow
                  key={task?.id}
                  task={task}
                  openTaskDrawer={openDrawer}
                  isInternalCareTeamHeaderActive={isInternalCareTeamHeaderActive}
                />
              ))
          )}
          {tasks?.length === 0 && (
            <Table.Tr>
              <Table.Td colSpan={3}>
                <Text size="xl" fw={700} c="imagine-green" mt="lg" ml="lg">
                  No tasks found.
                </Text>
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
      <Pagination perPage={PAGE_SIZE} count={totalTasks} />
    </>
  );
};
