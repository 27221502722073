import React from 'react';
import { Box, Group, Text, Divider } from '@mantine/core';
import { ContactPoint } from '@medplum/fhirtypes';
import { IconPhone } from '@tabler/icons-react';
import { System } from 'const-utils';
import { formatPhoneString } from 'imagine-dsl/utils/strings';

interface InvalidPhoneNumbersListProps {
  phoneNumbers: ContactPoint[];
}

export function InvalidPhoneNumbersList({ phoneNumbers }: InvalidPhoneNumbersListProps) {
  return (
    <Box>
      {phoneNumbers.map((phone, index) => {
        const invalidPhoneStatus = phone.extension?.find((ext) => ext.url === System.PhoneStatus.toString())?.valueCode;
        const isLastItem = index === phoneNumbers.length - 1;

        return (
          <Box key={phone.value}>
            <Group wrap="nowrap">
              <IconPhone size={16} />
              <Box>
                <Box>
                  <Text fw={700}>{formatPhoneString(phone.value || '')}</Text>
                  <Text c="dimmed">{invalidPhoneStatus}</Text>
                </Box>
              </Box>
            </Group>
            {!isLastItem && <Divider my="sm" color="var(--mantine-color-gray-2)" />}
          </Box>
        );
      })}
    </Box>
  );
}
