export enum ContactRelationship {
  NaturalMother = 'NMTH',
  StepMother = 'STPMTH',
  NaturalFather = 'NFTH',
  StepFather = 'STPFTH',
  CaseWorker = 'CASEWRKR',
  FosterParent = 'FRPRT',
  FacilityStaffMember = 'FACSTF',
  Grandmother = 'GRMTH',
  Grandfather = 'GRFTH',
  GreatGrandmother = 'GGRMTH',
  GreatGrandfather = 'GGRFTH',
  Brother = 'BRO',
  Sister = 'SIS',
  Aunt = 'AUNT',
  Uncle = 'UNCLE',
  Self = 'SLF',
  Other = 'OTH',
}
