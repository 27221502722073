import { MedplumClient, createReference, parseReference } from '@medplum/core';
import { EpisodeOfCare, Patient, PatientCommunication } from '@medplum/fhirtypes';
import { isCaregiver } from '../utils/patient';
import {
  CommunicationCategory,
  CommunicationMedium,
  HL7System,
  PatientType,
  ProgramStatus,
  System,
  welcomeMessage,
} from 'const-utils';
import { Logger } from '../utils/logging';
import { buildCommunication } from '../utils/communication';
import { getTranslatedValue } from '../utils/localization';
import { DeviceType } from 'const-utils/codeSystems/ImaginePediatrics';
import { match } from 'ts-pattern';

export enum EnrollmentError {
  PatientNotFound = 0,
}

export const syncCoverageTags = async (
  medplumClient: MedplumClient,
  coverageId: string,
  patientId: string,
  logger: Logger = console,
): Promise<void> => {
  const coverage = await medplumClient.readResource('Coverage', coverageId);
  const patient = await medplumClient.readResource('Patient', patientId);

  const eligibilityClass = coverage.class?.find((c) => c.type?.coding?.some((c) => c.system === System.Attribution));
  const lineOfBusiness = coverage.class?.find((cls) =>
    cls?.type?.coding?.some((c) => c.system === System.LineOfBusiness),
  );
  const plan = coverage.class?.find((cls) => cls?.type?.coding?.some((c) => c.system === HL7System.CoverageClass));
  const payorOrg = coverage.payor?.[0];
  const [_payorType, payorId] = payorOrg ? parseReference(payorOrg) : [null, null];
  const payor = payorId ? await medplumClient.readResource('Organization', payorId) : undefined;

  // Initialize patient meta and tags if they don't exist
  patient.meta ??= {};
  patient.meta.tag ??= [];

  // Create array of new tags to add
  const newTags = [];

  if (eligibilityClass?.value) {
    newTags.push({
      system: System.Attribution,
      code: eligibilityClass.value,
      display: eligibilityClass.name,
    });
  } else {
    logger.info('No eligibility class on coverage.');
  }

  if (payor && payorId) {
    newTags.push({
      system: System.Payor,
      code: payorId,
      display: payor.name,
    });
  } else {
    logger.info('No payor on coverage.');
  }

  if (plan) {
    newTags.push({
      system: System.HealthPlan,
      code: plan.value,
      display: plan.name,
    });
  } else {
    logger.info('No plan on coverage.');
  }

  if (lineOfBusiness) {
    newTags.push({
      system: System.LineOfBusiness,
      code: lineOfBusiness.value,
      display: lineOfBusiness.name,
    });
  } else {
    logger.info('No line of business on coverage.');
  }

  // Filter out existing tags with the same systems we're updating
  const systemsToUpdate = newTags.map((tag) => tag.system);
  const existingTags = patient.meta.tag.filter((tag) =>
    tag.system ? !systemsToUpdate.includes(tag.system as System) : true,
  );

  // Update patient with combined tags in a single call
  await medplumClient.updateResource({
    ...patient,
    meta: {
      ...patient.meta,
      tag: [...existingTags, ...newTags],
    },
  });
};

export const updatePatientTag = async (
  medplum: MedplumClient,
  patient: Patient,
  system: string,
  tag: string,
  display?: string,
): Promise<Patient> =>
  medplum.updateResource({
    ...patient,
    meta: {
      ...patient.meta,
      tag: [
        ...(patient.meta?.tag || []).filter((t) => t.system !== system),
        {
          system,
          code: tag,
          display: display || tag,
        },
      ],
    },
  });

export const enrollPatient = async (
  medplum: MedplumClient,
  patientId: string,
): Promise<EpisodeOfCare | EnrollmentError> => {
  const patient: Patient = await medplum.readResource('Patient', patientId);

  if (isCaregiver(patient)) {
    return EnrollmentError.PatientNotFound;
  }
  const patientRef = createReference(patient).reference;

  const existing = await medplum.searchOne('EpisodeOfCare', {
    patient: patientRef,
  });

  const updatedEpisodeOfCare = await match(existing)
    .with({ status: 'planned' }, (existing) => existing)
    .with({ status: 'active' }, (existing) => existing)
    .otherwise(() =>
      medplum.upsertResource(
        {
          resourceType: 'EpisodeOfCare',
          patient: createReference(patient),
          status: 'planned',
          statusHistory: [
            ...(existing?.statusHistory || []).map((history) =>
              history.status === 'waitlist' && !history.period?.end
                ? { ...history, period: { ...history.period, end: new Date().toISOString() } }
                : history,
            ),
            {
              status: 'planned',
              period: {
                start: new Date().toISOString(),
              },
            },
          ],
        },
        {
          patient: patientRef,
        },
      ),
    );

  const isAlreadyOnboardedOrEnrolled = patient.meta?.tag?.some(
    (t) =>
      t.system === System.ProgramStatus && (t.code === ProgramStatus.Onboarded || t.code === ProgramStatus.Enrolled),
  );

  if (!isAlreadyOnboardedOrEnrolled) {
    await updatePatientTag(medplum, patient, System.ProgramStatus, ProgramStatus.Enrolled);
  }

  return updatedEpisodeOfCare;
};

export const sendWelcomeMessage = async (medplum: MedplumClient, patientId: string): Promise<void> => {
  const patient: Patient = await medplum.readResource('Patient', patientId);
  if (!isCaregiver(patient)) {
    //only send welcome message to caregivers
    return;
  }
  const hasWelcomeMessage =
    (
      await medplum.searchResources('Communication', {
        subject: `Patient/${patientId}`,
        category: CommunicationCategory.Welcome,
        status: 'completed',
      })
    ).length > 0;
  if (hasWelcomeMessage) {
    return;
  }
  const languageCode =
    patient?.communication?.find(
      (c: PatientCommunication) => c?.language?.coding?.[0]?.system === System.PreferredLanguage,
    )?.language?.coding?.[0]?.code || 'en';

  const sender = await medplum.searchOne('Device', { type: DeviceType.ImaginePediatricsSystem });
  if (!sender) {
    throw new Error('Imagine Pediatrics system not found');
  }

  const welcomeComm = buildCommunication({
    medium: CommunicationMedium.Chat,
    category: CommunicationCategory.Welcome,
    contentString: getTranslatedValue(welcomeMessage, languageCode),
    subject: createReference(patient as Patient),
    sender: {
      reference: createReference(sender).reference,
      display: sender.deviceName?.find((n) => n.type === 'user-friendly-name')?.name || 'Imagine Pediatrics',
    },
  });
  await medplum.createResource(welcomeComm);
};

export async function getCaregiversForPatient(medplumClient: MedplumClient, patientId: string): Promise<Patient[]> {
  const relatedPersons = await medplumClient.searchResources('RelatedPerson', {
    patient: `Patient/${patientId}`,
  });
  if (!relatedPersons.length) {
    return [];
  }

  return medplumClient.searchResources('Patient', {
    _tag: PatientType.Caregiver,
    link: relatedPersons.map((rp) => `RelatedPerson/${rp.id}`).join(','),
  });
}
