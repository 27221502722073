import React, { useEffect, useState, CSSProperties } from 'react';
import { Combobox, useCombobox, InputBase, Input } from '@mantine/core';
import { ValueSet } from '@medplum/fhirtypes';

interface ValueSetDropdownProps<T> {
  valueSet: ValueSet;
  onSelect: (item: T) => void;
  label?: string;
  placeholder?: string;
  required?: boolean;
  withAsterisk?: boolean;
  selectedItem?: T | null;
  cypressIdentifier?: string;
  error?: string;
  style?: CSSProperties;
}

export const ValueSetDropdown = <T extends { code?: string; display?: string }>({
  valueSet,
  onSelect,
  label,
  placeholder,
  required = false,
  withAsterisk = true,
  selectedItem = null,
  cypressIdentifier,
  error,
  style,
}: ValueSetDropdownProps<T>): JSX.Element => {
  const [value, setValue] = useState<T | null>(selectedItem);

  useEffect(() => {
    setValue(selectedItem);
  }, [selectedItem]);

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const items = valueSet.compose?.include?.flatMap((inc) => inc.concept || []) || []; // cleanup?: can you use extractValues in selectValue.ts?

  const handleSelect = (item: T) => {
    setValue(item);
    combobox.closeDropdown();
    onSelect(item);
  };

  return (
    <Input.Wrapper label={label} required={required} withAsterisk={withAsterisk} style={{ ...style }}>
      <Combobox
        store={combobox}
        middlewares={{ flip: false }}
        onOptionSubmit={(key) => {
          const selectedItem = items.find((item) => item.code === key) as T;
          if (selectedItem) handleSelect(selectedItem);
        }}
      >
        <Combobox.Target>
          <InputBase
            data-cy={cypressIdentifier}
            component="button"
            type="button"
            pointer
            rightSection={<Combobox.Chevron />}
            onClick={() => combobox.toggleDropdown()}
            rightSectionPointerEvents="none"
            error={error}
            styles={(theme) => ({
              input: {
                color: value ? theme.black : theme.colors.gray[5],
              },
            })}
          >
            {value ? value.display : placeholder || label}
          </InputBase>
        </Combobox.Target>
        <Combobox.Dropdown style={{ maxHeight: 'calc(40vh - 100px)', overflowY: 'auto' }}>
          <Combobox.Options>
            {items.map((item) => (
              <Combobox.Option value={item.code} key={item.code}>
                {item.display}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </Input.Wrapper>
  );
};
