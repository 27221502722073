import { LinkedPatientFields } from '@/components/shared/ContactReviewForm';
import {
  CONTACT_TYPE_OTHER,
  RELATIONSHIP_OTHER,
  CONTACT_TYPE_REQUIRED_ERROR_MESSAGE,
  CUSTOM_CONTACT_TYPE_REQUIRED_ERROR_MESSAGE,
  RELATIONSHIP_REQUIRED_ERROR_MESSAGE,
  CUSTOM_RELATIONSHIP_REQUIRED_ERROR_MESSAGE,
} from '@/components/shared/constants';

export const linkValidator = (links: Record<string, LinkedPatientFields>): React.ReactNode | undefined => {
  const errors: Record<
    string,
    Partial<{
      contactType: string;
      relationship: string;
      customContactType: string;
      customRelationship: string;
    }>
  > = {};

  for (const [linkId, fields] of Object.entries(links)) {
    if (!fields.selected) {
      continue;
    }

    const fieldErrors: Partial<{
      contactType: string;
      relationship: string;
      customContactType: string;
      customRelationship: string;
    }> = {};

    if (!fields.contactType?.trim()) {
      fieldErrors.contactType = CONTACT_TYPE_REQUIRED_ERROR_MESSAGE;
    }
    if (!fields.relationship?.code?.trim()) {
      fieldErrors.relationship = RELATIONSHIP_REQUIRED_ERROR_MESSAGE;
    }

    if (fields.relationship?.code === RELATIONSHIP_OTHER && !fields.customRelationship?.trim()) {
      fieldErrors.customRelationship = CUSTOM_RELATIONSHIP_REQUIRED_ERROR_MESSAGE;
    }

    if (fields.contactType === CONTACT_TYPE_OTHER && !fields.customContactType?.trim()) {
      fieldErrors.customContactType = CUSTOM_CONTACT_TYPE_REQUIRED_ERROR_MESSAGE;
    }

    if (Object.keys(fieldErrors).length > 0) {
      errors[linkId] = fieldErrors;
    }
  }

  return Object.keys(errors).length > 0 ? (errors as unknown as React.ReactNode) : undefined;
};
