export enum FeatureFlag {
  ChatCaregiverMessageTranslations = 'chat_caregiver_message_translations',
  TranslateMessage = 'translate-message',
  TestFlag = 'test_flag',
  BulkTaskingAssignOthers = 'bulk_tasking_assign_others',
  EngagementPodReassignment = 'engagement_pod_reassignment',
  DisasterPreparednessSurvey = 'Disaster_Preparedness_Survey',
  TaskDueDateFilter = 'tasks_due_date_filter',
  DyteVideoProvider = 'dyte_video_provider',
  OutreachStatusPebble = 'outreach_status_pebble',
  CaregiverProfile = 'caregiver_profile',
  AthenaPrapareIntegration = 'Athena_PRAPARE_Integration',
  PatientPanelPayorFilter = 'patient_panel_payor_filter',
  AutoAssignResolver = 'auto_assign_resolver',
  CareHubAdmin = 'care_hub_admin',
  EngagementPodMapping = 'engagement_pod_mapping',
  MarketsAndPayors = 'care_hub_admin_markets_and_payors',
  BHTocV2 = 'bh_toc_v2',
  UseMedplumPostalCodeMapping = 'use_medplum_postal_code_mapping',
}
