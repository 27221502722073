import { Loader, Table, Text } from '@mantine/core';
import { Communication, Organization, Practitioner, Task, Patient } from '@medplum/fhirtypes';
import {
  Encounter,
  GetIncompleteChatTasksQuery,
  Maybe,
  Patient as GraphqlPatient,
  useGetLastChatTaskByPatientIdQuery,
} from 'medplum-gql';
import React, { useEffect, useMemo, useState } from 'react';
import { getSentByPractitioner } from 'imagine-dsl/utils/communication';
import { PatientColumn } from './PatientColumn';
import { AssignedToColumn } from './AssignedToColumn';
import { WaitTimeColumn } from './WaitTimeColumn';

import { CategoryColumn } from './CategoryColumn';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCommandCenterAlertContext } from '../CommandCenterAlertProvider';
import { Filters } from './Filters';
import { FilterValues, SupportedFields, filterBy } from '../utils/filter';
import { ChatDrawer } from '@/components/chat';
import { ChatDrawerProvider } from '@/components/chat/ChatDrawerProvider';
import { FetchedPatient } from '@/utils/queryTypes';
import { useUserSession } from '@/components/shared/UserSessionContext';
import { isSuicideAlertTask } from 'imagine-dsl/services/taskService';

export function TaskTable(): JSX.Element {
  const { profile } = useUserSession();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { incompleteTasks, userMarkets, defaultMarketId, loading } = useCommandCenterAlertContext();
  const [filterValues, setFilterValues] = useState<FilterValues>();

  useEffect(() => {
    if (defaultMarketId) {
      setFilterValues({
        assigneeId: null,
        patientId: null,
        category: null,
        primaryRnId: null,
        marketId: defaultMarketId,
      });
    }
  }, [defaultMarketId]);

  const focusedPatientId = searchParams.get('patient');

  const { data: focusedPatientTaskData, refetch: refetchFocusedTask } = useGetLastChatTaskByPatientIdQuery({
    variables: { patientId: `Patient/${focusedPatientId}` },
    skip: !focusedPatientId,
  });

  const focusedTask = useMemo(() => {
    if (!incompleteTasks) {
      return undefined;
    }

    if (focusedPatientId) {
      const taskFromList = incompleteTasks.find(
        (task) => (task?.for?.resource as Patient)?.id === focusedPatientId,
      ) as Task;
      return taskFromList || focusedPatientTaskData?.TaskList?.[0];
    }

    return undefined;
  }, [incompleteTasks, focusedPatientId, focusedPatientTaskData]);

  const practitionerResponded = (task: Task): boolean => {
    const lastCommunication = (task.focus?.resource as Encounter)?.CommunicationList?.[0];
    return getSentByPractitioner(lastCommunication as Maybe<Communication>);
  };

  const closeAndNavigate = (): void => {
    navigate('/');
  };

  const filteredTasks = useMemo((): GetIncompleteChatTasksQuery['TaskList'] => {
    if (!filterValues) {
      return incompleteTasks;
    }
    let incompleteTasksCopy = incompleteTasks;
    Object.keys(filterValues).forEach((key) => {
      incompleteTasksCopy = filterBy(
        incompleteTasksCopy,
        filterValues[key as keyof FilterValues],
        key as SupportedFields,
      );
    });
    return incompleteTasksCopy;
  }, [filterValues, incompleteTasks]);

  const openTaskDrawer = (task: Task): void => {
    const patientId = (task?.for?.resource as Patient)?.id;
    setSearchParams({ patient: patientId! });
  };

  const onFilter = (values: FilterValues): void => {
    setFilterValues(values);
  };

  return (
    <>
      {focusedTask?.for?.resource && (
        <ChatDrawerProvider
          opened={!!focusedTask}
          currentTask={focusedTask}
          refetchFocusedTask={refetchFocusedTask}
          // WARN: This is a workaround for now. We need to actually align these
          // types and make sure they are the same.
          patient={focusedTask.for.resource as FetchedPatient}
          close={closeAndNavigate}
        >
          <ChatDrawer withViewPatientProfile />
        </ChatDrawerProvider>
      )}
      <Filters
        userMarkets={userMarkets}
        defaultMarketId={defaultMarketId}
        tasks={incompleteTasks}
        onFilter={onFilter}
      />
      {loading && <Loader />}
      {!loading && (!filteredTasks || filteredTasks?.length === 0) && (
        <Text>There are no active tasks in your market</Text>
      )}
      {!loading && filteredTasks && filteredTasks.length > 0 && (
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Patient</Table.Th>
              <Table.Th>Category</Table.Th>
              <Table.Th>Assigned to</Table.Th>
              <Table.Th>Wait time</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {filteredTasks?.map((task) => (
              <Table.Tr
                onClick={() => openTaskDrawer(task as Task)}
                key={task?.id}
                bg={practitionerResponded(task as Task) ? 'brand-gray.1' : undefined}
                style={{
                  cursor: 'pointer',
                }}
              >
                <Table.Td>
                  <PatientColumn
                    patient={task?.for?.resource as GraphqlPatient}
                    market={(task?.for?.resource as GraphqlPatient).managingOrganization?.resource as Organization}
                  />
                </Table.Td>
                <Table.Td>
                  <CategoryColumn isSuicideAlertTask={isSuicideAlertTask(task as Task)} priority={task?.priority} />
                </Table.Td>
                <Table.Td>
                  <AssignedToColumn
                    practitionerResponded={practitionerResponded(task as Task)}
                    profile={profile}
                    assignee={task?.owner?.resource as Practitioner}
                  />
                </Table.Td>
                <Table.Td>
                  <WaitTimeColumn
                    practitionerResponded={practitionerResponded(task as Task)}
                    sent={(task?.focus?.resource as Encounter)?.CommunicationList?.[0]?.sent ?? task?.authoredOn}
                  />
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      )}
    </>
  );
}
