import { MedplumClient } from '@medplum/core';
import { System, UserRole } from 'const-utils';
import { GetPatientQuery } from 'medplum-gql';
import { CareTeam, Practitioner } from '@medplum/fhirtypes';

const pcpDataRetention = async (
  value: string,
  patient: GetPatientQuery['Patient'],
  medplum: MedplumClient,
): Promise<void> => {
  const externalCareTeamId = patient?.externalCareTeam?.[0]?.id;
  const id = patient?.externalCareTeam?.flatMap((careTeam) =>
    careTeam?.participant
      ?.filter((participant) =>
        participant.role?.some((role) => role.coding?.some((coding) => coding.code === UserRole.PrimaryCareProvider)),
      )
      ?.map(
        (participant) =>
          participant?.member?.resource?.__typename === 'Practitioner' && participant.member?.resource?.id,
      ),
  );
  const practitionerId = id?.[0] ?? '';

  if (externalCareTeamId) {
    if (practitionerId) {
      const refreshedPractitioner = await medplum.readResource('Practitioner', practitionerId);
      const updatedPractitioner = {
        ...refreshedPractitioner,
        name: [{ ...refreshedPractitioner.name?.[0], text: value }],
      };
      await medplum.updateResource(updatedPractitioner);
    }
  } else {
    const practitionerResult: Practitioner = await medplum.createResource({
      resourceType: 'Practitioner',
      name: [
        {
          text: value,
        },
      ],
    });
    const pid = patient?.identifier?.find((item) => item.system === System.Patient)?.value;
    const newCareTeam: CareTeam = {
      resourceType: 'CareTeam',
      name: 'External Care Team',
      identifier: [
        {
          system: System.ExternalCareTeam,
          value: `external-care-team:${pid}`,
        },
      ],
      subject: {
        reference: `Patient/${patient?.id}`,
      },
      status: 'active',
      participant: [
        {
          member: {
            reference: `Practitioner/${practitionerResult?.id}`,
          },
          role: [
            {
              coding: [
                {
                  system: System.CareTeamRole,
                  code: 'primarycareprovider',
                  display: 'Primary Care Provider',
                },
              ],
            },
          ],
        },
      ],
      meta: {
        tag: [
          {
            system: System.CareTeam,
            code: 'external',
          },
        ],
      },
    };
    await medplum.createResource(newCareTeam);
  }
};

export default pcpDataRetention;
