import React from 'react';
import { Flex, Button } from '@mantine/core';

interface ButtonContainerProps {
  outlineButtonTitle: string;
  outlineButtonAction: () => void;
  filledButtonTitle: string;
  filledButtonAction: () => void;
  filledButtonDisabled?: boolean;
}

export const ButtonContainer = ({
  outlineButtonAction,
  outlineButtonTitle,
  filledButtonAction,
  filledButtonTitle,
  filledButtonDisabled = false,
}: ButtonContainerProps) => {
  return (
    <Flex justify="end">
      <Button mr="md" variant="outline" onClick={outlineButtonAction}>
        {outlineButtonTitle}
      </Button>
      <Button disabled={filledButtonDisabled} onClick={filledButtonAction}>
        {filledButtonTitle}
      </Button>
    </Flex>
  );
};
