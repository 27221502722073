import {
  Group,
  Input,
  Loader,
  Paper,
  Select,
  MultiSelect,
  Table,
  Text,
  Button,
  ActionIcon,
  Tooltip,
  RangeSlider,
  Popover,
  OptionsFilter,
  ComboboxItem,
} from '@mantine/core';
import { useDebouncedValue, useDisclosure } from '@mantine/hooks';
import { useMedplum } from '@medplum/react';
import {
  IconEditCircle,
  IconPlus,
  IconSearch,
  IconSquare,
  IconSquareCheckFilled,
  IconChevronDown,
} from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import {
  Attribution,
  CarePathway,
  OutreachDisposition,
  OutreachStatus,
  ProgramStatus,
  attributionOptions,
  carePathwayOptions,
  outreachDispositionOptions,
  outreachStatusOptions,
  programStatusOptions,
} from 'const-utils/codeSystems/ImaginePediatrics';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import { useEngagementPodCareTeams } from '@/hooks/useEngagementPodCareTeams';
import { engagementManagerRoles, useHasRole } from '@/hooks/useHasRole';
import { CareTeamType } from 'const-utils';
import { useUserSession } from '@/components/shared/UserSessionContext';
import { PageSkeleton } from '@/design-system/PageSkeleton';
import { PatientRow } from '@/components/patientPanel/PatientRow';
import { SortIcon } from '@/components/shared/SortIcon';
import BulkTaskCreationModal from '@/components/shared/BulkTaskCreationModal';
import { Pagination } from '@/design-system/Pagination';
import { usePagination } from '@/design-system/hooks/usePagination';
import { PanelSortField, PanelSortOrder, useGetOutreachPanelQuery } from 'imagine-gql/client';
import { imagineClient } from '@/hooks/useImagineApolloClient';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import EngagementPodAssignmentModal from '@/components/patientProfile/modals/EngagementPodAssignmentModal';
import { getAllPostalCodes } from 'const-utils/empanelment/postalCodes';
import { useHealthPlanOptions } from '@/hooks/PayorHooks/useHealthPlanOptions';

const PER_PAGE = 20;

export const OutreachPanel = (): React.ReactNode => {
  const medplum = useMedplum();
  const flags = useFeatureFlags();
  const hasElevatedPrivilege = useHasRole(engagementManagerRoles) || medplum.isProjectAdmin();

  const bulkTaskingEnabled = flags.BulkTaskingAssignOthers || hasElevatedPrivilege;
  const [searchParams, setSearchParams] = useSearchParams();
  const sortInitialized = useRef(false);

  useEffect(() => {
    if (sortInitialized.current) {
      return;
    }
    sortInitialized.current = true;

    if (!searchParams.has('sort')) {
      searchParams.set('sort', 'outreachCount:desc');
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  const carePathwayFilter = searchParams.get('carePathway') || undefined;
  const attributionFilter = searchParams.get('attribution') || undefined;
  const programStatusFilter = searchParams.get('programStatus') || undefined;
  const outreachStatusFilter = searchParams.get('outreachStatus') || undefined;
  const dispositionFilter = searchParams.get('disposition') || undefined;
  const minAgeFilter = searchParams.get('minAge') || undefined;
  const maxAgeFilter = searchParams.get('maxAge') || undefined;
  const patientNameSearch = searchParams.get('s') || '';
  const engagementPodCareTeamId = searchParams.get('pod') ?? undefined;
  const myEngagementTeamPodOptions = useMemo(() => ({ skip: hasElevatedPrivilege }), [hasElevatedPrivilege]);
  const myEngagementPodCareTeamId = useMyEngagementTeamPodCareTeamId(myEngagementTeamPodOptions);
  const zipCodeOptionsFilter: OptionsFilter = ({ options, search }) => {
    if (search.length < 2) return [];
    return (options as ComboboxItem[]).filter((option) => option.value.startsWith(search));
  };
  const payorFilter = searchParams.get('payor') ?? undefined;
  const lastOutreachFilter = searchParams.get('lastOutreach') ?? undefined;
  const minOutreachCountFilter = searchParams.get('minOutreachCount') ?? undefined;
  const maxOutreachCountFilter = searchParams.get('maxOutreachCount') ?? undefined;

  useEffect(() => {
    if (myEngagementPodCareTeamId) {
      setSearchParams((prev) => {
        prev.set('pod', myEngagementPodCareTeamId);
        return prev;
      });
    }
  }, [myEngagementPodCareTeamId, setSearchParams]);

  const engagementPodCareTeamsQuery = useEngagementPodCareTeams();

  const [sortField, sortOrder] = searchParams.get('sort')?.split(':') ?? [];
  const { currentPage: page } = usePagination({});

  const [patientNameSearchDebounced] = useDebouncedValue(patientNameSearch, 500);
  const [minAgeFilterDebounced] = useDebouncedValue(minAgeFilter, 500);
  const [maxAgeFilterDebounced] = useDebouncedValue(maxAgeFilter, 500);
  const patientPanelQuery = usePatientPanel({
    engagementPodCareTeamId,
    patientName: patientNameSearchDebounced,
    carePathway: carePathwayFilter as CarePathway | undefined,
    attribution: attributionFilter as Attribution | undefined,
    programStatus: programStatusFilter as ProgramStatus | undefined,
    outreachStatus: outreachStatusFilter as OutreachStatus | undefined,
    dispositions: dispositionFilter?.split(',') as OutreachDisposition[] | undefined,
    sort: {
      field: sortField as PanelSortField,
      order: sortOrder as PanelSortOrder,
    },
    pageSize: PER_PAGE,
    page: page,
    minAge: minAgeFilterDebounced && minAgeFilter ? Number.parseInt(minAgeFilter, 10) : undefined,
    maxAge: maxAgeFilterDebounced && maxAgeFilter ? Number.parseInt(maxAgeFilter, 10) : undefined,
    zipCode: searchParams.get('zipCode')?.split(',') ?? undefined,
    payor: payorFilter as string | undefined,
    lastOutreach: lastOutreachFilter ? Number.parseInt(lastOutreachFilter) : undefined,
    minOutreachCount: minOutreachCountFilter ? Number.parseInt(minOutreachCountFilter) : undefined,
    maxOutreachCount: maxOutreachCountFilter ? Number.parseInt(maxOutreachCountFilter) : undefined,
  });

  const engagementPodOptions = useMemo(
    () =>
      engagementPodCareTeamsQuery.data?.entry
        ?.map((entry) => ({
          value: entry.resource?.id ?? '',
          label: entry.resource?.name ?? '',
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [engagementPodCareTeamsQuery.data],
  );

  const [selectedPatientIdsMap, setSelectedPatientIdsMap] = useState<Record<string, boolean>>({});
  const selectedPatientIds = useMemo(() => Object.keys(selectedPatientIdsMap), [selectedPatientIdsMap]);
  const [bulkTaskModalOpened, handleBulkTaskModal] = useDisclosure();
  const isLoading = engagementPodCareTeamId && patientPanelQuery.loading;
  const cachedAt = patientPanelQuery.data?.outreachPanels.cachedAt;
  const patientPanel = useMemo(() => patientPanelQuery.data?.outreachPanels.patients ?? [], [patientPanelQuery.data]);
  const selectedCount = useMemo(() => selectedPatientIds.length, [selectedPatientIds]);
  const allSelected = selectedCount === patientPanelQuery.data?.outreachPanels.total;
  const currentPageSelected = useMemo(
    () => patientPanel.every((p) => selectedPatientIdsMap[p.id]),
    [patientPanel, selectedPatientIdsMap],
  );
  const [isPodReassignmentModalOpen, { toggle: togglePodReassignmentModal }] = useDisclosure(false);

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [horizontalScrollShadow, setHorizontalScrollShadow] = useState({
    left: false,
    right: false,
  });
  const reconcileHorizontalScrollShadow = useCallback(
    (el: HTMLDivElement) => {
      const hasMoreToLeft = el.scrollLeft !== 0;
      const hasMoreToRight = el.scrollLeft + el.clientWidth <= el.scrollWidth;
      setHorizontalScrollShadow({
        left: hasMoreToLeft,
        right: hasMoreToRight,
      });
    },
    [setHorizontalScrollShadow],
  );
  // sets the initial scroll shadow state once the component mounts and data is loaded
  useEffect(() => {
    if (!scrollContainerRef.current || isLoading) {
      return;
    }

    const el = scrollContainerRef.current.firstChild as HTMLDivElement | null;
    if (!el) {
      return;
    }

    reconcileHorizontalScrollShadow(el);
  }, [isLoading, reconcileHorizontalScrollShadow]);

  const { identifiers: healthPlanOption } = useHealthPlanOptions();

  const payorOptions = healthPlanOption.map((identifier) => ({
    value: identifier,
    label: identifier.split(':')[2],
  }));

  const togglePatientSelect = (patientId: string) => {
    const nextMap = { ...selectedPatientIdsMap };
    if (nextMap[patientId]) {
      delete nextMap[patientId];
    } else {
      nextMap[patientId] = true;
    }
    setSelectedPatientIdsMap(nextMap);
  };
  const toggleSelectAllOnPage = () => {
    const nextMap = { ...selectedPatientIdsMap };
    patientPanel.forEach((p) => {
      if (currentPageSelected) {
        delete nextMap[p.id];
      } else {
        nextMap[p.id] = true;
      }
    });
    setSelectedPatientIdsMap(nextMap);
  };
  const toggleSelectAllResult = () => {
    const nextMap = { ...selectedPatientIdsMap };
    patientPanelQuery.data?.outreachPanels.allPatientIds.forEach((id) => {
      if (allSelected) {
        delete nextMap[id];
      } else {
        nextMap[id] = true;
      }
    });
    setSelectedPatientIdsMap(nextMap);
  };

  const createTasksHandler = () => {
    setSelectedPatientIdsMap({});
  };

  const handleBulkTaskModalOpen = async () => {
    handleBulkTaskModal.open();
  };
  const handleBulkTaskModalClose = async () => {
    handleBulkTaskModal.close();
  };

  const [ageRange, setAgeRange] = useState<[number, number]>([
    Number(searchParams.get('minAge')) || 0,
    Number(searchParams.get('maxAge')) || 24,
  ]);

  const [outreachCountRange, setOutreachCountRange] = useState<[number, number]>([
    Number(searchParams.get('minOutreachCount')) || 0,
    Number(searchParams.get('maxOutreachCount')) || 15,
  ]);

  return (
    <PageSkeleton withPaper={false}>
      {bulkTaskModalOpened && selectedPatientIds.length && (
        <BulkTaskCreationModal
          opened={bulkTaskModalOpened}
          closeModal={handleBulkTaskModalClose}
          onCreationSuccess={createTasksHandler}
          patientIds={selectedPatientIds}
        />
      )}
      {isPodReassignmentModalOpen && (
        <EngagementPodAssignmentModal
          isModalOpen={isPodReassignmentModalOpen}
          setModalOpen={togglePodReassignmentModal}
          patientIds={selectedPatientIds}
          refetch={patientPanelQuery.refetch}
        />
      )}
      <Group mb="xs" justify="space-between">
        <Group>
          <Select
            required
            placeholder="Engagement pod"
            data={engagementPodOptions}
            disabled={!hasElevatedPrivilege}
            value={engagementPodCareTeamId}
            onChange={(v) => {
              if (v) {
                searchParams.set('pod', v);
              } else {
                searchParams.delete('pod');
              }
              setSearchParams(searchParams);
            }}
          />
          <Input
            disabled={!engagementPodCareTeamId}
            value={patientNameSearch}
            onChange={(v) => {
              const search = v.target.value;
              if (search) {
                searchParams.set('s', search);
              } else {
                searchParams.delete('s');
              }
              setSearchParams(searchParams);
            }}
            leftSection={<IconSearch size={18} />}
            placeholder="Search by patient name"
          />
        </Group>
        {cachedAt && (
          <Tooltip label="To optimize performance of this page, some of the data has a maximum 'staleness' of ~5 minutes.">
            <Text size="xs" c="dimmed" maw="23rem">
              last updated {format(cachedAt, 'h:mm:ss a')}
            </Text>
          </Tooltip>
        )}
      </Group>
      <Group mb="md">
        <Select
          maw="200px"
          disabled={!engagementPodCareTeamId}
          label="Care Pathway"
          placeholder="Select to filter"
          data={carePathwayOptions}
          clearable
          searchable
          value={carePathwayFilter ?? ''}
          onChange={(v) => {
            if (v) {
              searchParams.set('carePathway', v);
            } else {
              searchParams.delete('carePathway');
            }
            setSearchParams(searchParams);
          }}
        />
        <Select
          maw="150px"
          disabled={!engagementPodCareTeamId}
          label="Attribution"
          placeholder="Select to filter"
          data={attributionOptions}
          clearable
          searchable
          value={attributionFilter ?? ''}
          onChange={(v) => {
            if (v) {
              searchParams.set('attribution', v);
            } else {
              searchParams.delete('attribution');
            }
            setSearchParams(searchParams);
          }}
        />
        <Select
          maw="150px"
          disabled={!engagementPodCareTeamId}
          label="Program Status"
          placeholder="Select to filter"
          data={programStatusOptions.filter((ps) => ps.value !== ProgramStatus.Onboarded.toString())}
          clearable
          searchable
          value={programStatusFilter ?? ''}
          onChange={(v) => {
            if (v) {
              searchParams.set('programStatus', v);
            } else {
              searchParams.delete('programStatus');
            }
            setSearchParams(searchParams);
          }}
        />
        <Select
          maw="175px"
          disabled={!engagementPodCareTeamId}
          label="Outreach Status"
          placeholder="Select to filter"
          data={outreachStatusOptions}
          clearable
          searchable
          value={outreachStatusFilter ?? ''}
          onChange={(v) => {
            if (v) {
              searchParams.set('outreachStatus', v);
            } else {
              searchParams.delete('outreachStatus');
            }
            setSearchParams(searchParams);
          }}
        />
        <MultiSelect
          style={{ flex: 1 }}
          maw="300px"
          miw="225px"
          disabled={!engagementPodCareTeamId}
          label="Disposition"
          placeholder="Select to filter"
          data={outreachDispositionOptions}
          clearable
          searchable
          value={dispositionFilter ? dispositionFilter.split(',') : []}
          onChange={(v) => {
            if (v) {
              searchParams.set('disposition', v.join(','));
            } else {
              searchParams.delete('disposition');
            }
            setSearchParams(searchParams);
          }}
        />

        <MultiSelect
          maw="150px"
          disabled={!engagementPodCareTeamId}
          label="Zip code"
          placeholder="Type to filter"
          data={getAllPostalCodes()}
          clearable
          searchable
          limit={10}
          maxDropdownHeight={200}
          filter={zipCodeOptionsFilter}
          value={searchParams.get('zipCode')?.split(',') ?? []}
          onChange={(v) => {
            if (v.length > 0) {
              searchParams.set('zipCode', v.join(','));
            } else {
              searchParams.delete('zipCode');
            }
            setSearchParams(searchParams);
          }}
        />

        {flags.PatientPanelPayorFilter && (
          <Select
            maw="200px"
            disabled={!engagementPodCareTeamId}
            label="Payor"
            placeholder="Select to filter"
            data={payorOptions}
            clearable
            searchable
            value={payorFilter ?? ''}
            onChange={(v) => {
              if (v) {
                searchParams.set('payor', v);
              } else {
                searchParams.delete('payor');
              }
              setSearchParams(searchParams);
            }}
          />
        )}

        {flags.PatientPanelPayorFilter && (
          <Select
            maw="200px"
            disabled={!engagementPodCareTeamId}
            label="Last Outreach"
            placeholder="Select to filter"
            data={[
              {
                value: '30',
                label: 'Within the past 30 Days',
              },
              {
                value: '60',
                label: 'Within the past 60 Days',
              },
              {
                value: '90',
                label: 'Within the past 90 Days',
              },
              {
                value: '120',
                label: 'More than 120+ Days',
              },
            ]}
            clearable
            searchable
            value={lastOutreachFilter ?? ''}
            onChange={(v) => {
              if (v) {
                searchParams.set('lastOutreach', v);
              } else {
                searchParams.delete('lastOutreach');
              }
              setSearchParams(searchParams);
            }}
          />
        )}

        <Popover width={333} trapFocus shadow="md">
          <Popover.Target>
            <Button
              mt={15}
              rightSection={<IconChevronDown size={16} />}
              variant="outline"
              color="imagine-green"
              disabled={!engagementPodCareTeamId}
            >
              Outreach Count
            </Button>
          </Popover.Target>
          <Popover.Dropdown>
            <Text pb={12}>Select Outreach Count</Text>
            <RangeSlider
              style={{ width: '100%', height: '50px', display: 'flex', justifyContent: 'center' }}
              maw="600px"
              mah="300px"
              min={0}
              max={15}
              minRange={1}
              name="Select Outreach Count"
              marks={[
                {
                  value: 0,
                  label: '0',
                },
                { value: 2, label: '2' },
                { value: 5, label: '5' },
                { value: 10, label: '10' },
                {
                  value: 15,
                  label: '15+',
                },
              ]}
              value={outreachCountRange}
              onChange={(value: [number, number]) => setOutreachCountRange(value)}
              onChangeEnd={(value: [number, number]) => {
                let [minOutreachCountValue, maxOutreachCountValue] = value;
                if (maxOutreachCountValue === 15) {
                  maxOutreachCountValue = 1000;
                }
                searchParams.set('minOutreachCount', minOutreachCountValue.toString());
                searchParams.set('maxOutreachCount', maxOutreachCountValue.toString());
                setSearchParams(searchParams);
              }}
            />
          </Popover.Dropdown>
        </Popover>

        <Popover width={333} trapFocus shadow="md">
          <Popover.Target>
            <Button
              mt={15}
              rightSection={<IconChevronDown size={16} />}
              variant="outline"
              color="imagine-green"
              disabled={!engagementPodCareTeamId}
            >
              Age
            </Button>
          </Popover.Target>
          <Popover.Dropdown>
            <Text pb={12}>Select age range</Text>
            <RangeSlider
              style={{ width: '100%', height: '50px', display: 'flex', justifyContent: 'center' }}
              maw="600px"
              mah="300px"
              min={0}
              max={24}
              minRange={1}
              name="Select Age Range"
              marks={[
                {
                  value: 0,
                  label: '0',
                },
                { value: 6, label: '6' },
                { value: 12, label: '12' },
                { value: 18, label: '18' },
                {
                  value: 24,
                  label: '24+',
                },
              ]}
              value={ageRange}
              onChange={(value: [number, number]) => setAgeRange(value)}
              onChangeEnd={(value: [number, number]) => {
                let [minAgeValue, maxAgeValue] = value;
                if (maxAgeValue === 24) {
                  maxAgeValue = 100;
                }
                searchParams.set('minAge', minAgeValue.toString());
                searchParams.set('maxAge', maxAgeValue.toString());
                setSearchParams(searchParams);
              }}
            />
          </Popover.Dropdown>
        </Popover>
      </Group>
      <Paper radius="lg" shadow="xs" p="xl" style={{ marginBottom: '20px' }}>
        {isLoading && <Loader />}
        {!engagementPodCareTeamId && <Text c="dimmed">Select an engagement pod</Text>}
        {!isLoading && engagementPodCareTeamId && (
          <>
            <Table.ScrollContainer
              ref={scrollContainerRef}
              minWidth="10rem"
              onScrollCapture={(e) => {
                const el = e.target as HTMLDivElement;
                reconcileHorizontalScrollShadow(el);
              }}
            >
              <Table>
                <Table.Thead>
                  <Table.Tr>
                    {bulkTaskingEnabled && (
                      <Table.Th
                        className={`table-sticky-column-left${horizontalScrollShadow.left ? '-shadow' : ''}`}
                        style={{ position: 'sticky', left: 0, zIndex: 1 }}
                        bg="white"
                      >
                        <ActionIcon onClick={toggleSelectAllOnPage}>
                          {currentPageSelected || allSelected ? <IconSquareCheckFilled /> : <IconSquare color="gray" />}
                        </ActionIcon>
                      </Table.Th>
                    )}
                    <Table.Th
                      className={`table-sticky-column-left${horizontalScrollShadow.left ? '-shadow' : ''}`}
                      miw="250px"
                      style={{ position: 'sticky', left: bulkTaskingEnabled ? 40 : 0, zIndex: 1 }}
                      bg="white"
                    >
                      <Group gap={0}>
                        <Text fw="bold">Patient</Text>
                        <ActionIcon
                          onClick={() => {
                            if (sortField === 'name') {
                              if (sortOrder === 'asc') {
                                searchParams.delete('sort');
                                setSearchParams(searchParams);
                                return;
                              }
                              searchParams.set('sort', 'name:asc');
                              setSearchParams(searchParams);
                            } else {
                              searchParams.set('sort', 'name:desc');
                              setSearchParams(searchParams);
                            }
                          }}
                        >
                          <SortIcon isSortField={sortField === 'name'} sortOrder={sortOrder} />
                        </ActionIcon>
                      </Group>
                    </Table.Th>
                    <Table.Th miw="150px">Attribution</Table.Th>
                    <Table.Th miw="150px">Program Status</Table.Th>
                    <Table.Th miw="150px">Outreach Status</Table.Th>
                    <Table.Th miw="200px">Disposition</Table.Th>
                    <Table.Th miw="200px">Health plan</Table.Th>
                    <Table.Th>Open outreach tasks</Table.Th>
                    <Table.Th miw="175px">
                      <Group gap={0}>
                        <Text fw="bold">Outreach count</Text>
                        <ActionIcon
                          onClick={() => {
                            if (sortField === 'outreachCount') {
                              if (sortOrder === 'asc') {
                                searchParams.delete('sort');
                                setSearchParams(searchParams);
                                return;
                              }
                              searchParams.set('sort', 'outreachCount:asc');
                              setSearchParams(searchParams);
                            } else {
                              searchParams.set('sort', 'outreachCount:desc');
                              setSearchParams(searchParams);
                            }
                          }}
                        >
                          <SortIcon isSortField={sortField === 'outreachCount'} sortOrder={sortOrder} />
                        </ActionIcon>
                      </Group>
                    </Table.Th>
                    <Table.Th miw="200px">
                      <Group gap={0}>
                        <Text fw="bold">Last outreach</Text>
                        <ActionIcon
                          onClick={() => {
                            if (sortField === 'lastOutreach') {
                              if (sortOrder === 'asc') {
                                searchParams.delete('sort');
                                setSearchParams(searchParams);
                                return;
                              }
                              searchParams.set('sort', 'lastOutreach:asc');
                              setSearchParams(searchParams);
                            } else {
                              searchParams.set('sort', 'lastOutreach:desc');
                              setSearchParams(searchParams);
                            }
                          }}
                        >
                          <SortIcon isSortField={sortField === 'lastOutreach'} sortOrder={sortOrder} />
                        </ActionIcon>
                      </Group>
                    </Table.Th>
                    <Table.Th
                      className={`table-sticky-column-right${horizontalScrollShadow.right ? '-shadow' : ''}`}
                      miw="140px"
                      style={{ position: 'sticky', right: 0, zIndex: 1 }}
                      bg="white"
                    >
                      Actions
                    </Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {patientPanel.length > 0 ? (
                    patientPanel.map((patient) => {
                      return (
                        <React.Fragment key={patient.id}>
                          <PatientRow
                            selected={!!selectedPatientIdsMap[patient.id]}
                            data={patient}
                            horizontalScrollShadow={horizontalScrollShadow}
                            onToggleSelect={togglePatientSelect}
                          />
                        </React.Fragment>
                      );
                    })
                  ) : (
                    <Text c="dimmed">No patients found</Text>
                  )}
                </Table.Tbody>
              </Table>
            </Table.ScrollContainer>
            <Group
              justify={bulkTaskingEnabled ? 'space-between' : 'flex-end'}
              align="baseline"
              className="table-sticky-footer"
              bg={'white'}
            >
              <Group>
                {bulkTaskingEnabled && (
                  <Button leftSection={<IconPlus />} disabled={selectedCount === 0} onClick={handleBulkTaskModalOpen}>
                    Create Task
                  </Button>
                )}

                <Button
                  leftSection={<IconEditCircle />}
                  disabled={selectedCount === 0}
                  onClick={togglePodReassignmentModal}
                >
                  Update Engagement POD
                </Button>

                {selectedCount > 0 && !allSelected && (
                  <>
                    <Text c="dimmed">
                      <b>{selectedCount}</b> selected
                    </Text>
                    <Button component="a" onClick={toggleSelectAllResult} variant="transparent">
                      {/* biome-ignore lint/a11y/useValidAnchor: <explanation> TODO: determine if we can remove this anchor */}
                      <a>Select all {patientPanelQuery.data?.outreachPanels.total} patients</a>
                    </Button>
                  </>
                )}
                {allSelected && (
                  <>
                    <Text c="dimmed">
                      <b>All {selectedCount}</b> patients selected
                    </Text>
                    <Button component="a" onClick={toggleSelectAllResult} variant="transparent">
                      {/* biome-ignore lint/a11y/useValidAnchor: <explanation> TODO: Determine can we remove the anchor tag?*/}
                      <a>Clear all selected patients</a>
                    </Button>
                  </>
                )}
              </Group>
              <Pagination count={patientPanelQuery.data?.outreachPanels.total ?? 0} perPage={PER_PAGE} />
            </Group>
          </>
        )}
      </Paper>
    </PageSkeleton>
  );
};

type PatientPanelOptions = {
  engagementPodCareTeamId?: string;
  patientName?: string;
  carePathway?: CarePathway;
  attribution?: Attribution;
  programStatus?: ProgramStatus;
  outreachStatus?: OutreachStatus;
  dispositions?: OutreachDisposition[];
  sort?: {
    field: PanelSortField;
    order: PanelSortOrder;
  };
  page: number;
  pageSize: number;
  minAge?: number;
  maxAge?: number;
  zipCode?: string[];
  payor?: string;
  lastOutreach?: number;
  minOutreachCount?: number;
  maxOutreachCount?: number;
};

const usePatientPanel = ({
  engagementPodCareTeamId,
  patientName,
  carePathway,
  attribution,
  programStatus,
  outreachStatus,
  dispositions,
  sort,
  page,
  pageSize,
  minAge,
  maxAge,
  zipCode,
  payor,
  lastOutreach,
  minOutreachCount,
  maxOutreachCount,
}: PatientPanelOptions) => {
  const nameSearch = patientName ? patientName : undefined;
  const carePathwaySearch = carePathway ? carePathway : undefined;
  const attributionSearch = attribution ? attribution : undefined;
  const programStatusSearch = programStatus ? programStatus : undefined;
  const outreachStatusSearch = outreachStatus ? outreachStatus : undefined;
  const dispositionSearch = dispositions ? dispositions : undefined;

  return useGetOutreachPanelQuery({
    variables: {
      input: {
        engagementPodCareTeamId: engagementPodCareTeamId!,
        name: nameSearch,
        carePathway: carePathwaySearch,
        attribution: attributionSearch,
        programStatus: programStatusSearch,
        outreachStatus: outreachStatusSearch,
        disposition: dispositionSearch?.join(','),
        sort: sort && {
          field: sort.field,
          order: sort.order,
        },
        page,
        pageSize,
        minAge,
        maxAge,
        zipCode,
        payor,
        lastOutreach,
        minOutreachCount,
        maxOutreachCount,
      },
    },
    client: imagineClient,
    skip: !engagementPodCareTeamId,
  });
};

const useMyEngagementTeamPodCareTeamId = ({ skip }: { skip: boolean }): string | undefined => {
  const [myEngagementPodCareTeamId, setEngagementMyPodCareTeamId] = useState<string>();
  const { profile } = useUserSession();
  const medplum = useMedplum();
  const myEngagementPods = useQuery(
    ['my-engagement-pod-care-teams', profile.id],
    () => {
      return medplum.search('CareTeam', {
        _tag: CareTeamType.EngagementPod,
        participant: `Practitioner/${profile.id}`,
      });
    },
    { enabled: !skip },
  );
  useEffect(() => {
    if (myEngagementPods.data?.entry?.length === 1) {
      setEngagementMyPodCareTeamId(myEngagementPods.data.entry[0].resource?.id);
    }
  }, [myEngagementPods.data]);

  return myEngagementPodCareTeamId;
};
