import React from 'react';
import { Button, Table, Text, ThemeIcon } from '@mantine/core';
import { Coding } from '@medplum/fhirtypes';
import { IconX } from '@tabler/icons-react';
import { useAddPostalCodesModal } from './AddPostalCodesModal/AddPostalCodesModalContext';

interface PostalCodeTableProps {
  postalCodes: Coding[];
  removePostalCode: (postalCode: string) => void;
  podName: string;
}

export const PostalCodeTable = ({ postalCodes, removePostalCode, podName }: PostalCodeTableProps) => {
  const { open } = useAddPostalCodesModal();

  return (
    <>
      <Table verticalSpacing="md" horizontalSpacing="lg" bg="white">
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Postal Codes</Table.Th>
            <Table.Th w="200px">Actions</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {postalCodes.length === 0 ? (
            <Table.Tr>
              <Table.Td colSpan={5} ta="center">
                <Text size="lg" fw="bold">
                  This pod does not have any postal codes assigned to it.
                </Text>
                <Button my="xl" onClick={() => open()}>
                  Add postal codes
                </Button>
              </Table.Td>
            </Table.Tr>
          ) : (
            postalCodes
              .filter((postalCode) => postalCode.code)
              .map((postalCode) => (
                <Table.Tr key={postalCode.code}>
                  <Table.Td>{postalCode.code}</Table.Td>
                  <Table.Td>
                    <Button variant="outline" onClick={() => removePostalCode(postalCode.code!)}>
                      <ThemeIcon size={20}>
                        <IconX />
                      </ThemeIcon>
                      <Text ml="xs">Remove</Text>
                    </Button>
                  </Table.Td>
                </Table.Tr>
              ))
          )}
        </Table.Tbody>
      </Table>
    </>
  );
};
