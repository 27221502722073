import {
  ActionIcon,
  Button,
  Checkbox,
  Collapse,
  Divider,
  Grid,
  Group,
  Radio,
  Select,
  TextInput,
  Title,
  Text,
  Alert,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import React, { useEffect, useState, useRef } from 'react';
import { IconAlertTriangle, IconPlus, IconTrash } from '@tabler/icons-react';
import { HasContactFields, PhoneNumber } from '@/components/shared/ContactReviewForm';
import { ContactRelationship, PhoneStatus, PreferredLanguage, System, extractValues } from 'const-utils';
import { UnitedStates } from '@/utils/usa';
import { MaybeExistingContactForm } from './MaybeExistingContactForm/MaybeExistingContactForm';
import { PhoneInput } from '../design-system/PhoneInput';
import { useMaybeExistingContacts } from './MaybeExistingContactForm/MaybeExistingContactContext';
import { Box } from '@mantine/core';
import { RelationshipDropdown } from './shared/RelationshipDropdown';
import { ContactTypeDropdown } from './shared/ContactTypeDropdown';
import { CONTACT_TYPE_OTHER, RELATIONSHIP_OTHER } from './shared/constants';
import { Coding } from '@medplum/fhirtypes';
import { getContactTypeCoding, parseRelationshipCode } from '@/utils/contactReviewUtils';

interface AbstractContactFormProps<T extends HasContactFields> {
  formWithContact: UseFormReturnType<T>;
  maybeNewContactIntended?: boolean;
  openAddContactModal?: () => void;
}

const PreferredLanguageCodes = extractValues(PreferredLanguage);

export default function ContactEditForm<T extends HasContactFields>({
  formWithContact,
  maybeNewContactIntended = false,
  openAddContactModal,
}: AbstractContactFormProps<T>): JSX.Element {
  const form = formWithContact as unknown as UseFormReturnType<HasContactFields>;
  const [phoneStatusCodes] = PhoneStatus.compose?.include?.map((c) => c.concept?.map((d) => d.display)) ?? [];
  const [isOtherContactType, setIsOtherContactType] = useState(false);
  const [isOtherRelationship, setIsOtherRelationship] = useState(false);
  const { setContact } = useMaybeExistingContacts();
  const isInitialized = useRef(false);

  // One time Initialization effect
  useEffect(() => {
    if (isInitialized.current) return;

    // Only run initialization for existing contacts
    if (form.values.contact.contactId) {
      const contactTypeConcept = getContactTypeCoding(form.values.contact.contactType);
      const isOtherCt = contactTypeConcept?.code === CONTACT_TYPE_OTHER;
      setIsOtherContactType(isOtherCt);
      if (isOtherCt) {
        form.setFieldValue('contact.contactType', 'Other');
        form.setFieldValue('contact.customContactType', form.values.contact.contactType);
      }

      const relationshipConcept = parseRelationshipCode(form.values.contact.relationship);
      const standardRelationshipCodes = ContactRelationship.compose?.include?.[0].concept?.map((c) => c.code) ?? [];

      // Check both conditions for "Other" relationship
      const isOther =
        relationshipConcept.code === RELATIONSHIP_OTHER ||
        !standardRelationshipCodes.includes(relationshipConcept.code ?? '');

      setIsOtherRelationship(isOther);
      if (isOther) {
        // Preserve original display value for both legacy and custom relationships
        form.setFieldValue('contact.customRelationship', form.values.contact.relationship?.display);
        form.setFieldValue('contact.relationship', {
          code: RELATIONSHIP_OTHER,
          display: 'Other',
          system: System.ContactRelationship,
        });
      }
    }
    isInitialized.current = true;
  }, [
    form.setFieldValue,
    form.values.contact.contactId,
    form.values.contact.contactType,
    form.values.contact.relationship,
  ]);

  // Sync (form values to context) effect
  useEffect(() => {
    setContact(form.values.contact); // sync form values to context
  }, [form.values.contact, setContact]);

  const handleContactTypeChange = (item: Coding) => {
    const isOther = item.code === CONTACT_TYPE_OTHER;
    setIsOtherContactType(isOther);

    if (isOther) {
      form.setFieldValue('contact.contactType', 'Other');
      if (!form.values.contact.contactId) {
        form.setFieldValue('contact.customContactType', '');
      }
    } else {
      form.setFieldValue('contact.contactType', item.display ?? '');
      form.setFieldValue('contact.customContactType', '');
    }
  };

  const handleRelationshipChange = (item: Coding) => {
    const isOther = item.code === RELATIONSHIP_OTHER;
    setIsOtherRelationship(isOther);

    if (isOther) {
      form.setFieldValue('contact.relationship', {
        ...item,
        display: 'Other',
      });
      // Don't clear custom relationship if editing existing contact
      if (form.values.contact.contactId) {
        // Keep existing custom relationship if it exists
        const currentCustomRelationship = form.values.contact.customRelationship;
        if (currentCustomRelationship) {
          form.setFieldValue('contact.customRelationship', currentCustomRelationship);
        }
      } else {
        // Clear for new contacts
        form.setFieldValue('contact.customRelationship', '');
      }
    } else {
      form.setFieldValue('contact.relationship', item);
      form.setFieldValue('contact.customRelationship', '');
    }
  };

  return (
    <>
      <Title order={4}> Name & relationship </Title>
      <br />
      <Group grow>
        <TextInput
          name="contactFirstName"
          withAsterisk
          label="Contact's first name"
          {...form.getInputProps('contact.firstName')}
        />
        <TextInput
          name="contactLastName"
          withAsterisk
          label="Contact's last name"
          {...form.getInputProps('contact.lastName')}
        />
      </Group>
      {maybeNewContactIntended && (
        <Alert color="orange" icon={<IconAlertTriangle />} mt="sm">
          <Text>
            If you are intending to create a{' '}
            <Text span fw="bold">
              new
            </Text>{' '}
            contact, use the{' '}
            <Text
              span
              fw="bold"
              td="underline"
              style={{ cursor: openAddContactModal ? 'pointer' : undefined }}
              onClick={() => openAddContactModal?.()}
            >
              Add Contact
            </Text>{' '}
            workflow instead to avoid overwriting the existing contact.
          </Text>
          <Text mt="md">
            If the{' '}
            <Text span fw="bold">
              existing
            </Text>{' '}
            contacts name has changed, you can continue to use this form.
          </Text>
          <Text mt="md" c="dimmed">
            Note: If the patient is transferring to a new caregiver, you should create a new contact and delete the old.
          </Text>
        </Alert>
      )}
      <br />
      <Grid>
        <Grid.Col span={6}>
          <ContactTypeDropdown
            selectedItem={getContactTypeCoding(form.values.contact.contactType)}
            onSelect={handleContactTypeChange}
            error={form.getInputProps('contact.contactType').error}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          {isOtherContactType && (
            <TextInput
              label="Specify contact type"
              required={isOtherContactType}
              {...form.getInputProps('contact.customContactType')}
            />
          )}
        </Grid.Col>
      </Grid>
      <br />
      <Grid>
        <Grid.Col span={6}>
          <RelationshipDropdown
            selectedItem={form.values.contact.relationship}
            onSelect={handleRelationshipChange}
            error={form.getInputProps('contact.relationship').error}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          {isOtherRelationship && (
            <TextInput
              label="Specify relationship to patient"
              required={isOtherRelationship}
              {...form.getInputProps('contact.customRelationship')}
            />
          )}
        </Grid.Col>
      </Grid>
      <br />
      <span>Primary contact</span>
      <Checkbox
        label="This is the patient's primary contact"
        pt="md"
        {...form.getInputProps('contact.primary', { type: 'checkbox' })}
      />

      <Box mt="40px">
        <Title order={4}>Language preference</Title>
        <br />
        <Checkbox
          {...form.getInputProps('contact.usePatientLanguage', { type: 'checkbox' })}
          label="Same as patient's language preference"
        />
        <Collapse in={!form.values.contact.usePatientLanguage}>
          <br />
          <Group>
            <Select
              withAsterisk
              label="Language"
              placeholder="Select language"
              data={PreferredLanguageCodes.map((d) => d.display) as string[]}
              {...form.getInputProps('contact.language')}
            />
            <Checkbox
              label="Requires interpreter"
              pt="lg"
              {...form.getInputProps('contact.requiresInterpreter', { type: 'checkbox' })}
            />
          </Group>
        </Collapse>
      </Box>

      <Box mt="40px">
        <Title order={4}>Contact information</Title>
        <br />
        {form.values.contact.phoneNumbers?.map((phone, index) => (
          <React.Fragment key={`${phone.type}-${phone.status}-${index}`}>
            {index > 0 && (
              <>
                <br />
                <Divider />
                <br />
              </>
            )}
            <Group justify="space-between">
              <PhoneInput
                style={{ flex: 1 }}
                required
                {...form.getInputProps(`contact.phoneNumbers.${index}.number`)}
              />
              <Select
                style={{ flex: 1 }}
                label="Phone type"
                placeholder="Select phone type"
                data={['home', 'work', 'mobile']}
                {...form.getInputProps(`contact.phoneNumbers.${index}.type`)}
              />
              <Select
                style={{ flex: 1 }}
                label="Phone status"
                placeholder="Please select"
                data={phoneStatusCodes as string[]}
                {...form.getInputProps(`contact.phoneNumbers.${index}.status`)}
              />
              <Radio
                label="Primary"
                pt="lg"
                {...form.getInputProps(`contact.phoneNumbers.${index}.primary`, { type: 'checkbox' })}
                onChange={() => {
                  const newPhoneNumbers = form.values.contact.phoneNumbers?.map((phone, i) => {
                    if (i === index) {
                      return { ...phone, primary: true };
                    }
                    return { ...phone, primary: false };
                  });
                  form.setFieldValue('contact.phoneNumbers', newPhoneNumbers);
                }}
              />
              {form.values.contact.phoneNumbers?.length > 1 && (
                <ActionIcon
                  mt="lg"
                  onClick={() => {
                    form.setFieldValue(
                      'contact.phoneNumbers',
                      form.values.contact.phoneNumbers?.filter((_, i) => i !== index),
                    );
                  }}
                >
                  <IconTrash size={22} color="red" />
                </ActionIcon>
              )}
            </Group>
          </React.Fragment>
        ))}
        <br />
        <Button
          type="button"
          onClick={() => {
            const existingNumbers = [...(form.values.contact.phoneNumbers || [])];
            const newNumber: PhoneNumber = {
              number: '',
              primary: false,
            };
            if (existingNumbers.length === 0) {
              newNumber.primary = true;
            }
            form.setFieldValue('contact.phoneNumbers', [...existingNumbers, newNumber]);
          }}
          variant="outline"
          leftSection={<IconPlus size={16} />}
          radius="md"
        >
          Add another phone number
        </Button>
        <br />
        <br />
        <TextInput
          name="email"
          type="email"
          label="Email"
          w="300px"
          placeholder=""
          {...form.getInputProps('contact.emailAddress')}
        />
      </Box>

      <Box mt="40px">
        <Title order={4}>Address</Title>
        <br />
        <Checkbox
          {...form.getInputProps('contact.usePatientAddress', { type: 'checkbox' })}
          label="Patient lives with contact"
        />
        <Collapse in={!form.values.contact.usePatientAddress}>
          <br />
          <Group grow align="start">
            <TextInput
              withAsterisk
              label="Address line 1"
              placeholder="123 E Main Street"
              {...form.getInputProps('contact.address.line1')}
            />
            <TextInput label="Address line 2" placeholder="Unit 1" {...form.getInputProps('contact.address.line2')} />
          </Group>
          <br />
          <Grid grow>
            <Grid.Col span={6}>
              <TextInput
                withAsterisk
                label="City"
                placeholder="Chicago"
                {...form.getInputProps('contact.address.city')}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <Select
                withAsterisk
                label="State"
                placeholder="Select state"
                data={UnitedStates.map((state) => ({ label: state.name, value: state.abbreviation }))}
                {...form.getInputProps('contact.address.state')}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <TextInput
                withAsterisk
                label="Zip code"
                placeholder="60601"
                {...form.getInputProps('contact.address.zip')}
              />
            </Grid.Col>
          </Grid>
        </Collapse>
      </Box>

      <Box mt="40px">
        <Title order={4}>Consent to receive SMS messages</Title>

        <Checkbox
          label="Caregiver consents to receive SMS messages"
          pt="md"
          {...form.getInputProps('contact.consentToReceiveSms', { type: 'checkbox' })}
        />
      </Box>

      <MaybeExistingContactForm />
    </>
  );
}
