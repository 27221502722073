import React, { useEffect } from 'react';
import { Divider, Group, SegmentedControl, Select, Text } from '@mantine/core';
import { useSearchParams } from 'react-router-dom';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import { PanelType } from './types';

export const PanelSelector = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const flags = useFeatureFlags();
  const isBHTocEnabled = flags.BHTocV2;
  const ALERT_STATUS = 'alertStatus';
  const ALERT_STATUS_OPEN = 'open';
  const ALERT_STATUS_RESOLVED = 'resolved';

  const currentPanel = searchParams.get('panel') || PanelType.OUTREACH;
  const currentAlertStatus = searchParams.get('alertStatus') || ALERT_STATUS_OPEN;

  useEffect(() => {
    if (!searchParams.has('panel')) {
      searchParams.set('panel', PanelType.OUTREACH);
      setSearchParams(searchParams);
    }
    if (!searchParams.has(ALERT_STATUS)) {
      searchParams.set(ALERT_STATUS, ALERT_STATUS_OPEN);
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  const handlePanelChange = (value: string | null) => {
    if (value) {
      searchParams.delete('pod');
      searchParams.delete('page');
      searchParams.set('panel', value);
      setSearchParams(searchParams);
    }
  };

  const data = [
    { value: PanelType.OUTREACH, label: 'Outreach' },
    ...(isBHTocEnabled ? [{ value: PanelType.BH_TOC, label: 'BH TOC' }] : []),
  ];

  const onAlertStatusChange = (value: string) => {
    searchParams.set(ALERT_STATUS, value);
    setSearchParams(searchParams);
  };

  return (
    <>
      <Divider orientation="vertical" size="sm" />
      <Group gap="xs">
        <Text size="md" c="dimmed">
          Select panel:
        </Text>
        <Select data={data} value={currentPanel} onChange={handlePanelChange} />
      </Group>
      {currentPanel === PanelType.BH_TOC && (
        <SegmentedControl
          value={currentAlertStatus}
          onChange={onAlertStatusChange}
          data={[
            { label: 'Open', value: ALERT_STATUS_OPEN },
            { label: 'Resolved', value: ALERT_STATUS_RESOLVED },
          ]}
        />
      )}
    </>
  );
};

export default PanelSelector;
