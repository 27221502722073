import { Table } from '@mantine/core';
import { formatHumanName } from '@medplum/core';
import { HumanName } from '@medplum/fhirtypes';
import { System } from 'const-utils';
import { CarePathwayReferralType, TaskType, carePathwayDisplay } from 'const-utils/codeSystems/ImaginePediatrics';
import { format } from 'date-fns';
import { useGetCompletedCarePathwayTasksByPatientIdQuery } from 'medplum-gql';
import { CarePathwayReferralTask } from 'imagine-dsl/models/tasks/carePathwayReferralTask';
import React from 'react';

export const CarePathwayHistory = ({ patientId }: { patientId: string }) => {
  const { data: taskList } = useGetCompletedCarePathwayTasksByPatientIdQuery({
    variables: {
      patientId: patientId,
      tag: TaskType.CarePathwayReferralReview,
    },
  });

  const formattedTasks = taskList?.TaskList?.filter((taskData) => taskData !== null).map((taskData) => {
    if (!taskData) {
      return null;
    }

    const task = new CarePathwayReferralTask(taskData);
    return {
      ...task,
      reasonsForReferral: task.reasonsForReferral,
      icd10Codes: task.icd10Codes,
      date: format(new Date(task.authoredOn ?? Date.now()), 'MM/dd/yyyy'),
      owner: task.owner && 'name' in task.owner ? formatHumanName(task.owner.name?.[0] as HumanName) : 'Unknown',
      status: task.status,
      referralType: task.referralType,
      notes: task.extension?.find((e) => e.url === System.CarePathwayReferralReviewNote.toString())?.valueString,
      originalCarePathwayValue: task.extension?.find((e) => e.url === System.OriginalCarePathwayValue.toString())
        ?.valueString,
      description: task.description,
      carePathwayChange:
        carePathwayDisplay[task.inputBySystem(System.CarePathway)?.valueString as keyof typeof carePathwayDisplay],
      requestor: task.requester?.display,
      referralNoteCode: task.referralNoteCode,
    };
  });

  return (
    <Table mt="lg">
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Care pathway change</Table.Th>
          <Table.Th>Source</Table.Th>
          <Table.Th>Date</Table.Th>
          <Table.Th>Description</Table.Th>
          <Table.Th>Reason(s) for referral</Table.Th>
          <Table.Th>ICD-10 code(s)</Table.Th>
          <Table.Th>Notes</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {formattedTasks?.map((task, index) => {
          const source = task?.referralType === CarePathwayReferralType.Data ? 'Care Hub System' : task?.requestor;
          const description = task?.referralType === CarePathwayReferralType.Data ? 'N/A' : task?.description;
          const icd10Codes = task?.referralType === CarePathwayReferralType.Data ? 'N/A' : task?.icd10Codes;
          const reasonsForReferral =
            task?.referralType === CarePathwayReferralType.Data ? 'N/A' : task?.reasonsForReferral;
          const owner =
            task?.referralType === CarePathwayReferralType.Data && task?.referralNoteCode === 'auto-approved'
              ? 'Care Hub System'
              : task?.owner;
          return (
            <Table.Tr key={index}>
              <Table.Td>
                {carePathwayDisplay[task?.originalCarePathwayValue as keyof typeof carePathwayDisplay]}
                <strong> {` > ${task?.carePathwayChange}`}</strong>
                <br />
                <br />
                {task?.status === 'accepted' ? `Approved by ${owner}` : `Rejected by ${owner}`}
              </Table.Td>
              <Table.Td>{source}</Table.Td>
              <Table.Td>{task?.date}</Table.Td>
              <Table.Td>{description}</Table.Td>
              <Table.Td>
                <ul>
                  {Array.isArray(reasonsForReferral)
                    ? reasonsForReferral.map((reason, idx) => <li key={idx}>{reason}</li>)
                    : reasonsForReferral}
                </ul>
              </Table.Td>
              <Table.Td>
                <ul>
                  {Array.isArray(icd10Codes) ? icd10Codes.map((code, idx) => <li key={idx}>{code}</li>) : icd10Codes}
                </ul>
              </Table.Td>
              <Table.Td>{task?.notes}</Table.Td>
            </Table.Tr>
          );
        })}
      </Table.Tbody>
    </Table>
  );
};
