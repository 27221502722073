import React, { createContext, PropsWithChildren, useContext, useState } from 'react';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { useMedplum } from '@medplum/react';
import { ContactPoint, Extension, GetPatientQuery, Patient, useGetPatientOutreachAttemptsLazyQuery } from 'medplum-gql';
import {
  ContactCaregiverFormValues,
  EnrollStep,
  FlowStep,
  enrollSteps,
  flowSteps,
  AppDownloadFormValues,
  ScheduleOnboardingVisitFormValues,
  SendCredentialsFormValues,
} from './types';
import { UseFormReturnType } from '@mantine/form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  validateAppDownload,
  validateContactCaregiver,
  validateContactReviewForm,
  contactReviewContinue,
} from './handlers';
import { ContactReviewForm, QueuedPatientLink } from '@/components/shared/ContactReviewForm';
import { hydrateContact, hydrateContactReviewForm, hydratePhoneNumbers } from '@/utils/contactReviewUtils';
import { notifications } from '@mantine/notifications';
import { useContactReviewForm } from './forms/contactReviewForm';
import { useContactCaregiverForm } from './forms/contactCaregiverForm';
import { useAppDownloadForm } from './forms/appDownloadForm';
import { useScheduleOnboardingVisitForm } from './forms/scheduleOnboardingVisitForm';
import { useSendCredentialsForm } from './forms/sendCredentialsForm';
import { validateCredentials } from './handlers/sendCredentials';
import { validateConsents } from './handlers/checkConsents';
import { validateScheduleOnboardingVisitForm } from './handlers/scheduleOnboardingVisit';
import { searchCaregiversWithProjectMembership, useApiClient } from '@/hooks/useApiClient';
import { ApolloQueryResult } from '@apollo/client';
import { getPhoneNumbers, isPrimaryCaregiver } from 'imagine-dsl/utils/patient';
import { getErrorMessage, logError } from '@/errors';
import { handleAddPatientPhoneNumber } from './handlers/contactCaregiver';
import { useSaveTouchpoint } from '@/components/outreach/useSaveTouchpoint';
import { System } from 'const-utils';
import { Coding } from '@medplum/fhirtypes';
import { useMaybeExistingContacts } from '@/components/MaybeExistingContactForm/MaybeExistingContactContext';
import { uniqBy } from 'lodash';
import { OutreachDisposition } from 'const-utils/codeSystems/ImaginePediatrics';
import { cleanPhoneNumber } from 'imagine-dsl/utils/strings';
import { CONTACT_TYPE_OTHER, RELATIONSHIP_OTHER } from '@/components/shared/constants';

interface OutreachContextProviderProps {
  patient: Patient;
  refreshPatient: () => Promise<ApolloQueryResult<GetPatientQuery>>;
}

interface OutreachContext {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  error: string | null;
  setError: (error: string | null) => void;
  phoneNumbers: string[];
  patient: Patient;
  caregiver?: Patient | null;
  refreshPatient: () => Promise<ApolloQueryResult<GetPatientQuery>>;
  // Forms
  contactCaregiverForm: UseFormReturnType<ContactCaregiverFormValues>;
  contactReviewForm: UseFormReturnType<ContactReviewForm>;
  appDownloadForm: UseFormReturnType<AppDownloadFormValues>;
  sendCredentialsForm: UseFormReturnType<SendCredentialsFormValues>;
  scheduleOnboardingVisitForm: UseFormReturnType<ScheduleOnboardingVisitFormValues>;

  // State
  appDownloadLinkSentAt: Date | null;
  appDownloadLinkError: string | null;
  setAppDownloadSentAt: (date: Date | null) => void;

  credentials: string | null;
  credentialsSentAt: Date | null;
  setCredentials: (credentials: string | null) => void;
  setCredentialsSentAt: (date: Date | null) => void;

  isCaregiverOnboarded: boolean;

  consentsCheckedAt: Date | null;
  setConsentsCheckedAt: (date: Date | null) => void;
  consentsSignedAt: Date | null;
  setConsentsSignedAt: (date: Date | null) => void;

  testMessageSentAt: Date | null;
  setTestMessageSentAt: (date: Date | null) => void;

  videoCallTestedAt: Date | null;
  setVideoCallTestedAt: (date: Date | null) => void;

  // Flow control
  canContinue: boolean;
  canBack: boolean;
  canComplete: boolean;
  handleContinue: () => Promise<void>;
  handleBack: () => void;
  // currentStepIdx: number;
  flowStep: FlowStep;
  flowStepIndex: number;
  enrollStep: EnrollStep;
  enrollStepIndex: number;
  wrapHandler: (handler: () => Promise<void>) => Promise<void>;

  // Log call and exit
  loggingCall: boolean;
  submittingCall: boolean;
  handleClickLogCallAndExit: () => void;
  closeLogCallModal: () => void;
  queuePatientToLink: (patient: Patient, relationship: Coding) => void;
  updatedQueuedLinkRelationship: (patient: Patient, relationship: Coding) => void;
  updatedQueuedLinkContactType: (patient: Patient, contactType: string) => void;
  dequeuePatientToLink: (patient: Patient) => void;
  queuedPatientsToLink: QueuedPatientLink[];
}

export const OutreachContext = createContext<OutreachContext>({} as OutreachContext);

export const useOutreach = (): OutreachContext => useContext(OutreachContext);

export const OutreachProvider = ({
  patient,
  refreshPatient,
  children,
}: PropsWithChildren<OutreachContextProviderProps>): JSX.Element => {
  const [searchParams] = useSearchParams();
  const [currentStepIdx, setCurrentStepIdx] = useState(Number(searchParams.get('step')));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loggingCall, setLoggingCall] = useState(false);
  const [submittingCall, setSubmittingCall] = useState(false);
  const [appDownloadLinkSentAt, setAppDownloadSentAt] = useState<Date | null>(null);
  const [appDownloadLinkError, _setAppDownloadLinkError] = useState<string | null>(null);
  const [credentials, setCredentials] = useState<string | null>(null);
  const [credentialsSentAt, setCredentialsSentAt] = useState<Date | null>(null);
  const [consentsCheckedAt, setConsentsCheckedAt] = useState<Date | null>(null);
  const [consentsSignedAt, setConsentsSignedAt] = useState<Date | null>(null);
  const [testMessageSentAt, setTestMessageSentAt] = useState<Date | null>(null);
  const [videoCallTestedAt, setVideoCallTestedAt] = useState<Date | null>(null);
  const [queuedPatientsToLink, setQueuedPatientsToLink] = useState<QueuedPatientLink[]>([]);
  const [isCaregiverOnboarded, setIsCaregiverOnboarded] = useState(false);
  const maybeExistingContacts = useMaybeExistingContacts();

  const [refreshOutreachAttempts] = useGetPatientOutreachAttemptsLazyQuery({
    variables: {
      id: patient.id!,
    },
    fetchPolicy: 'network-only',
  });

  const medplum = useMedplum();
  const apiClient = useApiClient();
  const navigate = useNavigate();

  const caregivers = (patient?.RelatedPersonList?.map((rp) => rp?.PatientList?.[0]).filter(Boolean) || []) as Patient[];
  const caregiver = caregivers.find((cg) => isPrimaryCaregiver(patient, cg)) || caregivers[0];

  const phoneNumberSet = new Set(getPhoneNumbers(patient).map((number) => cleanPhoneNumber(number)));
  const phoneNumbers = Array.from(phoneNumberSet);

  const contactCaregiverForm = useContactCaregiverForm({ patient, caregiver, phoneNumbers });
  const contactReviewForm = useContactReviewForm({ patient, ...contactCaregiverForm.values });
  const appDownloadForm = useAppDownloadForm({ patient });
  const scheduleOnboardingVisitForm = useScheduleOnboardingVisitForm();
  const sendCredentialsForm = useSendCredentialsForm({ patient });

  // The "enroll" flow step overlaps with the first step in `enrollSteps`,
  // so we need to subtract one from the total number of steps.
  const numSteps = flowSteps.length + enrollSteps.length - 1;

  // TODO: use memo to avoid re-rendering
  const flowStepIndex = Math.min(currentStepIdx, flowSteps.length - 1);
  const enrollStepIndex = Math.max(Math.min(currentStepIdx - flowSteps.length + 1, enrollSteps.length - 1), 0);

  const flowStep = flowSteps[flowStepIndex];
  const enrollStep = enrollSteps[enrollStepIndex];

  const disposition =
    scheduleOnboardingVisitForm.values.visitScheduled === 'yes'
      ? OutreachDisposition.EnrollmentCompletedOnboardingVisitScheduled
      : OutreachDisposition.EnrollmentCompletedOnboardingVisitNotScheduled;

  const touchpointValues = {
    notes: scheduleOnboardingVisitForm.values.notes,
    outcome: disposition,
  };

  const [logTouchpoint] = useSaveTouchpoint(patient.id!, {
    ...touchpointValues,
    details: {
      ...contactCaregiverForm.values,
      contactName:
        `${contactCaregiverForm.values.contactFirstName} ${contactCaregiverForm.values.contactLastName}`.trim(),
    },
  });

  const nextStep = (): void => {
    if (currentStepIdx === numSteps - 1) {
      return;
    }

    const newStep = Math.min(currentStepIdx + 1, numSteps - 1);
    setCurrentStepIdx(newStep);
  };

  const prevStep = (): void => {
    const newStep = Math.max(currentStepIdx - 1, 0);
    setCurrentStepIdx(newStep);
  };

  const wrapHandler = async (handler: () => Promise<void>): Promise<void> => {
    setLoading(true);
    setError(null);
    try {
      await handler();
    } catch (err) {
      const message = getErrorMessage(err);
      setError(message);
      notifications.show({ title: 'Error', message, color: 'status-error' });
    } finally {
      setLoading(false);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  const flowStepHandlers: Record<FlowStep, () => Promise<(() => void) | void>> = {
    [FlowStep.ContactCaregiver]: async () => {
      await validateContactCaregiver(contactCaregiverForm);

      let updatedPatientResponse;
      if (!phoneNumbers.includes(contactCaregiverForm.values.phoneNumber)) {
        await handleAddPatientPhoneNumber(medplum, patient.id!, contactCaregiverForm.values.phoneNumber);
        if (caregiver) {
          await handleAddPatientPhoneNumber(medplum, caregiver.id!, contactCaregiverForm.values.phoneNumber);
        }
        updatedPatientResponse = await refreshPatient();
      }
      contactReviewForm.setValues(
        hydrateContactReviewForm(updatedPatientResponse?.data?.Patient ?? patient, {
          firstNameOverride: contactCaregiverForm.values.contactFirstName,
          lastNameOverride: contactCaregiverForm.values.contactLastName,
        }),
      );
    },
    // @ts-ignore TS7030: Not all code paths return a value.
    // eslint-disable-next-line consistent-return
    [FlowStep.ContactInfo]: async () => {
      if (maybeExistingContacts.selected && maybeExistingContacts.relationship) {
        const newContact = _hydrateContact(
          contactReviewForm,
          maybeExistingContacts.selected as Patient,
          patient,
          maybeExistingContacts.relationship,
          maybeExistingContacts.contactType,
          maybeExistingContacts.customRelationship,
          maybeExistingContacts.customContactType,
        );
        contactReviewForm.setFieldValue('contact', newContact);
        contactReviewForm.values.contact = newContact;
        //TODO: refactor/clean this up. this form is overly complicated with all of these values passing around
        if (maybeExistingContacts.isOtherContactType) {
          contactReviewForm.setFieldValue('customContactType', maybeExistingContacts.customContactType);
          contactReviewForm.setFieldValue('contact.customContactType', maybeExistingContacts.customContactType);
        }
        if (maybeExistingContacts.isOtherRelationship) {
          contactReviewForm.setFieldValue('customRelationship', maybeExistingContacts.customRelationship);
          contactReviewForm.setFieldValue('contact.customRelationship', maybeExistingContacts.customRelationship);
        }
      }

      await validateContactReviewForm(contactReviewForm);
      const caregiver = await contactReviewContinue(apiClient, patient!.id!, contactReviewForm, () =>
        Promise.resolve(),
      );
      const primaryPhone = contactReviewForm.values.contact.phoneNumbers.find((p) => p.primary);
      appDownloadForm.setFieldValue('phoneNumber', primaryPhone?.number || '');
      if (queuedPatientsToLink.length > 0) {
        // concurrently calling /api/patients/links can cause a race condition that removes links due to stale patient reference
        // being used to update links
        for (const queued of queuedPatientsToLink) {
          await apiClient.fetch('/api/patients/links', {
            method: 'POST',
            body: JSON.stringify({
              caregiverId: caregiver.id,
              linkPatientId: queued.patient.id,
              relationship: queued.relationship,
              contactType: queued.contactType,
              ...(queued.customContactType ? { customContactType: queued.customContactType } : {}),
              ...(queued.customRelationship ? { customRelationship: queued.customRelationship } : {}),
            }),
          });
        }
      }
      const matches = await searchCaregiversWithProjectMembership(apiClient, `profile=Patient/${caregiver.id}`);
      if (matches.length > 0) {
        return () => {
          setIsCaregiverOnboarded(true);
          setCurrentStepIdx(4);
        };
      }
    },
    [FlowStep.Enroll]: () => Promise.resolve(),
  };

  const enrollStepHandlers: Record<EnrollStep, () => Promise<void>> = {
    [EnrollStep.AppDownload]: async () => {
      await validateAppDownload(appDownloadForm);
      sendCredentialsForm.setFieldValue('phoneNumber', appDownloadForm.values.phoneNumber);
    },
    [EnrollStep.Credentials]: async () => validateCredentials(credentials),
    [EnrollStep.Consents]: async () => validateConsents(consentsCheckedAt, consentsSignedAt),
    [EnrollStep.TestMessage]: async () => Promise.resolve(),
    [EnrollStep.TestVideoCall]: async () => Promise.resolve(),
    [EnrollStep.ScheduleOnboarding]: async () => {
      await validateScheduleOnboardingVisitForm(scheduleOnboardingVisitForm);
      await logTouchpoint();
      notifications.show({ title: 'Success', message: 'Enrollment complete', color: 'status-success' });
      await Promise.all([refreshPatient(), refreshOutreachAttempts()]);
      const to = `/Patient/${patient!.id}`;
      navigate(to);
    },
  };

  const handleContinue = async (): Promise<void> =>
    wrapHandler(async () => {
      const handler = flowStep === FlowStep.Enroll ? enrollStepHandlers[enrollStep] : flowStepHandlers[flowStep];
      const next = (await handler()) ?? nextStep;
      await refreshPatient();
      next();
    });

  const handleBack = (): void => {
    prevStep();
  };

  const handleClickLogCallAndExit = async (): Promise<void> => {
    setLoading(true);
    if (flowStep === FlowStep.ContactCaregiver) {
      if (
        !phoneNumbers.includes(contactCaregiverForm.values.phoneNumber) &&
        isValidPhoneNumber(contactCaregiverForm.values.phoneNumber)
      ) {
        await handleAddPatientPhoneNumber(medplum, patient.id!, contactCaregiverForm.values.phoneNumber);
        if (caregiver) {
          await handleAddPatientPhoneNumber(medplum, caregiver.id!, contactCaregiverForm.values.phoneNumber);
        }
        await refreshPatient();
      }
    }

    if (flowStep === FlowStep.ContactInfo) {
      contactReviewForm.validate();
      if (contactReviewForm.isValid()) {
        await contactReviewContinue(apiClient, patient!.id as string, contactReviewForm, refreshPatient).catch(
          (err) => {
            logError(err);
            notifications.show({ title: 'Error', message: 'Failed to save contact info', color: 'status-error' });
          },
        );
      }
    }

    setLoading(false);
    setLoggingCall(true);
  };

  const closeLogCallModal = (): void => {
    setLoggingCall(false);
    setSubmittingCall(false);
  };

  const canContinue = currentStepIdx < numSteps - 1;
  const canBack = currentStepIdx > 0;
  const canComplete = currentStepIdx === numSteps - 1;

  const queuePatientToLink = (patientToLink: Patient, relationship: Coding): void => {
    setQueuedPatientsToLink((prev: QueuedPatientLink[]) => [
      ...prev,
      { patient: patientToLink, relationship, contactType: '' },
    ]);
  };

  const updatedQueuedLinkRelationship = (
    patientToLink: Patient,
    relationship: Coding,
    customRelationship?: string,
  ): void => {
    setQueuedPatientsToLink((prev: QueuedPatientLink[]) =>
      prev.map((p) =>
        p.patient.id === patientToLink.id ? { ...p, relationship: relationship, customRelationship } : p,
      ),
    );
  };

  const updatedQueuedLinkContactType = (
    patientToLink: Patient,
    contactType: string,
    customContactType?: string,
  ): void => {
    setQueuedPatientsToLink((prev) =>
      prev.map((p) => (p.patient.id === patientToLink.id ? { ...p, contactType, customContactType } : p)),
    );
  };

  const dequeuePatientToLink = (patientToLink: Patient): void => {
    setQueuedPatientsToLink((prev) => prev.filter((p) => p.patient.id !== patientToLink.id));
  };

  const value = {
    appDownloadForm,
    appDownloadLinkError,
    appDownloadLinkSentAt,
    canBack,
    canComplete,
    canContinue,
    caregiver,
    closeLogCallModal,
    consentsCheckedAt,
    consentsSignedAt,
    contactCaregiverForm,
    contactReviewForm,
    credentials,
    credentialsSentAt,
    enrollStep,
    enrollStepIndex,
    error,
    flowStep,
    flowStepIndex,
    handleBack,
    handleClickLogCallAndExit,
    handleContinue,
    loading,
    submittingCall,
    loggingCall,
    patient,
    phoneNumbers,
    refreshPatient,
    scheduleOnboardingVisitForm,
    sendCredentialsForm,
    setAppDownloadSentAt,
    setConsentsCheckedAt,
    setConsentsSignedAt,
    setCredentials,
    setCredentialsSentAt,
    setError,
    setLoading,
    setTestMessageSentAt,
    setVideoCallTestedAt,
    testMessageSentAt,
    videoCallTestedAt,
    wrapHandler,
    queuePatientToLink,
    updatedQueuedLinkRelationship,
    updatedQueuedLinkContactType,
    dequeuePatientToLink,
    queuedPatientsToLink,
    isCaregiverOnboarded,
  };

  return <OutreachContext.Provider value={value}>{children}</OutreachContext.Provider>;
};

const _hydrateContact = (
  form: ReturnType<typeof useContactReviewForm>,
  caregiver: Patient,
  patient: Patient,
  relationship: Coding,
  contactType: Coding | undefined,
  customRelationship?: string,
  customContactType?: string,
) => {
  const newTelecom = form.values.contact.phoneNumbers
    .filter((n) => isValidPhoneNumber(n.number)) // Filter out invalid phone numbers that have potential to be saved in an abandoned outreach 'log call & exit'
    .map((n) => {
      const extension: Extension[] = [];
      if (n.primary) {
        extension.push({
          url: System.PrimaryPhone.toString(),
          valueBoolean: true,
        });
      }
      if (n.status) {
        extension.push({
          url: System.PhoneStatus.toString(),
          valueCode: n.status,
        });
      }

      return {
        system: 'phone',
        value: n.number,
        use: n.type,
        extension,
      };
    });

  const mergedTelecom = uniqBy([...newTelecom, ...(caregiver.telecom || [])], (t) => t.value) as ContactPoint[];
  let seenPrimary = false;
  const telecom = mergedTelecom.map((t) => {
    const isPrimary = t.extension?.some((e) => e.url === System.PrimaryPhone.toString() && e.valueBoolean);
    if (isPrimary) {
      if (seenPrimary) {
        t.extension = t.extension?.filter((e) => e.url !== System.PrimaryPhone.toString());
      }

      seenPrimary = true;
    }

    return t;
  });

  return hydrateContact(caregiver as Patient, patient, {
    relationshipOverride: {
      ...relationship,
      ...(customRelationship ? { code: RELATIONSHIP_OTHER, display: customRelationship } : {}),
    },
    contactTypeOverride: {
      ...contactType,
      ...(customContactType ? { code: CONTACT_TYPE_OTHER, display: customContactType } : {}),
    },
    phoneNumbersOverride: hydratePhoneNumbers({
      ...(caregiver as Patient),
      telecom,
    }),
  });
};
