import { useMedplum } from '@medplum/react';
import { getEngagementPodMappingResources } from 'imagine-dsl/services/engagementPodMappingService';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Group,
  Text,
  Loader,
  Center,
  Container,
  Paper,
  Breadcrumbs,
  Anchor,
  Table,
  ThemeIcon,
} from '@mantine/core';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import { useNavigate } from 'react-router-dom';
import { useDisclosure, useDocumentTitle } from '@mantine/hooks';
import { CreateEngagementPodModal } from '@/components/engagementPodMapping/CreateEngagementPodModal';
import { MappingResourceIdToPodName } from 'imagine-dsl/utils/types/engagementPodMapping';
import { notifications } from '@mantine/notifications';
import { IconHomePlus } from '@tabler/icons-react';
import { AddPostalCodesModal } from '@/components/engagementPodMapping/AddPostalCodesModal/AddPostalCodesModal';
import { useAddPostalCodesModal } from '@/components/engagementPodMapping/AddPostalCodesModal/AddPostalCodesModalContext';
import { Pagination } from '@/design-system/Pagination';
import { usePagination } from '@/design-system/hooks/usePagination';

const PODS_PER_PAGE = 10;

export const EngagementPodsPage = (): JSX.Element => {
  useDocumentTitle('Engagement Pod Mapping');
  const medplum = useMedplum();
  const flags = useFeatureFlags();
  const navigate = useNavigate();
  const [isCreatePodModalOpen, { close: closeCreatePodModal, open: openCreatePodModal }] = useDisclosure();
  const { open: openAddPostalCodesModal } = useAddPostalCodesModal();
  const { currentPage } = usePagination({});

  const [podMappings, setPodMappings] = useState<MappingResourceIdToPodName[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [podBeingUpdated, setPodBeingUpdated] = useState<string | null>(null);

  useEffect(() => {
    const fetchPodMapping = async () => {
      try {
        const resources = await getEngagementPodMappingResources(medplum);
        setPodMappings(resources);
      } catch (error) {
        notifications.show({
          message: `Error fetching engagement pods: ${error}`,
          color: 'status-error',
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchPodMapping();
  }, []);

  const addPodMapping = (newPodMapping: MappingResourceIdToPodName) => {
    setPodMappings((prev) => [...prev, newPodMapping]);
  };

  const handleOpenPostalCodesModal = (podName: string) => {
    setPodBeingUpdated(podName);
    openAddPostalCodesModal();
  };

  if (!flags.EngagementPodMapping) {
    return (
      <Container size="xl" w="100%" mt="xl">
        <Paper radius="lg" shadow="xs" p="xl">
          <Text size="lg">You are not permitted to view this page</Text>
        </Paper>
      </Container>
    );
  }

  if (isLoading) {
    return (
      <Center mt="xl">
        <Loader />
      </Center>
    );
  }

  const paginatedPodMappings = () => {
    const start = (currentPage - 1) * PODS_PER_PAGE;
    const end = start + PODS_PER_PAGE;
    return podMappings.slice(start, end);
  };

  return (
    <>
      {isCreatePodModalOpen && (
        <CreateEngagementPodModal
          isOpen={isCreatePodModalOpen}
          closeModal={closeCreatePodModal}
          addPodMapping={addPodMapping}
        />
      )}
      <Group align="center" justify="space-between" mb="md">
        <Breadcrumbs>
          <Anchor underline="always" onClick={() => navigate('/Admin')}>
            Admin
          </Anchor>
          <Anchor underline="never" onClick={() => navigate('/Admin/EngagementPods')}>
            Engagement pods
          </Anchor>
        </Breadcrumbs>
        <Button onClick={() => openCreatePodModal()}>Create new pod</Button>
      </Group>
      <Table verticalSpacing="md" horizontalSpacing="lg" bg="white">
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Pod</Table.Th>
            <Table.Th w="360px">Actions</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {paginatedPodMappings().map((pod) => (
            <Table.Tr key={pod.id}>
              {podBeingUpdated === pod.podName && (
                <AddPostalCodesModal destinationPodId={pod.id} podName={pod.podName} />
              )}
              <Table.Td>{pod.podName}</Table.Td>
              <Table.Td>
                <Button mr="md" variant="outline" onClick={() => navigate(`/Admin/EngagementPod/${pod.id}`)}>
                  <Text px="xs">View</Text>
                </Button>
                <Button onClick={() => handleOpenPostalCodesModal(pod.podName)} mr="md" variant="outline">
                  <ThemeIcon size={20}>
                    <IconHomePlus />
                  </ThemeIcon>
                  <Text ml="xs">Add Postal Codes</Text>
                </Button>
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
      <Group mb="xl" justify="flex-end">
        <Pagination perPage={PODS_PER_PAGE} count={podMappings.length} />
      </Group>
    </>
  );
};
