import React, { useEffect } from 'react';
import { Button, Group, Stack, TextInput, Tabs, Text, LoadingOverlay } from '@mantine/core';
import { MarketFormFields } from './MarketFormFields';
import { useInactivateMarket, useActivateMarket } from '@/hooks/MarketHooks/useInactivateMarket';
import { ModalWrapper } from '../Modals/ModalWrapper';
import { notifications } from '@mantine/notifications';
import { capitalize } from 'lodash';

type MarketModalProps = {
  mode: 'edit' | 'create';
  opened: boolean;
  onClose: () => void;
  onUpdate: () => void;
  marketData?: {
    id: string;
    name: string;
    status: boolean;
    athenaId: number;
    prapare?: boolean;
    acuteCare?: boolean;
  };
};

export const MarketModal: React.FC<MarketModalProps> = ({ mode, opened, onClose, onUpdate, marketData }) => {
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [confirmationInput, setConfirmationInput] = React.useState('');
  const [confirmationAction, setConfirmationAction] = React.useState<'activate' | 'inactivate'>('inactivate');
  const [isLoading, setIsLoading] = React.useState(false);
  const [activationReason, setActivationReason] = React.useState('');
  const confirmationFormatted = capitalize(confirmationAction);
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
  };

  const { inactivateMarket } = useInactivateMarket();
  const { activateMarket } = useActivateMarket();

  const handleConfirmation = async () => {
    if (confirmationInput === marketData?.name) {
      try {
        const action = confirmationAction === 'inactivate' ? inactivateMarket : activateMarket;
        const result = await action(marketData.id, activationReason);
        if (result) {
          setShowConfirmation(false);
          onClose();
          onUpdate();
          notifications.show({
            title: 'Success',
            message: `Market successfully ${confirmationAction}.`,
            color: 'status-success',
          });
        }
      } catch (error) {
        notifications.show({
          title: 'Error',
          message: `${confirmationFormatted} failed. Please enter the correct market name to confirm.`,
          color: 'status-error',
        });
      } finally {
        setIsLoading(false);
        onClose();
      }
    } else {
      notifications.show({
        title: 'Error',
        message: 'Market name does not match. Please enter the correct market name to confirm.',
        color: 'status-error',
      });
    }
  };

  useEffect(() => {
    if (!opened) {
      setShowConfirmation(false);
      setConfirmationInput('');
      setConfirmationAction('inactivate');
      setIsLoading(false);
      setActivationReason('');
    }
  }, [opened]);

  return (
    <ModalWrapper title={mode === 'edit' ? 'Update Market' : 'Add Market'} opened={opened} onClose={onClose}>
      <LoadingOverlay visible={isLoading} />
      <form onSubmit={handleSubmit}>
        {mode === 'edit' && (
          <Tabs defaultValue="edit" variant="outline">
            <Tabs.List>
              <Tabs.Tab value="edit">Edit Market</Tabs.Tab>
              {marketData?.status === true ? (
                <Tabs.Tab value="inactive" onClick={() => setConfirmationAction('inactivate')}>
                  Inactivate Market
                </Tabs.Tab>
              ) : (
                <Tabs.Tab value="active" onClick={() => setConfirmationAction('activate')}>
                  Activate Market
                </Tabs.Tab>
              )}
            </Tabs.List>

            <Tabs.Panel value="edit">
              <MarketFormFields
                mode="edit"
                onClose={onClose}
                marketData={{
                  name: marketData?.name || '',
                  id: marketData?.id || '',
                  status: marketData?.status ? 'active' : 'inactive',
                  athenaId: marketData?.athenaId || 0,
                  prapare: marketData?.prapare,
                  acuteCare: marketData?.acuteCare,
                }}
                onUpdate={onUpdate}
              />
            </Tabs.Panel>

            <Tabs.Panel value="inactive">
              <Stack gap={14} p="md">
                <TextInput label="Market ID" placeholder="Enter Market ID" defaultValue={marketData?.id} disabled />
                <TextInput label="Market Name" defaultValue={marketData?.name} disabled />
                <TextInput
                  label="Reason for Inactivation"
                  placeholder="Enter Reason"
                  value={activationReason}
                  onChange={(value) => setActivationReason(value.currentTarget.value)}
                  required
                />
              </Stack>
              <Group justify="flex-end" ml="auto" pt={10}>
                <Button variant="outline" onClick={onClose}>
                  Cancel
                </Button>
                <Button disabled={!activationReason} onClick={() => setShowConfirmation(true)}>
                  Inactivate Market
                </Button>
              </Group>
            </Tabs.Panel>

            <Tabs.Panel value="active">
              <Stack gap={14} p="md">
                <TextInput label="Market ID" placeholder="Enter Market ID" defaultValue={marketData?.id} disabled />
                <TextInput label="Market Name" defaultValue={marketData?.name} disabled />
                <TextInput
                  label="Reason for Activation"
                  value={activationReason}
                  onChange={(value) => setActivationReason(value.currentTarget.value)}
                  placeholder="Enter Reason"
                  required
                />
              </Stack>
              <Group justify="flex-end" ml="auto" pt={10}>
                <Button variant="outline" onClick={onClose}>
                  Cancel
                </Button>
                <Button disabled={!activationReason} onClick={() => setShowConfirmation(true)}>
                  Activate Market
                </Button>
              </Group>
            </Tabs.Panel>
          </Tabs>
        )}
        {mode === 'create' && <MarketFormFields onUpdate={onUpdate} mode="create" onClose={onClose} />}
      </form>

      {showConfirmation && (
        <ModalWrapper
          title={`Confirm ${confirmationFormatted}`}
          opened={showConfirmation}
          onClose={() => setShowConfirmation(false)}
        >
          <Text>
            Are you sure you want to {confirmationAction}
            <strong> {marketData?.name} </strong> as a Market?
          </Text>
          <TextInput
            placeholder="Type market name to confirm"
            value={confirmationInput}
            onChange={(e) => setConfirmationInput(e.currentTarget.value)}
            required
          />
          <Group justify="flex-end" ml="auto" pt={10}>
            <Button variant="outline" onClick={() => setShowConfirmation(false)}>
              Cancel
            </Button>
            <Button onClick={handleConfirmation}>Confirm</Button>
          </Group>
        </ModalWrapper>
      )}
    </ModalWrapper>
  );
};
