import React from 'react';
import { useGetPatientOutreachActivityQuery } from 'medplum-gql';
import { mapOutreachQueryResult } from 'imagine-dsl/utils/outreach';
import { Loader, Text, Table } from '@mantine/core';
import { TouchpointActivityItem } from '@/components/shared/TouchpointActivityItem';

export const useActivity = (patientId: string, from: Date | undefined, to?: Date | undefined) => {
  const outreachActivityQuery = useGetPatientOutreachActivityQuery({
    variables: {
      id: patientId,
      filter: `sent ge ${from?.toISOString()}${to ? ` and sent lt ${to.toISOString()}` : ''}`,
    },
  });

  const data = outreachActivityQuery.data && mapOutreachQueryResult(outreachActivityQuery.data);

  const renderActivity = () => {
    if (outreachActivityQuery.loading) {
      return <Loader />;
    }
    if (!data || data.length === 0) {
      return <Text c="dimmed">No touchpoints since task creation</Text>;
    }

    return (
      <Table>
        <Table.Tbody>
          {data?.map((activity) => {
            return <TouchpointActivityItem key={activity.id} touchpoint={activity} patientId={patientId} />;
          })}
        </Table.Tbody>
      </Table>
    );
  };

  return { renderActivity };
};
