import { Anchor, Box, Breadcrumbs, Button, Group, Tabs } from '@mantine/core';
import React, { useState } from 'react';
import { MarketsPage } from './Markets/Markets';
import { PayorsPage } from './Payors/Payors';
import { PageSkeleton } from '@/design-system/PageSkeleton';
import { MarketModal } from './Markets/MarketModal';
import { PayorModal } from './Payors/PayorModal';
import { useNavigate } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';
import { useDocumentTitle } from '@mantine/hooks';

export const MarketsAndPayorsPage = () => {
  useDocumentTitle('Markets and Payors - Care Hub');
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('Markets');
  const [marketRefreshKey, setMarketRefreshKey] = useState(0);
  const [payorRefreshKey, setPayorRefreshKey] = useState(0);
  const [marketModalOpened, marketModalHandlers] = useDisclosure(false);
  const [payorModalOpened, payorModalHandlers] = useDisclosure(false);

  const handleOpenModal = () => {
    activeTab === 'Markets' ? marketModalHandlers.open() : payorModalHandlers.open();
  };

  const handleCloseModal = () => {
    if (activeTab === 'Markets') {
      marketModalHandlers.close();
      setMarketRefreshKey((prevKey) => prevKey + 1);
    } else {
      payorModalHandlers.close();
      setPayorRefreshKey((prevKey) => prevKey + 1);
    }
  };

  const Section = ({ title, buttonLabel, modalProps }: any) => (
    <Group align="center" justify="space-between" style={{ width: '100%' }}>
      <Breadcrumbs>
        <Anchor underline="always" onClick={() => navigate('/Admin')}>
          Admin
        </Anchor>
        <Anchor underline="never" onClick={() => navigate('/Admin/MarketsAndPayors')}>
          {title}
        </Anchor>
      </Breadcrumbs>
      <Button size="xs" variant="filled" onClick={handleOpenModal} mr={15}>
        {buttonLabel}
      </Button>
      {activeTab === 'Markets' && <MarketModal {...modalProps} onClose={handleCloseModal} />}
      {activeTab === 'Payors' && <PayorModal {...modalProps} onClose={handleCloseModal} />}
    </Group>
  );

  return (
    <Box style={{ width: '100%', maxWidth: '1200px', margin: '0 auto' }}>
      <Section
        title={activeTab === 'Markets' ? 'Markets' : 'Payors'}
        buttonLabel={activeTab === 'Markets' ? 'Create New Market' : 'Create New Payor'}
        modalProps={{
          mode: 'create',
          opened: activeTab === 'Markets' ? marketModalOpened : payorModalOpened,
          onClose: handleCloseModal,
        }}
      />
      <PageSkeleton>
        <Tabs
          variant="outline"
          value={activeTab}
          onChange={(value) => setActiveTab((value ?? 'Markets') as 'Markets' | 'Payors')}
        >
          <Tabs.List>
            <Tabs.Tab value="Markets">Markets</Tabs.Tab>
            <Tabs.Tab value="Payors">Payors</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="Markets">
            <MarketsPage refreshKey={marketRefreshKey} />
          </Tabs.Panel>

          <Tabs.Panel value="Payors">
            <PayorsPage refreshKey={payorRefreshKey} />
          </Tabs.Panel>
        </Tabs>
      </PageSkeleton>
    </Box>
  );
};
