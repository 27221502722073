import React, { useState } from 'react';
import { IconCircleKey, IconEditCircle, IconTrash, IconDeviceMobile, IconDotsVertical } from '@tabler/icons-react';
import { ActionIcon, Menu, MenuDropdown, ThemeIcon, Tooltip } from '@mantine/core';
import { getSmsConsent } from 'imagine-dsl/utils/patient';
import { ExtendedRelatedPerson } from '../handlers/patientContactForm';

interface ContactActionsProps {
  active: boolean | undefined;
  onEdit: () => void;
  onDelete: () => void;
  onRegenerateCredentials: () => void;
  onSendAppDownloadLink: () => void;
  onActivate: () => void;
  person: ExtendedRelatedPerson;
}

const PatientContactActions = ({
  active,
  onEdit,
  onDelete,
  onRegenerateCredentials,
  onSendAppDownloadLink,
  onActivate,
  person,
}: ContactActionsProps): JSX.Element => {
  const [opened, setOpened] = useState(false);
  const caregiver = person?.PatientList?.[0];
  const smsOptIn = getSmsConsent(caregiver);

  const handleItemClick = (handler: () => void): void => {
    handler();
    setOpened(false);
  };

  return (
    <Menu
      opened={opened}
      onClose={() => setOpened(false)}
      width={250}
      position="bottom"
      withArrow
      shadow="md"
      radius="md"
    >
      <Menu.Target>
        <ActionIcon color="imagine-green">
          <IconDotsVertical size={22} onClick={() => setOpened(!opened)} />
        </ActionIcon>
      </Menu.Target>
      {active ? (
        <Menu.Dropdown>
          <Menu.Item
            leftSection={
              <ThemeIcon color="imagine-green" variant="transparent">
                <IconEditCircle size={20} />
              </ThemeIcon>
            }
            onClick={() => handleItemClick(onEdit)}
          >
            Edit contact
          </Menu.Item>
          <Menu.Item
            leftSection={
              <ThemeIcon color="imagine-green" variant="transparent">
                <IconCircleKey size={20} />
              </ThemeIcon>
            }
            onClick={() => handleItemClick(onRegenerateCredentials)}
          >
            Reset password
          </Menu.Item>

          <Tooltip label="Caregiver must opt in to SMS to send an app download link" disabled={smsOptIn}>
            <Menu.Item
              leftSection={
                <ThemeIcon color="imagine-green" variant="transparent">
                  <IconDeviceMobile size={20} />
                </ThemeIcon>
              }
              onClick={() => handleItemClick(onSendAppDownloadLink)}
              disabled={!smsOptIn}
              style={{ pointerEvents: 'all' }}
            >
              Send app download link
            </Menu.Item>
          </Tooltip>

          <Menu.Item leftSection={<IconTrash size={20} color="red" />} onClick={() => handleItemClick(onDelete)}>
            Delete
          </Menu.Item>
        </Menu.Dropdown>
      ) : (
        <MenuDropdown>
          <Menu.Item onClick={() => handleItemClick(onActivate)}>Reactivate Account</Menu.Item>
        </MenuDropdown>
      )}
    </Menu>
  );
};

export default PatientContactActions;
