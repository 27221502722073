import React, { useEffect, useState } from 'react';
import { useVideo } from '../video/VideoProvider';
import { DyteMeeting, registerAddons } from '@dytesdk/react-ui-kit';
import { VideoInterfaceWrapper } from './VideoInterfaceWrapper';
import VideoBackground from '@dytesdk/ui-kit-addons/video-background';
import { CloseButton } from '@mantine/core';
import { useDyteSelector } from '@dytesdk/react-web-core';
import {
  videoBackground1,
  videoBackground2,
  videoBackground3,
  videoBackground4,
  videoBackground5,
  videoBackground6,
  videoBackground7,
} from '@assets/images/video-backgrounds';
import { PostVideoSurvey } from './PostVideoSurvey';
import { UIConfig } from '@dytesdk/ui-kit';

export const DyteInterface = (): JSX.Element => {
  const { currentEncounter, dyteMeeting, endCall, activeDyteCall, setActiveDyteCall } = useVideo();
  const roomState = useDyteSelector((m) => m.self.roomState);
  const [hasLeftMeeting, setHasLeftMeeting] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [dyteConfig, setDyteConfig] = useState<UIConfig | undefined>(undefined);
  // these come from the dyte sdk (no exported types, unfortunately)
  type leaveRoomState = 'kicked' | 'ended' | 'left' | 'rejected' | 'connected-meeting' | 'disconnected' | 'failed';

  useEffect(() => {
    const videoBackground = new VideoBackground({
      images: [
        videoBackground1,
        videoBackground2,
        videoBackground3,
        videoBackground4,
        videoBackground5,
        videoBackground6,
        videoBackground7,
      ],
      modes: ['blur', 'virtual'],
    });

    const config = dyteMeeting ? registerAddons([videoBackground], dyteMeeting) : undefined;
    setDyteConfig(config);
  }, []);

  useEffect(() => {
    if (!dyteMeeting || !currentEncounter) {
      return;
    }

    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    //adds our existing endCall function to the Dyte interface
    const roomLeftListener = ({ state }: { state: leaveRoomState }) => {
      // this handles events with spotty wifi and keeps the meeting
      // open so the user can be reconnected, if possible
      if (state === 'disconnected') {
        return;
      }

      if (isFullscreen) {
        if (document.exitFullscreen != null) {
          document.exitFullscreen();
        } else if (document.mozExitFullScreen != null) {
          /* Firefox */
          document.mozExitFullScreen();
        } else if (document.webkitExitFullscreen != null) {
          /* Chrome, Safari & Opera */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen != null) {
          /* IE/Edge */
          document.msExitFullscreen();
        }
      }

      setHasLeftMeeting(true);
      endCall();
    };
    dyteMeeting?.self.on('roomLeft', roomLeftListener);

    return () => {
      dyteMeeting.self.removeListener('roomLeft', roomLeftListener);
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, [dyteMeeting, isFullscreen]);

  const handleMeetingClose = async () => {
    await dyteMeeting?.leave();
    setActiveDyteCall(false);
  };

  if (!dyteConfig) {
    return <div>Loading meeting...</div>;
  }

  return (
    <VideoInterfaceWrapper activeCall={activeDyteCall} overflow={isFullscreen ? 'visible' : 'hidden'}>
      {(roomState === 'init' || hasLeftMeeting) && (
        <CloseButton
          onClick={handleMeetingClose}
          c="white"
          top={10}
          right={10}
          pos="absolute"
          style={{
            zIndex: 1,
          }}
          data-cy="dyte-close-button"
        />
      )}

      <DyteMeeting
        mode="fill"
        size="sm"
        style={{ height: '640px' }}
        meeting={dyteMeeting}
        showSetupScreen={true}
        config={dyteConfig}
        data-cy="dyte-meeting"
      />

      {hasLeftMeeting && <PostVideoSurvey />}
    </VideoInterfaceWrapper>
  );
};
