import { Button, ButtonProps } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React from 'react';
import { IconArrowsShuffle } from '@tabler/icons-react';
import { Reference } from '@medplum/fhirtypes';
import { TOCTouchpointModal } from './TOCTouchpointModal';

interface TOCTouchpointButtonProps extends Omit<ButtonProps, 'onClick'> {
  patientId: string;
  basedOn?: Reference[];
  communicationRequestId?: string;
  taskId?: string;
}

export const TOCTouchpointButton = ({
  patientId,
  basedOn,
  communicationRequestId,
  taskId,
  ...buttonProps
}: TOCTouchpointButtonProps): JSX.Element => {
  const [open, handle] = useDisclosure();

  const close = async (): Promise<void> => {
    handle.close();
  };

  return (
    <>
      <Button variant="outline" color="imagine-green" {...buttonProps} onClick={handle.open}>
        <IconArrowsShuffle size={14} />
        <span style={{ paddingLeft: '6px' }}>Add touchpoint</span>
      </Button>
      {open && (
        <TOCTouchpointModal
          patientId={patientId}
          basedOn={basedOn}
          communicationRequestId={communicationRequestId}
          taskId={taskId}
          opened={open}
          closeModal={close}
        />
      )}
    </>
  );
};
