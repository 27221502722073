import { useEffect, useMemo, useState } from 'react';
import { Encounter } from '@medplum/fhirtypes';
import { useMedplum } from '@medplum/react';
import { useGetPatientAdtEncountersQuery } from 'medplum-gql';
import { BaseTask } from 'imagine-dsl/models/tasks/baseTask';
import { logError } from '@/errors';

export const useADTEncountersForTaskQuery = (
  patientId: string | null | undefined,
  communicationRequestId?: string,
  task?: BaseTask,
) => {
  const medplum = useMedplum();
  const [focusEncounter, setFocusEncounter] = useState<Encounter | undefined>(undefined);

  let communicationRequestRef: string | null | undefined;
  if (task) {
    communicationRequestRef = task?.task.basedOn?.[0]?.reference;
  } else if (communicationRequestId) {
    communicationRequestRef = `CommunicationRequest/${communicationRequestId}`;
  }
  const encounterDate = useMemo(() => {
    if (!focusEncounter) {
      return undefined;
    }
    const start = focusEncounter.period?.start;
    const end = focusEncounter.period?.end;
    if (!start && !end) {
      return undefined;
    }

    return new Date(start || end!);
  }, [focusEncounter]);

  const result = useGetPatientAdtEncountersQuery({
    variables: {
      patientRef: `Patient/${patientId}`,
      filter: encounterDate ? `date ge ${encounterDate.toISOString()}` : undefined,
    },
    skip: !focusEncounter,
  });

  useEffect(() => {
    if (!communicationRequestRef) {
      return;
    }

    const [resourceType, resourceId] = communicationRequestRef.split('/');
    if (!resourceType || !resourceId || resourceType !== 'CommunicationRequest') {
      return;
    }

    medplum
      .readResource(resourceType, resourceId)
      .then((commRequest) => {
        const encounterRef = commRequest.encounter?.reference;
        if (encounterRef) {
          const [encounterType, encounterId] = encounterRef.split('/');
          if (encounterType === 'Encounter' && encounterId) {
            return medplum.readResource('Encounter', encounterId);
          }
        }
        return undefined;
      })
      .then((encounter) => {
        if (encounter) {
          setFocusEncounter(encounter);
        }
      })
      .catch((e) => logError(e));
  }, [communicationRequestRef, medplum]);

  return result;
};
