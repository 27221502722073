import React, { CSSProperties } from 'react';
import { Coding } from '@medplum/fhirtypes';
import { ValueSetDropdown } from '@/design-system/ValueSetDropdown';
import { ContactRelationship } from 'const-utils';
import { getCode, getDisplay } from '@/utils/codingUtils';

/**
 * Props for the `RelationshipDropdown` component.
 *
 * @property selectedItem - Optional. The currently selected contact relationship, represented as a `Coding` object.
 * This property is optional to handle empty initial state, form resets, and allow for more flexible implementation patterns.
 * @property `onSelect` - Callback function that handles the event when a new contact type is selected.
 * @property `error` - Optional. Error message to be display form validation feedback.
 * @property `required` - Optional. Flag indicating whether the dropdown is a required field. Defaults to true.
 * @property `style` - Optional. Custom inline CSS styles for the component
 * @property `cypressIdentifier` - Optional. Identifier for end-to-end testing with Cypress. Defaults to
 *  'contact-type-select' if not provided. Useful for uniquely identifying multiple instances of this component in a test suite.
 */
interface RelationshipDropdownProps {
  selectedItem?: Coding;
  onSelect: (item: Coding) => void;
  error?: string;
  required?: boolean;
  style?: CSSProperties;
  cypressIdentifier?: string;
}

/**
 * `RelationshipDropdown` renders a dropdown for selecting a relationship to a patient.
 *
 * This specialized component wraps the `ValueSetDropdown` component, is pre-configured to use the `ContactRelationship` value set,
 * and formats the selected item using the provided `getCode` and `getDisplay` utility functions before rendering.
 *
 * @param selectedItem - The currently selected Coding item. If provided, its code and display values are extracted.
 * @param onSelect - Function called when the user selects a new Coding item.
 * @param error - Optional error message to be shown if validation fails.
 * @param required - Boolean flag indicating whether the field is mandatory. Defaults to `true`.
 * @param style - Optional inline styles to customize the component's appearance.
 * @param cypressIdentifier - Optional identifier for Cypress tests. Defaults to `'relationship-select'` if not provided.
 *
 * @returns A `ValueSetDropdown` component configured for contact type selection.
 */
export const RelationshipDropdown = ({
  selectedItem,
  onSelect,
  error,
  required = true,
  style,
  cypressIdentifier = 'relationship-select',
}: RelationshipDropdownProps) => {
  const formattedSelectedItem = selectedItem
    ? {
        code: getCode(selectedItem),
        display: getDisplay(selectedItem),
      }
    : undefined;

  return (
    <ValueSetDropdown
      valueSet={ContactRelationship}
      label="Relationship to patient"
      placeholder="Please select"
      selectedItem={formattedSelectedItem}
      onSelect={onSelect}
      required={required}
      cypressIdentifier={cypressIdentifier}
      error={error}
      style={style}
    />
  );
};
