import { TouchpointFragment } from 'medplum-gql';

/**
 * Returns the first role associated with a given touchpoint's sender
 *
 * @param touchpoint - The touchpoint to extract the practitioner role from
 * @returns the first practitioner role or null if not found
 */
export const getPractitionerRoleFromTouchpoint = (touchpoint: TouchpointFragment): string | null => {
  const sender = touchpoint.sender;
  if (!sender || sender?.resource?.__typename !== 'Practitioner') {
    return null;
  }

  const practitionerRole = sender.resource.PractitionerRoleList?.[0]?.code?.[0]?.coding?.[0]?.display || null;

  return practitionerRole;
};
