import { useState, useEffect } from 'react';

type MarketData = {
  name?: string;
  id?: string;
  status?: string;
  features?: string[];
  athenaId?: number;
  prapare?: boolean;
  acuteCare?: boolean;
};

export const useMarketForm = (marketData?: MarketData) => {
  const dynamicFeatures = [
    ...(marketData?.acuteCare === true ? ['Acute Care'] : []),
    ...(marketData?.prapare === true ? ['PRAPARE'] : []),
  ];

  const [formData, setFormData] = useState({
    name: marketData?.name || '',
    id: marketData?.id || '',
    status: marketData?.status || 'inactive',
    features: dynamicFeatures || [],
    athenaId: marketData?.athenaId || 0,
  });

  useEffect(() => {
    if (marketData) {
      setFormData((prevData) => ({
        ...prevData,
        name: marketData.name || '',
        id: marketData.id || '',
        status: marketData.status || 'inactive',
        features: [...(marketData.acuteCare ? ['acute'] : []), ...(marketData.prapare ? ['prapare'] : [])],
        athenaId: marketData.athenaId || 0,
      }));
    }
  }, [marketData]);

  const handleInputChange = (field: string, value: any) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  return { formData, handleInputChange };
};
