import { useMedplum } from '@medplum/react';
import { OrganizationType } from 'const-utils/codeSystems/ImaginePediatrics';
import { useCallback, useEffect, useState } from 'react';
import { notifications } from '@mantine/notifications';
import { fetchOptions } from 'imagine-dsl/services/organizationService';

export const useHealthPlanOptions = () => {
  const medplum = useMedplum();
  const [identifiers, setIdentifiers] = useState<string[]>([]);

  const fetchHealthPlanOptions = useCallback(() => {
    fetchOptions(medplum, OrganizationType.HealthPlan)
      .then((identifiers) => {
        setIdentifiers(identifiers.map((id) => id.identifier));
      })
      .catch((err) => {
        notifications.show({
          title: 'Error',
          message: err.message,
          color: 'status-error',
        });
      });
  }, [medplum]);

  useEffect(() => {
    fetchHealthPlanOptions();
  }, [fetchHealthPlanOptions]);
  return { identifiers };
};
