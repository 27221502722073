import React from 'react';
import { Box, Group, Text, Stack, Divider } from '@mantine/core';
import { ContactPoint } from '@medplum/fhirtypes';
import { IconMail } from '@tabler/icons-react';

interface EmailListProps {
  emails: ContactPoint[];
}

export function EmailList({ emails }: EmailListProps) {
  return (
    <Stack>
      {emails.map((email, index) => {
        const isLastItem = index === emails.length - 1;
        return (
          <Box key={email.value}>
            <Group wrap="nowrap" align="flex-start">
              <IconMail size={16} style={{ flexShrink: 0 }} />
              <Box>
                <Text size="sm" style={{ wordBreak: 'break-all' }}>
                  {email.value}
                </Text>
              </Box>
            </Group>
            {!isLastItem && <Divider my="sm" color="var(--mantine-color-gray-2)" />}
          </Box>
        );
      })}
    </Stack>
  );
}
