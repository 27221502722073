import { useCallback, useMemo, useState } from 'react';
import { AthenaProviderIdEntry } from 'imagine-dsl/services/practitionerService';
import { MarketOption } from '@/components/users/types';

export interface MarketData {
  id: string;
  name: string;
  marketIdentifier?: string;
}

export const useAthenaProviderIds = (initialEntries: AthenaProviderIdEntry[] = []) => {
  const [athenaProviderIdEntries, setAthenaProviderIdEntries] = useState<AthenaProviderIdEntry[]>(initialEntries);

  const addAthenaIdEntry = useCallback(() => {
    const newEntry: AthenaProviderIdEntry = {
      id: crypto.randomUUID(),
      market: '',
      providerId: '',
    };
    setAthenaProviderIdEntries((prev) => [...prev, newEntry]);
  }, []);

  const removeAthenaIdEntry = useCallback((id: string) => {
    setAthenaProviderIdEntries((prev) => prev.filter((entry) => entry.id !== id));
  }, []);

  const getValidEntries = useCallback(() => {
    return athenaProviderIdEntries.filter((entry) => entry.market);
  }, [athenaProviderIdEntries]);

  const handleMarketChange = useCallback(
    (entryId: string, value: string, entries = athenaProviderIdEntries, setEntries = setAthenaProviderIdEntries) => {
      if (value) {
        const updatedEntries = entries.map((e) => (e.id === entryId ? { ...e, market: value } : e));
        setEntries(updatedEntries);
      }
    },
    [],
  );

  const handleProviderIdChange = useCallback(
    (entryId: string, value: string, entries = athenaProviderIdEntries, setEntries = setAthenaProviderIdEntries) => {
      const updatedEntries = entries.map((en) => (en.id === entryId ? { ...en, providerId: value } : en));
      setEntries(updatedEntries);
    },
    [],
  );

  const handleExistingProviderIdChange = useCallback(
    (
      marketIdentifier: string,
      value: string,
      marketOptions: MarketOption[],
      entries = athenaProviderIdEntries,
      setEntries = setAthenaProviderIdEntries,
    ) => {
      const marketOption = marketOptions.find((option) => option.marketIdentifier === marketIdentifier);
      if (!marketOption) return;

      const existingEntryIndex = entries.findIndex(
        (entry) => entry.market === marketOption.value && entry.id.startsWith('existing-'),
      );

      if (existingEntryIndex >= 0) {
        setEntries((prev) => {
          const updated = [...prev];
          updated[existingEntryIndex].providerId = value;
          return updated;
        });
      } else if (value.trim() !== '') {
        const newEntry = {
          id: `existing-${marketIdentifier}`,
          market: marketOption.value,
          providerId: value,
        };
        setEntries((prev) => [...prev, newEntry]);
      }
    },
    [],
  );

  const getUsedMarkets = useCallback(
    (athenaMarketIds: Record<string, string>, marketOptions: MarketOption[]) => {
      const existingMarkets = Object.keys(athenaMarketIds)
        .filter(
          (marketIdentifier) => athenaMarketIds[marketIdentifier] && athenaMarketIds[marketIdentifier].trim() !== '',
        )
        .map((marketIdentifier) => {
          const marketOption = marketOptions.find((option) => option.marketIdentifier === marketIdentifier);
          return marketOption?.value || '';
        });

      const newMarkets = athenaProviderIdEntries
        .filter((entry) => !entry.id.startsWith('existing-') && entry.market && entry.providerId)
        .map((entry) => entry.market);

      return [...existingMarkets, ...newMarkets].filter(Boolean);
    },
    [athenaProviderIdEntries],
  );

  const getProviderIdForMarket = useCallback(
    (marketIdentifier: string, marketOptions: MarketOption[]) => {
      const marketOption = marketOptions.find((option) => option.marketIdentifier === marketIdentifier);
      if (!marketOption) return '';

      const entry = athenaProviderIdEntries.find(
        (entry) => entry.market === marketOption.value && entry.id.startsWith('existing-'),
      );

      return entry ? entry.providerId : '';
    },
    [athenaProviderIdEntries],
  );

  const getMarketDisplayName = useCallback((marketIdentifier: string, marketOptions: MarketOption[]) => {
    const marketOption = marketOptions.find((option) => option.marketIdentifier === marketIdentifier);
    return marketOption?.label || marketIdentifier;
  }, []);

  const handleClearProviderId = useCallback(
    (
      marketIdentifier: string,
      marketOptions: MarketOption[],
      entries = athenaProviderIdEntries,
      setEntries = setAthenaProviderIdEntries,
    ) => {
      const marketOption = marketOptions.find((option) => option.marketIdentifier === marketIdentifier);
      if (!marketOption) return;

      const existingEntryIndex = entries.findIndex(
        (entry) => entry.market === marketOption.value && entry.id.startsWith('existing-'),
      );

      if (existingEntryIndex >= 0) {
        setEntries((prev) => {
          const updated = [...prev];
          updated[existingEntryIndex].providerId = '';
          return updated;
        });
      }
    },
    [],
  );

  const initializeFromAthenaMarketIds = useCallback(
    (athenaMarketIds: Record<string, string>, markets: MarketData[]) => {
      if (Object.keys(athenaMarketIds).length === 0 || athenaProviderIdEntries.length > 0) {
        return;
      }

      const existingEntries = Object.entries(athenaMarketIds)
        .filter(([_, providerId]) => providerId)
        .map(([marketIdentifier, providerId]) => {
          const market = markets.find((m) => m.marketIdentifier === marketIdentifier);
          return {
            id: `existing-${marketIdentifier}`,
            market: market?.name || '',
            providerId,
          };
        })
        .filter((entry) => entry.market);

      if (existingEntries.length > 0) {
        setAthenaProviderIdEntries(existingEntries);
      }
    },
    [athenaProviderIdEntries.length],
  );

  const newEntries = useMemo(
    () => athenaProviderIdEntries.filter((entry) => !entry.id.startsWith('existing-')),
    [athenaProviderIdEntries],
  );

  const existingEntries = useMemo(
    () => athenaProviderIdEntries.filter((entry) => entry.id.startsWith('existing-')),
    [athenaProviderIdEntries],
  );

  return {
    athenaProviderIdEntries,
    setAthenaProviderIdEntries,
    addAthenaIdEntry,
    removeAthenaIdEntry,
    getValidEntries,
    handleMarketChange,
    handleProviderIdChange,
    handleExistingProviderIdChange,
    getUsedMarkets,
    getProviderIdForMarket,
    getMarketDisplayName,
    handleClearProviderId,
    newEntries,
    existingEntries,
    initializeFromAthenaMarketIds,
  };
};

export default useAthenaProviderIds;
