import React, { useState } from 'react';
import { Attachment } from '@medplum/fhirtypes';
import { Button, Modal, Stack, Group, Text, TextInput, ThemeIcon, Select } from '@mantine/core';
import { AttachmentDisplay } from '@medplum/react';
import { IconCloudUpload } from '@tabler/icons-react';
import { showNotification } from '@mantine/notifications';
import {
  ConsentCode,
  ConsentResponse,
  ConsentResponses,
  QuestionnaireType,
} from 'const-utils/codeSystems/ImaginePediatrics';

interface AttachmentModalProps {
  modalTitle: string;
  pendingAttachment?: Attachment;
  setConsentType: (value: QuestionnaireType | undefined | null) => void;
  setConsentResponse: (value: ConsentResponse | undefined) => void;
  setPendingAttachment: (value: Attachment | undefined) => void;
  onAttachDocument: (value: Attachment | undefined) => void;
}

export function ManualConsentUploadModal({
  modalTitle,
  pendingAttachment,
  setConsentType,
  setConsentResponse,
  setPendingAttachment,
  onAttachDocument,
}: AttachmentModalProps): JSX.Element {
  const [type, setType] = useState<string | undefined | null>(undefined);
  const [response, setResponse] = useState<string | undefined | null>(undefined);

  const consentOptions = Object.entries(ConsentCode)
    .map(([key, value]) => ({
      label: value,
      value: key,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const responseOptions = Object.entries(ConsentResponses).map(([key, value]) => ({
    label: value,
    value: key,
  }));

  const clickHandler = () => {
    if (!type) {
      showNotification({
        color: 'status-error',
        title: 'Error',
        message: 'Please select a consent type',
      });
      return;
    }

    if (type === QuestionnaireType.HixnyConsent.toString() && !response) {
      showNotification({
        color: 'status-error',
        title: 'Error',
        message: 'Please select a response value of accept or deny',
      });
      return;
    }
    onAttachDocument(pendingAttachment);
    setPendingAttachment(undefined);
  };

  return (
    <Modal.Root
      size="xl"
      padding="xl"
      radius="xl"
      opened={!!pendingAttachment}
      onClose={() => {
        setPendingAttachment(undefined);
      }}
    >
      <Modal.Overlay />
      <Modal.Content style={{ borderRadius: 20 }}>
        <Modal.Header>
          <Modal.Title c="imagine-green" style={{ fontSize: '20px' }}>
            {modalTitle}
          </Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <Stack>
            <Group>
              <AttachmentDisplay value={pendingAttachment as Attachment} maxWidth={200} />

              <Stack flex="1" h={300}>
                <Text>Name</Text>
                <TextInput
                  mt={-15}
                  width="auto"
                  defaultValue={pendingAttachment?.title}
                  onChange={(event) => {
                    setPendingAttachment({
                      ...pendingAttachment,
                      title: event.currentTarget.value,
                    });
                  }}
                />
                <Select
                  label="Consent Type"
                  required
                  onChange={(value) => {
                    setType(value);
                    setConsentType(value as QuestionnaireType);
                  }}
                  data={consentOptions}
                />
                {type === QuestionnaireType.HixnyConsent.toString() ? (
                  <Select
                    label="Set response value (accept/deny)"
                    required
                    onChange={(value) => {
                      setResponse(value);
                      setConsentResponse(value as ConsentResponse);
                    }}
                    data={responseOptions}
                  />
                ) : null}
              </Stack>
            </Group>
            <Group justify="flex-end">
              <Button
                variant="outline"
                onClick={() => {
                  setPendingAttachment(undefined);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={clickHandler}
                rightSection={
                  <ThemeIcon variant="transparent">
                    <IconCloudUpload size={20} />
                  </ThemeIcon>
                }
              >
                Upload document
              </Button>
            </Group>
          </Stack>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
}
