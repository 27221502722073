import {
  alertTypeMapping,
  bhTOCTaskProgresses,
  bhTOCTaskCaseOutcomes,
} from 'const-utils/codeSystems/ImaginePediatrics';
import {
  Maybe,
  CommunicationRequestFragment,
  PatientFromCommunicationRequestFragment,
  EncounterFromCommunicationRequestFragment,
  Practitioner,
} from 'medplum-gql';
import { parseISO } from 'date-fns';
import { extractPod } from '../../utils/careTeam';
import { isDefined } from '../../utils/lists';
import { getProgramStatus } from '../../utils/patient';
import { System } from 'const-utils/codeSystems/system';

export class CommunicationRequest {
  constructor(public request: Maybe<CommunicationRequestFragment>) {
    this.request = request;
  }

  isValid(): boolean {
    return !!this.patient && !!this.encounter;
  }

  get id(): string | undefined {
    return this.request?.id ?? undefined;
  }

  get patient(): Maybe<PatientFromCommunicationRequestFragment> {
    if (!this.request?.subject?.resource || this.request.subject.resource.__typename !== 'Patient') {
      return null;
    }
    return this.request.subject.resource;
  }

  get market(): string | undefined {
    if (
      !this.patient ||
      !this.patient.managingOrganization?.resource ||
      this.patient.managingOrganization.resource.__typename !== 'Organization'
    ) {
      return undefined;
    }
    return this.patient.managingOrganization.resource.name || undefined;
  }

  get encounter(): Maybe<EncounterFromCommunicationRequestFragment> {
    if (!this.request?.encounter?.resource || this.request.encounter.resource.__typename !== 'Encounter') {
      return null;
    }
    return this.request.encounter.resource;
  }

  get assignee(): Practitioner | null {
    if (!this.request?.sender?.resource || this.request.sender.resource.__typename !== 'Practitioner') {
      return null;
    }
    return this.request.sender.resource as Practitioner;
  }

  get status(): string | undefined {
    return this.request?.status ?? undefined;
  }

  get pod() {
    if (!this.patient) {
      return undefined;
    }
    return extractPod(this.patient.CareTeamList?.filter(isDefined) || []);
  }

  get programStatus(): string | undefined {
    if (!this.patient) {
      return undefined;
    }
    const status = getProgramStatus(this.patient);
    return status === null ? undefined : status;
  }

  get caseOutcome(): string | undefined {
    const statusCode = this.request?.statusReason?.coding?.find(
      (coding) => coding.system === System.BHTOCTaskCaseOutcome,
    )?.code;
    if (!statusCode) {
      return undefined;
    }
    const foundStatus = Object.entries(bhTOCTaskCaseOutcomes).find(([key]) => key === statusCode);

    return foundStatus ? foundStatus[1] : undefined;
  }

  get outreachStatusCode(): string | undefined {
    const code = this.request?.statusReason?.coding?.find((coding) => coding.system === System.BHTOCTaskProgress)?.code;
    return code || undefined;
  }

  get outreachStatus(): string | undefined {
    const statusCode = this.outreachStatusCode;
    if (!statusCode) {
      return undefined;
    }

    const foundStatus = Object.entries(bhTOCTaskProgresses).find(([key]) => key === statusCode);

    return foundStatus ? foundStatus[1] : undefined;
  }

  get alertType(): string | undefined {
    if (!this.encounter) {
      return undefined;
    }

    const classCode = this.encounter.class?.code;

    if (!classCode) {
      return undefined;
    }

    return alertTypeMapping[classCode];
  }

  get dischargeDate(): Date | undefined {
    if (!this.encounter?.period?.end) {
      return undefined;
    }
    return parseISO(`${this.encounter.period.end}T23:00:00Z`);
  }
}
