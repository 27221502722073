import { Badge, Box, Group, Text, ThemeIcon, Tooltip } from '@mantine/core';
import {
  IconFirstAidKit,
  IconHeart,
  IconHeartExclamation,
  IconHourglassLow,
  IconPlant,
  IconDeviceUnknown,
} from '@tabler/icons-react';
import { CarePathway } from 'const-utils/codeSystems/ImaginePediatrics';
import React from 'react';

const carePathwayLookup: Record<
  CarePathway | 'Unknown',
  {
    carePathwayDisplay: string;
    icon?: JSX.Element;
    background: string;
    color: string;
  }
> = {
  CC: {
    carePathwayDisplay: 'Core Care',
    icon: (
      <ThemeIcon c="brand-secondary.8" size={16}>
        <IconHeart size={16} />
      </ThemeIcon>
    ),
    background: 'imagine-green.0',
    color: 'brand-secondary.8',
  },
  M: {
    carePathwayDisplay: 'Medical',
    icon: (
      <ThemeIcon size={16} c="status-warn.9">
        <IconFirstAidKit size={16} />
      </ThemeIcon>
    ),
    background: 'status-warn.0',
    color: 'status-warn.9',
  },
  BH: {
    carePathwayDisplay: 'Behavioral',
    icon: (
      <ThemeIcon size={16} c="brand-blue.9">
        <IconPlant size={16} />
      </ThemeIcon>
    ),
    background: 'brand-blue.0',
    color: 'brand-blue.9',
  },
  AC: {
    carePathwayDisplay: 'Acute Care Only',
    icon: (
      <ThemeIcon size={16} c="brand-secondary.8">
        <IconHeartExclamation size={16} />
      </ThemeIcon>
    ),
    background: 'imagine-green.0',
    color: 'brand-secondary.8',
  },
  Unknown: {
    carePathwayDisplay: 'Unknown',
    icon: (
      <ThemeIcon size={16} c="brand-gray.8">
        <IconDeviceUnknown size={16} />
      </ThemeIcon>
    ),
    background: 'brand-gray.2',
    color: 'brand-gray.8',
  },
};

interface ClusterBadgeProps {
  cluster: string;
  isOpenCarePathwayReferralReview?: boolean;
  hideSubtext?: boolean;
  carePathway?: string;
  condensedDisplay?: boolean;
  iconOnly?: boolean;
}

export const ClusterBadge = ({
  cluster,
  isOpenCarePathwayReferralReview,
  hideSubtext,
  carePathway,
  condensedDisplay,
  iconOnly,
}: ClusterBadgeProps): JSX.Element => {
  const [, ...rest] = cluster.split(':');
  const carePathwayCode = carePathway?.split(':')[0];
  const clusterOption =
    cluster === 'Unknown' ? carePathwayLookup[cluster] : carePathwayLookup[carePathwayCode as CarePathway];

  if (!clusterOption) {
    return <></>;
  }

  const Icon = clusterOption.icon;

  return (
    <Group gap="xs">
      <Badge
        leftSection={iconOnly ? undefined : Icon}
        c={clusterOption.color}
        bg={clusterOption.background}
        variant="light"
        p={condensedDisplay ? '0' : undefined}
        circle={!!iconOnly}
      >
        {iconOnly && <Box mt={6}>{Icon}</Box>}
        {!condensedDisplay && (
          <Group gap="xs">
            {clusterOption.carePathwayDisplay}
            {isOpenCarePathwayReferralReview && (
              <Tooltip label="Care pathway referral has been requested" arrowOffset={50} arrowSize={8} withArrow>
                <IconHourglassLow size={16} />
              </Tooltip>
            )}
          </Group>
        )}
      </Badge>
      {condensedDisplay && <Text c="dimmed">{clusterOption.carePathwayDisplay}</Text>}
      {!hideSubtext && (
        <Text size="sm" c="gray">
          {rest.join('')}
        </Text>
      )}
    </Group>
  );
};
