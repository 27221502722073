import React, { useMemo, useState } from 'react';
import { Button, Checkbox, Group, Paper, Stack, Table, Text } from '@mantine/core';
import { useGetPanelCommunicationRequestsQuery } from 'medplum-gql';
import { Pagination } from '@/design-system/Pagination';
import { System } from 'const-utils';
import { CommunicationRequestType, TaskType } from 'const-utils/codeSystems/ImaginePediatrics';
import { usePagination } from '@/design-system/hooks/usePagination';
import { PageSkeleton } from '@/design-system/PageSkeleton';
import { useSearchParams } from 'react-router-dom';
import { TOCPanelRow } from '../TOCPanelRow';
import { IconCircleCheck, IconUserPlus } from '@tabler/icons-react';
import { CommunicationRequest } from 'imagine-dsl/models/communications/communicationRequest';
import { ClinicalPodFilter } from './ClinicalPodFilter';
import { AlertTypeFilter } from './AlertTypeFilter';
import { filterAndSortCommunicationRequestModels } from 'imagine-dsl/utils/communicationRequest';
import classes from './BHTocPanel.module.css';
import BulkTaskCreationModal from '@/components/shared/BulkTaskCreationModal';
import { useDisclosure } from '@mantine/hooks';

export const BHTocPanel = (): JSX.Element => {
  const PAGE_SIZE = 20;
  const [searchParams] = useSearchParams();
  const { currentPage, changePage } = usePagination({});
  const alertStatus = searchParams.get('alertStatus');
  const showResolved = alertStatus === 'resolved';
  const selectedPodIds = searchParams.get('pod')?.split(',') ?? [];
  const selectedAlertType = searchParams.get('alertType');
  const [selectedRequestIds, setSelectedRequestIds] = useState<string[]>([]);
  const [bulkTaskModalOpened, handleBulkTaskModal] = useDisclosure();

  const {
    data: bhTOCRequests,
    loading,
    refetch,
  } = useGetPanelCommunicationRequestsQuery({
    variables: {
      identifier: `${System.CommunicationRequestType}|${CommunicationRequestType.BHTOC}`,
      filter: showResolved ? 'status eq completed' : 'status ne completed',
    },
  });

  const communicationRequests = bhTOCRequests?.CommunicationRequestList?.map(
    (request) => new CommunicationRequest(request),
  );

  const filteredItems = useMemo(() => {
    return (
      filterAndSortCommunicationRequestModels(communicationRequests, {
        selectedPodIds,
        selectedAlertType,
      }) || []
    );
  }, [communicationRequests, selectedPodIds, selectedAlertType]);

  // Client side paging
  const totalItems = filteredItems.length ?? 0;
  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex = startIndex + PAGE_SIZE;
  const paginatedItems = filteredItems?.slice(startIndex, endIndex) ?? [];

  // Go to first page of results when filtered down to only fit on one page
  if (totalItems < PAGE_SIZE && currentPage > 1) {
    changePage(1);
  }

  const handleOnRequestSelected = (isSelected: boolean, selectedRequestId?: string) => {
    if (isSelected && selectedRequestId && !selectedRequestIds.includes(selectedRequestId)) {
      setSelectedRequestIds([...selectedRequestIds, selectedRequestId]);
      return;
    }
    if (!isSelected && selectedRequestId && selectedRequestIds.includes(selectedRequestId)) {
      setSelectedRequestIds(selectedRequestIds.filter((id) => id !== selectedRequestId));
    }
  };

  const onSelectAllChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.currentTarget.checked;
    if (isChecked) {
      const allIds = paginatedItems.map((request) => request.id!);
      setSelectedRequestIds(allIds);
    } else {
      setSelectedRequestIds([]);
    }
  };

  const handleBulkTaskModalOpen = async () => {
    handleBulkTaskModal.open();
  };
  const handleBulkTaskModalClose = async () => {
    handleBulkTaskModal.close();
  };

  const taskTypes = [
    {
      label: 'Schedule Appointment BH TOC',
      value: TaskType.ScheduleAppointmentBHTOC,
    },
  ];

  const communicationRequestPatientIdMapping = useMemo(() => {
    const mapping: Record<string, string> = {};
    for (const id of selectedRequestIds) {
      const request = filteredItems.find((item) => item.id === id);
      if (request?.patient?.id) {
        mapping[request?.patient?.id] = id;
      }
    }
    return mapping;
  }, [selectedRequestIds, filteredItems]);

  const selectedPatientIds = useMemo(() => {
    const patientIds: string[] = [];
    for (const id of selectedRequestIds) {
      const request = filteredItems.find((item) => item.id === id);
      if (request?.patient?.id) {
        patientIds.push(request?.patient?.id);
      }
    }
    return patientIds;
  }, [selectedRequestIds, filteredItems]);

  const handleOnBulkTaskCreationSuccess = () => {
    refetch();
    setSelectedRequestIds([]);
  };

  const handleOnTaskSaved = () => {
    refetch();
  };

  return (
    <>
      <PageSkeleton withPaper={false}>
        <Group mb={10}>
          <ClinicalPodFilter />
          <AlertTypeFilter />
        </Group>
        <Paper mb={30}>
          <Table verticalSpacing="sm" horizontalSpacing="sm" withRowBorders={false}>
            <Table.Thead>
              <Table.Tr className={classes.tableHeader}>
                <Table.Th>
                  <Checkbox onChange={onSelectAllChanged} />
                </Table.Th>
                <Table.Th>Patient</Table.Th>
                <Table.Th>Pod</Table.Th>
                <Table.Th>Alert type</Table.Th>
                <Table.Th>{showResolved ? 'Case Outcome' : 'TOC task progress'}</Table.Th>
                <Table.Th>{showResolved ? 'Resolved By' : 'Assigned To'}</Table.Th>
                <Table.Th>Discharge Date</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {!loading &&
                (paginatedItems.length > 0 ? (
                  paginatedItems.map((request) => (
                    <TOCPanelRow
                      request={request}
                      resolved={showResolved}
                      key={request?.id}
                      onSelectedChanged={handleOnRequestSelected}
                      isSelected={selectedRequestIds.includes(request.id!)}
                      onTaskSaved={handleOnTaskSaved}
                    />
                  ))
                ) : (
                  <Table.Tr>
                    <Table.Td colSpan={7}>
                      <Stack align="center" m={15}>
                        <IconCircleCheck size={50} color="#BEBEBE" />
                        <Text fw={700} size="lg">
                          {showResolved
                            ? 'There are no resolved BH TOC alerts.'
                            : 'There are no open BH TOC alerts. Please check back later.'}
                        </Text>
                      </Stack>
                    </Table.Td>
                  </Table.Tr>
                ))}
            </Table.Tbody>
          </Table>
          <Group
            justify="space-between"
            pl={24}
            pr={24}
            pt={16}
            pb={16}
            className={`${classes.footer} table-sticky-footer`}
            bg="white"
          >
            <Button
              variant="outline"
              leftSection={<IconUserPlus size={16} />}
              onClick={handleBulkTaskModalOpen}
              disabled={!selectedRequestIds.length}
            >
              Create Task
            </Button>
            <Pagination count={totalItems} perPage={PAGE_SIZE} addTopMargin={false} />
          </Group>
        </Paper>
        {bulkTaskModalOpened && selectedRequestIds.length && (
          <BulkTaskCreationModal
            opened={bulkTaskModalOpened}
            closeModal={handleBulkTaskModalClose}
            onCreationSuccess={handleOnBulkTaskCreationSuccess}
            patientIds={selectedPatientIds}
            taskTypes={taskTypes}
            communicationRequestPatientIdMapping={communicationRequestPatientIdMapping}
          />
        )}
      </PageSkeleton>
    </>
  );
};

export default BHTocPanel;
