import { TaskType } from 'const-utils';
import { BaseTaskWrapper } from './baseTask';
import { Maybe, PractitionerInfoFragment } from 'medplum-gql';
import { Task } from '@medplum/fhirtypes';

export type OutreachTaskType =
  | TaskType.AppointmentRequest
  | TaskType.Callback
  | TaskType.CallPartneringProvider
  | TaskType.FirstCall
  | TaskType.InformationRequest
  | TaskType.InformationRequestVerification
  | TaskType.MedicalTOCOutreach
  | TaskType.NetworkEngagement
  | TaskType.NoShowFollowUp
  | TaskType.OutreachFollowUp
  | TaskType.ReEngagement
  | TaskType.ServiceRecovery;

export class OutreachTask extends BaseTaskWrapper<OutreachTaskType> {
  get taskType(): { code: string; display: string } {
    return {
      code: this.task.code?.coding?.[0].code ?? 'Unknown',
      display: this.task.code?.coding?.[0].display ?? 'Unknown',
    };
  }

  get priority(): string {
    return (this.task.businessStatus as Task['businessStatus'])?.coding?.[0].code ?? 'Unknown';
  }

  get owner(): Maybe<PractitionerInfoFragment> {
    if (this.task.owner?.resource?.__typename === 'Practitioner') {
      return this.task.owner.resource;
    }
    return null;
  }

  get requestor(): Maybe<PractitionerInfoFragment> {
    if (this.task.requester?.resource?.__typename === 'Practitioner') {
      return this.task.requester.resource;
    }
    return null;
  }
  get createdDate(): string | undefined {
    return this.task.authoredOn ?? undefined;
  }

  get editor(): string {
    const author = this.task.meta?.author?.display ?? 'Unknown';
    return author.includes('outreach-touchpoint-bot') ? 'Automated Task Creation' : author;
  }

  get updatedDate(): string | undefined {
    return this.task.lastModified ?? undefined;
  }

  get notes(): string {
    return this.task.note?.[0].text ?? '';
  }

  get hasBeenEdited(): boolean {
    return this.updatedDate !== this.createdDate;
  }
}
