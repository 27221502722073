// Domain specific validation rules, helpers, and messages for the patient contact form
import { ContactRelationship, ContactType } from 'const-utils';
import { Coding } from 'medplum-gql';

export const validationMessages = {
  required: {
    firstName: 'First name is required',
    lastName: 'Last name is required',
    contactType: 'Contact type is required',
    relationship: 'Relationship is required',
    language: 'Language is required',
    phone: 'Phone number is required',
    address: {
      line1: 'Address line 1 is required',
      city: 'City is required',
      state: 'State is required',
      postalCode: 'Zip code is required',
    },
  },
  invalid: {
    email: 'Invalid email',
    phone: 'Invalid phone number',
    duplicatePhone: 'Duplicate phone number',
    contactType: 'This Contact Type is no longer available. Please make another selection',
    relationship: 'This Relationship to Patient is no longer available. Please make another selection',
  },
};

export function validateContactType(value: string): string | null {
  if (!value) return validationMessages.required.contactType;

  const supportedContactTypes = ContactType.compose?.include?.flatMap((inc) => inc.concept || []) || [];

  if (!supportedContactTypes.some((item) => item.display === value)) {
    return validationMessages.invalid.contactType;
  }
  return null;
}

export function validateRelationship(value: Coding | undefined): string | null {
  if (!value) return validationMessages.required.relationship;

  const supportedRelationships = ContactRelationship.compose?.include?.flatMap((inc) => inc.concept || []) || [];

  if (!supportedRelationships.some((item) => item.code === value?.code)) {
    return validationMessages.invalid.relationship;
  }
  return null;
}
