import React from 'react';
import { Group, Pagination as MantinePagination, rem, Text } from '@mantine/core';
import { usePagination } from './hooks/usePagination';

interface PaginationProps {
  activePage?: number;
  changePage?: (page: number) => void;
  perPage?: number;
  count: number;
  addTopMargin?: boolean;
}

export const Pagination = ({
  activePage,
  changePage,
  count,
  perPage = 20,
  addTopMargin = true,
}: PaginationProps): JSX.Element => {
  const { currentPage, pageBounds, getTotalPages, ...pagination } = usePagination({
    initialPage: activePage,
    changePage,
    perPage,
  });

  const allVisible = count <= perPage;

  return (
    <MantinePagination.Root
      color="imagine-green"
      radius="lg"
      size="sm"
      value={currentPage}
      onChange={pagination.changePage}
      total={getTotalPages(count || 0, perPage)}
    >
      <Group mt={addTopMargin ? rem('28px') : 0} justify="right" align="center">
        {allVisible && <Text c={'dimmed'}>Showing all {count} results</Text>}
        {!allVisible && (
          <Text c={'dimmed'}>
            Showing{' '}
            <b>
              {pageBounds.at(0)}-{pageBounds.at(1)}
            </b>{' '}
            of {count} results
          </Text>
        )}
        <MantinePagination.First />
        <MantinePagination.Previous />
        <MantinePagination.Items />
        <MantinePagination.Next />
        <MantinePagination.Last />
      </Group>
    </MantinePagination.Root>
  );
};
