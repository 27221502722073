import React, { Dispatch, SetStateAction, useState } from 'react';
import { Divider, Group, LoadingOverlay, Stack, Text, ThemeIcon, UnstyledButton } from '@mantine/core';
import { Attachment, Communication, DocumentReference } from '@medplum/fhirtypes';
import { getSentByImaginePediatrics } from 'imagine-dsl/utils/communication';
import { AttachmentDisplay } from '@/components/chat/AttachmentDisplay';
import { formatWithYesterdayAndToday } from 'imagine-dsl/utils/dates';
import { HL7System } from 'const-utils';
import { IconCircle } from '@tabler/icons-react';
import { useTranslateChatTextMutation } from 'imagine-gql/client';
import { imagineClient } from '@/hooks/useImagineApolloClient';
import { getErrorMessage } from '@/errors';
import { notifications } from '@mantine/notifications';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';

interface ChatBubbleProps {
  message: Communication;
}

function PractitionerBubble({
  contentString,
  translationSourceString,
}: { contentString: string; translationSourceString?: string }): JSX.Element {
  const [isOriginalVisible, setIsOriginalVisible] = useState(false);
  const isTranslated = !!translationSourceString;

  return (
    <Stack
      w="fit-content"
      p="sm"
      style={{
        borderRadius: '12px',
        borderTopRightRadius: 0,
        whiteSpace: 'pre-line',
        maxWidth: '100%',
        wordBreak: 'break-word',
        textAlign: 'left',
      }}
      bg="primary-50.1"
      gap="xs"
      my={4}
    >
      <Text>{contentString}</Text>

      {isOriginalVisible && (
        <>
          <Divider color="brand-gray.4" />
          <Text>{translationSourceString}</Text>
        </>
      )}

      {isTranslated && (
        <Group gap={0} justify="flex-end">
          <Text>Translated</Text>
          <ThemeIcon>
            <IconCircle fill="black" size={6} color="black" />
          </ThemeIcon>

          <UnstyledButton
            onClick={() => setIsOriginalVisible(!isOriginalVisible)}
            c="imagine-green.6"
            td={'underline'}
            fw={700}
          >
            {isOriginalVisible ? 'Hide Original' : 'Show Original'}
          </UnstyledButton>
        </Group>
      )}
    </Stack>
  );
}

interface CaregiverBubbleProps {
  contentString: string;
  translatedContentString: string;
  isTranslationLoading: boolean;
  isTranslationVisible: boolean;
  setIsTranslationVisible: Dispatch<SetStateAction<boolean>>;
}

function CaregiverBubble({
  contentString,
  translatedContentString,
  isTranslationLoading,
  isTranslationVisible,
  setIsTranslationVisible,
}: CaregiverBubbleProps): JSX.Element {
  const isTranslated = !!translatedContentString;

  return (
    <Stack
      w="fit-content"
      p="sm"
      style={{
        borderRadius: '12px',
        borderTopLeftRadius: 0,
        whiteSpace: 'pre-line',
        maxWidth: '100%',
        wordBreak: 'break-word',
      }}
      bg="var(--mantine-color-imagine-gray-0)"
      my={4}
      gap="xs"
      pos="relative"
    >
      <Text>{isTranslationVisible ? translatedContentString : contentString}</Text>

      {isTranslated && (
        <Group gap={0} justify="flex-end">
          <>
            <Text>Translated</Text>
            <ThemeIcon w="fit-content" miw="fit-content" mx={4}>
              <IconCircle fill="black" size={6} color="black" />
            </ThemeIcon>
          </>

          <UnstyledButton
            onClick={() => {
              setIsTranslationVisible(!isTranslationVisible);
            }}
            c="imagine-green.6"
            td={'underline'}
            fw={700}
          >
            {isTranslationVisible ? 'Show Original' : 'Show Translation'}
          </UnstyledButton>
        </Group>
      )}

      <LoadingOverlay visible={isTranslationLoading} loaderProps={{ type: 'dots', size: 'md' }} />
    </Stack>
  );
}

export function ChatBubble({ message }: ChatBubbleProps): JSX.Element {
  const { ChatCaregiverMessageTranslations: isCaregiverTranslationEnabled } = useFeatureFlags();
  const [isCaregiverTranslationLoading, setIsCaregiverTranslationLoading] = useState(false);
  const [isCaregiverTranslationVisible, setIsCaregiverTranslationVisible] = useState(false);
  const [translatedCaregiverContentString, setTranslatedCaregiverContentString] = useState('');

  const hasNotTranslatedCaregiver = translatedCaregiverContentString === '';

  const contentString = message.payload?.find((p) => p.contentString)?.contentString;
  const contentAttachments = message.payload?.filter((p) => p.contentAttachment).map((p) => p.contentAttachment) || [];
  const contentReferences = message.payload?.filter((p) => p.contentReference).map((p) => p.contentReference) || [];

  const practitionerTranslationExtension = message.payload
    ?.find((p) => p.contentString)
    ?.extension?.find((e) => e.url === HL7System.Translation)?.extension;
  const practitionerTranslationSourceString = practitionerTranslationExtension?.find(
    (e) => e.url === 'content',
  )?.valueString;

  const isSentByPractitioner = getSentByImaginePediatrics(message);
  const isReadReceiptVisible = isSentByPractitioner && message.received;
  const isCaregiverTranslateButtonEnabled =
    isCaregiverTranslationEnabled && !isSentByPractitioner && hasNotTranslatedCaregiver;

  if (!contentString && contentAttachments.length === 0 && contentReferences.length === 0) {
    return <></>;
  }

  const [translate] = useTranslateChatTextMutation({
    client: imagineClient,
  });

  const fetchTranslatedText = async () => {
    if (!contentString) {
      return;
    }

    try {
      const data = await translate({
        variables: { text: contentString, sourceLanguage: 'auto', targetLanguage: 'en' },
      });

      setTranslatedCaregiverContentString(data.data?.translateText?.translatedText || '');
      setIsCaregiverTranslationVisible(true);
    } catch (err) {
      notifications.show({
        title: 'Error',
        message: getErrorMessage(err),
        color: 'status-error',
      });
    }

    setIsCaregiverTranslationLoading(false);
  };

  return (
    <Stack
      style={{
        gap: 0,
        alignItems: isSentByPractitioner ? 'end' : 'start',
      }}
      w={400}
    >
      {contentString && isSentByPractitioner && (
        <PractitionerBubble
          contentString={contentString}
          translationSourceString={practitionerTranslationSourceString}
        />
      )}

      {contentString && !isSentByPractitioner && (
        <CaregiverBubble
          contentString={contentString}
          translatedContentString={translatedCaregiverContentString}
          isTranslationLoading={isCaregiverTranslationLoading}
          isTranslationVisible={isCaregiverTranslationVisible}
          setIsTranslationVisible={setIsCaregiverTranslationVisible}
        />
      )}

      {contentAttachments.length > 0 &&
        contentAttachments.map((contentAttachment) => {
          const key = contentAttachment?.url?.split('?')[0];
          return (
            contentAttachment && <AttachmentDisplay key={key} value={contentAttachment as Attachment} maxWidth={200} />
          );
        })}

      {contentReferences.length > 0 &&
        contentReferences.map((contentReference) => {
          const documentReference: DocumentReference = contentReference?.resource as DocumentReference;
          const contentAttachment: Attachment = documentReference?.content?.[0].attachment;
          const key = contentAttachment?.url?.split('?')[0];
          return <AttachmentDisplay key={key} value={contentAttachment} maxWidth={200} />;
        })}
      <Group gap={0}>
        <Text size="xs" c="dimmed">
          {formatWithYesterdayAndToday(message.sent!, 'EEE, MMMM do, yyyy hh:mm aaa', true)}
        </Text>

        {isReadReceiptVisible && (
          <Text fw={600} size="xs" c="dimmed" ml="xs">
            Read
          </Text>
        )}

        {isCaregiverTranslateButtonEnabled && (
          <>
            <ThemeIcon w="fit-content" miw="fit-content" mx={4}>
              <IconCircle fill="black" size={6} color="black" />
            </ThemeIcon>

            <UnstyledButton
              onClick={() => {
                setIsCaregiverTranslationLoading(true);
                fetchTranslatedText();
              }}
              data-testid="translate-button"
              fz="xs"
              c="brand-gray.7"
              td={'underline'}
              fw={700}
            >
              Translate
            </UnstyledButton>
          </>
        )}
      </Group>
    </Stack>
  );
}
