export { FeatureFlag } from './flags';

export * from './bots';
export * from './utils/environment';

export { System } from './codeSystems/system';
export { HL7OrganizationType, HL7System, HL7ValueSet } from './codeSystems/HL7';

export {
  ImaginePediatricsCodeSystem,
  OutreachStatus,
  ProgramStatus,
  CallDisposition,
  CommunicationMedium,
  DocumentType,
  documentTypeMap,
  CommunicationCategory,
  CommunicationNoteType,
  CallRoute,
  SYSTEM_URL,
  Extensions,
  Attribution,
  TaskType,
  UserRole,
  UserRoleDisplay,
  ProfileType,
} from './codeSystems/ImaginePediatrics';
export { OutreachCallDisposition } from './valueSets/OutreachCallDisposition';
export { GenderIdentity } from './valueSets/GenderIdentity';
export { PreferredPronoun } from './valueSets/PreferredPronouns';
export { PreferredLanguage } from './valueSets/PreferredLanguage';
export { LivingSituation } from './valueSets/LivingSituation';
export { ContactType } from './valueSets/ContactType';
export { ContactRelationship } from './valueSets/ContactRelationship';
export { DisengagementReason } from './valueSets/DisengagementReason';
export { AddressType } from './valueSets/AddressType';
export { PhoneStatus } from './valueSets/PhoneStatus';
export { CarePathwayReferralReason } from './valueSets/CarePathwayReferralReason';
export { TaskTypes } from './valueSets/TaskType';
export { TaskPriority } from './valueSets/TaskPriority';
export { postalCodeToPod, podToPostalCodesMap } from './empanelment/postalCodes';
export { codeSystemConcepts, Concepts } from './codeSystems/ImaginePediatrics';

export { selectCodeByDisplay, selectDisplayByCode, extractValues } from './valueSets/selectValue';

export enum ISOSystem {
  Country = 'urn:iso:std:iso:3166',
  StateJurisdiction = 'urn:iso:std:iso:3166-2',
}

export const LOINCSystem = 'http://loinc.org';

export enum LOINCSystemCodes {
  PatientConsent = '59284-0',
  ReleaseOfInformation = '64292-6',
  YesAnswerOption = 'LA33-6',
  NoAnswerOption = 'LA32-8',
}

export { default as SubscriptionsExtensions } from './utils/subscriptionsExtensions';

export * from './tagging';
export * from './notifications';

export type Env = 'dev' | 'stage' | 'mirror' | 'prod';

// TODO: consider moving these to environment variables
const DEV_PROJECT_ID = '42cfb786-98a8-4a41-acc3-4081f2f7af0f';
const STAGE_PROJECT_ID = 'fb0e477b-f07c-4cc6-aba5-e4ead035f347';
const MIRROR_PROJECT_ID = '0194f10d-aca6-778b-bbc9-620919218655';
const PROD_PROJECT_ID = 'fc27d50d-623c-48db-aa04-176d9133474e';

export const envToProjectId: Record<Env, string> = Object.freeze({
  dev: DEV_PROJECT_ID,
  stage: STAGE_PROJECT_ID,
  mirror: MIRROR_PROJECT_ID,
  prod: PROD_PROJECT_ID,
});

export const projectIdToEnv: Record<string, Env> = Object.freeze(
  Object.fromEntries(Object.entries(envToProjectId).map(([env, projectId]) => [projectId, env as Env])),
);

export { getIdentifierSearchTerm } from './utils/identifier';
