import { CodeableConcept, Coding, RelatedPerson } from '@medplum/fhirtypes';
import { RelatedPerson as GraphqlRelatedPerson, CodeableConcept as GraphqlCodeableConcept } from 'medplum-gql';
import { HL7ValueSet, System } from 'const-utils';
import { capitalize } from 'lodash';

export const getRelatedPersonRelationship = (
  relatedPerson: RelatedPerson | GraphqlRelatedPerson,
): Coding | undefined => {
  const relationships = relatedPerson?.relationship;
  if (!relationships?.length) return undefined;

  return (
    (relationships.find((relation) => relation?.coding?.[0]?.system === System.ContactRelationship)
      ?.coding?.[0] as Coding) ||
    (relationships.find((relation) => relation?.coding?.[0]?.system === HL7ValueSet.RelatedPersonRelationshipType)
      ?.coding?.[0] as Coding)
  );
};

export function getUserDefinedRelationshipInfo(relatedPerson: RelatedPerson | GraphqlRelatedPerson): string {
  // Extract contact type coding (if available) using the custom system for contact types.
  const contactType = relatedPerson?.relationship?.find(
    (relation: CodeableConcept | GraphqlCodeableConcept) => relation?.coding?.[0]?.system === System.ContactType,
  )?.coding?.[0]?.display;

  // Use the existing helper to extract the relationship coding.
  const relationshipCoding = getRelatedPersonRelationship(relatedPerson);
  const relationshipDisplay = relationshipCoding?.display;

  return `${contactType ? `${contactType}, ` : ''}${capitalize(relationshipDisplay || '')}`;
}
