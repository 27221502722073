import React, { useEffect, useState } from 'react';
import { Modal, Text, Button, CloseButton, Group, Flex, LoadingOverlay, Switch } from '@mantine/core';
import { activateEngagementPod } from 'imagine-dsl/services/engagementPodMappingService';
import { useMedplum } from '@medplum/react';
import { notifications } from '@mantine/notifications';

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  engagementPodMappingResourceId: string;
  careTeamStatus: string;
  setCareTeamStatus: (status: string) => void;
}

export const UpdateEngagementPodStatusModal = ({
  isOpen,
  closeModal,
  engagementPodMappingResourceId,
  careTeamStatus,
  setCareTeamStatus,
}: ModalProps) => {
  const [hasActiveStatus, setHasActiveStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const medplum = useMedplum();

  useEffect(() => {
    setHasActiveStatus(careTeamStatus === 'active');
  }, []);

  const onActivateEngagementPod = async () => {
    setIsLoading(true);
    try {
      const updatedStatus = await activateEngagementPod(medplum, engagementPodMappingResourceId);
      setCareTeamStatus(updatedStatus);
      notifications.show({
        message: 'Engagement pod set to active',
        color: 'status-success',
      });
    } catch (error) {
      notifications.show({
        message: 'Error updating engagement pod status',
        color: 'status-error',
      });
    } finally {
      setIsLoading(false);
      closeModal();
    }
  };

  return (
    <Modal withCloseButton={false} centered size="md" padding="md" opened={isOpen} onClose={closeModal}>
      <LoadingOverlay visible={isLoading} />
      <Group justify="space-between" mb="xl">
        <Modal.Title c="imagine-green">Update status</Modal.Title>
        <CloseButton onClick={closeModal} />
      </Group>
      <Modal.Body px={0} m={0}>
        <Group>
          <Switch checked={hasActiveStatus} onChange={(event) => setHasActiveStatus(event.currentTarget.checked)} />
          <Text>
            Pod is <strong>{hasActiveStatus ? 'Active' : 'Inactive'}</strong>
          </Text>
        </Group>
        <Flex mt="xl" justify="end">
          <Button mr="md" variant="outline" onClick={closeModal}>
            Cancel
          </Button>
          <Button disabled={!hasActiveStatus} onClick={onActivateEngagementPod}>
            Save changes
          </Button>
        </Flex>
      </Modal.Body>
    </Modal>
  );
};
