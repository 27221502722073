export enum CommunicationRequestType {
  BHTOC = 'bh-toc',
}

export enum BHTOCTaskProgress {
  NotStarted = 'not-started',
  CaseComplete = 'case-complete',
  FirstCallMessageCompleted = 'first-call-message-completed',
  BHTOCVisitScheduled = 'bh-toc-visit-scheduled',
  PendingPodCompletion = 'pending-pod-completion',
  StillInpatient = 'still-inpatient',
}

export const bhTOCTaskProgresses: Record<BHTOCTaskProgress, string> = {
  [BHTOCTaskProgress.NotStarted]: 'Not started',
  [BHTOCTaskProgress.CaseComplete]: 'Case complete',
  [BHTOCTaskProgress.FirstCallMessageCompleted]: 'First call/message completed',
  [BHTOCTaskProgress.BHTOCVisitScheduled]: 'BH TOC visit scheduled',
  [BHTOCTaskProgress.PendingPodCompletion]: 'Pending pod completion',
  [BHTOCTaskProgress.StillInpatient]: 'Still inpatient',
};

export const alertTypeMapping: Record<string, string> = {
  IMP: 'Inpatient',
  EMER: 'ED',
};
