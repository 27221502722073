import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Text,
  Button,
  Checkbox,
  Group,
  Modal,
  MultiSelect,
  Select,
  Stack,
  TextInput,
  Tooltip,
  ThemeIcon,
  Box,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { CareTeamType, UserRole, UserRoleDisplay } from 'const-utils';
import { useMedplum } from '@medplum/react';
import { notifications } from '@mantine/notifications';
import { validateEmail } from 'imagine-dsl/utils/strings';
import { logError } from '@/errors';
import { User, useUpsertCarehubUserMutation } from 'imagine-gql/client';
import { imagineClient } from '@/hooks/useImagineApolloClient';
import { useEngagementPodCareTeams } from '@/hooks/useEngagementPodCareTeams';
import { engagementRoles } from '@/hooks/useHasRole';
import { useGetPractitionerRoleQuery, useGetUserCareTeamsQuery } from 'medplum-gql';
import { includes, omit } from 'lodash';
import { carePathwayDisplay, carePathwayOptions } from 'const-utils/codeSystems/ImaginePediatrics';
import { IconInfoCircle } from '@tabler/icons-react';
import { DateRangePicker } from '@/design-system/DateRangePicker';
import { setHours, setMinutes } from 'date-fns';
import { AthenaProviderIdEntry } from 'imagine-dsl/services/practitionerService';
import useAthenaProviderIds from '@/hooks/useAthenaProviderIds';
import ExistingAthenaProviderIds from '@/components/users/ExistingAthenaProviderIds';
import NewAthenaProviderIds from '@/components/users/NewAthenaProviderIds';
import { getAthenaMarketIds } from 'imagine-dsl/utils/practitioner';
import { getMarketIdentifier } from 'imagine-dsl/utils/identifiers';

interface UpsertFormProps {
  title: string;
  onClose: () => void;
  onSuccess: () => void;
  user?: User;
}

interface UpsertFormValues {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  markets: string[];
  engagementPodId: string;
  admin: boolean;
  readOnly: boolean;
  carePathwayApprover: boolean;
  carePathways: string[];
  approverStartDate?: Date | null;
  approverEndDate?: Date | null;
  athenaProviderIds?: AthenaProviderIdEntry[];
}

interface MarketData {
  id: string;
  name: string;
  marketIdentifier?: string;
}

export const UpsertForm: React.FC<UpsertFormProps> = ({ title, user, onClose, onSuccess }: UpsertFormProps) => {
  const medplum = useMedplum();
  const [markets, setMarkets] = useState<MarketData[]>([]);
  const isAdmin = medplum.isProjectAdmin();

  const { data: practitionerData, refetch: refetchPractitionerData } = useGetPractitionerRoleQuery({
    variables: {
      id: user?.id ?? '',
    },
    skip: !user?.id,
  });

  const athenaMarketIds = useMemo(() => getAthenaMarketIds(practitionerData), [practitionerData]);

  const roles = useMemo(() => Object.values(UserRole).map((value) => ({ label: UserRoleDisplay[value], value })), []);
  const carePathwayApprover = includes(user?.roles?.map((r) => r.role).join(', '), 'Medical Director');

  const userCarePathways = user?.roles?.reduce<string[]>((acc, r) => {
    if (r.role.split(',').some((role) => Object.values(carePathwayDisplay).includes(role))) {
      return acc.concat(r.role.split(','));
    }
    return acc;
  }, []);

  const carePathwaysValues = userCarePathways
    ? carePathwayOptions.filter((o) => userCarePathways.includes(o.label)).map((o) => o.value)
    : [];

  const approverStartDate = user?.roles?.find((r) => r.role.includes('Medical Director'))?.startDate;
  const approverEndDate = user?.roles?.find((r) => r.role.includes('Medical Director'))?.endDate;

  const {
    athenaProviderIdEntries,
    setAthenaProviderIdEntries,
    addAthenaIdEntry,
    removeAthenaIdEntry,
    getValidEntries,
    initializeFromAthenaMarketIds,
  } = useAthenaProviderIds([]);

  const form = useForm<UpsertFormValues>({
    initialValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
      role: roles.find((r) => r.value === user?.roles?.[0]?.role || r.label === user?.roles?.[0]?.role)?.value || '',
      markets: [...new Set(user?.roles?.map((r) => r.market))],
      engagementPodId: '',
      admin: user?.admin || false,
      readOnly: user?.readOnly || false,
      carePathwayApprover,
      carePathways: carePathwaysValues,
      approverStartDate: approverStartDate ? new Date(approverStartDate) : undefined,
      approverEndDate: approverEndDate ? new Date(approverEndDate) : undefined,
      athenaProviderIds: [],
    },
    transformValues: (values) => {
      const endDateTime = values.approverEndDate ? setMinutes(setHours(values.approverEndDate, 12), 59) : null;

      return {
        ...values,
        approverEndDate: endDateTime,
      };
    },
    validate: {
      firstName: (value) => (value ? undefined : 'First name is required'),
      lastName: (value) => (value ? undefined : 'Last name is required'),
      email: (value) => (validateEmail(value) ? undefined : 'Invalid email'),
      role: (value) => (value ? undefined : 'Role is required'),
      markets: (value) => (value.length > 0 ? undefined : 'Please select at least one market'),
    },
  });

  const getUserCareTeamsQuery = useGetUserCareTeamsQuery({
    variables: {
      practitionerRef: `Practitioner/${user?.id}`,
      tag: CareTeamType.EngagementPod,
    },
    skip: !user?.id,
  });
  const engagementPodIdInitialized = useRef(false);
  useEffect(() => {
    if (
      !form.values.engagementPodId &&
      getUserCareTeamsQuery.data?.CareTeamList?.length &&
      !engagementPodIdInitialized.current
    ) {
      form.setFieldValue('engagementPodId', getUserCareTeamsQuery.data?.CareTeamList?.[0]?.id ?? '');
      engagementPodIdInitialized.current = true;
    }
  }, [form, getUserCareTeamsQuery.data]);

  const isEngagementRole = useMemo(
    () => form.values.role && engagementRoles.includes(form.values.role as UserRole),
    [form.values.role],
  );

  const engagementPodCareTeamsQuery = useEngagementPodCareTeams();
  const engagementPodOptions = engagementPodCareTeamsQuery.data?.entry
    ?.map((entry) => ({
      value: entry.resource?.id ?? '',
      label: entry.resource?.name ?? '',
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  useEffect(() => {
    //implemented to cleanup pattern for asynchronous operations - "cancellation pattern."
    let ignore = false;

    medplum
      .searchResources<'Organization'>('Organization', {
        _tag: 'market',
      })
      .then((result) => {
        if (ignore) {
          return;
        }

        const marketData: MarketData[] = result.map((org) => {
          const marketIdentifier = getMarketIdentifier(org);

          return {
            id: org.id || '',
            name: org.name || 'Unknown',
            marketIdentifier,
          };
        });

        setMarkets(marketData);
      })
      .catch((err) => {
        if (ignore) {
          return;
        }

        notifications.show({ title: 'Error', message: 'Failed to load markets', color: 'status-error' });
        logError(err);
      });

    return () => {
      ignore = true;
    };
  }, [medplum]);

  useEffect(() => {
    if (user?.id && Object.keys(athenaMarketIds).length > 0) {
      initializeFromAthenaMarketIds(athenaMarketIds, markets);
    }
  }, [user?.id, athenaMarketIds, markets, initializeFromAthenaMarketIds]);

  const marketOptions = useMemo(
    () =>
      markets.map((m) => ({
        label: m.name,
        value: m.name,
        marketIdentifier: m.marketIdentifier,
      })),
    [markets],
  );

  const [upsert, { loading }] = useUpsertCarehubUserMutation({
    client: imagineClient,
    onCompleted: () => {
      notifications.show({
        title: 'Success',
        message: user ? 'User updated' : 'User successfully onboarded',
        variant: 'success',
      });
      onClose();
      onSuccess();
      getUserCareTeamsQuery.refetch().catch((e) => logError(e));
    },
    onError: (err) => {
      notifications.show({
        title: 'Error',
        message: user ? `Failed to update user: ${err.message}` : `Failed to onboard user: ${err.message}`,
        color: 'status-error',
      });
      logError(err);
    },
  });

  const onCarePathwayApproverChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue('carePathwayApprover', e.target.checked);
    if (!e.target.checked) {
      form.setFieldValue('carePathways', []);
      form.setFieldValue('approverStartDate', undefined);
      form.setFieldValue('approverEndDate', undefined);
    }
  };

  const onSubmit = async (values: UpsertFormValues) => {
    const validEntries = getValidEntries();

    const athenaProviderIdsMap = validEntries.reduce<Record<string, string>>((acc, entry) => {
      if (entry.market) {
        const market = markets.find((m) => m.name === entry.market);
        if (market?.marketIdentifier) {
          acc[market.marketIdentifier] = entry.providerId || '';
        }
      }
      return acc;
    }, {});

    try {
      await upsert({
        variables: {
          input: {
            ...omit(values, ['carePathwayApprover']),
            approverEndDate: form.getTransformedValues().approverEndDate?.toISOString() ?? null,
            approverStartDate: values.approverStartDate?.toISOString() ?? null,
            update: !!user,
            athenaProviderIds: athenaProviderIdsMap,
          },
        },
      });

      if (user?.id) {
        await refetchPractitionerData();
      }
    } catch (err) {
      logError(err);
      notifications.show({
        title: 'Error',
        message: user
          ? `Failed to update user: ${(err as Error).message}`
          : `Failed to onboard user: ${(err as Error).message}`,
        color: 'status-error',
      });
    }
  };

  return (
    <Modal size="lg" title={title} opened onClose={onClose}>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack>
          <Group grow>
            <TextInput
              disabled={!!user}
              type="text"
              label="First name"
              placeholder="Jane"
              name="firstName"
              {...form.getInputProps('firstName')}
            />
            <TextInput
              disabled={!!user}
              type="text"
              label="Last name"
              placeholder="Doe"
              name="lastName"
              {...form.getInputProps('lastName')}
            />
          </Group>
          <TextInput
            type="email"
            label="Email"
            placeholder="jdoe@imaginepediatrics.org"
            required
            disabled={!!user}
            name="email"
            {...form.getInputProps('email')}
          />
          <Select
            data-cy="role-select"
            data={roles}
            label="Role"
            placeholder="Select role"
            required
            {...form.getInputProps('role')}
          />
          <MultiSelect
            data-cy="markets-select"
            data={marketOptions}
            label="Market(s)"
            placeholder="Select market(s)"
            required
            {...form.getInputProps('markets')}
          />
          {isEngagementRole && (
            <Select
              clearable
              data={engagementPodOptions || []}
              label="Engagement Pod"
              placeholder="Select engagement pod"
              {...form.getInputProps('engagementPodId')}
            />
          )}

          {isAdmin && (
            <>
              <ExistingAthenaProviderIds
                athenaMarketIds={athenaMarketIds || {}}
                setAthenaProviderIdEntries={setAthenaProviderIdEntries}
                athenaProviderIdEntries={athenaProviderIdEntries}
                marketOptions={marketOptions}
              />

              <NewAthenaProviderIds
                athenaProviderIdEntries={athenaProviderIdEntries}
                setAthenaProviderIdEntries={setAthenaProviderIdEntries}
                addAthenaIdEntry={addAthenaIdEntry}
                removeAthenaIdEntry={removeAthenaIdEntry}
                athenaMarketIds={athenaMarketIds || {}}
                marketOptions={marketOptions}
              />
            </>
          )}

          <Box>
            <Text>Permissions</Text>
            <Group gap="xl" mt="sm">
              <Checkbox
                label="Admin?"
                disabled={form.values.readOnly}
                {...form.getInputProps('admin', { type: 'checkbox' })}
              />
              <Checkbox
                label="Read only?"
                disabled={form.values.admin}
                name="readOnly"
                {...form.getInputProps('readOnly', { type: 'checkbox' })}
              />

              <Checkbox
                label={
                  <Group gap={4}>
                    <Text>Care pathway approver?</Text>
                    <Tooltip
                      multiline
                      w="400px"
                      position="bottom-start"
                      withArrow
                      label={
                        <Text>
                          This allows users to approve or deny care pathway referral requests for any market they are
                          apart of.
                          <br />
                          Select the care pathways below that they should be allowed to approve. You can also indicate
                          start and end dates for this role.
                        </Text>
                      }
                    >
                      <ThemeIcon size="sm" radius="xl" color="status-info">
                        <IconInfoCircle />
                      </ThemeIcon>
                    </Tooltip>
                  </Group>
                }
                {...form.getInputProps('carePathwayApprover', { type: 'checkbox' })}
                onChange={onCarePathwayApproverChange}
              />
            </Group>
          </Box>

          <MultiSelect
            label="Care pathways"
            required={form.values.carePathwayApprover}
            data={carePathwayOptions}
            disabled={!form.values.carePathwayApprover}
            {...form.getInputProps('carePathways')}
          />
          <Stack gap={5}>
            <Text>Date range</Text>
            <DateRangePicker
              setStartDate={(date) => form.setFieldValue('approverStartDate', date)}
              setEndDate={(date) => form.setFieldValue('approverEndDate', date)}
              startDate={form.getValues().approverStartDate}
              endDate={form.getValues().approverEndDate}
              disabled={!form.getValues().carePathwayApprover}
              clearable
              w="50%"
            />
          </Stack>

          <Button disabled={loading} loading={loading} type="submit">
            {user ? 'Update' : 'Onboard'}
          </Button>
        </Stack>
      </form>
    </Modal>
  );
};
