import { System } from 'const-utils';
import { GetPractitionerRoleQuery } from 'medplum-gql';

/**
 * Gets Athena market IDs from a Practitioner's identifiers
 * @param practitionerData - The practitioner data object containing identifiers
 * @returns Object containing Athena IDs mapped to each market name
 */
export const getAthenaMarketIds = (practitionerData: GetPractitionerRoleQuery | undefined): Record<string, string> => {
  if (!practitionerData?.Practitioner) {
    return {};
  }

  const athenaIdentifiers = practitionerData.Practitioner.identifier?.filter(
    (id) => id?.system === System.AthenaId.toString(),
  );

  if (!athenaIdentifiers || athenaIdentifiers.length === 0) {
    return {};
  }

  const result: Record<string, string> = {};

  for (const identifier of athenaIdentifiers) {
    if (identifier.extension) {
      const marketExt = identifier.extension.find((ext) => ext.url === System.MarketIdentifier.toString());
      if (marketExt?.valueString) {
        result[marketExt.valueString] = identifier.value || '';
      }
    }
  }

  return result;
};
