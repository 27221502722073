import { MedplumClient, parseReference } from '@medplum/core';
import { Basic, CareTeam, Coding, Reference, Resource } from '@medplum/fhirtypes';
import { System } from 'const-utils';
import { BasicType } from 'const-utils/codeSystems/ImaginePediatrics';
import { MappingResourceIdToPodName } from 'imagine-dsl/utils/types/engagementPodMapping';

/**
 * Retrieves an array of engagement pod names and their
 * corresponding Basic resource IDs
 *
 * @param medplum - The Medplum client instance.
 */
export const getEngagementPodMappingResources = async (
  medplum: MedplumClient,
): Promise<MappingResourceIdToPodName[]> => {
  const mappings = await medplum.searchResources(
    'Basic',
    {
      _tag: BasicType.EngagementPodMapping,
    },
    {
      cache: 'no-cache',
    },
  );

  const resourceIdAndName = mappings
    .filter((mapping) => mapping.subject?.display !== undefined && mapping.id !== undefined)
    .map((mapping) => ({
      id: mapping.id!,
      podName: mapping.subject?.display!,
    }))
    .sort((a, b) => a.podName.localeCompare(b.podName));
  return resourceIdAndName;
};

/**
 * Retrieves the status of an Engagement Pod CareTeam resource
 *
 * @param careTeamRef - The reference to the CareTeam resource
 * @param medplum - The Medplum client instance.
 *
 * @returns The status of the CareTeam resource
 */
export const getCareTeamStatusFromMapping = async (
  careTeamRef: Reference<Resource> | undefined,
  medplum: MedplumClient,
): Promise<string> => {
  const [_, careTeamId] = parseReference(careTeamRef);
  const careTeam = await medplum.readResource('CareTeam', careTeamId);
  return careTeam.status || '';
};

/**
 * Updates a Basic resource to remove a postal code from the coding array
 *
 * @param medplum - The Medplum client instance.
 * @param postalCode - The postal code to remove
 * @param mappingResource - The Basic resource to update
 *
 * @returns The updated Basic resource
 */
export const removePostalCodeByBasicResource = async (
  medplum: MedplumClient,
  postalCode: string,
  mappingResource: Basic,
): Promise<Basic> => {
  const updatedCodes = mappingResource.code?.coding?.filter((coding) => coding.code !== postalCode) || [];
  const updatedMappingResource = await medplum.updateResource({
    ...mappingResource,
    code: {
      coding: updatedCodes,
    },
  });

  return updatedMappingResource;
};

/**
 * Updates a Basic resource to remove a postal code from the coding array
 *
 * @param medplum - The Medplum client instance.
 * @param postalCode - The postal code to remove
 * @param mappingResourceId - The Basic resource id to update
 *
 * @returns The updated Basic resource
 */
export const removePostalCodeByBasicId = async (
  medplum: MedplumClient,
  postalCode: string,
  mappingResourceId: string,
): Promise<Basic> => {
  const basicResource = await medplum.readResource('Basic', mappingResourceId);
  const updatedMappingResource = await removePostalCodeByBasicResource(medplum, postalCode, basicResource);

  return updatedMappingResource;
};

/**
 * Updates the status of an Engagement Pod CareTeam
 * resource to 'active'
 *
 * @param medplum - The Medplum client instance.
 * @param basicResourceId - The id of the Basic mapping resource
 *
 * @returns The status of the CareTeam resource after the update
 */
export const activateEngagementPod = async (medplum: MedplumClient, basicResourceId: string): Promise<string> => {
  const basicResource = await medplum.readResource('Basic', basicResourceId);
  const [_, careTeamId] = parseReference(basicResource.subject);
  const careTeamResource = await medplum.readResource('CareTeam', careTeamId);
  const updatedResource = await medplum.updateResource({
    ...careTeamResource,
    status: 'active',
  });
  return updatedResource.status;
};

/**
 * Adds new postal code object for each postal code in param
 *
 * @param medplum - The Medplum client instance.
 * @param basicResourceId - The id of the Basic mapping resource
 * @param postalCodesToAdd - An array of postal codes to add (strings)
 *
 * @returns The Basic resource with updated coding array
 */
export const updateBasicResourceWithPostalCodes = async (
  medplum: MedplumClient,
  basicResourceId: string,
  postalCodesToAdd: string[],
): Promise<Basic> => {
  const basicResource = await medplum.readResource('Basic', basicResourceId);
  const updatedCodes: Coding[] = postalCodesToAdd.map((code) => ({
    system: System.PostalCode,
    code: code,
  }));

  const updatedMappingResource = await medplum.updateResource({
    ...basicResource,
    code: {
      coding: [...(basicResource.code?.coding || []), ...updatedCodes],
    },
  });

  return updatedMappingResource;
};

/**
 *
 * @param medplum - The Medplum client instance.
 * @param marketIdentifier - The identifier tied to geographic market
 *
 * @returns An array of CareTeam resources
 */
export const getCareTeamsByMarket = async (medplum: MedplumClient, marketIdentifier: string): Promise<CareTeam[]> => {
  const basicResources = await medplum.searchResources('Basic', {
    identifier: `${System.Organization}|${marketIdentifier}`,
  });

  const careTeamResources = await Promise.all(
    basicResources.map(async (basicResource) => {
      return await medplum.readReference(basicResource.subject! as Reference<CareTeam>);
    }),
  );

  return careTeamResources;
};
