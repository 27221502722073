import React, { useEffect } from 'react';
import { Modal, Text, FileInput } from '@mantine/core';
import { useAddPostalCodesModal } from '../AddPostalCodesModalContext';
import { useMedplum } from '@medplum/react';
import { TitleWithClose } from '@/design-system/modal/TitleWithClose';
import { ButtonContainer } from '@/design-system/modal/ButtonContainer';
import { DuplicationAction } from '../constants';
import { Basic } from '@medplum/fhirtypes';
import { getCurrentMapping } from 'imagine-dsl/utils/engagementPodMapping';

interface UploadCsvProps {
  destinationPodId: string;
  podName: string;
}

export const UploadCsv = ({ destinationPodId, podName }: UploadCsvProps) => {
  const {
    handleClosePostalCodesModal,
    postalCodesToAdd,
    setPostalCodesToAdd,
    setIsLoading,
    setPostalCodesUploaded,
    setPostalCodeDuplicates,
  } = useAddPostalCodesModal();
  const medplum = useMedplum();
  const [allBasicResources, setAllBasicResources] = React.useState<Basic[]>([]);

  useEffect(() => {
    const getAllMappingResources = async () => {
      const mappingResources = await medplum.searchResources('Basic', { _tag: 'engagement-pod-mapping' });
      setAllBasicResources(mappingResources);
    };

    getAllMappingResources();
  }, []);

  const handleFileUpload = (file: File | null) => {
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        if (e.target?.result) {
          const csvText = e.target.result as string;
          const lines = csvText.split('\n');
          const cleanedLines = lines.map((line) => line.replace(/\r/g, '').trim()).filter((line) => line !== '');
          const uniqueCodes = [...new Set(cleanedLines)];
          setPostalCodesToAdd(uniqueCodes);
        }
      };

      reader.readAsText(file);
    }
  };

  const onContinue = async () => {
    setIsLoading(true);

    for (const postalCode of postalCodesToAdd) {
      const currentMapping = getCurrentMapping(allBasicResources, postalCode);
      if (!currentMapping) {
        continue;
      }

      //if postal code is already mapped to the pod, no need to add it
      if (currentMapping.resourceId === destinationPodId) {
        setPostalCodesToAdd((prevPostalCodes) => prevPostalCodes.filter((code) => code !== postalCode));
        continue;
      }

      setPostalCodeDuplicates((prevDuplicates) => [
        ...prevDuplicates,
        {
          postalCode: postalCode,
          basicId: currentMapping.resourceId,
          currentPodName: currentMapping.podName,
          duplicateAction: DuplicationAction.MOVE_TO_NEW_POD,
        },
      ]);
    }
    setPostalCodesUploaded(true);
    setIsLoading(false);
  };

  return (
    <>
      <TitleWithClose title="Add postal codes" onClose={handleClosePostalCodesModal} />
      <Modal.Body p="0" my="lg">
        <Text>
          First, upload a CSV file containing the postal codes you'd like to map to{' '}
          <Text span fw={700}>
            {podName}.
          </Text>
        </Text>
        <FileInput
          my="xl"
          label="Select file"
          required
          description="Accepted formats: CSV"
          accept=".csv"
          onChange={handleFileUpload}
        />
        <ButtonContainer
          outlineButtonTitle="Cancel"
          outlineButtonAction={handleClosePostalCodesModal}
          filledButtonTitle="Continue"
          filledButtonAction={onContinue}
          filledButtonDisabled={postalCodesToAdd.length === 0 || !postalCodesToAdd}
        />
      </Modal.Body>
    </>
  );
};
