import React from 'react';
import { TitleWithClose } from '@/design-system/modal/TitleWithClose';
import { Group, Modal, Text } from '@mantine/core';
import { ButtonContainer } from '@/design-system/modal/ButtonContainer';
import { useAddPostalCodesModal } from '../AddPostalCodesModalContext';
import { useMedplum } from '@medplum/react';
import {
  removePostalCodeByBasicId,
  updateBasicResourceWithPostalCodes,
} from 'imagine-dsl/services/engagementPodMappingService';
import { notifications } from '@mantine/notifications';
import { DuplicationAction } from '../constants';

interface ConfirmMappingProps {
  podName: string;
  destinationPodId: string;
}

export const ConfirmMapping = ({ podName, destinationPodId }: ConfirmMappingProps) => {
  const {
    handleClosePostalCodesModal,
    postalCodesToAdd,
    postalCodeDuplicates,
    setIsLoading,
    setEngagementPodMappingResource,
  } = useAddPostalCodesModal();
  const medplum = useMedplum();

  const removeDuplicatesFromOriginalMapping = async () => {
    for (const duplicate of postalCodeDuplicates) {
      if (duplicate.duplicateAction === DuplicationAction.MOVE_TO_NEW_POD) {
        //TODO: evaluate whether/not we should batch these updates if a lot of duplicates
        await removePostalCodeByBasicId(medplum, duplicate.postalCode, duplicate.basicId);
      }
    }
  };

  const onContinue = async () => {
    setIsLoading(true);
    try {
      await removeDuplicatesFromOriginalMapping();
      const updatedResource = await updateBasicResourceWithPostalCodes(medplum, destinationPodId, postalCodesToAdd);
      setEngagementPodMappingResource(updatedResource);
      notifications.show({
        message: `${postalCodesToAdd.length} postal codes added to pod`,
        color: 'status-success',
      });
      handleClosePostalCodesModal();
    } catch (error) {
      notifications.show({
        message: `Error updating postal codes: ${error}`,
        color: 'status-error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <TitleWithClose title="Confirm postal code mapping" onClose={handleClosePostalCodesModal} />
      <Modal.Body p="0" my="md">
        {postalCodesToAdd.length === 0 || !postalCodesToAdd ? (
          <Group my="xl">
            <Text>
              There are no postal codes to add. If you uploaded a csv, then those postal codes already exist in this
              pod.
            </Text>
          </Group>
        ) : (
          <Group my="xl">
            <Text>
              You are about to add{' '}
              <Text span fw={700}>
                {postalCodesToAdd.length}
              </Text>{' '}
              postal codes to {podName}.
            </Text>
            <Text>
              Are you sure you want to proceed?{' '}
              <Text span fw={700}>
                This can't be undone.
              </Text>
            </Text>
          </Group>
        )}

        <ButtonContainer
          outlineButtonTitle="Cancel"
          outlineButtonAction={handleClosePostalCodesModal}
          filledButtonTitle="Yes, add postal codes"
          filledButtonAction={onContinue}
          filledButtonDisabled={postalCodesToAdd.length === 0 || !postalCodesToAdd}
        />
      </Modal.Body>
    </>
  );
};
