import { useMedplum } from '@medplum/react';
import { notifications } from '@mantine/notifications';
import { OrganizationType } from 'const-utils/codeSystems/ImaginePediatrics';
import { useCallback, useEffect, useState } from 'react';
import { System } from 'const-utils';
import { Feature } from 'const-utils/codeSystems/ImaginePediatrics/Features';
import { ORG_BUSINESS } from 'const-utils/utils/constants';

export const useCreateMarket = () => {
  const medplum = useMedplum();
  const createMarket = useCallback(
    async (marketName: string, active: boolean, athenaId: string, prapare: boolean, acuteCareOnly: boolean) => {
      try {
        const createMarket = await medplum.createResource({
          name: marketName,
          resourceType: 'Organization',
          active: active,
          type: [
            {
              coding: [
                {
                  system: System.OrganizationType,
                  code: 'bus',
                  display: 'Non-Healthcare Business or Corporation',
                },
              ],
            },
          ],
          identifier: [
            {
              system: System.Organization,
              value: `${ORG_BUSINESS}${marketName}`,
            },
          ],
          extension: [
            {
              url: System.AthenaId,
              valueString: athenaId,
            },
            {
              url: System.Feature,
              valueCode: Feature.PRAPARE,
              valueBoolean: prapare,
            },
            {
              url: System.Feature,
              valueCode: Feature.AcuteCareOnly,
              valueBoolean: acuteCareOnly,
            },
          ],
          meta: {
            tag: [
              {
                system: System.OrganizationType,
                code: OrganizationType.Market,
              },
            ],
          },
        });

        return createMarket;
      } catch (error) {
        notifications.show({
          title: 'Error creating Market:',
          message: error as string,
          color: 'status-error',
        });
        return null;
      }
    },
    [medplum],
  );
  return { createMarket };
};

export const useFetchAthenaIds = () => {
  const medplum = useMedplum();
  const [athenaIds, setAthenaIds] = useState<string[]>([]);

  useEffect(() => {
    const fetchAthenaIds = async (): Promise<void> => {
      try {
        const response = await medplum.search('Organization', {
          _tag: OrganizationType.Market,
        });
        const ids = response.entry
          ? response.entry.map(
              (entry) => entry?.resource?.extension?.find((ext) => ext.url === System.AthenaId)?.valueString || '',
            )
          : [];
        setAthenaIds(ids);
      } catch (error) {
        notifications.show({
          title: 'Error',
          message: 'Failed to fetch Athena IDs.',
          color: 'status-error',
        });
      }
    };
    fetchAthenaIds();
  }, [medplum]);

  return { athenaIds };
};
