import React, { useEffect, useMemo, useState } from 'react';
import { PostalCodeTable } from '@/components/engagementPodMapping/PostalCodeTable';
import { usePagination } from '@/design-system/hooks/usePagination';
import { Pagination } from '@/design-system/Pagination';
import { Center, Container, Divider, Group, Loader, Paper, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useMedplum } from '@medplum/react';
import {
  getCareTeamStatusFromMapping,
  removePostalCodeByBasicResource,
} from 'imagine-dsl/services/engagementPodMappingService';
import { Params, useParams } from 'react-router-dom';
import { UpdateEngagementPodStatusModal } from '@/components/engagementPodMapping/UpdateEngagementPodStatusModal';
import { useDisclosure } from '@mantine/hooks';
import { PodMappingSubheader } from '@/components/engagementPodMapping/PodMappingSubheader';
import { PodMappingHeader } from '@/components/engagementPodMapping/PodMappingHeader';
import { useAddPostalCodesModal } from '@/components/engagementPodMapping/AddPostalCodesModal/AddPostalCodesModalContext';
import { AddPostalCodesModal } from '@/components/engagementPodMapping/AddPostalCodesModal/AddPostalCodesModal';

const POSTAL_CODES_PER_PAGE = 20;

export const EngagementPodPage = (): JSX.Element => {
  const { id = '' } = useParams<Params>();
  const { currentPage } = usePagination({});
  const medplum = useMedplum();
  const [isStatusModalOpen, { open: openStatusModal, close: closeStatusModal }] = useDisclosure();
  const {
    opened: isAddPostalCodesModalOpen,
    setEngagementPodMappingResource,
    engagementPodMappingResource,
  } = useAddPostalCodesModal();

  const [isLoading, setIsLoading] = useState(true);
  const [careTeamStatus, setCareTeamStatus] = useState<string>('');

  useEffect(() => {
    const fetchEngagementPod = async () => {
      try {
        const mappingResource = await medplum.readResource('Basic', id);
        const status = await getCareTeamStatusFromMapping(mappingResource?.subject, medplum);
        setEngagementPodMappingResource(mappingResource);
        setCareTeamStatus(status);
      } catch (error) {
        notifications.show({
          message: `Error fetching mapping resource: ${error}`,
          color: 'status-error',
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchEngagementPod();
  }, [id]);

  const removePostalCode = async (postalCode: string) => {
    try {
      const updatedMappingResource = await removePostalCodeByBasicResource(
        medplum,
        postalCode,
        engagementPodMappingResource!,
      );
      setEngagementPodMappingResource(updatedMappingResource);
      notifications.show({
        message: `Postal code ${postalCode} removed`,
        color: 'status-success',
      });
    } catch (error) {
      notifications.show({
        message: `Error removing postal code: ${error}`,
        color: 'status-error',
      });
    }
  };

  const postalCodes = useMemo(() => {
    const { code } = engagementPodMappingResource || {};
    return code?.coding || [];
  }, [engagementPodMappingResource]);

  const paginatedPostalCodes = () => {
    const start = (currentPage - 1) * POSTAL_CODES_PER_PAGE;
    const end = start + POSTAL_CODES_PER_PAGE;
    return postalCodes.slice(start, end);
  };

  if (isLoading) {
    return (
      <Center mt="xl">
        <Loader />
      </Center>
    );
  }

  if (!engagementPodMappingResource) {
    return (
      <Container size="xl" w="100%" mt="xl">
        <Paper radius="lg" shadow="xs" p="xl">
          <Text size="lg">Engagement pod mapping not found.</Text>
        </Paper>
      </Container>
    );
  }

  const podName = engagementPodMappingResource.subject?.display || 'Engagement Pod';

  return (
    <>
      {isAddPostalCodesModalOpen && (
        <AddPostalCodesModal destinationPodId={engagementPodMappingResource.id!} podName={podName} />
      )}
      {isStatusModalOpen && (
        <UpdateEngagementPodStatusModal
          isOpen={isStatusModalOpen}
          closeModal={closeStatusModal}
          engagementPodMappingResourceId={engagementPodMappingResource?.id!}
          setCareTeamStatus={setCareTeamStatus}
          careTeamStatus={careTeamStatus}
        />
      )}
      <PodMappingHeader podName={podName} postalCoding={postalCodes} />
      <Divider color="brand-gray.5" mb="xs" />
      <PodMappingSubheader podStatus={careTeamStatus} podName={podName} openStatusModal={openStatusModal} />
      <Group mb="xl" justify="flex-end">
        <PostalCodeTable postalCodes={paginatedPostalCodes()} removePostalCode={removePostalCode} podName={podName} />
        <Pagination perPage={POSTAL_CODES_PER_PAGE} count={postalCodes.length} />
      </Group>
    </>
  );
};
