export enum HL7System {
  ActCode = 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
  ConsentScope = 'http://terminology.hl7.org/CodeSystem/consentscope',
  CoverageClass = 'http://terminology.hl7.org/CodeSystem/coverage-class',
  DisplayCategory = 'http://terminology.hl7.org/CodeSystem/display-category',
  GenderIdentity = 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-genderIdentity',
  HCPCS = 'http://terminology.hl7.org/CodeSystem/HCPCS',
  ICD10 = 'http://hl7.org/fhir/sid/icd-10',
  ICD10CM = 'http://hl7.org/fhir/sid/icd-10-cm',
  InterpreterRequired = 'http://hl7.org/fhir/StructureDefinition/patient-interpreterRequired',
  LivingArrangement = 'http://terminology.hl7.org/CodeSystem/v3-LivingArrangement',
  OrganizationType = 'http://terminology.hl7.org/CodeSystem/organization-type',
  PrapareSurvey = 'http://hl7.org/fhir/us/sdoh-clinicalcare/Questionnaire/SDOHCC-QuestionnairePRAPARE',
  PreferredPronoun = 'http://terminology.hl7.org/ValueSet/pronouns',
  QuestionnaireDisplayCategorySystem = 'http://hl7.org/fhir/StructureDefinition/questionnaire-displayCategory',
  RoleCode = 'http://terminology.hl7.org/CodeSystem/v3-RoleCode',
  StatusReason = 'http://terminology.hl7.org/CodeSystem/status-reason',
  SupersedingTask = 'http://hl7.org/fhir/StructureDefinition/superseding-task',
  TimeZone = 'http://hl7.org/fhir/StructureDefinition/timezone',
  Translation = 'http://hl7.org/fhir/StructureDefinition/translation',
}

export const HL7OrganizationType = {
  InsuranceProvider: {
    system: HL7System.OrganizationType,
    code: 'ins',
    display: 'Insurance Provider',
  },
};

export enum HL7ValueSet {
  RoleCode = 'http://terminology.hl7.org/ValueSet/v3-RoleCode',
  RelatedPersonRelationshipType = 'http://hl7.org/fhir/ValueSet/relatedperson-relationshiptype',
}
