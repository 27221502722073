import React, { useState, useEffect, useMemo } from 'react';
import { Button, Group, Modal, Select, Stack, TextInput, Textarea, Divider } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useMedplum, useMedplumProfile } from '@medplum/react';
import { format } from 'date-fns';
import { logError } from '@/errors';
import { notifications } from '@mantine/notifications';
import { Reference } from '@medplum/fhirtypes';
import {
  OutreachModality,
  OutreachDisposition,
  outreachDispositionMap,
  outreachModalityMap,
} from 'const-utils/codeSystems/ImaginePediatrics';
import { BHTOCTaskProgress, bhTOCTaskProgresses } from 'const-utils/codeSystems/ImaginePediatrics/CommunicationRequest';
import { SelectWithSearch } from '@/components/shared/SelectWithSearch';
import { formatHumanName } from '@medplum/core';
import { HumanName } from '@medplum/fhirtypes';
import {
  useSearchPractitionersQuery,
  SearchPractitionersQueryVariables,
  Maybe,
  GetTasksDocument,
  GetPatientPanelDocument,
  GetPatientActivityDocument,
  GetPatientOutreachActivityDocument,
} from 'medplum-gql';
import { useApolloClient } from '@apollo/client';
import { saveTOCTouchpoint } from 'imagine-dsl/services/tocService';

interface TOCTouchpointModalProps {
  patientId: string;
  opened: boolean;
  closeModal: () => Promise<void>;
  basedOn?: Reference[];
  communicationRequestId?: string;
  taskId?: string;
}

interface TOCTouchpointFormValues {
  outcome?: OutreachDisposition;
  notes: string;
  tocTaskProgress?: BHTOCTaskProgress;
  assignedTo: string;
  dueDate?: string;
  dueTime: string;
  priority?: string;
  taskNotes: string;
}

const defaultModality = OutreachModality.PhoneOutboundStandard;

export const TOCTouchpointModal = ({
  patientId,
  opened,
  closeModal,
  basedOn,
  communicationRequestId,
  taskId,
}: TOCTouchpointModalProps): JSX.Element => {
  const medplum = useMedplum();
  const profile = useMedplumProfile();
  const apolloClient = useApolloClient();
  const [submitting, setSubmitting] = useState(false);

  const form = useForm<TOCTouchpointFormValues>({
    initialValues: {
      outcome: undefined,
      notes: '',
      tocTaskProgress: undefined,
      assignedTo: '',
      dueDate: format(new Date(), 'yyyy-MM-dd'),
      dueTime: '23:59',
      priority: undefined,
      taskNotes: '',
    },
    validate: {
      outcome: (value) => !value && 'Outcome is required',
      tocTaskProgress: (value) => !value && 'TOC task progress is required',
      assignedTo: (value) => !value && 'Assignee is required',
      dueDate: (value) => !value && 'Due Date is required',
    },
    validateInputOnBlur: true,
  });

  // Get outreach disposition options for the default modality
  const outreachDispositionOptions = useMemo(() => {
    return outreachModalityMap[defaultModality].dispositions.map((disposition) => ({
      value: disposition,
      label: outreachDispositionMap[disposition].display,
    }));
  }, []);

  // Get TOC task progress options
  const tocTaskProgressOptions = useMemo(() => {
    return Object.entries(bhTOCTaskProgresses).map(([value, label]) => ({
      value,
      label,
    }));
  }, []);

  const [searchParams, setSearchParams] = useState<Maybe<SearchPractitionersQueryVariables>>();
  const { data } = useSearchPractitionersQuery({
    skip: !searchParams,
    variables: searchParams || {},
  });
  const [practitionerOptions, setPractitionerOptions] = useState<{ label: string; value: string }[]>([]);

  useEffect(() => {
    if (data) {
      setPractitionerOptions(
        data.PractitionerList?.filter((p) => p?.name?.[0].family && p?.name?.[0].given).map((practitioner) => {
          return {
            label: formatHumanName(practitioner?.name?.[0] as HumanName),
            value: practitioner?.id ?? '',
          };
        }) || [],
      );
    }
  }, [data]);

  const onSearch = (name: Maybe<string>): void => {
    if (!name) {
      setSearchParams({});
    } else {
      setSearchParams({ name });
    }
  };

  const onAssignToTeamMember = async (practitionerId: string): Promise<void> => {
    form.setFieldValue('assignedTo', practitionerId);
    form.validateField('assignedTo');
  };

  const close = async (): Promise<void> => {
    await closeModal();
  };

  const saveTOCTouchpointData = async (): Promise<void> => {
    setSubmitting(true);
    form.validate();
    if (!form.isValid() || !profile) {
      setSubmitting(false);
      return;
    }

    try {
      if (!form.values.outcome) {
        throw new Error('Outcome is required');
      }

      if (!form.values.tocTaskProgress) {
        throw new Error('TOC task progress is required');
      }

      const dueDateTime = form.values.dueDate ? `${form.values.dueDate}T${form.values.dueTime}:00` : '';

      await saveTOCTouchpoint({
        medplum,
        patientId,
        practitioner: profile,
        // Communication fields
        outcome: form.values.outcome,
        notes: form.values.notes,
        basedOn,
        // CommunicationRequest fields
        communicationRequestId,
        tocTaskProgress: form.values.tocTaskProgress,
        // Task fields
        taskId,
        assignToPractitionerId: form.values.assignedTo,
        dueDate: dueDateTime,
        priority: form.values.priority,
        taskNotes: form.values.taskNotes,
      });

      await apolloClient.refetchQueries({
        include: [
          GetTasksDocument,
          GetPatientPanelDocument,
          GetPatientActivityDocument,
          GetPatientOutreachActivityDocument,
        ],
      });

      await close();
      setSubmitting(false);

      notifications.show({
        title: 'Success',
        message: 'BH TOC outreach saved successfully',
        color: 'green',
      });
    } catch (err) {
      setSubmitting(false);
      notifications.show({
        title: 'Error saving BH TOC outreach',
        message: err instanceof Error ? err.message : 'Unknown error',
        color: 'red',
      });
      logError(err);
    }
  };

  return (
    <Modal.Root size="lg" opened={opened} onClose={close} centered radius="xl">
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title c="imagine-green">Add touchpoint</Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <Stack gap="md">
            <Select
              label="Call outcome"
              placeholder="Please select"
              required
              data={outreachDispositionOptions}
              {...form.getInputProps('outcome')}
            />
            <Textarea label="Touchpoint notes" {...form.getInputProps('notes')} autosize minRows={3} maxRows={5} />

            <Select
              label="TOC task progress"
              placeholder="Please select"
              required
              data={tocTaskProgressOptions}
              {...form.getInputProps('tocTaskProgress')}
            />
            <Divider my="sm" size="sm" />
            <SelectWithSearch
              label="Assign next task to"
              assign={onAssignToTeamMember}
              search={onSearch}
              listOptions={practitionerOptions}
              fieldValidation={() => form.validateField('assignedTo')}
              fieldError={form.errors.assignedTo}
              formValue={form.values.assignedTo}
            />
            <Group grow>
              <TextInput required label="Due date" type="date" {...form.getInputProps('dueDate')} />
              <TextInput required label="Time" type="time" {...form.getInputProps('dueTime')} />
            </Group>

            <Select
              label="Priority"
              placeholder="Please select"
              data={[
                { value: 'routine', label: 'Routine' },
                { value: 'urgent', label: 'Urgent' },
                { value: 'asap', label: 'ASAP' },
                { value: 'stat', label: 'STAT' },
              ]}
              {...form.getInputProps('priority')}
            />

            <Textarea label="Notes" {...form.getInputProps('taskNotes')} autosize minRows={3} maxRows={5} />

            <Group justify="flex-end" mt="md">
              <Button variant="subtle" onClick={close}>
                Cancel
              </Button>
              <Button
                variant="filled"
                type="submit"
                onClick={saveTOCTouchpointData}
                loading={submitting}
                disabled={!form.values.outcome || !form.values.tocTaskProgress || !form.values.assignedTo}
              >
                Add touchpoint and update task
              </Button>
            </Group>
          </Stack>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
