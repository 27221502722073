import React, { PropsWithChildren, createContext, useContext, useState } from 'react';
import { GetPatientQuery, Patient } from 'medplum-gql';
import { Coding } from '@medplum/fhirtypes';
import { useMedplum } from '@medplum/react';
import { notifications } from '@mantine/notifications';
import { isEmpty } from 'lodash';
import { ApolloQueryResult } from '@apollo/client';
import { getErrorMessage } from '@/errors';
import { RELATIONSHIP_OTHER } from '@/components/shared/constants';

interface LinkPatientProps {
  addSelectedCaregiverForPatient: (caregiverId: string, patientId: string) => void;
  onRelationshipSelected: (patientId: string, value: Coding | undefined) => void;
  onContactTypeSelected: (patientId: string, value: Coding | undefined) => void;
  onCreateLinkClick: (patientToLink: Patient, patient: Patient) => void;
  onNewLinkClick: (patientToLink: Patient) => void;
  linkingLoading: (patientId: string) => boolean;
  linkingDisabled: (patientId: string) => boolean;
  linking: (patientId: string) => boolean;
  selectedRelationship: { [key: string]: { code?: string; display?: string } | undefined };
  selectedContactType: { [key: string]: { code?: string; display?: string } | undefined };
  selectedCaregiverForPatient: Record<string, string>;
  isOtherContactRelationshipSelected: boolean;
  isOtherContactTypeSelected: boolean;
  customRelationship: string;
  customContactType: string;
  setCustomRelationship: (value: string) => void;
  setCustomContactType: (value: string) => void;
}

const LinkPatientContext = createContext({} as LinkPatientProps);

export const useLinkPatient = (): LinkPatientProps => {
  return useContext(LinkPatientContext);
};

export const LinkPatientProvider = ({
  children,
  refetchPatient,
}: PropsWithChildren<{ refetchPatient: () => Promise<ApolloQueryResult<GetPatientQuery>> }>): JSX.Element => {
  const medplum = useMedplum();
  const [selectedRelationship, setSelectedRelationship] = useState<{
    [key: string]: { code?: string; display?: string } | undefined;
  }>({});
  const [selectedContactType, setSelectedContactType] = useState<{
    [key: string]: { code?: string; display?: string } | undefined;
  }>({});
  const [selectedCaregiverForPatient, setSelectedCaregiverForPatient] = useState<Record<string, string>>({});
  const [isOtherContactRelationshipSelected, setIsOtherContactRelationshipSelected] = useState<boolean>(false);
  const [isOtherContactTypeSelected, setIsOtherContactTypeSelected] = useState<boolean>(false);
  const [customRelationship, setCustomRelationship] = useState<string>('');
  const [customContactType, setCustomContactType] = useState<string>('');
  const [isLinkingLoadingMap, setIsLinkingLoadingMap] = useState<{ [key: string]: boolean }>({});
  const [isLinkingMap, setIsLinkingMap] = useState<{ [key: string]: boolean }>({});

  const onRelationshipSelected = (patientId: string, value: Coding | undefined): void => {
    setSelectedRelationship((prev) => ({
      ...prev,
      [patientId]: value ? { code: value.code, display: value.display } : undefined,
    }));
    if (value?.code === RELATIONSHIP_OTHER) {
      setIsOtherContactRelationshipSelected(true);
    } else {
      setCustomRelationship('');
      setIsOtherContactRelationshipSelected(false);
    }
  };

  const onContactTypeSelected = (patientId: string, value: Coding | undefined): void => {
    setSelectedContactType((prev) => ({
      ...prev,
      [patientId]: value ? { code: value.code, display: value.display } : undefined,
    }));
    if (value?.code === 'other') {
      setIsOtherContactTypeSelected(true);
    } else {
      setIsOtherContactTypeSelected(false);
      setCustomContactType('');
    }
  };

  const addSelectedCaregiverForPatient = (caregiverId: string, patientId: string): void => {
    setSelectedCaregiverForPatient((prev) => ({ ...prev, [patientId]: caregiverId }));
  };

  const onCreateLinkClick = (patientToLink: Patient, patient: Patient): void => {
    if (isLinkingLoadingMap[patientToLink.id!]) {
      return;
    }
    setIsLinkingLoadingMap((prev) => ({ ...prev, [patientToLink.id!]: true }));

    const caregiverId = selectedCaregiverForPatient[patientToLink.id!];
    const body = JSON.stringify({
      primaryPatientId: patient.id,
      linkPatientId: patientToLink.id,
      relationship: customRelationship
        ? { code: customRelationship, display: customRelationship }
        : selectedRelationship[patientToLink.id!],
      contactType: customContactType ? customContactType : selectedContactType[patientToLink.id!]?.display,
      caregiverId,
    });

    fetch('/api/patients/links', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + medplum.getAccessToken(),
        'Content-Type': 'application/json',
      },
      body,
    })
      .then((response) => response.json())
      .then(async (_data) => {
        setIsLinkingMap(({ [patientToLink.id!]: _, ...rest }) => ({ ...rest }));
        await refetchPatient().catch((err) =>
          notifications.show({
            title: 'Error refetching patient',
            message: getErrorMessage(err),
            color: 'status-error',
          }),
        );
      })
      .catch((err) => {
        notifications.show({
          title: 'Error linking patient',
          message: getErrorMessage(err),
          color: 'status-error',
        });
      })
      .finally(() => {
        setIsLinkingLoadingMap(({ [patientToLink.id!]: _, ...rest }) => ({ ...rest }));
      });
  };

  const onNewLinkClick = (patientToLink: Patient): void => {
    setIsLinkingMap((prev) => ({ ...prev, [patientToLink.id!]: true }));
  };

  const linkingDisabled = (patientId: string): boolean => {
    return (
      isEmpty(selectedCaregiverForPatient[patientId]) ||
      !selectedRelationship[patientId] ||
      !selectedContactType[patientId] ||
      (isOtherContactRelationshipSelected && !customRelationship) ||
      (isOtherContactTypeSelected && !customContactType)
    );
  };
  const linkingLoading = (patientId: string): boolean => {
    return isLinkingLoadingMap[patientId];
  };
  const linking = (patientId: string): boolean => {
    return isLinkingMap[patientId];
  };

  const value = {
    onRelationshipSelected,
    onContactTypeSelected,
    addSelectedCaregiverForPatient,
    onCreateLinkClick,
    onNewLinkClick,
    linkingLoading,
    linkingDisabled,
    linking,
    selectedCaregiverForPatient,
    selectedRelationship,
    selectedContactType,
    isOtherContactRelationshipSelected,
    isOtherContactTypeSelected,
    customRelationship,
    customContactType,
    setCustomRelationship,
    setCustomContactType,
  };

  return <LinkPatientContext.Provider value={value}>{children}</LinkPatientContext.Provider>;
};
