import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFeatureFlags } from '@/hooks/useFeatureFlags';
import { OutreachPanel } from '../../components/patientPanel/panels/OutreachPanel';
import BHTocPanel from '../../components/patientPanel/panels/BHTocPanel';
import { PanelType } from '@/components/patientPanel/types';
import { useDocumentTitle } from '@mantine/hooks';

export const PatientPanelPage = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const flags = useFeatureFlags();
  const isBHTocEnabled = flags.BHTocV2;

  const currentPanel = searchParams.get('panel') || PanelType.OUTREACH;
  const title = currentPanel === PanelType.BH_TOC ? 'BH TOC' : 'Outreach';

  useDocumentTitle(`${title} Patient Panel - Care Hub`);

  if (currentPanel === PanelType.BH_TOC && !isBHTocEnabled) {
    return <OutreachPanel />;
  }

  if (currentPanel === PanelType.BH_TOC) {
    return <BHTocPanel />;
  }

  return <OutreachPanel />;
};

export default PatientPanelPage;
