import React from 'react';
import { useClinicalPods } from '@/hooks/usePods';
import { Menu, Button, ScrollArea, Checkbox, Text } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { useSearchParams } from 'react-router-dom';

/**
 * A component that displays clinical pods with 'All' and 'Unassigned' options.
 * Utilizes URLSearchParams 'pod' param name.
 *
 * @example
 * <ClinicalPodFilter />
 */
export const ClinicalPodFilter = (): JSX.Element => {
  const pods = useClinicalPods();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedPodIds = searchParams.get('pod')?.split(',') ?? [];
  const hasSelectedPods = selectedPodIds.length > 0;

  const setSelectedPod = (podId: string | null | undefined) => {
    if (!podId) {
      return;
    }

    if (podId === 'Unassigned') {
      searchParams.delete('pod');
      searchParams.set('pod', podId);
      setSearchParams(searchParams);
      return;
    }
    if (podId === 'All') {
      searchParams.delete('pod');
      setSearchParams(searchParams);
      return;
    }

    const validPodIds = selectedPodIds.filter((id) => id !== 'Unassigned'); // Remove Unassigned when a pod is selected
    const newSelectedPodIds = validPodIds.includes(podId)
      ? validPodIds.filter((id) => id !== podId)
      : [...validPodIds, podId];

    if (newSelectedPodIds.length === 0) {
      searchParams.delete('pod'); // Reset to All
    } else {
      searchParams.set('pod', newSelectedPodIds.join(','));
    }

    setSearchParams(searchParams);
  };

  return (
    <Menu closeOnItemClick={false} position="bottom-start" shadow="md">
      <Menu.Target>
        <Button
          variant="outline"
          bg={hasSelectedPods ? 'var(--mantine-color-primary-50-1)' : 'white'}
          rightSection={<IconChevronDown size={14} />}
        >
          <Text pl={5} fw={hasSelectedPods ? 'bold' : 'normal'}>
            {hasSelectedPods ? `Pod (${selectedPodIds.length})` : 'Pod'}
          </Text>
        </Button>
      </Menu.Target>
      <Menu.Dropdown w={250} p={10}>
        <ScrollArea h={250}>
          <Menu.Item
            p={10}
            leftSection={<Checkbox checked={selectedPodIds.length === 0} onChange={() => setSelectedPod('All')} />}
          >
            All
          </Menu.Item>
          {pods.pods?.map(
            (pod) =>
              pod?.id && (
                <Menu.Item
                  p={10}
                  key={pod.id}
                  leftSection={
                    <Checkbox checked={selectedPodIds.includes(pod.id)} onChange={() => setSelectedPod(pod.id)} />
                  }
                >
                  {pod.name}
                </Menu.Item>
              ),
          )}
          <Menu.Item
            p={10}
            leftSection={
              <Checkbox checked={selectedPodIds.at(0) === 'Unassigned'} onChange={() => setSelectedPod('Unassigned')} />
            }
          >
            Unassigned pod
          </Menu.Item>
        </ScrollArea>
      </Menu.Dropdown>
    </Menu>
  );
};
