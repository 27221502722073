import React from 'react';
import { Flex, Group, Text, Button, Tooltip } from '@mantine/core';
import { IconToggleLeft, IconPointFilled } from '@tabler/icons-react';

interface PodMappingSubheaderProps {
  podStatus: string;
  podName: string;
  openStatusModal: () => void;
}

export const PodMappingSubheader = ({ podStatus, podName, openStatusModal }: PodMappingSubheaderProps): JSX.Element => {
  return (
    <Group justify="space-between">
      <h3>{podName}</h3>
      <Group>
        <Flex>
          <Text>Pod status:</Text>
          <IconPointFilled size="20" color={podStatus === 'active' ? 'green' : 'black'} />
          <Text>{podStatus}</Text>
        </Flex>
        <Tooltip
          position="bottom"
          label="Only inactive pods can be updated"
          events={{ hover: podStatus !== 'inactive', focus: false, touch: false }}
        >
          <Button disabled={podStatus !== 'inactive'} size="xs" variant="outline" onClick={openStatusModal}>
            <IconToggleLeft size={18} stroke={1.5} />
            <Text size="xs" ml="sm">
              Update status
            </Text>
          </Button>
        </Tooltip>
      </Group>
    </Group>
  );
};
