import { CommunicationRequest, Reference, Patient, Encounter } from '@medplum/fhirtypes';
import { ResourceBuilderParams } from './resource';
import { System } from 'const-utils';
import { BHTOCTaskProgress, CommunicationRequestType } from 'const-utils/codeSystems/ImaginePediatrics';
import { CommunicationRequest as CommunicationRequestModel } from 'imagine-dsl/models/communications/communicationRequest';

type ManagedFields = {
  subject: NonNullable<CommunicationRequest['subject']>;
  requestType: string;
  status?: CommunicationRequest['status'];
};

type CreateCommunicationRequestParams = ResourceBuilderParams<CommunicationRequest, ManagedFields>;

/**
 * Builds a CommunicationRequest resource with the provided parameters
 *
 * @param args - the details of the communication request to create
 * @param args.subject - the resource the communication request is for (usually a Patient)
 * @param args.requestType - the type of communication request which will be used in the identifier
 * @param args.status - the status of the communication request, defaults to 'active'
 * @returns a CommunicationRequest object that can be used to create a CommunicationRequest resource
 */
export const buildCommunicationRequest = (args: CreateCommunicationRequestParams): CommunicationRequest => {
  const { subject, requestType, status, ...rest } = args;

  const now = new Date().toISOString();
  return {
    resourceType: 'CommunicationRequest',
    status: status || 'active',
    subject,
    authoredOn: now,
    identifier: [
      {
        system: System.CommunicationRequestType,
        value: requestType,
      },
      ...(args.identifier || []),
    ],
    ...rest,
  };
};

/**
 * Builds a BH TOC CommunicationRequest resource
 *
 * @param subject - the patient the communication request is for
 * @param encounter - the encounter the communication request is related to
 * @returns a CommunicationRequest object for BH TOC
 */
export const buildBHTOCCommunicationRequest = (
  subject: Reference<Patient>,
  encounter: Reference<Encounter>,
): CommunicationRequest => {
  return buildCommunicationRequest({
    subject,
    requestType: CommunicationRequestType.BHTOC,
    encounter,

    statusReason: {
      coding: [
        {
          system: System.BHTOCTaskProgress,
          code: BHTOCTaskProgress.NotStarted,
        },
      ],
    },
  });
};

interface CommunicationRequestFilters {
  selectedPodIds: string[];
  selectedAlertType: string | null;
}

export const filterAndSortCommunicationRequestModels = (
  communicationRequests: CommunicationRequestModel[] | undefined,
  filters: CommunicationRequestFilters,
) => {
  return communicationRequests
    ?.filter((communicationRequest) => {
      //Pods filter
      if (filters.selectedPodIds.length === 0) {
        return true;
      }
      if (filters.selectedPodIds.includes('Unassigned')) {
        return !communicationRequest.pod;
      }
      return communicationRequest.pod && filters.selectedPodIds.includes(communicationRequest.pod?.id);
    })
    .filter((communicationRequest) => {
      // Alert type filter
      if (!filters.selectedAlertType) return true;

      return communicationRequest.alertType === filters.selectedAlertType;
    })
    .sort((a: CommunicationRequestModel, b: CommunicationRequestModel) => {
      if (!a.dischargeDate) return 1;
      if (!b.dischargeDate) return -1;

      return a.dischargeDate > b.dischargeDate ? -1 : 1;
    });
};
