import { QuestionnaireType } from './Questionnaires';

export enum ConsentType {
  CaregiverConsent = 'caregiver-consent',
  PatientConsent = 'patient-consent',
  ConsentToTreatAndTelehealth = 'Consent to Treat and Telehealth',
  Hixny = 'HIXNY Electronic Data Access Consent Form',
  ReleaseOfInformation = 'Release of information consent',
  Eula = 'End User License Agreement',
}

export enum ConsentIdentifier {
  ConsentToTreat = 'https://imaginepediatrics.org/consentToTreat',
  EULA = 'https://imaginepediatrics.org/eula',
  ReleaseOfInformation = 'https://imaginepediatrics.org/releaseOfInformation',
}

export const LegacyConsentIdentifier: Partial<Record<QuestionnaireType, string>> = {
  'consent-to-treat': 'TreatAndTelehealthConsents.pdf',
  'release-of-information': 'RoiConsent.pdf',
  'end-user-license-agreement': 'ULA.pdf',
};

export enum ConsentQuestionnaireLinkId {
  ConsentAccept = 'consent-accept',
}
export const ConsentCode: Partial<Record<QuestionnaireType, string>> = {
  'consent-to-treat': 'Consent to Treat and Telehealth',
  'release-of-information': 'Release of information consent',
  'end-user-license-agreement': 'End User License Agreement',
  'hixny-consent': 'HIXNY Electronic Data Access Consent Form',
};

export enum ConsentResponse {
  Accept = 'consent-accept',
  Deny = 'consent-deny',
}
export const ConsentResponses: Partial<Record<ConsentResponse, string>> = {
  'consent-accept': 'Accept',
  'consent-deny': 'Deny',
};
