import { Organization, Practitioner } from '@medplum/fhirtypes';
import { System } from 'const-utils';

export const getMarketIdentifier = (managingOrganization?: Organization): string | undefined => {
  const marketIdentifier = managingOrganization?.identifier?.find(
    (identifier) => identifier.system === System.Organization,
  );

  return marketIdentifier?.value;
};

export const getAthenaProviderIdForMarket = (provider: Practitioner, marketIdentifier: string): string | undefined => {
  const athenaProviderIdentifier = provider.identifier?.find(
    (i) =>
      i.system === System.AthenaId &&
      i.extension?.find(
        (marketExtension) =>
          marketExtension.url === System.MarketIdentifier && marketExtension.valueString === marketIdentifier,
      ),
  );

  return athenaProviderIdentifier?.value?.trim();
};
