import { Button, Modal, Text } from '@mantine/core';
import React, { useState } from 'react';
import { Patient, RelatedPerson } from '@medplum/fhirtypes';
import { patientTempCredentials } from '../helpers/PatientTempCredentials';
import { useApiClient } from '@/hooks/useApiClient';
import { SelectInput } from '../helpers/SelectInput';
import { notifications } from '@mantine/notifications';
import { getErrorMessage, logError } from '@/errors';

interface ExtendedRelatedPerson extends RelatedPerson {
  PatientList?: Patient[];
}

interface credentialsProps {
  selectedPerson: ExtendedRelatedPerson;
  setSelectedPerson: (value: ExtendedRelatedPerson | undefined) => void;
}

const PatientCredentialsModal = ({ selectedPerson, setSelectedPerson }: credentialsProps): JSX.Element => {
  const [credentials, setCredentials] = useState<string>('XXXXXXXXXX');
  const [loading, setLoading] = useState<boolean>(false);

  const caregiver = selectedPerson?.PatientList?.[0];

  const caregiverId = caregiver?.id;
  const phoneNumbers = caregiver?.telecom
    ?.filter((telecom) => telecom.system === 'phone')
    .map((telecom) => telecom.value)
    .filter(Boolean) as string[];

  const [phoneNumber, setPhoneNumber] = useState<string>(phoneNumbers?.[0] || '');

  const apiClient = useApiClient();

  const handleSubmit = async (): Promise<void> => {
    setLoading(true);
    try {
      const response = await patientTempCredentials(apiClient, caregiverId!, phoneNumber);
      setCredentials(response.generatedPassword);

      if (!response.success) {
        notifications.show({
          title: 'Error sending temporary password to caregiver',
          message: getErrorMessage(response.error),
          color: 'status-warn',
        });
        logError(response.error);
      }
    } catch (error: unknown) {
      notifications.show({
        title: 'Error',
        message: getErrorMessage(error),
        color: 'status-error',
      });
      logError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={
        <Text size="sm" fw={700} c="imagine-green">
          Generate new credentials
        </Text>
      }
      opened={!!selectedPerson}
      onClose={() => {
        setSelectedPerson(undefined);
      }}
      size="lg"
      radius="lg"
      centered
    >
      <Text fz="xs" mb="sm" c="dimmed">
        <strong>NOTE:</strong> This will be the phone number the caregiver uses to log in
      </Text>
      <SelectInput
        label="Select phone number"
        onChange={setPhoneNumber}
        withAsterisk
        value={phoneNumber}
        data={phoneNumbers}
      />
      <Text>
        Generate temporary password<span style={{ color: 'red' }}>*</span>
      </Text>
      <Text fz="xs" mb="sm" c="dimmed">
        Generate a temporary password the caregiver can use to log in for the first time.
      </Text>
      <Text fz="md" mb="sm">{`Temporary password: ${credentials ? credentials : 'XXXXXXX'}`}</Text>
      <Button
        variant="outline"
        color="imagine-green"
        radius="md"
        onClick={() => {
          setSelectedPerson(undefined);
        }}
        style={{ marginRight: '10px' }}
      >
        Cancel
      </Button>
      <Button
        onClick={handleSubmit}
        type="submit"
        color="imagine-green"
        radius="md"
        disabled={loading}
        loading={loading}
      >
        Generate Temporary Password
      </Button>
    </Modal>
  );
};

export default PatientCredentialsModal;
