import React, { createContext, useContext, ReactNode, useState, SetStateAction, Dispatch } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { Basic } from '@medplum/fhirtypes';

interface AddPostalCodesModalContext {
  engagementPodMappingResource: Basic | undefined;
  setEngagementPodMappingResource: Dispatch<SetStateAction<Basic | undefined>>;
  opened: boolean;
  handleClosePostalCodesModal: () => void;
  open: () => void;
  postalCodesToAdd: string[];
  setPostalCodesToAdd: Dispatch<SetStateAction<string[]>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  postalCodesUploaded: boolean;
  setPostalCodesUploaded: Dispatch<SetStateAction<boolean>>;
  postalCodeDuplicates: PostalCodeDuplicate[];
  setPostalCodeDuplicates: Dispatch<SetStateAction<PostalCodeDuplicate[]>>;
  duplicatesResolved: boolean;
  setDuplicatesResolved: Dispatch<SetStateAction<boolean>>;
}

export interface PostalCodeDuplicate {
  postalCode: string;
  basicId: string;
  currentPodName: string;
  duplicateAction?: string;
}

const AddPostalCodesmodalContextValue = createContext<AddPostalCodesModalContext | undefined>(undefined);

export const AddPostalCodesModalProvider = ({ children }: { children: ReactNode }) => {
  const [engagementPodMappingResource, setEngagementPodMappingResource] = useState<Basic>();
  const [opened, { close, open }] = useDisclosure();
  const [postalCodesToAdd, setPostalCodesToAdd] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [postalCodesUploaded, setPostalCodesUploaded] = useState<boolean>(false);
  const [postalCodeDuplicates, setPostalCodeDuplicates] = useState<PostalCodeDuplicate[]>([]);
  const [duplicatesResolved, setDuplicatesResolved] = useState<boolean>(false);

  const handleClosePostalCodesModal = () => {
    setPostalCodesUploaded(false);
    setPostalCodeDuplicates([]);
    setPostalCodesToAdd([]);
    setDuplicatesResolved(false);
    close();
  };
  return (
    <AddPostalCodesmodalContextValue.Provider
      value={{
        engagementPodMappingResource,
        setEngagementPodMappingResource,
        opened,
        handleClosePostalCodesModal,
        open,
        postalCodesToAdd,
        setPostalCodesToAdd,
        isLoading,
        setIsLoading,
        postalCodesUploaded,
        setPostalCodesUploaded,
        postalCodeDuplicates,
        setPostalCodeDuplicates,
        duplicatesResolved,
        setDuplicatesResolved,
      }}
    >
      {children}
    </AddPostalCodesmodalContextValue.Provider>
  );
};

export const useAddPostalCodesModal = () => {
  const context = useContext(AddPostalCodesmodalContextValue);
  if (!context) {
    throw new Error('useAddPostalCodesModal must be used within a AddPostalCodesModalProvider');
  }
  return context;
};
