import { Practitioner, TouchpointFragment } from 'medplum-gql';
import { getCommunicationNoteValueForExtension } from '../../utils/communication';
import { getPractitionerRoleFromTouchpoint } from '../../utils/practitionerRole';
import { OutreachAttempt } from '../../utils/outreach';
import { getCodingDisplayForValueSet } from 'const-utils/valueSets/selectValue';
import { CommunicationNoteType, OutreachCallDisposition } from 'const-utils';
import { Coding } from '@medplum/fhirtypes';
import { OutreachModality } from 'const-utils/codeSystems/ImaginePediatrics';
import { getName } from '../../utils/patient';

export class TaskTouchpoint {
  constructor(public touchpoint: TouchpointFragment) {
    this.touchpoint = touchpoint;
  }

  get senderName(): string {
    const sender = this.touchpoint?.sender?.resource as Practitioner;
    return getName(sender) || 'Unknown';
  }

  get senderRole(): string {
    return getPractitionerRoleFromTouchpoint(this.touchpoint) || 'Unknown';
  }

  get sentTime(): Date | undefined {
    return this.touchpoint.sent ? new Date(this.touchpoint.sent) : undefined;
  }

  get outcome(): string {
    const statusReason = this.touchpoint.statusReason?.coding?.[0];
    if (statusReason) {
      return getCodingDisplayForValueSet(OutreachCallDisposition, statusReason as Coding);
    }

    return 'Unknown';
  }

  get phoneNumber(): string | undefined {
    return getCommunicationNoteValueForExtension(this.touchpoint, CommunicationNoteType.PhoneNumber);
  }

  get modality(): OutreachModality {
    return getCommunicationNoteValueForExtension(
      this.touchpoint,
      CommunicationNoteType.OutreachModality,
    ) as OutreachModality;
  }

  get contactName(): string | undefined {
    return getCommunicationNoteValueForExtension(this.touchpoint, CommunicationNoteType.ContactName);
  }

  get callbackDateTimeStart(): Date | undefined {
    const callbackStart = getCommunicationNoteValueForExtension(this.touchpoint, CommunicationNoteType.CallbackStart);
    return callbackStart ? new Date(callbackStart) : undefined;
  }

  get callbackDateTimeEnd(): Date | undefined {
    const callbackEnd = getCommunicationNoteValueForExtension(this.touchpoint, CommunicationNoteType.CallbackEnd);
    return callbackEnd ? new Date(callbackEnd) : undefined;
  }

  get callbackTimeZone(): string | undefined {
    return getCommunicationNoteValueForExtension(this.touchpoint, CommunicationNoteType.CallbackTimezone);
  }

  get note(): string | undefined {
    return getCommunicationNoteValueForExtension(this.touchpoint, CommunicationNoteType.Notes);
  }

  get touchpointData(): OutreachAttempt {
    return {
      senderName: this.senderName,
      senderRole: this.senderRole,
      sentTime: this.sentTime,
      outcome: this.outcome,
      phoneNumber: this.phoneNumber || undefined,
      modality: this.modality || undefined,
      contactName: this.contactName || undefined,
      callbackDateTimeStart: this.callbackDateTimeStart,
      callbackDateTimeEnd: this.callbackDateTimeEnd,
      callbackTimezone: this.callbackTimeZone,
      note: this.note,
    };
  }
}
