import { useMedplum } from '@medplum/react';
import { OrganizationType } from 'const-utils/codeSystems/ImaginePediatrics';
import { useCallback } from 'react';
import { fetchOptions, Identifier } from 'imagine-dsl/services/organizationService';

export const useMarketOptions = (): { fetchMarketOptions: () => Promise<Identifier[]> } => {
  const medplum = useMedplum();

  const fetchMarketOptions = useCallback(async () => {
    try {
      const identifiers = await fetchOptions(medplum, OrganizationType.Market);
      return identifiers;
    } catch (err) {
      throw new Error('Error fetching market options');
    }
  }, [medplum]);

  return { fetchMarketOptions };
};
