import React, { useState } from 'react';
import { Avatar, Button, Group, Paper, Text, Anchor, Stack, Badge, Box, Tooltip } from '@mantine/core';
import { ExtendedRelatedPerson } from '@/components/patientProfile/handlers/patientContactForm';
import { formatHumanName } from '@medplum/core';
import { IconKey, IconPencil, IconDeviceMobile, IconWorld } from '@tabler/icons-react';
import { Patient, RelatedPerson } from '@medplum/fhirtypes';
import PatientCredentialsModal from '../patientProfile/modals/PatientCredentialsModal';
import { SendAppDownloadLinkModal } from '../patientProfile/modals/SendAppDownloadLinkModal';
import PatientContactModal from '../patientProfile/modals/PatientContactModal';
import {
  getContactType,
  getLinkedPatientsCount,
  getPatientLanguagePreference,
  getPatientRequiresInterpreter,
  getRelationshipType,
  getSmsConsent,
} from 'imagine-dsl/utils/patient';
import { getInitials } from 'imagine-dsl/utils/strings';
import { ApolloQueryResult } from '@apollo/client';
import { GetPatientQuery } from 'medplum-gql';

interface CaregiverHeaderProps {
  contact: ExtendedRelatedPerson;
  patient: Patient;
  onClose: () => void;
  refetch: () => Promise<ApolloQueryResult<GetPatientQuery>>;
}

interface ExtendedRelatedPersonModalProps extends RelatedPerson {
  PatientList?: Patient[];
}

type ModalType = 'credentials' | 'download' | 'editContact' | null;

export function CaregiverHeader({ contact, patient, onClose, refetch }: CaregiverHeaderProps) {
  const [activeModal, setActiveModal] = useState<ModalType>(null);
  const caregiver = contact?.PatientList?.[0];
  const relationship = contact?.relationship;
  const caregiverName = formatHumanName(caregiver?.name?.[0] ?? { given: [], family: '' });
  const patientName = formatHumanName(patient?.name?.[0] ?? { given: [], family: '' });

  const requiresInterpreter =
    getPatientRequiresInterpreter(caregiver?.extension ?? []) ||
    getPatientRequiresInterpreter(patient?.extension ?? []);
  const language =
    getPatientLanguagePreference(caregiver?.communication ?? []) ||
    getPatientLanguagePreference(patient?.communication ?? []);
  const photoUrl = caregiver?.photo?.[0]?.url;
  const relationshipType = getRelationshipType(relationship);
  const contactType = getContactType(relationship);
  const smsOptIn = getSmsConsent(caregiver);
  const numberOfLinkedPatients = getLinkedPatientsCount(contact as RelatedPerson, patient.id);

  return (
    <Paper p="xl" radius={0} bg="white">
      <Group align="flex-start">
        <Anchor>
          <Avatar src={photoUrl} alt="Caregiver photo" size={100} color="imagine-green" radius={9999}>
            {getInitials(caregiverName)}
          </Avatar>
        </Anchor>

        <Stack gap="xs" pl="xl">
          <Stack gap={4}>
            <Group gap="xs">
              <Text fw={700} size="xl" c="imagine-green">
                {caregiverName}
              </Text>
              {language && (
                <Group gap={8}>
                  <Badge
                    leftSection={<IconWorld size={14} />}
                    styles={{
                      root: {
                        backgroundColor: 'var(--mantine-color-blue-0)',
                        color: 'var(--mantine-color-blue-9)',
                        textTransform: 'none',
                        padding: '4px 8px',
                        height: 'auto',
                      },
                    }}
                  >
                    {language}
                  </Badge>
                  {requiresInterpreter && <Text size="sm">Requires interpreter</Text>}
                </Group>
              )}
            </Group>
            <Text size="sm" c="dimmed">
              {contactType?.[0] ? `${contactType} and` : ''} {relationshipType} to{' '}
              <Anchor fw={700} underline="always" onClick={onClose}>
                {patientName}
              </Anchor>{' '}
              and caregiver of{' '}
              <Box fw={700} c="imagine-green" style={{ display: 'inline' }}>
                {numberOfLinkedPatients} other patients
              </Box>
            </Text>
          </Stack>

          <Group gap="xs" mt="sm">
            <Button
              variant="outline"
              onClick={() => setActiveModal('editContact')}
              leftSection={<IconPencil size={16} />}
            >
              Edit caregiver information
            </Button>
            <Button variant="outline" onClick={() => setActiveModal('credentials')} leftSection={<IconKey size={16} />}>
              Reset password
            </Button>

            <Tooltip label="Caregiver must opt in to SMS to send an app download link" disabled={smsOptIn}>
              <Button
                variant="outline"
                onClick={() => setActiveModal('download')}
                leftSection={<IconDeviceMobile size={16} />}
                disabled={!smsOptIn}
              >
                Send app download link
              </Button>
            </Tooltip>
          </Group>
        </Stack>
      </Group>

      {activeModal === 'credentials' && (
        <PatientCredentialsModal
          selectedPerson={contact as unknown as ExtendedRelatedPersonModalProps}
          setSelectedPerson={() => setActiveModal(null)}
        />
      )}

      {activeModal === 'download' && (
        <SendAppDownloadLinkModal
          selectedPerson={contact as unknown as ExtendedRelatedPersonModalProps}
          setSelectedPerson={() => setActiveModal(null)}
        />
      )}

      {activeModal === 'editContact' && (
        <PatientContactModal
          key={contact?.PatientList?.[0]?.id}
          selectedPerson={contact as unknown as ExtendedRelatedPersonModalProps}
          patient={patient}
          setSelectedPerson={() => setActiveModal(null)}
          isModalOpen={!!contact}
          closeModal={() => {
            setActiveModal(null);
            onClose();
          }}
          label="Edit contact"
          refetch={refetch}
          openAddContactModal={() => {
            setActiveModal('editContact');
          }}
        />
      )}
    </Paper>
  );
}
