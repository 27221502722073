import React from 'react';
import { Modal, Stack } from '@mantine/core';
import { Patient } from '@medplum/fhirtypes';
import { ExtendedRelatedPerson } from '@/components/patientProfile/handlers/patientContactForm';
import { ContactInfo } from '@/components/caregiverProfile/ContactInfo';
import { PreferencesAndSettings } from '@/components/caregiverProfile/PreferencesAndSettings';
import { CaregiverHeader } from '@/components/caregiverProfile/Caregiverheader';
import { ApolloQueryResult } from '@apollo/client';
import { GetPatientQuery } from 'medplum-gql';

interface CaregiverProfileProps {
  opened: boolean;
  onClose: () => void;
  contact: ExtendedRelatedPerson;
  patient: Patient;
  refetch: () => Promise<ApolloQueryResult<GetPatientQuery>>;
}

export function CaregiverProfileModal({ opened, onClose, contact, patient, refetch }: CaregiverProfileProps) {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      size="auto"
      padding={0}
      styles={{
        header: { display: 'none' },
      }}
    >
      <CaregiverHeader contact={contact} patient={patient} onClose={onClose} refetch={refetch} />

      <Stack p="xl" gap="xl">
        <ContactInfo contact={contact} />
        <PreferencesAndSettings contact={contact} />
      </Stack>
    </Modal>
  );
}
