import { useState, useEffect } from 'react';

type PayorData = {
  name?: string;
  id?: string;
  status?: string;
};

export const usePayorForm = (payorData?: PayorData) => {
  const [formData, setFormData] = useState({
    name: payorData?.name || '',
    id: payorData?.id || '',
    status: payorData?.status || 'inactive',
  });

  useEffect(() => {
    if (payorData) {
      setFormData({
        name: payorData.name || '',
        id: payorData.id || '',
        status: payorData.status || 'inactive',
      });
    }
  }, [payorData]);

  const handleInputChange = (field: string, value: any) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  return { formData, handleInputChange };
};
