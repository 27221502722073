import React, { useEffect, useState } from 'react';
import { Table, Text, LoadingOverlay, Badge, Button, Box } from '@mantine/core';
import { useFetchMarkets, extractMarketExtensions } from '@/hooks/MarketHooks/useFetchMarkets';
import { Pagination } from '@/design-system/Pagination';
import { MarketModal } from './MarketModal';
import { EMPTY_DATA_PLACEHOLDER } from 'const-utils/utils/constants';
import { notifications } from '@mantine/notifications';

type Market = {
  id: string;
  name: string;
  status: boolean;
  athenaId?: number;
  acuteCare?: boolean;
  prapare?: boolean;
  extension: Array<{ url: string; valueString?: string; valueCode?: string; valueBoolean?: boolean }>;
};

const PAGE_SIZE = 10;

export const MarketsPage = ({ refreshKey }: { refreshKey: number }) => {
  const [markets, setMarkets] = useState<Market[]>([]);
  const { fetchMarkets } = useFetchMarkets();
  const [loading, setLoading] = useState(true);
  const [modalOpened, setModalOpened] = useState(false);
  const [selectedMarket, setSelectedMarket] = useState<Market | null>(null);

  const fetchAndUpdateMarkets = async () => {
    setLoading(true);
    try {
      const marketData = await fetchMarkets();
      setMarkets(
        marketData.map((market) => ({
          ...market,
          athenaId: market.athenaId ? Number(market.athenaId) : undefined,
        })) || [],
      );
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: `Failed to fetch markets: ${error}`,
        color: 'status-error',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAndUpdateMarkets();
  }, [refreshKey]);

  const handleEditMarket = (market: Market) => {
    setSelectedMarket(market);
    setModalOpened(true);
  };

  return (
    <Box>
      {selectedMarket && (
        <MarketModal
          mode="edit"
          opened={modalOpened}
          onClose={() => setModalOpened(false)}
          marketData={selectedMarket ? { ...selectedMarket, athenaId: selectedMarket.athenaId || 0 } : undefined}
          onUpdate={fetchAndUpdateMarkets}
        />
      )}
      <Table highlightOnHover horizontalSpacing="sm" verticalSpacing="sm">
        <Table.Thead>
          <Table.Tr>
            <Table.Th>ID</Table.Th>
            <Table.Th>Name</Table.Th>
            <Table.Th>Feature(s)</Table.Th>
            <Table.Th>Status</Table.Th>
            <Table.Th>Athena ID</Table.Th>
            <Table.Th>Actions</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {loading ? (
            <Table.Tr>
              <Table.Th colSpan={6}>
                <LoadingOverlay visible zIndex={1000} overlayProps={{ radius: 'sm', blur: 2 }} />
              </Table.Th>
            </Table.Tr>
          ) : (
            markets.map((market, marketIndex) => {
              const { athenaId, prapare, acuteCare } = extractMarketExtensions(market.extension);

              return (
                <Table.Tr key={marketIndex}>
                  <Table.Td>{market.id}</Table.Td>
                  <Table.Td>{market.name}</Table.Td>
                  {acuteCare || prapare ? (
                    <Table.Td>
                      {acuteCare && <Text>Acute Care</Text>}
                      {prapare && <Text>PRAPARE</Text>}
                    </Table.Td>
                  ) : (
                    <Table.Td>{EMPTY_DATA_PLACEHOLDER}</Table.Td>
                  )}

                  <Table.Td>
                    <Badge color={market.status ? 'green' : 'red'} variant="filled">
                      {market.status ? 'Active' : 'Inactive'}
                    </Badge>
                  </Table.Td>
                  <Table.Td align="center">{athenaId && <Text>{athenaId}</Text>}</Table.Td>
                  <Table.Td align="center">
                    <Button variant="outline" onClick={() => handleEditMarket(market)}>
                      Edit
                    </Button>
                  </Table.Td>
                </Table.Tr>
              );
            })
          )}
          {markets.length === 0 && !loading && (
            <Table.Tr>
              <Table.Td colSpan={6}>
                <Text size="xl" fw={700} c="imagine-green" mt="lg" ml="lg">
                  No markets found.
                </Text>
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
      <Pagination perPage={PAGE_SIZE} count={markets.length} />
    </Box>
  );
};
