import { useMedplum } from '@medplum/react';
import { notifications } from '@mantine/notifications';
import { useCallback } from 'react';

export const useInactivatePayor = () => {
  const medplum = useMedplum();
  const inactivatePayor = useCallback(
    async (id: string) => {
      try {
        const inactivatePayor = await medplum.patchResource('Organization', id, [
          {
            op: 'replace',
            path: '/active',
            value: false,
          },
        ]);
        notifications.show({
          title: 'Payor Inactivated',
          message: 'The payor has been successfully inactivated',
          color: 'status-success',
        });
        return inactivatePayor;
      } catch (error) {
        notifications.show({
          title: 'Error',
          message: 'Failed to inactivate payor',
          color: 'status-error',
        });
        return null;
      }
    },
    [medplum],
  );
  return { inactivatePayor };
};

export const useActivatePayor = () => {
  const medplum = useMedplum();
  const activatePayor = useCallback(
    async (id: string) => {
      try {
        const activatePayor = await medplum.patchResource('Organization', id, [
          {
            op: 'replace',
            path: '/active',
            value: true,
          },
        ]);
        notifications.show({
          title: 'Payor Activated',
          message: 'The payor has been successfully activated',
          color: 'status-success',
        });
        return activatePayor;
      } catch (error) {
        notifications.show({
          title: 'Error',
          message: 'Failed to activate payor',
          color: 'status-error',
        });
        return null;
      }
    },
    [medplum],
  );
  return { activatePayor };
};
