import { MedplumClient, createReference, ProfileResource } from '@medplum/core';
import { Bundle, BundleEntry, CommunicationRequest, Reference } from '@medplum/fhirtypes';
import { buildOutreachAttemptCommunication } from './outreachService';
import { System, TaskType } from 'const-utils';
import { OutreachDisposition, OutreachModality } from 'const-utils/codeSystems/ImaginePediatrics';
import { BHTOCTaskProgress } from 'const-utils/codeSystems/ImaginePediatrics/CommunicationRequest';
import { buildOutreachTask } from '../utils/task';

interface SaveTOCTouchpointParams {
  medplum: MedplumClient;
  patientId: string;
  practitioner: ProfileResource;
  // Communication fields
  outcome: OutreachDisposition;
  notes: string;
  basedOn?: Reference[];
  // CommunicationRequest fields
  communicationRequestId?: string;
  tocTaskProgress: BHTOCTaskProgress;
  // Task fields
  taskId?: string;
  assignToPractitionerId: string;
  dueDate: string;
  priority?: string;
  taskNotes: string;
}

/**
 * Saves a TOC touchpoint as a transaction. This includes:
 * 1. Creating a Communication with the call outcome
 * 2. Updating the CommunicationRequest with TOC task progress
 * 3. Creating or updating the associated Task
 *
 * @param params - Parameters for the TOC touchpoint transaction
 * @returns The result of the batch transaction
 */
export const saveTOCTouchpoint = async (params: SaveTOCTouchpointParams): Promise<Bundle> => {
  const {
    medplum,
    patientId,
    practitioner,
    outcome,
    notes,
    basedOn,
    communicationRequestId,
    tocTaskProgress,
    taskId,
    assignToPractitionerId,
    dueDate,
    priority = 'routine',
    taskNotes,
  } = params;

  const bundleEntries: BundleEntry[] = [];

  // 1. Create Communication with the call outcome
  const communication = buildOutreachAttemptCommunication({
    patientRef: { reference: `Patient/${patientId}` },
    practitionerRef: createReference(practitioner),
    outcome: {
      code: outcome,
      display: outcome,
    },
    sent: new Date(),
    modality: OutreachModality.PhoneOutboundStandard,
    phoneNumber: '',
    notes,
    basedOn,
  });

  bundleEntries.push({
    request: {
      method: 'POST',
      url: 'Communication',
    },
    resource: communication,
  });

  // 2. Update CommunicationRequest with TOC task progress
  let communicationRequestResource: CommunicationRequest | undefined;
  if (communicationRequestId) {
    communicationRequestResource = await medplum.readResource('CommunicationRequest', communicationRequestId);

    if (communicationRequestResource) {
      communicationRequestResource.statusReason = {
        coding: [
          {
            system: System.BHTOCTaskProgress,
            code: tocTaskProgress,
          },
        ],
      };
      communicationRequestResource.sender = {
        reference: `Practitioner/${assignToPractitionerId}`,
      };

      bundleEntries.push({
        request: {
          method: 'PUT',
          url: `CommunicationRequest/${communicationRequestId}`,
        },
        resource: communicationRequestResource,
      });
    }
  }

  // 3. Create or update Task
  const task = buildOutreachTask({
    forPatientId: patientId,
    assignToPractitionerId,
    requestedByPractitionerId: practitioner.id || '',
    dueDate,
    taskType: {
      code: TaskType.ScheduleAppointmentBHTOC,
      display: 'Schedule Appointment BH TOC',
    },
    businessStatus: priority || 'routine',
    notes: taskNotes || '',
    id: taskId,
    createdDate: new Date().toISOString(),
    basedOn: communicationRequestResource ? [createReference(communicationRequestResource)] : [],
  });

  bundleEntries.push({
    request: {
      method: taskId ? 'PUT' : 'POST',
      url: taskId ? `Task/${taskId}` : 'Task',
    },
    resource: task,
  });

  // Execute the transaction
  const bundle: Bundle = {
    resourceType: 'Bundle',
    type: 'transaction',
    entry: bundleEntries,
  };

  return medplum.executeBatch(bundle);
};
