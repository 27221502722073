import React from 'react';
import { Stack, TextInput, Select, Box, Group, Button, LoadingOverlay } from '@mantine/core';
import { useCreatePayor } from '@/hooks/PayorHooks/useCreatePayor';
import { usePayorForm } from '@/hooks/PayorHooks/usePayorForm';
import { useFetchExistingPayors, useUpdatePayor } from '@/hooks/PayorHooks/useUpdatePayor';
import { notifications } from '@mantine/notifications';

type PayorFormFieldsProps = {
  payorData?: {
    name?: string;
    id?: string;
    status?: string;
    features?: string[];
  };
  onClose: () => void;
  onUpdate: () => void;
  mode: 'create' | 'edit';
};

export const PayorFormFields: React.FC<PayorFormFieldsProps> = ({ payorData, mode, onClose, onUpdate }) => {
  const { createPayor } = useCreatePayor();
  const { updatePayor } = useUpdatePayor();
  const { formData, handleInputChange } = usePayorForm(payorData);
  const { existingPayorNames } = useFetchExistingPayors();
  const [isLoading, setIsLoading] = React.useState(false);

  const handleCreatePayor = async () => {
    setIsLoading(true);

    try {
      const result = await createPayor(formData?.name, formData?.status === 'active');
      if (result) {
        notifications.show({
          title: 'Success',
          message: 'Payor created successfully',
          color: 'status-success',
        });
      }
      onClose();
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Payor creation failed',
        color: 'status-error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdatePayor = async () => {
    try {
      const result = await updatePayor(formData?.id, formData?.name, formData?.status === 'active');
      if (result) {
        notifications.show({
          title: 'Success',
          message: 'Payor updated successfully',
          color: 'status-success',
        });
        onUpdate();
      }
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Payor update failed',
        color: 'status-error',
      });
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  const handleSubmit = async () => {
    if (mode === 'create') {
      if (!formData.name || existingPayorNames.includes(formData.name)) {
        notifications.show({
          title: 'Error',
          message: 'Please enter a Payor name or select a unique payor name',
          color: 'status-error',
        });
        return;
      }
      await handleCreatePayor();
      onClose();
    } else {
      await handleUpdatePayor();
      onClose();
    }
  };

  return (
    <Box>
      <LoadingOverlay visible={isLoading} />
      <Stack gap={14} p="md">
        <TextInput
          label="Payor Name"
          placeholder="Enter Payor Name"
          required
          value={formData.name}
          onChange={(e) => handleInputChange('name', e.currentTarget.value)}
        />

        {mode === 'create' && (
          <Select
            label="Status"
            placeholder="Select Status"
            data={[
              { value: 'active', label: 'Active' },
              { value: 'inactive', label: 'Inactive' },
            ]}
            required
            value={formData.status}
            onChange={(value) => handleInputChange('status', value)}
          />
        )}
      </Stack>
      <Group justify="flex-end" ml="auto" pt={10}>
        <Button variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={mode === 'create' && !formData.name} onClick={handleSubmit}>
          {mode === 'create' ? 'Add Payor' : 'Update Payor'}
        </Button>
      </Group>
    </Box>
  );
};
