import { CareTeamMemberCandidate } from 'imagine-dsl/utils/careTeam';
import { Button, Combobox, Group, InputBase, Select, Stack, Text, useCombobox } from '@mantine/core';
import { GetPatientQuery, useSearchPractitionersQuery } from 'medplum-gql';
import React, { useMemo, useState, useCallback } from 'react';
import { CareTeamMemberRole, CareTeamMemberRoleDisplay } from 'const-utils/codeSystems/ImaginePediatrics';
import useManageInternalCareTeamMember from './useManageInternalCareTeamMember';
import { useDisclosure } from '@mantine/hooks';
import { logError } from '@/errors';
import { notifications } from '@mantine/notifications';
import { ApolloQueryResult } from '@apollo/client';
import { debounce } from 'lodash';

interface AddCareTeamMemberProps {
  cancel: () => void;
  careTeamId?: string;
  previousMemberId?: string;
  patientId: string;
  role?: CareTeamMemberRole;
  refetch: () => Promise<ApolloQueryResult<GetPatientQuery>>;
}

export default function SaveInternalCareTeamMember({
  cancel,
  careTeamId,
  previousMemberId,
  role,
  refetch,
  patientId,
}: AddCareTeamMemberProps): JSX.Element {
  const [selectedRole, setSelectedRole] = useState<CareTeamMemberRole | undefined>(role);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const { data, loading: practitionerLoading } = useSearchPractitionersQuery({
    variables: {
      name: searchQuery,
    },
  });

  const candidates = useMemo(() => {
    if (!data?.PractitionerList) return [];

    return data.PractitionerList.filter((practitioner) => practitioner?.id && practitioner?.name?.[0]).map(
      (practitioner) => {
        const name = practitioner?.name?.[0];
        const fullName = [...(name?.given || []), name?.family].filter(Boolean).join(' ');

        return {
          name: fullName || 'Unknown',
          practitionerId: practitioner?.id || '',
          role: 'Practitioner',
        };
      },
    );
  }, [data]);

  const [selected, setSelected] = useState<CareTeamMemberCandidate | undefined>(undefined);

  const isSelected = !!selected && !!selectedRole;

  const manageCareTeamMember = useManageInternalCareTeamMember(
    careTeamId,
    selected,
    selectedRole,
    previousMemberId,
    refetch,
    patientId,
    cancel,
  );
  const [loading, { open: setLoading, close: setNotLoading }] = useDisclosure(false);

  return (
    <Stack
      style={{
        width: '100%',
        height: '100%',
        minHeight: '150px',
      }}
      justify="space-between"
    >
      <Stack>
        {role === undefined && (
          <Select
            style={{ flexGrow: 1 }}
            label="Role"
            required
            onChange={(value) => setSelectedRole(value as CareTeamMemberRole)}
            data={Object.values(CareTeamMemberRole).map((r) => ({
              value: r,
              label: CareTeamMemberRoleDisplay[r],
            }))}
            placeholder="Select a practitioner role"
          />
        )}
        {selectedRole !== undefined && (
          <Text>
            You are saving a <b>{CareTeamMemberRoleDisplay[selectedRole]}</b> on the care team.
          </Text>
        )}
        <SelectPractitioner
          practitioners={candidates}
          practitioner={selected}
          setPractitioner={setSelected}
          loading={practitionerLoading}
          onSearch={setSearchQuery}
        />
      </Stack>
      <Group justify="right">
        <Button variant="outline" color="imagine-green" onClick={cancel} disabled={loading}>
          Cancel
        </Button>
        <Button
          color="imagine-green"
          loading={loading}
          disabled={!isSelected}
          onClick={async () => {
            setLoading();
            try {
              await manageCareTeamMember();
            } catch (e) {
              logError(e);
              notifications.show({
                message: 'Error saving care team member',
                color: 'status-error',
              });
            } finally {
              setNotLoading();
            }
          }}
        >
          Save
        </Button>
      </Group>
    </Stack>
  );
}

type SelectPractitionerProps = {
  practitioners: CareTeamMemberCandidate[];
  practitioner?: CareTeamMemberCandidate;
  setPractitioner: React.Dispatch<React.SetStateAction<CareTeamMemberCandidate | undefined>>;
  loading?: boolean;
  onSearch: (query: string) => void;
};

const SelectPractitioner = ({
  practitioners,
  practitioner,
  setPractitioner,
  loading = false,
  onSearch,
}: SelectPractitionerProps): JSX.Element => {
  const combobox = useCombobox();

  const data = useMemo(
    () =>
      practitioners.map((c) => ({
        ...c,
        practitionerRole: c.role,
        value: c.practitionerId,
        label: c.name,
      })),
    [practitioners],
  );

  const [value, setValue] = useState<CareTeamMemberCandidate | undefined>(practitioner);
  const [search, setSearch] = useState('');

  const debouncedSearch = useCallback(
    debounce((query: string) => {
      onSearch(query);
    }, 300),
    [onSearch],
  );

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={(val) => {
        const p = practitioners.find((n) => n.practitionerId === val);
        setValue(p);
        setSearch(p?.name || '');
        setPractitioner(p);
        combobox.closeDropdown();
      }}
    >
      <Combobox.Target>
        <InputBase
          required
          label="Practitioner"
          rightSection={<Combobox.Chevron />}
          rightSectionPointerEvents="none"
          value={search}
          onChange={(event) => {
            const _val = event.currentTarget.value;
            setSearch(_val);

            combobox.openDropdown();
            debouncedSearch(_val);

            combobox.updateSelectedOptionIndex();
          }}
          onBlur={() => {
            combobox.closeDropdown();
            setSearch(value?.name || '');
          }}
          placeholder="Search for a practitioner"
        />
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>
          {loading && (
            <Combobox.Option value="loading" disabled>
              <Text>Loading...</Text>
            </Combobox.Option>
          )}
          {!loading && data.length > 0
            ? data.map((item) => (
                <Combobox.Option
                  value={item.practitionerId}
                  key={item.practitionerId}
                  selected={item.practitionerId === practitioner?.practitionerId}
                >
                  <Text>{item.name}</Text>
                  {item.email && <Text c="gray">{item.email}</Text>}
                </Combobox.Option>
              ))
            : !loading && <Combobox.Empty>Nothing found</Combobox.Empty>}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
