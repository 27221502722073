import React, { useEffect, useState } from 'react';
import { BHTOCTaskProgress, bhTOCTaskProgresses } from 'const-utils/codeSystems/ImaginePediatrics';
import { Box, Stepper, Title } from '@mantine/core';
import { useMedplum } from '@medplum/react';
import { CommunicationRequest } from '@medplum/fhirtypes';
import { System } from 'const-utils';

type TaskProgressProps = {
  communicationRequestId: string | null;
};
export const TaskProgress = ({ communicationRequestId }: TaskProgressProps) => {
  const medplum = useMedplum();
  const [communicationRequest, setCommunicationRequest] = useState<CommunicationRequest | null>(null);
  useEffect(() => {
    if (communicationRequestId) {
      const fetchCommunicationRequest = async () => {
        const communicationRequest = await medplum.readResource('CommunicationRequest', communicationRequestId);
        setCommunicationRequest(communicationRequest);
      };

      fetchCommunicationRequest();
    }
  }, [communicationRequestId, medplum]);

  // Get progress status code from statusReason coding
  const getOutreachStatusCode = (): string | undefined => {
    return communicationRequest?.statusReason?.coding?.find((coding) => coding.system === System.BHTOCTaskProgress)
      ?.code;
  };

  const tocStatus = (getOutreachStatusCode() as BHTOCTaskProgress) || BHTOCTaskProgress.NotStarted;

  // Determine stepper state based on status
  const getStepperState = () => {
    if (tocStatus === BHTOCTaskProgress.NotStarted) {
      return 0;
    }
    if (tocStatus === BHTOCTaskProgress.CaseComplete) {
      return 2;
    }

    return 1;
  };

  // Get the status description for "In Progress" state
  const getStatusDescription = () => {
    if (tocStatus !== BHTOCTaskProgress.NotStarted && tocStatus !== BHTOCTaskProgress.CaseComplete) {
      return bhTOCTaskProgresses[tocStatus];
    }
    return undefined;
  };

  return (
    <Box mb="xl" w="60%" p="md">
      <Title size={18} mb="md">
        TOC task progress
      </Title>
      <Stepper active={getStepperState()} size="sm">
        <Stepper.Step label="Not started" />
        <Stepper.Step label="In progress" description={getStatusDescription()} />
        <Stepper.Step
          label="Complete"
          description={tocStatus === BHTOCTaskProgress.CaseComplete ? 'Case complete' : undefined}
        />
      </Stepper>
    </Box>
  );
};
