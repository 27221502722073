import React from 'react';
import { Group, Breadcrumbs, Anchor, Button, Text } from '@mantine/core';
import { IconFileExport, IconHomePlus } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { useAddPostalCodesModal } from './AddPostalCodesModal/AddPostalCodesModalContext';
import { Coding } from '@medplum/fhirtypes';

interface PodMappingHeaderProps {
  podName: string;
  postalCoding: Coding[];
}

export const PodMappingHeader = ({ podName, postalCoding }: PodMappingHeaderProps): JSX.Element => {
  const navigate = useNavigate();
  const { open } = useAddPostalCodesModal();

  const downloadPostalCodes = () => {
    const codesOnly = postalCoding.map((code) => code.code);
    const csvContent = codesOnly.map((code) => `"${code}"`).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'postal_codes.csv';
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <Group align="center" justify="space-between" mb="md">
      <Breadcrumbs>
        <Anchor underline="always" onClick={() => navigate('/Admin')}>
          Admin
        </Anchor>
        <Anchor underline="always" onClick={() => navigate('/Admin/EngagementPods')}>
          Engagement pods
        </Anchor>
        <Anchor underline="never">{podName}</Anchor>
      </Breadcrumbs>
      <Group>
        <Button variant="outline" onClick={downloadPostalCodes}>
          <IconFileExport stroke={1.5} size="16" />
          <Text ml="xs">Export postal codes</Text>
        </Button>
        <Button onClick={() => open()}>
          <IconHomePlus stroke={1.5} size="16" />
          <Text ml="xs">Add postal codes</Text>
        </Button>
      </Group>
    </Group>
  );
};
