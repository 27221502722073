import React from 'react';
import { Alert, Box, Radio, RadioGroup, Stack, Text } from '@mantine/core';
import { formatAddress } from '@medplum/core';
import { IconAlertCircle } from '@tabler/icons-react';
import { getName } from 'imagine-dsl/utils/patient';
import { formatPhoneString } from 'imagine-dsl/utils/strings';
import { uniqBy } from 'lodash';
import { useMaybeExistingContacts } from './MaybeExistingContactContext';
import { CONTACT_TYPE_OTHER, RELATIONSHIP_OTHER } from '../shared/constants';
import { ContactRelationshipDefinitionForm } from '../shared/ContactRelationshipDefinition';

export const MaybeExistingContactForm = (): React.ReactNode => {
  const {
    setSelected,
    selected,
    setRelationship,
    relationship,
    setCustomRelationship,
    setIsOtherRelationship,
    isOtherRelationship,
    setContactType,
    contactType,
    setIsOtherContactType,
    setCustomContactType,
    isOtherContactType,
    candidates,
    key,
  } = useMaybeExistingContacts();

  if (!candidates?.length) {
    return null;
  }

  const title = candidates.length > 1 ? 'Existing contacts' : 'Existing contact';
  const message =
    candidates.length > 1
      ? 'Multiple existing contacts with matching phone number(s). Please review and select the appropriate option.'
      : 'A contact with matching phone number(s) already exists. Please review and select the appropriate option.';

  const handleCustomContactTypeChange = (value: string) => {
    setCustomContactType(value);
  };
  const handleCustomRelationshipTypeChange = (value: string) => {
    setCustomRelationship(value);
  };

  return (
    <Alert icon={<IconAlertCircle />} variant="outline" color="orange" my="lg" title={title}>
      <Text mb="md">{message}</Text>
      <Stack>
        <RadioGroup
          key={key}
          variant="vertical"
          onChange={(id) => setSelected(candidates.find((c) => c.id === id) ?? null)}
          defaultValue={selected === null ? 'none' : selected?.id}
        >
          {candidates.map((maybeExistingContact) => {
            const label = (
              <Box style={{ fontSize: '1.5rem' }}>
                <Text fw="bold">{getName(maybeExistingContact)}</Text>
                {uniqBy(maybeExistingContact.telecom ?? [], (t) => formatPhoneString(t.value)).map((telecom, i) => (
                  <Text key={i}>
                    {formatPhoneString(telecom.value)}
                    {telecom.use && (
                      <Text span c="dimmed">
                        {' '}
                        {telecom.use}
                      </Text>
                    )}
                  </Text>
                ))}
                {maybeExistingContact.address?.[0] && (
                  <Text c="dimmed">{formatAddress(maybeExistingContact.address?.[0])}</Text>
                )}
              </Box>
            );

            return <Radio key={maybeExistingContact.id} mb="sm" label={label} value={maybeExistingContact.id} />;
          })}
          <Radio key="none" mb="sm" value="none" label="None apply - continue with creating new contact" />
        </RadioGroup>
      </Stack>
      {selected && (
        <ContactRelationshipDefinitionForm
          patientName={''}
          selectedContactType={contactType}
          onSelectContactType={(item) => {
            setContactType(item);
            setIsOtherContactType(item.code === CONTACT_TYPE_OTHER);
          }}
          isOtherContactType={isOtherContactType}
          onSelectCustomContactType={handleCustomContactTypeChange}
          selectedRelationship={relationship}
          onSelectRelationship={(item) => {
            setRelationship(item);
            setIsOtherRelationship(item.code === RELATIONSHIP_OTHER);
          }}
          onSelectCustomRelationship={handleCustomRelationshipTypeChange}
          isOtherRelationship={isOtherRelationship}
        />
      )}
    </Alert>
  );
};
