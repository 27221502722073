import { MedplumClient, parseReference, resolveId } from '@medplum/core';
import { Consent } from '@medplum/fhirtypes';
import { HL7System, LOINCSystem, LOINCSystemCodes, System } from 'const-utils';
import {
  ConsentCode,
  ConsentType,
  LegacyConsentIdentifier,
  QuestionnaireType,
} from 'const-utils/codeSystems/ImaginePediatrics';
import { getMarketKey } from './types/organization';
import { ISOStates } from 'const-utils/codeSystems/ISO';

const PATIENT_CONSENTS: QuestionnaireType[] = [
  QuestionnaireType.ConsentToTreat,
  QuestionnaireType.ReleaseOfInformation,
];

const CAREGIVER_CONSENTS: QuestionnaireType[] = [QuestionnaireType.EndUserLicenseAgreement];

export const getRequiredConsents = (type: ConsentType, market?: string | undefined): QuestionnaireType[] => {
  if (type === ConsentType.PatientConsent) {
    if (market === 'US-NY') {
      return [...PATIENT_CONSENTS, QuestionnaireType.HixnyConsent];
    }
    return PATIENT_CONSENTS;
  }

  return CAREGIVER_CONSENTS;
};

export const isLegacyConsentWithDocRef = async (
  medplum: MedplumClient,
  consent: Consent,
  consentType: QuestionnaireType,
): Promise<boolean> => {
  if (!consent.sourceReference) {
    return false;
  }

  const legacyIdentifier = ConsentCode[consentType];

  if (!legacyIdentifier) {
    return false;
  }

  const consentDocRef = await medplum.searchOne('DocumentReference', {
    status: 'current',
    type: legacyIdentifier,
  });

  if (resolveId(consent.sourceReference) === consentDocRef?.id) {
    return true;
  }

  return false;
};

export const isLegacyConsentWithPdf = (consent: Consent, consentType: QuestionnaireType): boolean => {
  const consentIdentifier = consent.identifier?.find((identifier) => identifier.system === System.Consent);

  if (!consentIdentifier) {
    return false;
  }

  const legacyIdentifier = LegacyConsentIdentifier[consentType];
  if (!legacyIdentifier) {
    return false;
  }

  if (consentIdentifier.value?.includes(legacyIdentifier)) {
    return true;
  }

  return false;
};

export const isAnyLegacyConsent = async (medplum: MedplumClient, consent: Consent, consentType: QuestionnaireType) => {
  if (
    (await isLegacyConsentWithDocRef(medplum, consent, consentType)) ||
    isLegacyConsentWithPdf(consent, consentType)
  ) {
    return true;
  }

  return false;
};

export const doesConsentExist = async (medplum: MedplumClient, consents: Consent[], consentType: QuestionnaireType) => {
  for (const consent of consents) {
    const legacyConsentExists = await isAnyLegacyConsent(medplum, consent, consentType);
    const currentConsentExists = consent.meta?.tag?.some((tag) => tag.code === consentType) ?? false;

    if (legacyConsentExists || currentConsentExists) {
      return true;
    }
  }
  return false;
};

export const getJurisdiction = async (
  medplum: MedplumClient,
  patientId: string,
): Promise<{ code: string; display: string } | undefined> => {
  const patient = await medplum.readResource('Patient', patientId);
  const managingOrganization = await medplum.readResource(
    'Organization',
    parseReference(patient.managingOrganization)?.[1],
  );
  const marketKey = getMarketKey(managingOrganization);

  if (!marketKey) {
    throw new Error('Market not found for organization');
  }

  const jurisdiction = marketKey ? ISOStates[marketKey] : undefined;

  return jurisdiction;
};

export const generateDraftConsent = (
  medplum: MedplumClient,
  id: string,
  consentCode: QuestionnaireType,
  jurisdiction?: { code: string; display: string },
): void => {
  const newConsent: Consent = {
    resourceType: 'Consent',
    status: 'draft',
    patient: { reference: `Patient/${id}` },
    scope: {
      coding: [
        {
          system: HL7System.ConsentScope,
          code: 'treatment',
          display: 'Treatment',
        },
      ],
    },
    meta: {
      tag: [
        {
          system: System.ConsentType,
          code: consentCode,
          display: ConsentCode[consentCode],
        },
        {
          system: System.ConsentSource,
          code: 'questionnaire',
          display: 'questionnaire',
        },
        ...(jurisdiction
          ? [
              {
                system: System.ConsentJurisdiction,
                code: jurisdiction.code,
                display: jurisdiction.display,
              },
            ]
          : []),
      ],
    },
    // This is a workaround for a medplum issue.
    policyRule: {
      coding: [
        {
          code: 'None',
          display: 'None',
        },
      ],
    },
    category: [
      {
        coding: [
          {
            system: LOINCSystem,
            code: LOINCSystemCodes.PatientConsent,
            display: 'Patient Consent',
          },
        ],
      },
    ],
  };

  medplum.createResource(newConsent);
};

export const getConsentSignature = (consent: Consent): string | undefined => {
  return consent.extension?.find((ext) => ext.url === System.CaregiverSignature)?.valueString;
};

export const getConsentSignerContactType = (consent: Consent): string | undefined => {
  return consent.performer?.at(0)?.extension?.find((ext) => ext.url === System.ContactType)?.valueString;
};

export const getConsentSignerRelationship = (consent: Consent): string | undefined => {
  return consent.performer?.at(0)?.extension?.find((ext) => ext.url === System.ContactRelationship)?.valueString;
};
