import React from 'react';
import { Box, Paper, Text, Group } from '@mantine/core';
import { ExtendedRelatedPerson } from '@/components/patientProfile/handlers/patientContactForm';
import { IconCircleCheck, IconCircleX } from '@tabler/icons-react';
import { CaregiverDevices } from '../devices/CaregiverDevices';
import { getSmsConsent } from 'imagine-dsl/utils/patient';

interface PreferencesAndSettingsProps {
  contact: ExtendedRelatedPerson;
}

export function PreferencesAndSettings({ contact }: PreferencesAndSettingsProps) {
  const caregiver = contact?.PatientList?.[0];
  const smsOptIn = getSmsConsent(caregiver);

  return (
    <Box>
      <Text fw={700} mb="md" c="imagine-green">
        Preferences & settings
      </Text>
      <Group grow align="flex-start">
        <Paper withBorder p="md" radius="md">
          <Text size="sm" fw={700} mb="xs">
            SMS preferences
          </Text>
          <Box>
            <Group>
              <Text size="sm">SMS status:</Text>
              <Group gap={6}>
                {smsOptIn ? <IconCircleCheck size={16} color="green" /> : <IconCircleX size={16} color="red" />}
                <Text size="sm">{smsOptIn ? 'Opted in' : 'Opted out'}</Text>
              </Group>
            </Group>
          </Box>
        </Paper>

        <Paper withBorder p="md" radius="md">
          <Text size="sm" fw={700} mb="md">
            Device settings
          </Text>
          <CaregiverDevices caregiverId={contact?.PatientList?.[0]?.id ?? ''} />
        </Paper>
      </Group>
    </Box>
  );
}
